import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Article,
  Assessment,
  Assignment,
  ChevronRight,
  Close,
  ContentCopy,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  ExpandMore,
  Group,
  Home,
  Info,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
  Troubleshoot,
  VoiceChat,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import updatePrompt from 'redux/actions/Common/updatePrompt';
import createNewPrompt from 'redux/actions/Common/createNewPrompt';
import deletePrompt from 'redux/actions/Common/deletePrompt';
import getPracticeCallLinks from 'redux/actions/Common/getPracticeCallLinks';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import parse from 'html-react-parser';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PracticeCallLinks = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [currentTab, setCurrentTab] = useState('');

  const {
    links,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allPracticeLinks);

  const [selectedConversation, setSelectedConversation] = useState(null);
  const [viewType, setViewType] = useState('list');
  const [initialPromptExpanded, setInitialPromptExpanded] = useState(false);

  const chatsScrollview = useRef(null);

  const [currentParam, setCurrentParam] = useState('transcript');

  const scrollDivRef = useRef(null);
  const scrollDiv1Ref = useRef(null);
  const [currentMethod, setCurrentMethod] = useState('meddpic');
  const [showPlaybook, setShowPlaybook] = useState(false);
  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    false,
  );

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        links &&
        links.length % 25 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(
        getPracticeCallLinks(25, lastTime, (data) => {
          // setLinks(data || []);
        }),
      );
    }
  };

  useEffect(() => {
    if (
      selectedConversation?.analysis?.fillerInfo?.fillers &&
      Object.keys(selectedConversation?.analysis?.fillerInfo?.fillers) &&
      Object.keys(selectedConversation?.analysis?.fillerInfo?.fillers).length >
        0
    ) {
      setScriptReadingFillerWords(
        Object.keys(selectedConversation?.analysis?.fillerInfo?.fillers),
      );
    }
    if (
      selectedConversation?.analysis?.repetitionInfo?.repetitions &&
      Object.keys(
        selectedConversation?.analysis?.repetitionInfo?.repetitions,
      ) &&
      Object.keys(selectedConversation?.analysis?.repetitionInfo?.repetitions)
        .length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(
          selectedConversation?.analysis?.repetitionInfo?.repetitions,
        ),
      );
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  useEffect(() => {
    dispatch(
      getPracticeCallLinks(25, null, (data) => {
        // setLinks(data || []);
      }),
    );
  }, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
          gap={2}
          alignItems={'start'}
        >
          {viewType !== 'list' ? (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => {
                setSelectedConversation(null);
                setViewType('list');
              }}
              color="primary"
              size="small"
              sx={{
                whiteSpace: 'nowrap',
                minWidth: 100,
              }}
            >
              Go Back
            </Button>
          ) : null}
          {viewType === 'list' ? (
            <TableContainer
              component={Paper}
              sx={{
                minHeight: 'calc(100vh - 120px)',
                maxHeight: 'calc(100vh - 120px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                },
              }}
              onScroll={handleListScroll}
            >
              <Table
                sx={{ minWidth: 750 }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        ID
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                        align="center"
                      >
                        DATE
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant={'caption'}
                        fontWeight={700}
                        sx={{ textTransform: 'uppercase' }}
                        align="center"
                      >
                        ACTIONS
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {links.map((link, i) => (
                    <TableRow
                      key={i}
                      // sx={{
                      //   '&:last-child td, &:last-child th': {
                      //     border: 0,
                      //   },
                      // }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant={'subtitle2'} fontWeight={700}>
                          {link?.id}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant={'subtitle2'} fontWeight={700}>
                          {link?.userIdOrEmail}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          // fontWeight={700}
                          // sx={{
                          //   color: theme.palette.success.main,
                          // }}
                        >
                          {moment(link?.createdOn).format('DD MMMM YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          <Tooltip title="Copy Link" arrow placement="top">
                            <ContentCopy
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={async () => {
                                try {
                                  await navigator.clipboard.writeText(
                                    `${actionCodeBaseUrl}call/practice?tk=${link?.id}&pc=${link?.pass}`,
                                  );
                                  alert('Link Copied to clipboard');
                                } catch (err) {
                                  alert('Failed to copy link');
                                  console.error('Failed to copy: ', err);
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              link?.customFields?.conversation
                                ? 'View Conversation Transcript'
                                : 'Conversation transcript not available'
                            }
                          >
                            <VoiceChat
                              sx={{
                                cursor: link?.customFields?.conversation
                                  ? 'pointer'
                                  : 'default',
                                color: link?.customFields?.conversation
                                  ? useTheme().palette.primary.main
                                  : useTheme().palette.text.secondary,
                              }}
                              onClick={async () => {
                                if (link?.customFields?.conversation) {
                                  setSelectedConversation(
                                    link?.customFields?.conversation,
                                  );
                                  setViewType('conversation');
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              link?.customFields?.conversation?.analysis
                                ? 'View Report'
                                : 'Report is not available'
                            }
                          >
                            <Assessment
                              sx={{
                                cursor: link?.customFields?.conversation
                                  ?.analysis
                                  ? 'pointer'
                                  : 'default',
                                color: link?.customFields?.conversation
                                  ?.analysis
                                  ? useTheme().palette.primary.main
                                  : useTheme().palette.text.secondary,
                              }}
                              onClick={() => {
                                if (
                                  link?.customFields?.conversation?.analysis
                                ) {
                                  setSelectedConversation(
                                    link?.customFields?.conversation,
                                  );
                                  setViewType('report');
                                }
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell
                      variant="footer"
                      colSpan={6}
                      sx={{
                        padding: 0,
                      }}
                    >
                      {fetchingPagination ? (
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 1,
                            textAlign: 'center',
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            // type="submit"
                            color="primary"
                            onClick={handleListScroll}
                            // className="searchSettings"
                            disabled
                            // fullWidth
                          >
                            <Typography color="textPrimary">
                              Loading more links ...{' '}
                            </Typography>
                            <CircularProgress size={20} />
                          </Button>
                        </Box>
                      ) : emptyList === false && links.length >= 10 ? (
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 1,
                            textAlign: 'center',
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            // type="submit"
                            // fullWidth
                            color="primary"
                            onClick={(e) => handleListScroll(e, true)}
                            // className="searchSettings"
                          >
                            Load More
                          </Button>
                        </Box>
                      ) : null}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : viewType === 'conversation' ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 180px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 180px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                // background: '#d3d3d3',
                paddingBottom: isXs || isSm ? '120px' : '20px',
              }}
              ref={chatsScrollview}
            >
              {selectedConversation?.messages?.map((msg, index) => (
                <Box
                  key={msg?.id}
                  sx={{
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    borderRadius: 1,
                    maxWidth: index === 0 ? '100%' : '80%',
                    alignSelf:
                      msg?.gptMessage?.role === 'user' ? 'end' : 'start',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    background: index === 0 ? '#d3d3d3' : 'transparent',
                  }}
                >
                  {index === 0 ? (
                    <Accordion
                      expanded={initialPromptExpanded}
                      onChange={() => setInitialPromptExpanded((prev) => !prev)}
                      elevation={0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="submission-content"
                        id="submission-header"
                        sx={{
                          height: '30px',
                          background: '#d3d3d3',
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          mb={1}
                          width={'100%'}
                        >
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            Initial Prompt
                          </Typography>
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            {moment(msg?.createdOn).format(
                              'DD MMMM YYYY hh:mm:ss A',
                            )}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          color={index === 0 ? '#343a40' : 'text.primary'}
                        >
                          {msg?.gptMessage?.content}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mb={1}
                      >
                        {msg?.gptMessage?.role === 'user' ? (
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            You
                          </Typography>
                        ) : index !== 0 ? (
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            System
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            Initial Prompt
                          </Typography>
                        )}
                        {index === 0 ? null : (
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            {moment(msg?.createdOn).format(
                              'DD MMMM YYYY hh:mm:ss A',
                            )}
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        color={index === 0 ? '#343a40' : 'text.primary'}
                      >
                        {msg?.gptMessage?.content}
                      </Typography>
                      {msg?.customFields?.gcpAudioLink ||
                      msg?.customFields?.elevenLabsAudioLink ? (
                        <>
                          <Divider />
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                            flexDirection={{
                              xs: 'column',
                              sm: 'column',
                              md: 'row',
                            }}
                          >
                            {msg?.customFields?.elevenLabsAudioLink ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  Eleven Labs
                                </Typography>
                                {msg?.customFields?.elevenLabsAudioLink ? (
                                  <audio
                                    // autoPlay={
                                    //   index ===
                                    //     selectedConversation?.messages?.length -
                                    //       1 &&
                                    //   selectedConversation?.audioService?.includes(
                                    //     'ELEVEN_LABS',
                                    //   ) &&
                                    //   autoPlay
                                    // }
                                    preload="true"
                                    controls
                                    src={msg?.customFields?.elevenLabsAudioLink}
                                    style={{
                                      maxWidth: '220px',
                                      padding: '10px 20px',
                                    }}
                                  />
                                ) : null}
                              </Box>
                            ) : null}
                            {msg?.customFields?.gcpAudioLink ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  Google
                                </Typography>
                                {msg?.customFields?.gcpAudioLink ? (
                                  <audio
                                    // autoPlay={
                                    //   index ===
                                    //     selectedConversation?.messages?.length -
                                    //       1 &&
                                    //   !selectedConversation?.audioService?.includes(
                                    //     'ELEVEN_LABS',
                                    //   ) &&
                                    //   autoPlay
                                    // }
                                    preload="true"
                                    controls
                                    src={msg?.customFields?.gcpAudioLink}
                                    style={{
                                      maxWidth: '220px',
                                      padding: '10px 20px',
                                    }}
                                  />
                                ) : null}
                              </Box>
                            ) : null}
                          </Box>
                        </>
                      ) : null}
                    </>
                  )}
                </Box>
              ))}
              <Box></Box>
              {selectedConversation?.customFields?.audioLink ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Typography variant="body1" color={'text.secondary'}>
                    Merged Audio Conversation
                  </Typography>
                  {selectedConversation?.customFields?.audioLink ? (
                    <audio
                      preload="true"
                      controls
                      src={selectedConversation?.customFields?.audioLink}
                      style={{
                        maxWidth: '220px',
                        padding: '10px 20px',
                      }}
                    />
                  ) : null}
                </Box>
              ) : null}
            </Box>
          ) : viewType === 'report' ? (
            <Box>
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Box
                  // gap={2}
                  display={'flex'}
                  // p={2}
                  sx={{
                    border: '1px solid #d3d3d3',
                    height: 'auto',
                    borderRadius: 2,
                  }}
                >
                  <Box
                    flex={1}
                    display={'flex'}
                    flexDirection={'column'}
                    p={3}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {selectedConversation &&
                    selectedConversation?.customFields &&
                    selectedConversation?.customFields?.audioLink ? (
                      <video
                        src={selectedConversation?.customFields?.audioLink}
                        controls
                        style={{
                          objectFit: 'contain',
                          backgroundColor: 'rgba(0,0,0,1)',
                          width: '100%',
                          height: '100%',
                          borderRadius: 5,
                          // borderTopLeftRadius: 8,
                          // borderBottomLeftRadius: 8,
                        }}
                        onError={(e) => {
                          console.log(e);
                        }}
                        controlsList="nofullscreen nodownload"
                        disablePictureInPicture
                      ></video>
                    ) : null}
                  </Box>
                  <Box
                    flex={3}
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                      borderLeft: '1px solid #d3d3d3',
                    }}
                  >
                    <Box
                      display={'flex'}
                      // alignItems={'center'}
                      // p={1}
                      // pl={1}
                      justifyContent={'space-between'}
                      sx={{
                        borderBottom: '1px solid #d3d3d3',
                      }}
                    >
                      <Tabs
                        value={currentParam}
                        onChange={(e, newValue) => setCurrentParam(newValue)}
                        aria-label="Parameter Tabs"
                        orientation={'horizontal'}
                        // indicatorColor={theme.palette.background.default}
                        variant="scrollable"
                        // scrollButtons={!isMd}
                        allowScrollButtonsMobile
                      >
                        <Tab
                          value={'transcript'}
                          label={
                            <Typography variant="body1">Transcript</Typography>
                          }
                          id={`simple-tab-${0}`}
                          aria-controls={`simple-tabpanel-${0}`}
                          sx={{}}
                        />
                        <Tab
                          value={'fillers'}
                          label={
                            <Typography variant="body1">
                              Filler Words
                            </Typography>
                          }
                          id={`simple-tab-${0}`}
                          aria-controls={`simple-tabpanel-${0}`}
                          sx={{}}
                        />
                        <Tab
                          value={'repeatitions'}
                          label={
                            <Typography variant="body1">
                              Repeated Words
                            </Typography>
                          }
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                        <Tab
                          value={'pace'}
                          label={<Typography variant="body1">Pace</Typography>}
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                      </Tabs>
                    </Box>
                    {currentParam === 'transcript' ? (
                      <Box display={'flex'}>
                        <Box
                          ref={scrollDivRef}
                          sx={{
                            flex: 2,
                            padding: 2,
                            // border: '1px solid #d3d3d3',
                            borderRadius: 2,
                            mark: {
                              backgroundColor: theme.palette.primary.main,
                              color: 'white',
                              padding: '2px 6px',
                            },
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            minHeight: '270px',
                            maxHeight: '270px',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                              // outline: '1px solid slategrey',
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {selectedConversation?.transcript}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            flex: 1,
                            borderLeft: '1px solid #d3d3d3',
                            padding: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: 250,
                              position: 'relative',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="caption"
                                color={'text.secondary'}
                                // textAlign={'center'}
                              >
                                Call Participation
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                {Object.keys(
                                  selectedConversation?.analysis
                                    ?.speakProportion,
                                )
                                  ?.filter((sp) => sp !== 'silence')
                                  ?.map((sp, index) => (
                                    <Box
                                      key={sp}
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                    >
                                      <Box
                                        sx={{
                                          width: 12,
                                          height: 12,
                                          background:
                                            sp === 'spk_0'
                                              ? '#79155B'
                                              : sp === 'spk_1'
                                              ? '#468B97'
                                              : sp === 'spk_2'
                                              ? '#EF6262'
                                              : sp === 'spk_3'
                                              ? '#F3AA60'
                                              : sp === 'spk_4'
                                              ? '#F1C93B'
                                              : sp === 'spk_5'
                                              ? '#461959'
                                              : '#999',
                                        }}
                                      ></Box>
                                      <Typography
                                        variant="caption"
                                        color={'text.secondary'}
                                      >
                                        {selectedConversation?.speakers[sp] ||
                                          'silence'}
                                      </Typography>
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                            <Pie
                              options={{
                                responsive: true,
                                layout: {
                                  padding: 5,
                                },
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  tooltip: {
                                    enabled: true,
                                    callbacks: {
                                      // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                                      label: (yDatapoint) => {
                                        // console.log(yDatapoint);
                                        return yDatapoint?.label;
                                      },
                                    },
                                  },
                                  title: {
                                    display: false,
                                    text: 'Call Participation',
                                  },
                                  datalabels: {
                                    color: 'white',
                                    font: {
                                      weight: 'bold',
                                      size: 16,
                                    },
                                    formatter: function(value, context) {
                                      return `${value}%`;
                                    },
                                  },
                                },
                                elements: {
                                  arc: {
                                    borderRadius: 6,
                                    borderSkipped: false,
                                  },
                                },
                                scales: {
                                  y: {
                                    display: false,
                                  },
                                  x: {
                                    display: false,
                                  },
                                },
                              }}
                              data={{
                                labels:
                                  (
                                    selectedConversation?.analysis
                                      ?.speakProportion &&
                                    Object.keys(
                                      selectedConversation?.analysis
                                        ?.speakProportion,
                                    )
                                  )?.map(
                                    (key) =>
                                      selectedConversation?.speakers[key] ||
                                      'silence',
                                  ) || [],
                                datasets: [
                                  {
                                    animation: false,
                                    label: '',
                                    data: Object.keys(
                                      selectedConversation?.analysis
                                        ?.speakProportion || {},
                                    )
                                      ?.filter((key) => key !== 'silence')
                                      ?.map(
                                        (key) =>
                                          selectedConversation?.analysis
                                            ?.speakProportion[key],
                                      ),
                                    backgroundColor: [
                                      ...[
                                        '#79155B',
                                        '#468B97',
                                        '#EF6262',
                                        '#F3AA60',
                                        '#F1C93B',
                                        '#461959',
                                      ].slice(
                                        0,
                                        Object.values(
                                          selectedConversation?.analysis
                                            ?.speakProportion || {},
                                        )?.length - 1,
                                      ),
                                      '#d3d3d3',
                                    ],
                                  },
                                ],
                              }}
                            />
                            <Tooltip title="Percentages may not add up to 100 as a portion of the call recording corresponds to silence, when no one was speaking.">
                              <Info
                                sx={{
                                  color: '#999',
                                  position: 'absolute',
                                  top: 10,
                                  right: -10,
                                  cursor: 'pointer',
                                  fontSize: 18,
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        ref={scrollDivRef}
                        sx={{
                          padding: 2,
                          // border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          mark: {
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            padding: '2px 6px',
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                          minHeight: '270px',
                          maxHeight: '270px',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                        }}
                      >
                        {currentParam === 'fillers' ? (
                          <>
                            <Box
                              display={'flex'}
                              gap={2}
                              sx={{
                                flexWrap: 'wrap',
                              }}
                            >
                              {selectedConversation?.analysis?.fillerInfo
                                ?.fillers &&
                              Object.keys(
                                selectedConversation?.analysis?.fillerInfo
                                  ?.fillers,
                              ) &&
                              Array.isArray(
                                Object.keys(
                                  selectedConversation?.analysis?.fillerInfo
                                    ?.fillers,
                                ),
                              ) &&
                              Object.keys(
                                selectedConversation?.analysis?.fillerInfo
                                  ?.fillers,
                              )?.length > 0 ? (
                                Object.keys(
                                  selectedConversation?.analysis?.fillerInfo
                                    ?.fillers,
                                )
                                  ?.sort((a, b) => {
                                    if (
                                      selectedConversation?.analysis?.fillerInfo
                                        ?.fillers?.[a] <
                                      selectedConversation?.analysis?.fillerInfo
                                        ?.fillers?.[b]
                                    ) {
                                      return 1;
                                    } else if (
                                      selectedConversation?.analysis?.fillerInfo
                                        ?.fillers?.[a] >
                                      selectedConversation?.analysis?.fillerInfo
                                        ?.fillers?.[b]
                                    ) {
                                      return -1;
                                    }
                                    return 0;
                                  })
                                  ?.map((filler, index) => (
                                    <Typography key={index} sx={{}}>
                                      {filler}{' '}
                                      <Chip
                                        label={
                                          selectedConversation?.analysis
                                            ?.fillerInfo?.fillers?.[filler]
                                        }
                                        size="small"
                                        color="primary"
                                      ></Chip>
                                    </Typography>
                                  ))
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No filler words
                                </Typography>
                              )}
                            </Box>
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {selectedConversation?.analysis?.fillerInfo
                                ?.transcript
                                ? parse(
                                    selectedConversation?.analysis?.fillerInfo?.transcript?.replace(
                                      fillerWordsRegexp,
                                      (highlight) => {
                                        return `<mark>${highlight
                                          .split('{')
                                          .join('')
                                          .split('}')
                                          .join('')}</mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam === 'repeatitions' ? (
                          <>
                            <Box
                              display={'flex'}
                              gap={2}
                              sx={{
                                flexWrap: 'wrap',
                              }}
                            >
                              {selectedConversation?.analysis?.repetitionInfo
                                ?.repetitions &&
                              Object.keys(
                                selectedConversation?.analysis?.repetitionInfo
                                  ?.repetitions,
                              ) &&
                              Array.isArray(
                                Object.keys(
                                  selectedConversation?.analysis?.repetitionInfo
                                    ?.repetitions,
                                ),
                              ) &&
                              Object.keys(
                                selectedConversation?.analysis?.repetitionInfo
                                  ?.repetitions,
                              )?.length > 0 ? (
                                Object.keys(
                                  selectedConversation?.analysis?.repetitionInfo
                                    ?.repetitions,
                                )
                                  ?.sort((a, b) => {
                                    if (
                                      selectedConversation?.analysis
                                        ?.repetitionInfo?.repetitions?.[a] <
                                      selectedConversation?.analysis
                                        ?.repetitionInfo?.repetitions?.[b]
                                    ) {
                                      return 1;
                                    } else if (
                                      selectedConversation?.analysis
                                        ?.repetitionInfo?.repetitions?.[a] >
                                      selectedConversation?.analysis
                                        ?.repetitionInfo?.repetitions?.[b]
                                    ) {
                                      return -1;
                                    }
                                    return 0;
                                  })
                                  ?.map((filler, index) => (
                                    <Typography key={index} sx={{}}>
                                      {filler}{' '}
                                      <Chip
                                        label={
                                          selectedConversation?.analysis
                                            ?.repetitionInfo?.repetitions?.[
                                            filler
                                          ]
                                        }
                                        size="small"
                                        color="primary"
                                      ></Chip>
                                    </Typography>
                                  ))
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No repeated words
                                </Typography>
                              )}
                            </Box>
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {selectedConversation?.analysis?.repetitionInfo
                                ?.transcript
                                ? parse(
                                    selectedConversation?.analysis?.repetitionInfo?.transcript?.replace(
                                      repeatWordsRegexp,
                                      (highlight) => {
                                        return `<mark>${highlight
                                          .split('{')
                                          .join('')
                                          .split('}')
                                          .join('')}</mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam === 'pace' ? (
                          <Box>
                            <LineGraph
                              height={230}
                              labels={
                                Object.keys(
                                  selectedConversation?.analysis?.paceGraph ||
                                    {},
                                )?.length > 15
                                  ? [
                                      ...new Set(
                                        Object.keys(
                                          selectedConversation?.analysis
                                            ?.paceGraph,
                                        ).filter(
                                          (n) =>
                                            n %
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    selectedConversation
                                                      ?.analysis?.paceGraph,
                                                  )?.length / 30,
                                                )) ===
                                            0,
                                        ),
                                      ),
                                    ]
                                  : Object.keys(
                                      selectedConversation?.analysis
                                        ?.paceGraph || {},
                                    )
                              }
                              data={
                                Object.values(
                                  selectedConversation?.analysis?.paceGraph ||
                                    {},
                                )?.length > 15
                                  ? Object.values(
                                      selectedConversation?.analysis?.paceGraph,
                                    )
                                      .reduce((acc, curr, index) => {
                                        const averageValue =
                                          Object.values(
                                            selectedConversation?.analysis
                                              ?.paceGraph,
                                          )
                                            ?.slice(
                                              index *
                                                (2 +
                                                  Math.floor(
                                                    Object.keys(
                                                      selectedConversation
                                                        ?.analysis?.paceGraph,
                                                    )?.length / 30,
                                                  )),
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    selectedConversation
                                                      ?.analysis?.paceGraph,
                                                  )?.length / 30,
                                                )) *
                                                (index + 1),
                                            )
                                            ?.reduce(
                                              (acc, curr) => acc + curr,
                                              0,
                                            ) /
                                          (2 +
                                            Math.floor(
                                              Object.keys(
                                                selectedConversation?.analysis
                                                  ?.paceGraph,
                                              )?.length / 30,
                                            ));
                                        acc.push(averageValue);
                                        return acc;
                                      }, [])
                                      .slice(
                                        0,
                                        Object.keys(
                                          selectedConversation?.analysis
                                            ?.paceGraph,
                                        ).filter(
                                          (n) =>
                                            n %
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    selectedConversation
                                                      ?.analysis?.paceGraph,
                                                  )?.length / 30,
                                                )) ===
                                            0,
                                        ).length,
                                      )
                                  : Object.values(
                                      selectedConversation?.analysis
                                        ?.paceGraph || {},
                                    )
                              }
                            />
                          </Box>
                        ) : currentParam === 'transcript' ? (
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {selectedConversation?.transcript}
                          </Typography>
                        ) : null}
                      </Box>
                    )}
                  </Box>
                </Box>
                {selectedConversation?.analysis ? (
                  <>
                    <Box
                      sx={{
                        // p: 2,
                        border: '1px solid #d3d3d3',
                        minHeight: 300,
                      }}
                    >
                      <Box display={'flex'} gap={2}>
                        {selectedConversation?.analysis?.scoreStatus ===
                        'completed' ? (
                          <Box
                            flex={1}
                            sx={{
                              padding: 2,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              minHeight: 300,
                            }}
                          >
                            {scoreCalculationInProcess ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                gap={2}
                              >
                                <CircularProgress size={20} />
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  Score generation is in process...
                                </Typography>
                              </Box>
                            ) : (
                              <>
                                <Typography
                                  variant="subtitle1"
                                  color={'text.secondary'}
                                >
                                  Scores
                                </Typography>
                                <Bar
                                  style={{
                                    width: '100%',
                                  }}
                                  options={{
                                    responsive: true,
                                    layout: {
                                      padding: 10,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                        // position: 'top',
                                      },
                                      tooltip: {
                                        enabled: true,
                                      },

                                      title: {
                                        display: false,
                                        text: 'Skills Score',
                                      },
                                      datalabels: {
                                        color: 'white',
                                        font: {
                                          weight: 'bold',
                                          size: 14,
                                        },
                                        // rotation: -90,
                                        formatter: function(value, context) {
                                          return `${value}`;
                                        },
                                      },
                                    },
                                    elements: {
                                      bar: {
                                        borderRadius: 6,
                                        borderSkipped: false,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        beginAtZero: true,
                                        max: 100,
                                      },
                                    },
                                  }}
                                  data={{
                                    labels: [
                                      'Pace',
                                      'Fluency',
                                      'Confidence',
                                      'Enthusiasm',
                                    ],
                                    datasets: [
                                      {
                                        label: 'Score',
                                        data: [
                                          selectedConversation?.analysis
                                            ?.paceScore === undefined
                                            ? '-'
                                            : Math.round(
                                                selectedConversation?.analysis
                                                  ?.paceScore,
                                              ),
                                          selectedConversation?.analysis
                                            ?.fluencyScore === undefined
                                            ? '-'
                                            : Math.round(
                                                selectedConversation?.analysis
                                                  ?.fluencyScore,
                                              ),
                                          selectedConversation?.analysis
                                            ?.confidenceScore === undefined
                                            ? '-'
                                            : Math.round(
                                                selectedConversation?.analysis
                                                  ?.confidenceScore,
                                              ),
                                          selectedConversation?.analysis
                                            ?.enthusiasmScore === undefined
                                            ? '-'
                                            : Math.round(
                                                selectedConversation?.analysis
                                                  ?.enthusiasmScore,
                                              ),
                                        ],
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        barThickness: 20,
                                        pointStyle: 'circle',
                                        fill: true,
                                      },
                                    ],
                                  }}
                                />
                              </>
                            )}
                          </Box>
                        ) : null}
                        <Box
                          flex={3}
                          sx={{
                            borderLeft: '1px solid #d3d3d3',
                          }}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Tabs
                              value={currentMethod}
                              onChange={(e, newValue) =>
                                setCurrentMethod(newValue)
                              }
                              aria-label="Feedback Tabs"
                              orientation={'horizontal'}
                              // indicatorColor={theme.palette.background.default}
                              variant="scrollable"
                              // scrollButtons={!isMd}
                              allowScrollButtonsMobile
                            >
                              {selectedConversation?.analysis?.playbook ? (
                                <Tab
                                  value={'playbook'}
                                  label={
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                    >
                                      <Typography variant="body1">
                                        Playbook
                                      </Typography>
                                    </Box>
                                  }
                                  id={`simple-tab-${5}`}
                                  aria-controls={`simple-tabpanel-${5}`}
                                  sx={{}}
                                />
                              ) : null}
                              {selectedConversation?.analyseMEDDPIC ? (
                                <Tab
                                  value={'meddpic'}
                                  label={
                                    <Typography variant="body1">
                                      MEDDPIC
                                    </Typography>
                                  }
                                  id={`simple-tab-${0}`}
                                  aria-controls={`simple-tabpanel-${0}`}
                                  sx={{}}
                                />
                              ) : null}
                              {selectedConversation?.analyseSPICED ? (
                                <Tab
                                  value={'spiced'}
                                  label={
                                    <Typography variant="body1">
                                      SPICED
                                    </Typography>
                                  }
                                  id={`simple-tab-${5}`}
                                  aria-controls={`simple-tabpanel-${5}`}
                                  sx={{}}
                                />
                              ) : null}
                              {selectedConversation?.analyseSPIN ? (
                                <Tab
                                  value={'spin'}
                                  label={
                                    <Typography variant="body1">
                                      SPIN
                                    </Typography>
                                  }
                                  id={`simple-tab-${5}`}
                                  aria-controls={`simple-tabpanel-${5}`}
                                  sx={{}}
                                />
                              ) : null}
                              {selectedConversation?.analyseBANT ? (
                                <Tab
                                  value={'bant'}
                                  label={
                                    <Typography variant="body1">
                                      BANT
                                    </Typography>
                                  }
                                  id={`simple-tab-${5}`}
                                  aria-controls={`simple-tabpanel-${5}`}
                                  sx={{}}
                                />
                              ) : null}
                              {selectedConversation?.analyseChallenger ? (
                                <Tab
                                  value={'challenger'}
                                  label={
                                    <Typography variant="body1">
                                      CHALLENGER
                                    </Typography>
                                  }
                                  id={`simple-tab-${5}`}
                                  aria-controls={`simple-tabpanel-${5}`}
                                  sx={{}}
                                />
                              ) : null}
                              {selectedConversation?.analysis?.summary ? (
                                <Tab
                                  value={'summary'}
                                  label={
                                    <Typography variant="body1">
                                      Call Summary
                                    </Typography>
                                  }
                                  id={`simple-tab-${5}`}
                                  aria-controls={`simple-tabpanel-${5}`}
                                  sx={{}}
                                />
                              ) : null}
                            </Tabs>
                            {selectedConversation?.playbook &&
                            currentMethod === 'playbook' ? (
                              <Tooltip
                                title="show playbook"
                                placement="left"
                                arrow
                              >
                                <Article
                                  sx={{
                                    cursor: 'pointer',
                                    color: theme.palette.primary.main,
                                    marginRight: 2,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPlaybook((prev) => !prev);
                                  }}
                                />
                              </Tooltip>
                            ) : null}
                          </Box>
                          {selectedConversation?.analysis?.[currentMethod] ? (
                            <Box
                              ref={scrollDiv1Ref}
                              sx={{
                                display: 'flex',
                                gap: 2,
                                borderTop: '1px solid #d3d3d3',

                                minHeight: '300px',
                                maxHeight: '300px',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {
                                  width: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                  webkitBoxShadow:
                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: 2,
                                  // outline: '1px solid slategrey',
                                },
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  padding: 2,
                                  flex: 1,
                                  borderRight: '1px solid #d3d3d3',
                                }}
                              >
                                {
                                  selectedConversation?.analysis?.[
                                    currentMethod
                                  ]
                                }
                              </Typography>
                              {selectedConversation?.playbook &&
                              currentMethod === 'playbook' &&
                              showPlaybook ? (
                                <Drawer
                                  open={showPlaybook}
                                  anchor="right"
                                  onClose={() => setShowPlaybook(false)}
                                >
                                  <Box
                                    p={1}
                                    sx={{
                                      borderBottom: '1px solid #d3d3d3',
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => setShowPlaybook(false)}
                                    >
                                      <ChevronRight sx={{}} />
                                    </IconButton>
                                  </Box>
                                  <Box
                                    p={2}
                                    sx={{
                                      minWidth: 500,
                                      maxWidth: 500,
                                      minHeight: 'calc(100vh - 60px)',
                                      maxHeight: 'calc(100vh - 60px)',
                                      overflowY: 'scroll',
                                      '&::-webkit-scrollbar': {
                                        width: '5px',
                                      },
                                      '&::-webkit-scrollbar-track': {
                                        boxShadow:
                                          'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow:
                                          'inset 0 0 6px rgba(0,0,0,0.00)',
                                      },
                                      '&::-webkit-scrollbar-thumb': {
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        borderRadius: 2,
                                        // outline: '1px solid slategrey',
                                      },
                                      flex: 1,
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color={'text.secondary'}
                                    >
                                      Playbook
                                    </Typography>
                                    <RichTextEditor
                                      value={selectedConversation?.playbook}
                                      onChange={() => {}}
                                      readOnly
                                    />
                                  </Box>
                                </Drawer>
                              ) : null}
                            </Box>
                          ) : (
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              sx={{
                                minHeight: 'calc(100vh - 500px)',
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  padding: 2,
                                }}
                                color={'text.secondary'}
                              >
                                Not available
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      {/* <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fixed>
  );
};

export default PracticeCallLinks;
