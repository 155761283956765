import uuid from 'draft-js/lib/uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const updateContestSection = (contestId, payload, callback, errorCallback) => {
  return async (dispatch) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.put(
          `/cs/competitions/${contestId}/sections`,
          [payload],
          {
            params: {
              'request-id': uuid(),
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(response, 'this is the response');
        if (response.status === 200) {
          console.log('200');
          if (typeof callback === 'function') callback(response?.data);
        } else {
          if (typeof errorCallback === 'function')
            errorCallback(response?.data);
        }
      } catch (e) {
        console.log(e);
        if (typeof errorCallback === 'function')
          errorCallback(e?.response?.data?.message);
      }
      unsubscribe();
    });
  };
};

export default updateContestSection;
