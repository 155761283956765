const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  prompts: [],
  emptyList: null,
  lastTime: null,
};

const getAllPromptsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GET_ALL_PROMPTS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GET_ALL_PROMPTS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GET_ALL_PROMPTS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        prompts: action.payload.prompts,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_PROMPTS_TO_ALL_PROMPTS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        prompts: Array.isArray(action.payload.prompts)
          ? state.prompts
            ? state.prompts.concat(action.payload.prompts)
            : action.payload.prompts
          : state.prompts,
        emptyList:
          Array.isArray(action.payload.prompts) &&
          (action.payload.prompts.length === 0 ||
            action.payload.prompts.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'UPDATE_PROMPTS_AFTER_DELETE': {
      const updatedPrompts = state.prompts.filter(
        (pr) => pr.id !== action.payload.id,
      );
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        prompts: updatedPrompts,
        emptyList: state.emptyList,
        lastTime: state.lastTime,
        error: false,
        errorText: '',
      });
    }
    case 'GET_ALL_PROMPTS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        prompts: [],
        emptyList: false,
        lastTime: '',
      });
    case 'GET_ALL_PROMPTS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllPromptsReducer;
