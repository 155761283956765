import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { ThemeModeToggler } from './components';
import { useDispatch } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import packageJSON from '../../../../../package.json';
import { Typography } from '@mui/material';

const Topbar = ({ onSidebarOpen }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const dispatch = useDispatch();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Hiring Express Admin"
          width={{ xs: 40, md: 40 }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
        {process.env.REACT_APP_TARGET_ENV === 'development' ? (
          <Typography variant="h5" color={'warning.main'}>
            Dev Admin
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box>
          <Typography variant="body2" color={'text.primary'}>
            v{packageJSON.version}
          </Typography>
        </Box>
        <Box marginLeft={3}>
          <ThemeModeToggler />
        </Box>
        <Box marginLeft={3}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              dispatch(
                firebaseLogout(() => {
                  dispatch(firebaseCheckSession());
                }),
              );
            }}
          >
            Logout
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Box>
          <Typography variant="body2" color={'text.primary'}>
            v{packageJSON.version}
          </Typography>
        </Box>
        <Box marginLeft={3}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              dispatch(
                firebaseLogout(() => {
                  dispatch(firebaseCheckSession());
                }),
              );
            }}
          >
            Logout
          </Button>
        </Box>
        <Box marginRight={2} marginLeft={2}>
          <ThemeModeToggler />
        </Box>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
