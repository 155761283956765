import axios from 'utils/axios';

const firebaseSignin = (idToken, callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'FIREBASE_LOGGING_IN' });
    try {
      let response = await axios.get('/us/users/admin/login', {
        params: {
          idToken,
        },
      });
      if (
        response.status === 200 &&
        response.data !== undefined &&
        response?.data?.authenticated
      ) {
        const userInfo = response.data.user;
        try {
          // localStorage.setItem('token', idToken);
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          dispatch({
            type: 'FIREBASE_LOGIN_SUCCESSFUL',
            payload: response.data,
          });
          callback(response.data);
        } catch (e) {
          dispatch({
            type: 'FIREBASE_LOGIN_FAIL',
            payload: 'Unable to login',
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
      } else {
        dispatch({
          type: 'FIREBASE_LOGIN_FAIL',
          payload: 'Unable to login',
        });
        if (typeof errCallback === 'function') {
          errCallback(response?.data?.message);
        }
      }
    } catch (e) {
      console.log(e);
      dispatch({ type: 'FIREBASE_LOGIN_FAIL', payload: 'Unable to Login' });
      if (typeof errCallback === 'function') {
        errCallback(e?.message);
      }
    }
  };
};

export default firebaseSignin;
