import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Close,
  CopyAll,
  Delete,
  Edit,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchemaPlaybook = yup.object({
  companyName: yup
    .string()
    .trim()
    .required('Please enter company name'),
  productDescription: yup
    .string()
    .trim()
    .required('Please enter product description'),
  keyFeatures: yup
    .string()
    .trim()
    .required('Please enter key features of the product or service'),
  secondaryFeatures: yup.string(),
  idealCustomerProfile: yup.string(),
  methodologies: yup.array(),
  customerQualificationQuestions: yup.string(),
  followUpQuestions: yup.string(),
  objectionsAndAnswers: yup.array(),
  expectedCallToAction: yup
    .string()
    .trim()
    .required('Please enter your call to action'),
  competingProducts: yup
    .string()
    .trim()
    .required('Please enter any of the competing product or service'),
});

const ConversationAIUsers = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    users,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allUsers);

  const [selectedUser, setSelectedUser] = useState(null);

  const [designation, setDesignation] = useState('account_admin');
  const [playbook, setPlaybook] = useState('');
  const [playbookChanged, setPlaybookChanged] = useState(false);
  const [companyNameChanged, setCompanyNameChanged] = useState(false);
  const [withPlaybook, setWithPlaybook] = useState(false);

  const initialValuesPlaybook = {
    companyName: '',
    productDescription: '',
    keyFeatures: '',
    secondaryFeatures: '',
    idealCustomerProfile: '',
    methodologies: [],
    customerQualificationQuestions: '',
    followUpQuestions: '',
    objectionsAndAnswers: [
      {
        question: '',
        answer: '',
      },
    ],
    expectedCallToAction: '',
    competingProducts: '',
  };

  const handleSubmit = () => {
    // dispatch(
    //   updatePlaybookForUser(
    //     selectedUser,
    //     {
    //       // playbook: playbook,
    //       organization: companyName,
    //     },
    //     () => {
    //       dispatch(getAllUsers(designation, 15, null, () => {}));
    //       alert('Information Updated!');
    //     },
    //   ),
    // );
  };

  const onSubmitPlaybook = (values) => {
    console.log(values);
    if (withPlaybook) {
      dispatch(
        updatePlaybookForUser(
          selectedUser,
          {
            // playbook: playbook,
            // organization: companyName,
            playbookStructure: {
              bant: formikPlaybook.values.methodologies.includes('bant'),
              companyName: formikPlaybook?.values?.companyName,
              competingProducts: formikPlaybook.values.competingProducts,
              customerQualificationQuestions:
                formikPlaybook.values.customerQualificationQuestions,
              customerQuestions: formikPlaybook.values.objectionsAndAnswers,
              expectedCallToAction: formikPlaybook.values.expectedCallToAction,
              idealCustomerProfile: formikPlaybook.values.idealCustomerProfile,
              meddpic: formikPlaybook.values.methodologies.includes('meddpic'),
              playbook: formikPlaybook.values.methodologies.includes(
                'playbook',
              ),
              // playbookText: '',
              productDescription: formikPlaybook.values.productDescription,
              productKeyFeatures: formikPlaybook.values.keyFeatures,
              secondaryFeatures: formikPlaybook.values.secondaryFeatures,
              spin: formikPlaybook.values.methodologies.includes('spin'),
              star: formikPlaybook.values.methodologies.includes('star'),
              followUpQuestions: formikPlaybook.values.followUpQuestions,
            },
          },
          () => {
            dispatch(getAllUsers(designation, 15, null, () => {}));
            alert('Information Updated!');
          },
        ),
      );
    }
  };

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        users &&
        users.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllUsers(designation, 15, lastTime, () => {}));
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setPlaybook(
        users?.find((us) => us?.id === selectedUser)?.custom?.playbook || '',
      );
      formikPlaybook.setValues({
        companyName:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.companyName || '',
        productDescription:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.productDescription || '',
        keyFeatures:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.productKeyFeatures || '',
        secondaryFeatures:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.secondaryFeatures || '',
        idealCustomerProfile:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.idealCustomerProfile || '',
        methodologies: [
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.bant && 'bant',
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.star && 'star',
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.spin && 'spin',
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.meddpic && 'meddpic',
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.playbook && 'playbook',
        ].filter((mthd) => mthd),
        competingProducts:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.competingProducts || '',
        expectedCallToAction:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.expectedCallToAction || '',
        customerQualificationQuestions:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.customerQualificationQuestions || '',
        followUpQuestions:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.followUpQuestions || '',
        objectionsAndAnswers:
          users?.find((us) => us?.id === selectedUser)?.custom
            ?.playbookStructure?.customerQuestions || [],
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (
      playbook !==
      users?.find((us) => us?.id === selectedUser)?.custom?.playbook
    ) {
      setPlaybookChanged(true);
    } else {
      setPlaybookChanged(false);
    }
  }, [playbook]);

  useEffect(() => {
    dispatch(getAllUsers(designation, 15, null, () => {}));
  }, []);

  // console.log(recordedBlobs);
  // console.log(selectedAudio);
  // console.log(recording);
  // console.log('transcript', transcript);
  // console.log(
  //   'browserSupportsSpeechRecognition',
  //   browserSupportsSpeechRecognition,
  // );
  // console.log('listening', listening);
  // console.log(message);
  // console.log(permissionDenied);
  // console.log(permissionPersisting);
  // console.log(showPermissionDeniedError);
  // console.log(audioTypes);
  // console.log(users);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            // display={selectedUser ? 'none' : 'flex'}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                  }
            }
          >
            {users?.map((user) => (
              <Box
                // flex={1}
                key={user?.id}
                sx={{
                  // flex: 1,
                  padding: '8px 16px',
                  border: '1px solid #d3d3d3',
                  borderRadius: 2,
                  cursor: 'pointer',
                  background:
                    selectedUser === user?.id
                      ? theme.palette.primary.main
                      : 'default',
                  color: selectedUser === user?.id ? '#fff' : 'default',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setSelectedUser(user?.id)}
              >
                <Typography
                  variant="body1"
                  color={'text.primary'}
                  fontWeight={'bold'}
                >
                  {user?.email}
                </Typography>
                <Typography
                  variant="body1"
                  color={'text.primary'}
                  fontWeight={'bold'}
                >
                  {user?.custom?.organization}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={'text.secondary'}
                  fontWeight={'bold'}
                >
                  {user?.fullName}
                </Typography>
              </Box>
            ))}
            {selectedUser ? null : fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more users ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && users?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? 0 : 0,
              }}
            >
              {selectedUser ? <Box></Box> : null}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this conversation?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteConversation(showConfirmDeleteDialog, () => {
                  setConversations((prev) =>
                    prev?.filter((cs) => cs.id !== showConfirmDeleteDialog),
                  );
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fixed>
  );
};

export default ConversationAIUsers;
