const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  dataPointsByExpId: [],
  emptyList: null,
  lastTime: null,
};

const getVideoDataPointsByExperimentIdReducer = (
  state = initialstate,
  action,
) => {
  switch (action.type) {
    case 'FETCHING_ALL_VIDEO_DATA_POINTS_BY_EXPERIMENT_ID':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_VIDEO_DATA_POINTS_BY_EXPERIMENT_ID_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_VIDEO_DATA_POINTS_BY_EXPERIMENT_ID_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        dataPointsByExpId: action.payload.dataPoints,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_DATA_POINTS_TO_ALL_DATA_POINTS_BY_EXPERIMENT_ID':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        dataPointsByExpId: Array.isArray(action.payload.dataPoints)
          ? state.dataPoints
            ? state.dataPoints.concat(action.payload.dataPoints)
            : action.payload.dataPoints
          : state.dataPoints,
        emptyList:
          Array.isArray(action.payload.dataPoints) &&
          (action.payload.dataPoints.length === 0 ||
            action.payload.dataPoints.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'FETCHING_ALL_VIDEO_DATA_POINTS_BY_EXPERIMENT_ID_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        dataPointsByExpId: [],
        emptyList: false,
        lastTime: '',
      });
    case 'FETCHING_ALL_VIDEO_DATA_POINTS_BY_EXPERIMENT_ID_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getVideoDataPointsByExperimentIdReducer;
