import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getPineconeIndices = (callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_PINECONE_INDICES' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      const reqId = uuidv4();
      try {
        const response = await axios.get('/ads/pinecone/indices', {
          params: {
            'request-id': reqId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-version': 'v2',
          },
        });
        if (response.status === 200) {
          dispatch({
            type: 'FETCHING_PINECONE_INDICES_DONE',
          });
          if (typeof callback === 'function') callback(response?.data);
        } else {
          dispatch({
            type: 'FETCHING_PINECONE_INDICES_FAILED',
            payload: response.data,
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'FETCHING_PINECONE_INDICES_FAILED',
          payload: e.message,
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default getPineconeIndices;
