const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  users: [],
  emptyList: null,
  lastTime: null,
};

const getAllUsersReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GET_ALL_USERS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GET_ALL_USERS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GET_ALL_USERS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        users: action.payload.users,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'UPDATE_USER_IN_ALL_USERS': {
      const index = state.participantsDetails.findIndex(
        ({ participation }) =>
          participation.id === action.payload.participationId,
      );
      let newArr = [...state.participantsDetails];
      if (newArr?.[index]?.participation !== undefined) {
        newArr[index]['participation'][action.payload.property] =
          action.payload.value;
      } else {
        newArr[index]['participation'][action.payload.property] =
          action.payload.value;
      }
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        users: newArr,
        lastTime: state.lastTime,
        emptyList: false,
        error: false,
        errorText: '',
      });
    }
    case 'ADD_USERS_TO_ALL_USERS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        users: Array.isArray(action.payload.users)
          ? state.users
            ? state.users.concat(action.payload.users)
            : action.payload.users
          : state.users,
        emptyList:
          Array.isArray(action.payload.users) &&
          (action.payload.users.length === 0 ||
            action.payload.users.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GET_ALL_USERS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        users: [],
        emptyList: false,
        lastTime: '',
      });
    case 'GET_ALL_USERS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllUsersReducer;
