import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getDefaultPrompts = (callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_DEFAULT_PROMPTS' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      const reqId = uuidv4();
      try {
        const response = await axios.get('/ads/ai-contest/prompts', {
          params: {
            'request-id': reqId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          dispatch({
            type: 'FETCHING_DEFAULT_PROMPTS_DONE',
            payload: response?.data,
          });
          if (typeof callback === 'function') callback(response?.data);
        } else {
          dispatch({
            type: 'FETCHING_DEFAULT_PROMPTS_FAILED',
            payload: response.data,
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'FETCHING_DEFAULT_PROMPTS_FAILED',
          payload: e.message,
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default getDefaultPrompts;
