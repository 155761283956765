import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);

  const role = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo') || '{}').roleName
    : '';

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && restricted ? (
          role == 'ROLE_RECRUITER' ? (
            <Redirect to="/admin/recruiter/home" />
          ) : (
            <Redirect to="/f550aeea-0bb4-41e9-b615-7a6cf101e740" />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  restricted: PropTypes.bool,
};

export default PublicRoute;
