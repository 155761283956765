const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  experiments: [],
  emptyList: null,
  lastTime: null,
};

const getExperimentsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_EXPERIMENTS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_EXPERIMENTS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_EXPERIMENTS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        experiments: action.payload.experiments,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    // case 'UPDATE_USER_IN_ALL_USERS': {
    //   const index = state.participantsDetails.findIndex(
    //     ({ participation }) =>
    //       participation.id === action.payload.participationId,
    //   );
    //   let newArr = [...state.participantsDetails];
    //   if (newArr?.[index]?.participation !== undefined) {
    //     newArr[index]['participation'][action.payload.property] =
    //       action.payload.value;
    //   } else {
    //     newArr[index]['participation'][action.payload.property] =
    //       action.payload.value;
    //   }
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     experiments: newArr,
    //     lastTime: state.lastTime,
    //     emptyList: false,
    //     error: false,
    //     errorText: '',
    //   });
    // }
    case 'ADD_EXPERIMENTS_TO_ALL_EXPERIMENTS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        experiments: Array.isArray(action.payload.experiments)
          ? state.experiments
            ? state.experiments.concat(action.payload.experiments)
            : action.payload.experiments
          : state.experiments,
        emptyList:
          Array.isArray(action.payload.experiments) &&
          (action.payload.experiments.length === 0 ||
            action.payload.experiments.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'FETCHING_EXPERIMENTS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        experiments: [],
        emptyList: false,
        lastTime: '',
      });
    case 'FETCHING_EXPERIMENTS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getExperimentsReducer;
