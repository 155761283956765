/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import { InputLabel } from '@mui/material';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
});

const Form = () => {
  const theme = useTheme();
  const inputImage = useRef();
  const [imageSizeError, setImageSizeError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invalidImageType, setInvalidImageType] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const onFileChange = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setImageSizeError(true);
      } else {
        setSelectedFile(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      inputImage.current.value = null;
      setInvalidImageType(true);
    }
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
            textAlign: 'center',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Create an account
        </Typography>
        <Typography
          color="text.secondary"
          sx={{
            textAlign: 'center',
          }}
        >
          Fill out the form to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'column' }}
              alignItems={{ xs: 'center', sm: 'center' }}
              justifyContent={'center'}
              width={1}
              maxWidth={600}
              margin={'20px auto'}
            >
              <InputLabel htmlFor="profileImg" style={{ cursor: 'pointer' }}>
                <Avatar
                  variant="circular"
                  alt="Profile"
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : '/default-profile.png'
                  }
                  sx={{
                    width: theme.spacing(12),
                    height: theme.spacing(12),
                    border: '2px solid #343a40',
                    // boxShadow: '0 0 10px #343a40',
                    // borderRadius: '50%',
                    // margin: 10,
                  }}
                />
                <input
                  style={{ display: 'none' }}
                  id="profileImg"
                  ref={inputImage}
                  type="file"
                  accept="image/*"
                  onChange={onFileChange}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: -3,
                    right: -3,
                    color: '#000',
                  }}
                >
                  {selectedFile !== null ? (
                    <LoopIcon
                      style={{ fontSize: '2rem', transform: 'rotate(90deg)' }}
                    />
                  ) : (
                    <AddIcon style={{ fontSize: '2rem' }} />
                  )}
                </Box>
              </InputLabel>
              <Typography
                color="text.secondary"
                sx={{
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                Profile Picture
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First name"
              variant="outlined"
              name={'firstName'}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last name"
              variant="outlined"
              name={'lastName'}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password *"
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Already have an account?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/auth/login'}
                    underline={'none'}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Sign up
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            // justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant={'subtitle2'}
              color={'text.secondary'}
              // align={'center'}
            >
              By clicking "Sign up" button you agree with our{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/tos'}
                underline={'none'}
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/privacy'}
                underline={'none'}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
