import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const createCustomPlan = (payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_CUSTOM_PLAN' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/ads/plans`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'CREATING_CUSTOM_PLAN_DONE',
              payload: {
                plan: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'CREATING_CUSTOM_PLAN_FAILED',
              payload: `Failed to create custom plan`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'CREATING_CUSTOM_PLAN_FAILED',
            payload: `Failed to create custom plan`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_CUSTOM_PLAN_FAILED',
        payload: `Failed to create custom plan`,
      });
    }
  };
};

export default createCustomPlan;
