import uuid from 'draft-js/lib/uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const uploadAudio = (signedURL, audioData, callback, errorCallback) => {
  console.log(signedURL, 'signedURL', audioData);
  return async (dispatch) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.put(signedURL, audioData, {
          headers: {
            'Content-Type': audioData?.type?.split(';')?.[0],
          },
        });
        console.log(response);
        if (response.status === 200) {
          // console.log('im here');
          if (typeof callback === 'function') callback();
        } else {
          if (typeof errorCallback === 'function') errorCallback();
        }
      } catch (e) {
        console.log(e);
      }
      unsubscribe();
    });
  };
};

export default uploadAudio;
