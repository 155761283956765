import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getPlans = (callback) => {
  return async (dispatch) => {
    // if (lastTime) {
    //   dispatch({
    //     type: 'GET_ALL_PLANS_WITH_PAGINATION',
    //   });
    // } else {
    dispatch({ type: 'GET_ALL_PLANS' });
    // }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/ads/plans`, {
            params: {
              'request-id': reqId,
              limit: 50,
              lastSeen: null,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            // if (lastTime) {
            //   await dispatch({
            //     type: 'ADD_PROMPTS_TO_ALL_PROMPTS',
            //     payload: {
            //       prompts: response?.data,
            //       lastTime: response?.data?.[response?.data?.length - 1]?.id,
            //     },
            //   });
            // } else {
            await dispatch({
              type: 'GET_ALL_PLANS_DONE',
              payload: {
                prompts: response?.data,
                // lastTime: response?.data?.[response?.data?.length - 1]?.id,
              },
            });
            // }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GET_ALL_PLANS_FAILED',
              payload: `Failed to fetch prompts`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GET_ALL_PLANS_FAILED',
            payload: `Failed to fetch prompts`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GET_ALL_PLANS_FAILED',
        payload: `Failed to fetch prompts`,
      });
    }
  };
};

export default getPlans;
