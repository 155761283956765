import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  FormControlLabel,
  Checkbox,
  Switch,
  Tooltip,
} from '@mui/material';
import { Experiment, Fixed } from 'layouts';
import React, { useEffect, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack, Assessment, Close, Link } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
//actions
import getParticipantsByCompetitionId from 'redux/actions/Common/getParticipantsByCompetitionId';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

//component
import CompetitionReportEmployerView from '../CompetitionReportEmployerView';
import getContestReport from 'redux/actions/Common/getContestReport';
import moment from 'moment';
import excludeParticipation from 'redux/actions/Common/excludeParticipation';
import getContestPrivateLinkToken from 'redux/actions/Common/getContestPrivateLinkToken';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';

const columns = [
  // { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'name',
    headerName: 'Name',
    width: 130,
    valueGetter: (params) =>
      params.value
        ? `${params?.value
            ?.trim()
            ?.split(' ')?.[0]
            ?.split('')?.[0]
            ?.toUpperCase()}${params?.value
            ?.trim()
            ?.split(' ')?.[0]
            ?.slice(1)} ${params?.value
            ?.trim()
            ?.split(' ')?.[1]
            ?.split('')?.[0]
            ?.toUpperCase()}${params?.value
            ?.trim()
            ?.split(' ')?.[1]
            ?.slice(1)}`
        : '',
    headerClassName: 'header',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 130,
    headerClassName: 'header',
  },
  {
    field: 'fakeName',
    headerName: 'Fake Name',
    width: 130,
    valueGetter: (params) =>
      params.value
        ? `${params?.value
            ?.trim()
            ?.split(' ')?.[0]
            ?.split('')?.[0]
            ?.toUpperCase()}${params?.value
            ?.trim()
            ?.split(' ')?.[0]
            ?.slice(1)} ${params?.value
            ?.trim()
            ?.split(' ')?.[1]
            ?.split('')?.[0]
            ?.toUpperCase()}${params?.value
            ?.trim()
            ?.split(' ')?.[1]
            ?.slice(1)}`
        : '',
    headerClassName: 'header',
  },
  {
    field: 'report',
    headerName: 'Report',
    width: 130,
    renderCell: (params) => (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Tooltip
          arrow
          placement="top"
          title={
            params?.value?.reportStatus === 'calculated'
              ? 'View Report'
              : params?.value?.reportStatus === 'in_process'
              ? 'in processing'
              : ''
          }
        >
          <Assessment
            sx={{
              cursor:
                params?.value?.reportStatus === 'calculated'
                  ? 'pointer'
                  : 'default',
              color:
                params?.value?.reportStatus === 'calculated'
                  ? useTheme().palette.primary.main
                  : params?.value?.reportStatus === 'in_process'
                  ? useTheme().palette.text.secondary
                  : useTheme().palette.text.secondary,
            }}
            onClick={() => {
              if (params?.value?.reportStatus === 'calculated') {
                params?.value?.onClick();
              }
            }}
          />
        </Tooltip>
        <Tooltip
          arrow
          placement="top"
          title={
            params?.value?.reportStatus === 'calculated'
              ? 'Copy public report link'
              : params?.value?.reportStatus === 'in_process'
              ? 'in processing'
              : ''
          }
        >
          <Link
            sx={{
              cursor:
                params?.value?.reportStatus === 'calculated'
                  ? 'pointer'
                  : 'default',
              color:
                params?.value?.reportStatus === 'calculated'
                  ? useTheme().palette.primary.main
                  : params?.value?.reportStatus === 'in_process'
                  ? useTheme().palette.text.secondary
                  : useTheme().palette.text.secondary,
            }}
            onClick={() => {
              if (params?.value?.reportStatus === 'calculated') {
                params?.value?.onCopyPublicReportLink();
              }
            }}
          />
        </Tooltip>
      </Box>
    ),
    headerClassName: 'header',
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    width: 130,
    headerClassName: 'header',
    renderCell: (params) => {
      return (
        <Switch
          size="small"
          checked={params?.value?.excluded}
          onChange={params?.value?.onChange}
        />
      );
    },
  },
  {
    field: 'clarity',
    headerName: 'Clarity',
    width: 100,
    headerClassName: 'header',
  },
  { field: 'pace', headerName: 'Pace', width: 100, headerClassName: 'header' },
  {
    field: 'fluency',
    headerName: 'Fluency',
    width: 100,
    headerClassName: 'header',
  },
  {
    field: 'identifyingNeeds',
    headerName: 'Identifying Needs',
    width: 130,
    headerClassName: 'header',
  },
  {
    field: 'productKnowledge',
    headerName: 'Product Knowledge',
    width: 130,
    headerClassName: 'header',
  },
  {
    field: 'confidence',
    headerName: 'Confidence',
    width: 100,
    headerClassName: 'header',
  },
  {
    field: 'enthusiasm',
    headerName: 'Enthusiasm',
    width: 100,
    headerClassName: 'header',
  },
  {
    field: 'engaging',
    headerName: 'Engaging',
    width: 100,
    headerClassName: 'header',
  },
  {
    field: 'substance',
    headerName: 'Substance',
    width: 100,
    headerClassName: 'header',
  },
  {
    field: 'duration',
    headerName: 'Duration(in Minutes)',
    width: 100,
    headerClassName: 'header',
    valueGetter: (params) =>
      params.value ? `${Math.ceil(params.value / 1000 / 60)}` : '-',
  },
  {
    field: 'startedOn',
    headerName: 'Started On',
    headerClassName: 'header',
    width: 140,
    renderCell: (params) => {
      return params.value ? (
        <Tooltip
          arrow
          placement="auto"
          title={moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}
        >
          <Typography>{moment(params.value).format('DD MMMM YYYY')}</Typography>
        </Tooltip>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'completedOn',
    headerName: 'Completed On',
    headerClassName: 'header',
    width: 140,
    renderCell: (params) => {
      return params.value ? (
        <Tooltip
          arrow
          placement="auto"
          title={moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}
        >
          <Typography>{moment(params.value).format('DD MMMM YYYY')}</Typography>
        </Tooltip>
      ) : (
        '-'
      );
    },
  },
];

// const rows = [
//   { id: 1, name: 'Jon', engagging: 8, substance: 4 },
//   { id: 2, name: 'sim', engagging: 9, substance: 5 },
//   { id: 3, name: 'tim', engagging: 10, substance: 6 },
// ];

const CompetitionsParticipants = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    participantsDetails,
    fetching,
    fetchingPagination,
    competitionDetails,
    lastTime,
    emptyList,
  } = useSelector((state) => state.getParticipantsByCompetitionIdState);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const contestReportData = useSelector((state) => state.contestReportData);

  const [showCalendarModal, setShowCalendarModal] = useState(null);
  const { contestId } = useParams();
  // candidateResponse
  const [page, setPage] = useState('allParticipants');
  const [selectedCompetitions, setSelectedCompetions] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [section1, setSection1] = useState(null);
  const [section2, setSection2] = useState(null);
  const [section3, setSection3] = useState(null);
  const [section2McqsWithAnswer, setSection2McqWithAnswer] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPartialResponses, setShowPartialResponses] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        participantsDetails &&
        participantsDetails.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      // if (paginateWithFilters) {
      //   dispatch(
      //     getAllConnections(
      //       {
      //         searchRequest: {
      //           countries: [currentCountry.country],
      //           jobId: showAssignBox,
      //           keyword: formState.values.skills,
      //           lastTime: allConnectedApplicants?.lastTime,
      //           remote: formState.values.remote,
      //           relocation: formState.values.relocation,
      //           size: 10,
      //           sponserVisa: formState.values.visa,
      //           score: showAIPoweredScore && showAssignBox ? true : false,
      //         },
      //       },
      //       () => {},
      //     ),
      //   );
      // } else {
      dispatch(
        getParticipantsByCompetitionId(
          contestId,
          'employer',
          showPartialResponses ? 'all' : '',
          15,
          lastTime,
          () => {},
        ),
      );
      // }
    }
  };

  useEffect(() => {
    if (selectedParticipant) {
      // console.log(selectedParticipant, 'this are th');
      selectedParticipant?.sections?.forEach((section) => {
        if (section?.competitionSection?.sectionType === 'script_reading') {
          setSection1(section);
        }
        if (section?.competitionSection?.sectionType === 'customer_qa') {
          setSection2(section);
          let questionsWithAnswer = [];
          section?.competitionSection?.sectionQuestion?.forEach((question) => {
            section?.participationSection?.sectionAnswers?.forEach((answer) => {
              if (question?.questionId === answer?.id) {
                questionsWithAnswer.push({
                  question: question,
                  answer: answer,
                });
              }
            });
          });
          // console.log(questionsWithAnswer, 'questionsWithAnswer');
          setSection2McqWithAnswer(questionsWithAnswer);
        }
        if (section?.competitionSection?.sectionType === 'pitch') {
          setSection3(section);
        }
      });
    }
  }, [selectedParticipant]);

  useEffect(() => {
    dispatch(
      getParticipantsByCompetitionId(
        contestId,
        'employer',
        showPartialResponses ? 'all' : '',
        15,
        null,
        () => {},
      ),
    );
  }, [showPartialResponses]);

  useEffect(() => {
    dispatch(
      getParticipantsByCompetitionId(
        contestId,
        'employer',
        showPartialResponses ? '' : '',
        15,
        null,
        () => {},
      ),
    );
  }, []);

  useEffect(() => {
    if (
      participantsDetails &&
      Array.isArray(participantsDetails) &&
      participantsDetails.length > 0
    ) {
      setRows(
        participantsDetails
          ?.map((p) => p.participation)
          ?.map((pd) => {
            return {
              ...pd,
              id: pd?.id,
              name: pd?.user?.fullName,
              email: pd?.user?.email,
              fakeName: pd?.candidateFakeName || '',
              duration: pd?.duration,
              clarity:
                pd?.report?.clarity === undefined
                  ? '-'
                  : Math.round(pd?.report?.clarity),
              pace:
                pd?.report?.pace === undefined
                  ? '-'
                  : Math.round(pd?.report?.pace),
              confidence:
                pd?.report?.speechConfidence === undefined
                  ? '-'
                  : Math.round(pd?.report?.speechConfidence),
              enthusiasm:
                pd?.report?.enthusiasm === undefined
                  ? '-'
                  : Math.round(pd?.report?.enthusiasm),
              fluency:
                pd?.report?.fluency === undefined
                  ? '-'
                  : Math.round(pd?.report?.fluency),
              identifyingNeeds:
                pd?.report?.needs === undefined
                  ? '-'
                  : Math.round(pd?.report?.needs),
              productKnowledge:
                pd?.report?.productKnowledge === undefined
                  ? '-'
                  : Math.round(pd?.report?.productKnowledge),
              engaging:
                pd?.report?.engagement === undefined ||
                pd?.report?.engagement === 'NaN'
                  ? '-'
                  : Math.round(pd?.report?.engagement),
              substance:
                pd?.report?.substance === undefined ||
                pd?.report?.substance === 'NaN'
                  ? '-'
                  : Math.round(pd?.report?.substance),
              report: {
                reportStatus: pd?.report?.status,
                processing: contestReportData?.fetching,
                onClick: () => {
                  dispatch(
                    getContestReport(
                      pd?.id,
                      'employer',
                      (data) => {
                        setSelectedRow(data);
                        setPage('participantDetails');
                      },
                      () => {},
                    ),
                  );
                },
                onCopyPublicReportLink: () => {
                  dispatch(
                    getContestPrivateLinkToken(
                      pd?.id,
                      'public_report_link',
                      async (data) => {
                        try {
                          await navigator.clipboard.writeText(
                            `${actionCodeBaseUrl}p/report/${data}`,
                          );
                          alert('Link Copied.');
                        } catch (err) {
                          alert('Failed to copy link.');
                          console.error('Failed to copy: ', err);
                        }
                      },
                    ),
                  );
                },
              },
              exclude: {
                onChange: (e) => {
                  dispatch(
                    excludeParticipation(
                      pd?.id,
                      e.target.checked,
                      () => {
                        alert('updated successfully.');
                        dispatch({
                          type: 'UPDATE_PARTICIPANT_OF_ALL_PARTICIPANTS',
                          payload: {
                            property: 'excludedByAdmin',
                            participationId: pd?.id,
                            value: !e.target.checked,
                          },
                        });
                      },
                      () => {
                        alert('Failed to exclude participation.');
                      },
                    ),
                  );
                },
                excluded: pd?.excludedByAdmin,
              },
            };
          }),
      );
    }
  }, [participantsDetails]);

  // console.log(participantsDetails);

  return page === 'participantDetails' ? (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <CompetitionReportEmployerView
          setPage={setPage}
          selectedRow={selectedRow}
        />
      </Box>
    </Fixed>
  ) : (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <Box mb={2}>
          <ArrowBack
            onClick={() =>
              history.push({
                pathname: `/f550aeea-0bb4-41e9-b615-7a6cf101e740/contests`,
                state: {
                  contestId: competitionDetails?.id,
                },
              })
            }
            color="#ffffff"
            sx={{ cursor: 'pointer' }}
          />
        </Box>
        {/* <FormControlLabel
              control={
                <Checkbox
                  checked={showPartialResponses}
                  value={'partialResponses'}
                  name="partialResponses"
                />
              }
              label="Include Partial Responses"
              onChange={() => setShowPartialResponses((prev) => !prev)}
            /> */}
        {fetching ? (
          <Box
            width={1}
            height={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <CircularProgress size={20} />
            <Typography variant="body1" color={'text.primary'} mt={4}>
              Loading all participants ...
            </Typography>
          </Box>
        ) : participantsDetails &&
          Array.isArray(participantsDetails) &&
          participantsDetails.length > 0 ? (
          <>
            <Box
              sx={{
                width: 1,
                '& .header': {
                  background: theme.palette.background.default,
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                },
              }}
            >
              <DataGrid
                // autoHeight={true}
                // autoPageSize={true}
                rows={rows}
                // onRowClick={(rowData) => {
                //   console.log(rowData);
                //   setSelectedRow(rowData);
                //   setPage('participantDetails');
                // }}
                columns={columns}
                rowSelection={false}
                disableRowSelectionOnClick={true}
                // hideFooter
                // checkboxSelection
              />
              {fetchingPagination ? (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    // type="submit"
                    color="primary"
                    disabled
                  >
                    Loading more participants ...
                    <CircularProgress size={20} />
                  </Button>
                </Box>
              ) : emptyList === false && participantsDetails?.length >= 15 ? (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleListScroll(e, true)}
                  >
                    Load More
                  </Button>
                </Box>
              ) : null}
            </Box>
          </>
        ) : (
          <Box
            width={1}
            height={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Typography variant="body1" color={'text.primary'} mt={4}>
              No participants found
            </Typography>
          </Box>
        )}
      </Box>
    </Fixed>
  );
};

export default CompetitionsParticipants;
