import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch } from 'react-redux';
import getAllDemoRequests from 'redux/actions/Common/getAllDemoRequests';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const DemoRequests = () => {
  const theme = useTheme();
  const history = useHistory();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [processing, setProcessing] = useState(false);
  const [demoRequests, setDemoRequests] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDemoRequests((data) => setDemoRequests(data)));
  }, []);

  useEffect(() => {}, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        {demoRequests && demoRequests.length > 0 ? (
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Created On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {demoRequests.map((user) => (
                  <TableRow
                    key={user?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {user?.id}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {user?.email}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {`${user?.firstName} ${user?.lastName}`}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {user?.status}
                    </TableCell>
                    <TableCell align="center">
                      {moment(user?.createdOn).format('DD MMMM YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </Fixed>
  );
};

export default DemoRequests;
