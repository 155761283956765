import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getPrivacyTos = (type, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_PRIVACY_TOS' });
    // try {
    //   let unsubscribe = onAuthStateChanged(auth, async (user) => {
    //     const token = await user?.getIdToken();
    const reqId = uuidv4();
    try {
      const response = await axios.get(`/us/tos-and-privacy`, {
        params: {
          'request-id': reqId,
          type: type,
        },
        headers: {
          // Authorization: `Bearer ${token}`,
          // 'x-api-version': 'v2',
        },
      });
      if (response.status === 200) {
        await dispatch({
          type: 'FETCHING_PRIVACY_TOS_DONE',
          payload: {
            bots: response?.data,
          },
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'FETCHING_PRIVACY_TOS_FAILED',
          payload: `Failed to get privacy or tos`,
        });
      }
    } catch (e) {
      dispatch({
        type: 'FETCHING_PRIVACY_TOS_FAILED',
        payload: `Failed to get privacy or tos`,
      });
    }
    //     unsubscribe();
    //   });
    // } catch (e) {
    //   dispatch({
    //     type: 'FETCHING_PRIVACY_TOS_FAILED',
    //     payload: `Failed to get privacy or tos`,
    //   });
    // }
  };
};

export default getPrivacyTos;
