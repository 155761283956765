import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  Grid,
  Drawer,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  Group,
  Link,
  Notes,
  Redo,
  Replay,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllVideoDataPoints from 'redux/actions/Common/getAllVideoDataPoints';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import SkillTagInput from './components/skillTagInput';
import labelDataPoint from 'redux/actions/Common/labelDataPoint';
import addLabels from 'redux/actions/Common/addLabels';
import getLabels from 'redux/actions/Common/getLabels';
import getExperiments from 'redux/actions/Common/getExperiments';
import createNewExperiment from 'redux/actions/Common/createNewExperiment';
import getVideoDataPointsByExperimentId from 'redux/actions/Common/getVideoDataPointsByExperimentId';
import addDataPoints from 'redux/actions/Common/addDataPoints';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultLabels = [
  'clarity',
  'pace',
  'fluency',
  'confidence',
  'enthusiasm',
  'delivery',
  'content',
];

const Contests = () => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [processing, setProcessing] = useState(false);
  const [contests, setContests] = useState([]);
  const dispatch = useDispatch();
  const swiperRef = useRef();

  const videoDataPoints = useSelector((state) => state.videoDataPoints);
  const videoDataPointsByExpId = useSelector(
    (state) => state.videoDataPointsByExpId,
  );
  const {
    experiments,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allExperiments);
  const { dataPoints } = videoDataPoints;
  const { dataPointsByExpId } = videoDataPointsByExpId;
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [labeler, setLabeler] = useState('select');
  const [labels, setLabels] = useState([]);
  const [labelValues, setLabelValues] = useState({});
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [showFullScript, setShowFullScript] = useState(null);

  const [selectedExperiment, setSelectedExperient] = useState('');
  const [newExperimentDialog, setNewExperimentDialog] = useState(false);
  const [experimentTitle, setExperimentTitle] = useState('');
  const [experimentDescription, setExperimentDescription] = useState('');
  const [creatingExperiment, setCreatingExperiment] = useState(false);

  const [showAddDataPointsDialog, setShowAddDataPointsDialog] = useState('');
  const [selectedDataPoints, setSelectedDataPoints] = useState([]);

  const [allDataPointsSelected, setAllDataPointsSelected] = useState(false);

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        experiments &&
        experiments.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getExperiments(15, lastTime, () => {}));
    }
  };

  const handleAllDataPointsListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !videoDataPoints?.fetching &&
        !videoDataPoints?.fetchingPagination &&
        dataPoints &&
        dataPoints.length % 15 === 0 &&
        videoDataPoints?.emptyList === false) ||
      paginate
    ) {
      dispatch(getAllVideoDataPoints(15, videoDataPoints?.lastTime, () => {}));
    }
  };

  const handleLabelData = (sentenceIndex) => {
    dispatch(
      labelDataPoint(
        {
          dataPointId: dataPoints?.[activeVideoIndex]?.id,
          expId: selectedExperiment,
          sentenceId: Object.values(
            dataPoints?.[activeVideoIndex]?.video?.sentenceMap,
          )?.[sentenceIndex]?.id,
          labelers: labeler,
          labels: labelValues,
        },
        () => {
          setLabelValues([]);
          if (swiperRef?.current?.isEnd) {
            alert('All sentences labeled successfully');
          } else {
            swiperRef?.current?.slideNext();
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (
      dataPointsByExpId?.[activeVideoIndex]?.video?.sentenceMap &&
      Object.values(
        dataPointsByExpId?.[activeVideoIndex]?.video?.sentenceMap,
      ) &&
      Object.values(dataPointsByExpId?.[activeVideoIndex]?.video?.sentenceMap)
        .length > 0 &&
      labeler &&
      labeler !== 'select'
    ) {
      if (
        Object.values(
          dataPointsByExpId?.[activeVideoIndex]?.video?.sentenceMap,
        )?.[swiperIndex]?.expLabelerLabelMap?.[selectedExperiment]?.[labeler]
      ) {
        setLabelValues(
          Object.values(
            dataPointsByExpId?.[activeVideoIndex]?.video?.sentenceMap,
          )?.[swiperIndex]?.expLabelerLabelMap?.[selectedExperiment]?.[labeler],
        );
      } else {
        // setLabels([]);
        setLabelValues({});
      }
    }
    if (showFullScript) {
      setShowFullScript(
        dataPointsByExpId?.[activeVideoIndex]?.video?.transcriptionJob || '',
      );
    }
  }, [dataPointsByExpId, activeVideoIndex, swiperIndex, labeler]);

  useEffect(() => {
    if (
      labeler &&
      labeler !== 'select' &&
      labels &&
      Array.isArray(labels) &&
      labels.length > 0
    ) {
      dispatch(
        addLabels({
          [labeler]: labels,
        }),
      );
    }
  }, [labeler, labels]);

  useEffect(() => {
    if (
      dataPoints
        ?.map((dp) => selectedDataPoints?.includes(dp?.id))
        ?.filter((boolV) => !boolV)?.length > 0
    ) {
      setAllDataPointsSelected(false);
    } else {
      setAllDataPointsSelected(true);
    }
  }, [selectedDataPoints, dataPoints]);

  useEffect(() => {
    if (selectedExperiment) {
      dispatch(
        getVideoDataPointsByExperimentId(
          selectedExperiment,
          15,
          null,
          () => {},
        ),
      );
      dispatch(
        getLabels((data) => {
          setLabeler(Object.keys(data?.preference || {})?.[0] || 'select');
          setLabels(Object.values(data?.preference || {})?.[0] || []);
          console.log(data);
        }),
      );
    }
  }, [selectedExperiment]);

  useEffect(() => {
    if (showAddDataPointsDialog) {
      dispatch(getAllVideoDataPoints(15, null, () => {}));
    }
  }, [showAddDataPointsDialog]);

  useEffect(() => {
    dispatch(getExperiments(15, null, () => {}));
    // dispatch(
    //   getLabels((data) => {
    //     setLabels(Object.values(data?.preference || {})?.[0] || []);
    //     console.log(data);
    //   }),
    // );
  }, []);

  // console.log(dataPoints);
  // console.log(labels);
  // console.log(swiperRef);
  // console.log(swiperIndex);
  // console.log(labeler);
  // console.log(labels);
  // console.log(selectedDataPoints);
  // console.log(showFullScript);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'hidden',
          padding: '10px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            // display={selectedExperiment ? 'none' : 'flex'}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={{
              borderRight: '1px solid #d3d3d3',
              minHeight: 'calc(100vh - 80px)',
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              padding: 2,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              fullWidth
              startIcon={<Add />}
              onClick={() => {
                setNewExperimentDialog(true);
              }}
              sx={{
                // minWidth: isXs ? '48px' : '300px',
                whiteSpace: 'nowrap',
              }}
            >
              Create new experiment
            </Button>
            {experiments?.map((experiment) => (
              <Box
                // flex={1}
                key={experiment?.id}
                sx={{
                  // flex: 1,
                  padding: '8px 16px',
                  border: '1px solid #d3d3d3',
                  borderRadius: 2,
                  cursor: 'pointer',
                  background:
                    selectedExperiment === experiment?.id
                      ? theme.palette.primary.main
                      : 'default',
                  color:
                    selectedExperiment === experiment?.id ? '#fff' : 'default',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setSelectedExperient(experiment?.id)}
              >
                <Typography
                  variant="body1"
                  color={'text.primary'}
                  fontWeight={'bold'}
                >
                  {experiment?.title}
                </Typography>
                <Typography
                  variant="caption"
                  color={'text.primary'}
                  // fontWeight={'bold'}
                >
                  {moment(experiment?.createdOn).format('DD MMMM YYYY')}
                </Typography>
              </Box>
            ))}
            {selectedExperiment ? null : fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more experiments ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && experiments?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
              display: 'flex',
              overflow: 'scroll',
              // flexDire
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight: 'calc(100vh - 80px)',
                maxHeight: 'calc(100vh - 80px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: 0,
                width: 1,
              }}
            >
              {selectedExperiment ? (
                <Box width={1}>
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      // alignItems={'center'}
                      gap={1}
                    >
                      <Typography sx={{ width: 'auto' }}>Review as</Typography>
                      <Select
                        size="small"
                        value={labeler}
                        onChange={(e) => setLabeler(e.target.value)}
                      >
                        <MenuItem value="select">Select reviewer</MenuItem>
                        <MenuItem value="deepak">Deepak</MenuItem>
                        <MenuItem value="gaurav">Gaurav</MenuItem>
                        <MenuItem value="rashi">Rashi</MenuItem>
                        <MenuItem value="saurabh">Saurabh</MenuItem>
                        <MenuItem value="shubham">Shubham</MenuItem>
                        <MenuItem value="vijay">Vijay</MenuItem>
                      </Select>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      // alignItems={'center'}
                      gap={1}
                    >
                      <Typography sx={{ width: 'auto' }}>
                        Select Labels
                      </Typography>
                      <FormControl sx={{ minWidth: 300 }}>
                        <Select
                          size="small"
                          // labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={labels}
                          onChange={(e) =>
                            setLabels(
                              // On autofill we get a stringified value.
                              typeof e.target.value === 'string'
                                ? e.target.value.split(',')
                                : e.target.value,
                            )
                          }
                          input={<OutlinedInput />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {defaultLabels.map((label) => (
                            <MenuItem key={label} value={label}>
                              <Checkbox checked={labels.indexOf(label) > -1} />
                              <ListItemText primary={label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      gap={1}
                    >
                      <Typography sx={{ width: 'auto' }}>.</Typography>
                      <Button
                        size="small"
                        startIcon={<Add />}
                        onClick={() =>
                          setShowAddDataPointsDialog(selectedExperiment)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Add Data Points
                      </Button>
                    </Box>
                  </Box>
                  <Box display={'flex'} mt={2} width={1}>
                    <Box
                      flex={1}
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{
                        minHeight: 'calc(100vh - 200px)',
                        maxHeight: 'calc(100vh - 200px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                          // outline: '1px solid slategrey',
                        },
                        paddingBottom: 0,
                      }}
                    >
                      {dataPointsByExpId &&
                      Array.isArray(dataPointsByExpId) &&
                      dataPointsByExpId.length > 0 ? (
                        <>
                          {dataPointsByExpId.map((dp, index) => {
                            return (
                              <Box
                                key={dp?.id}
                                display={'flex'}
                                sx={{
                                  borderLeft:
                                    index === activeVideoIndex
                                      ? '2px solid #d3d3d3'
                                      : 'none',
                                  borderTop:
                                    index === activeVideoIndex
                                      ? '2px solid #d3d3d3'
                                      : 'none',
                                  borderBottom:
                                    index === activeVideoIndex
                                      ? '2px solid #d3d3d3'
                                      : 'none',
                                  borderRight:
                                    index !== activeVideoIndex
                                      ? '2px solid #d3d3d3'
                                      : 'none',
                                  padding: 2,
                                  cursor: 'pointer',
                                  background:
                                    index === activeVideoIndex
                                      ? '#d3d3d3'
                                      : null,
                                  // boxShadow:
                                  //   index === activeVideoIndex
                                  //     ? '-4px 0 10px #d3d3d3'
                                  //     : 'none',
                                  width: 200,
                                }}
                                onClick={() => {
                                  setActiveVideoIndex(index);
                                }}
                              >
                                <video
                                  src={dp?.video?.customFields?.url}
                                  controls
                                  style={{
                                    objectFit: 'contain',
                                    backgroundColor: 'rgba(0,0,0,1)',
                                    width: '100%',
                                    height: '100%',
                                  }}
                                  onError={(e) => {
                                    console.log(e);
                                  }}
                                  controlsList="nofullscreen nodownload"
                                  disablePictureInPicture
                                ></video>
                              </Box>
                            );
                          })}
                        </>
                      ) : (
                        <Box>
                          <Typography>
                            No Data point exists for this experiment. Please add
                            few data points to experiment with.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    {dataPointsByExpId &&
                    Array.isArray(dataPointsByExpId) &&
                    dataPointsByExpId.length > 0 ? (
                      <Swiper
                        // ref={swiperRef}
                        slidesPerView={1}
                        // spaceBetween={10}
                        // navigation={true}
                        initialSlide={swiperIndex}
                        modules={[Navigation]}
                        onSlideChange={(swiper) =>
                          setSwiperIndex(swiper?.activeIndex)
                        }
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        style={{
                          flex: 3,
                          borderTop: '2px solid #d3d3d3',
                          borderBottom: '2px solid #d3d3d3',
                          borderRight: '2px solid #d3d3d3',
                          background: '#d3d3d3',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 16,
                          padding: 16,
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          minHeight: 'calc(100vh - 200px)',
                          maxHeight: 'calc(100vh - 200px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                        }}
                      >
                        {Object.values(
                          dataPointsByExpId?.[activeVideoIndex]?.video
                            ?.sentenceMap || {},
                        )?.map((sentence, sentenceIndex) => (
                          <SwiperSlide
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {({ isActive }) =>
                              isActive ? (
                                <Box>
                                  {showFullScript ? (
                                    <Box
                                      sx={{
                                        maxHeight: '120px',
                                        overflowY: 'scroll',
                                        '&::-webkit-scrollbar': {
                                          width: '5px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                          boxShadow:
                                            'inset 0 0 6px rgba(0,0,0,0.00)',
                                          webkitBoxShadow:
                                            'inset 0 0 6px rgba(0,0,0,0.00)',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          borderRadius: 2,
                                        },
                                        marginTop: 3,
                                        border: '1px solid #343a40',
                                        padding: 1,
                                      }}
                                    >
                                      <Typography variant="body1">
                                        {showFullScript}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  <Button
                                    variant="text"
                                    onClick={() =>
                                      setShowFullScript((prev) =>
                                        prev
                                          ? ''
                                          : dataPointsByExpId?.[
                                              activeVideoIndex
                                            ]?.video?.transcriptionJob,
                                      )
                                    }
                                    startIcon={<Notes />}
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                      zIndex: 999,
                                    }}
                                  >
                                    Full transcript
                                  </Button>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    // border={'1px solid #c3c3c3'}
                                    key={sentence?.id}
                                    gap={2}
                                  >
                                    <Typography
                                      variant="body1"
                                      fontWeight={'bold'}
                                    >
                                      Sentence {sentenceIndex + 1}
                                    </Typography>
                                    <Box flex={1}>
                                      <video
                                        autoPlay
                                        src={sentence?.customFields?.url}
                                        controls
                                        style={{
                                          objectFit: 'contain',
                                          backgroundColor: 'rgba(0,0,0,1)',
                                          // width: '100%',
                                          height: '280px',
                                        }}
                                        onError={(e) => {
                                          console.log(e);
                                        }}
                                        controlsList="nofullscreen nodownload"
                                        disablePictureInPicture
                                      ></video>
                                    </Box>
                                    <Box flex={3} p={1}>
                                      <Typography>
                                        {sentence?.transcript}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {labels &&
                                  Array.isArray(labels) &&
                                  labels.length > 0 ? (
                                    <Box
                                      display={'flex'}
                                      flexDirection={'row'}
                                      alignItems={'center'}
                                      mt={2}
                                      gap={1}
                                    >
                                      {labels.map((label, labelIndex) => (
                                        <Box
                                          display={'flex'}
                                          gap={1}
                                          key={labelIndex}
                                        >
                                          <TextField
                                            placeholder={label}
                                            label={label}
                                            value={labelValues?.[label]}
                                            size="small"
                                            onChange={(e) =>
                                              setLabelValues((prev) => {
                                                return {
                                                  ...prev,
                                                  [label]: e.target.value,
                                                };
                                              })
                                            }
                                          />
                                        </Box>
                                      ))}
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          handleLabelData(sentenceIndex);
                                        }}
                                      >
                                        Submit
                                      </Button>
                                    </Box>
                                  ) : null}
                                  <Box
                                    display={'flex'}
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                    mt={2}
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      disabled={swiperRef?.current?.isBeginning}
                                      onClick={() => {
                                        swiperRef?.current?.slidePrev();
                                      }}
                                    >
                                      Previous
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      disabled={swiperRef?.current?.isEnd}
                                      onClick={() => {
                                        swiperRef?.current?.slideNext();
                                      }}
                                    >
                                      Next
                                    </Button>
                                  </Box>
                                </Box>
                              ) : (
                                <Box></Box>
                              )
                            }
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    ) : null}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography color={'text.secondary'}>
                    Select an experiment or create new to start with
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={Boolean(showFullScript)}
          onClose={(e, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              return;
            }
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              // boxShadow: theme.shadows[5],
              outline: 'none',
              maxHeight: '80vh',
              maxWidth: '90vw',
              minWidth: '90vw',
              overflowY: 'hidden',
              borderRadius: 1,
              padding: 2,
              position: 'relative',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                padding: '4px',
                background: theme.palette.background.default,
                ':hover': {
                  backgroundColor: '#d3d3d3',
                },
              }}
              onClick={(e) => {
                setShowFullScript(null);
              }}
            >
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
            <Box
              sx={{
                marginTop: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body1">{showFullScript}</Typography>
            </Box>
          </Box>
        </Modal> */}
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={newExperimentDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setNewExperimentDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Title
              </Typography>
              <TextField
                placeholder="Title"
                variant="outlined"
                color="primary"
                size="small"
                name="experimentTitle"
                value={experimentTitle}
                onChange={(e) => setExperimentTitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Description
              </Typography>
              <TextField
                placeholder="Description"
                multiline
                rows={2}
                variant="outlined"
                color="primary"
                size="small"
                name="experimentDescription"
                value={experimentDescription}
                onChange={(e) => setExperimentDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  size="small"
                  variant="contained"
                  disabled={!experimentTitle?.trim() || creatingExperiment}
                  onClick={() => {
                    setCreatingExperiment(true);
                    dispatch(
                      createNewExperiment(
                        {
                          description: experimentDescription?.trim(),
                          title: experimentTitle?.trim(),
                        },
                        (data) => {
                          setCreatingExperiment(false);
                          dispatch(getExperiments(15, null, () => {}));
                          setNewExperimentDialog(false);
                        },
                      ),
                    );
                  }}
                >
                  Create new experiment
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Drawer
        open={Boolean(showAddDataPointsDialog)}
        anchor="right"
        onClose={(e) => {
          setShowAddDataPointsDialog(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <IconButton
            onClick={(e) => {
              setShowAddDataPointsDialog(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 120px)',
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Select and add data points
          </Typography>
          {dataPoints && dataPoints.length > 0 ? (
            <>
              {dataPoints.map((dp, index) => {
                return (
                  <Box
                    key={dp?.id}
                    display={'flex'}
                    sx={{
                      padding: 2,
                      cursor: 'pointer',
                      background: selectedDataPoints?.includes(dp?.id)
                        ? theme.palette.primary.main
                        : null,
                      // boxShadow:
                      //   index === activeVideoIndex
                      //     ? '-4px 0 10px #d3d3d3'
                      //     : 'none',
                      position: 'relative',
                    }}
                    onClick={() => {
                      if (selectedDataPoints?.includes(dp?.id)) {
                        setSelectedDataPoints((prev) =>
                          prev.filter((item) => item !== dp?.id),
                        );
                      } else {
                        setSelectedDataPoints((prev) => {
                          return [...prev, dp?.id];
                        });
                      }
                    }}
                  >
                    <video
                      src={dp?.video?.customFields?.url}
                      controls
                      style={{
                        objectFit: 'contain',
                        backgroundColor: 'rgba(0,0,0,1)',
                        width: '100%',
                        height: '100%',
                      }}
                      onError={(e) => {
                        console.log(e);
                      }}
                      controlsList="nofullscreen nodownload"
                      disablePictureInPicture
                    ></video>
                    {dp?.video?.transcriptionJob ? (
                      <Typography
                        sx={{
                          position: 'absolute',
                          top: 40,
                          right: 40,
                          color: '#fff',
                        }}
                      >
                        Add this
                      </Typography>
                    ) : null}
                  </Box>
                );
              })}
              {videoDataPoints?.fetchingPagination ? (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    // type="submit"
                    color="primary"
                    disabled
                  >
                    Loading more data points ...
                    <CircularProgress size={20} />
                  </Button>
                </Box>
              ) : videoDataPoints?.emptyList === false &&
                dataPoints?.length >= 15 ? (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleAllDataPointsListScroll(e, true)}
                    sx={{
                      whiteSpace: 'nowrap',
                      minWidth: 100,
                    }}
                  >
                    Load More
                  </Button>
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Button
            variant="text"
            onClick={() => {
              if (allDataPointsSelected) {
                setSelectedDataPoints([]);
              } else {
                setSelectedDataPoints(dataPoints?.map((dp) => dp?.id));
              }
            }}
          >
            {allDataPointsSelected ? 'All Item Selected' : 'Select All'}
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedDataPoints?.length === 0}
            onClick={() => {
              dispatch(
                addDataPoints(
                  selectedExperiment,
                  selectedDataPoints,
                  (data) => {
                    console.log(data);
                    dispatch(
                      getVideoDataPointsByExperimentId(
                        selectedExperiment,
                        15,
                        null,
                        () => {},
                      ),
                    );
                  },
                ),
              );
            }}
          >
            Done
          </Button>
        </Box>
      </Drawer>
    </Fixed>
  );
};

export default Contests;
