import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getJSONProperties = (payload, callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_JSON_PROPERTIES_FORM_PROMPT_SECTION' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      const reqId = uuidv4();
      try {
        const response = await axios.post(
          '/ads/prompts/sections/json-props',
          payload,
          {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          },
        );
        if (response.status === 200) {
          dispatch({
            type: 'FETCHING_JSON_PROPERTIES_FORM_PROMPT_SECTION_DONE',
          });
          if (typeof callback === 'function') callback(response?.data);
        } else {
          dispatch({
            type: 'FETCHING_JSON_PROPERTIES_FORM_PROMPT_SECTION_FAILED',
            payload: response.data,
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'FETCHING_JSON_PROPERTIES_FORM_PROMPT_SECTION_FAILED',
          payload: e.message,
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default getJSONProperties;
