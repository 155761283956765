const initialstate = {
  fetching: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  creds: null,
  expiry: 0,
};

const getCredsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_CREDS':
      return Object.assign({}, state, {
        fetching: true,
      });
    case 'FETCHING_CREDS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        creds: action.payload.creds,
        expiry: action.payload.expiry,
      });
    case 'FETCHING_CREDS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        creds: null,
        expiry: 0,
      });
    default:
      return state;
  }
};

export default getCredsReducer;
