import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getPresetQuestions from 'redux/actions/Common/getPresetQuestions';
import updatePresetQuestions from 'redux/actions/Common/updatePresetQuestions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PresetQuestions = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showQuestionDialog, setShowQuestionDialog] = useState('');

  const [question, setQuestion] = useState('');

  const handleAddSectionClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (showQuestionDialog !== 'create') {
      setQuestion(showQuestionDialog);
    }
  }, [showQuestionDialog]);

  useEffect(() => {
    setFetching(true);
    dispatch(
      getPresetQuestions((qs) => {
        setQuestions(qs);
        setFetching(false);
      }),
    );
  }, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          // position={'relative'}
          gap={1}
          sx={{
            minHeight: 'calc(100vh - 120px)',
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
          }}
        >
          {fetching ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              p={2}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <CircularProgress size={24} />
              <Typography color={'text.secondary'}>
                Loading all preset questions ....{' '}
              </Typography>
            </Box>
          ) : questions && questions.length > 0 ? (
            questions.map((question, i) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: '8px 16px',
                  border: '1px solid #d3d3d3',
                  borderRadius: 1,
                }}
                key={i + 1}
              >
                <Typography variant={'body1'}>{question}</Typography>
                <Box display={'flex'} alignItems={'center'}>
                  <IconButton
                    onClick={() => {
                      setShowQuestionDialog(question);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      dispatch(
                        updatePresetQuestions(
                          questions?.filter((q, qi) => i !== qi),
                          () => {
                            setQuestions(
                              questions?.filter((q, qi) => i !== qi),
                            );
                          },
                        ),
                      );
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              display={'flex'}
              justifyContent={'center'}
              p={2}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography color={'text.secondary'}>
                No preset questions found
              </Typography>
            </Box>
          )}
        </Box>
        <Tooltip arrow placement="top" title="Create New">
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            sx={{ position: 'absolute', bottom: 10, right: 30 }}
            onClick={() => setShowQuestionDialog('create')}
          >
            <Add />
          </Fab>
        </Tooltip>
      </Box>
      <Dialog
        open={Boolean(showQuestionDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
            minWidth: 500,
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{ minWidth: 500 }}
            display={'flex'}
            flexDirection={'column'}
            // alignItems={'center'}
            gap={2}
          >
            <DialogContentText id="alert-dialog-description" flex={1}>
              {showQuestionDialog === 'create'
                ? 'Create Question'
                : 'Update Question'}
            </DialogContentText>
            <TextField
              autoFocus
              placeholder="Question"
              variant="outlined"
              color="primary"
              size="small"
              multiline
              rows={3}
              name="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowQuestionDialog(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              if (showQuestionDialog === 'create') {
                dispatch(
                  updatePresetQuestions(
                    [question, ...(questions || [])],
                    () => {
                      setQuestions((prev) => [question, ...(prev || [])]);
                      setShowQuestionDialog(null);
                    },
                  ),
                );
              } else {
                dispatch(
                  updatePresetQuestions(
                    questions.map((qs) =>
                      qs === showQuestionDialog ? question : qs,
                    ),
                    () => {
                      setQuestions(
                        questions.map((qs) =>
                          qs === showQuestionDialog ? question : qs,
                        ),
                      );
                      setShowQuestionDialog(null);
                    },
                  ),
                );
              }
            }}
            color="primary"
            autoFocus
          >
            {showQuestionDialog === 'create'
              ? 'Save Question'
              : 'Update Question'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default PresetQuestions;
