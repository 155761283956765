import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const createNewConversationForUser = (payload, candidateId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_NEW_CONVERSATION_FOR_USER' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/ads/users/${candidateId}/conversation`,
            payload,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'CREATING_NEW_CONVERSATION_FOR_USER_DONE',
              payload: {
                conversationId: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'CREATING_NEW_CONVERSATION_FOR_USER_FAILED',
              payload: `Failed to create new conversation`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'CREATING_NEW_CONVERSATION_FOR_USER_FAILED',
            payload: `Failed to create new conversation`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_NEW_CONVERSATION_FOR_USER_FAILED',
        payload: `Failed to create new conversation`,
      });
    }
  };
};

export default createNewConversationForUser;
