import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import getAllJobs from 'redux/actions/Common/getAllJobs';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Modal,
} from '@mui/material';
import moment from 'moment';
import {
  AddLink,
  Close,
  ContentCopy,
  Delete,
  Edit,
  Group,
  Link,
  Redo,
  Replay,
} from '@mui/icons-material';
import deleteContest from 'redux/actions/Common/deleteContest';
import updateContest from 'redux/actions/Common/updateContest';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DataGrid } from '@mui/x-data-grid';
import Contests from '../Contests/Contests';
import ContestsList from '../ContestsList/ContestsList';
import linkingCompetitionToJob from 'redux/actions/Common/linkCompetitionToJob';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';

const columns = [
  {
    field: 'title',
    headerName: 'JobTitle',
    width: 240,
    headerClassName: 'header',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 130,
    renderCell: (params) => (
      <Box display={'flex'} alignItems={'center'}>
        <Tooltip title="Link Contest" placement="top" arrow>
          <IconButton
            onClick={() => {
              params?.value?.onContestLink();
            }}
          >
            <AddLink />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy Job Link" placement="top" arrow>
          <IconButton
            onClick={() => {
              params?.value?.onCopyJobLink();
            }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    headerClassName: 'header',
  },
  {
    field: 'applicationsCount',
    headerName: 'Applications',
    width: 130,
    valueGetter: (params) => (params.value ? `${params.value}` : '-'),
    headerClassName: 'header',
  },
  {
    field: 'companyName',
    headerName: 'Company',
    width: 130,
    valueGetter: (params) =>
      params.value
        ? `${params?.value
            ?.trim()
            ?.split(' ')?.[0]
            ?.split('')?.[0]
            ?.toUpperCase()}${params?.value
            ?.trim()
            ?.split(' ')?.[0]
            ?.slice(1)} ${params?.value
            ?.trim()
            ?.split(' ')?.[1]
            ?.split('')?.[0]
            ?.toUpperCase()}${params?.value
            ?.trim()
            ?.split(' ')?.[1]
            ?.slice(1)}`
        : '',
    headerClassName: 'header',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    headerClassName: 'header',
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 130,
    valueGetter: (params) =>
      params.value
        ? `${params?.value?.city || ''} ${params?.value?.state || ''} ${params
            ?.value?.country || ''}`
        : '',
    headerClassName: 'header',
  },
  {
    field: 'openings',
    headerName: 'Openings',
    width: 130,
    headerClassName: 'header',
  },
  {
    field: 'skills',
    headerName: 'Skills',
    width: 130,
    headerClassName: 'header',
  },
  {
    field: 'experience',
    headerName: 'Experience',
    width: 130,
    valueGetter: (params) =>
      `${params?.value?.min || 0} - ${params?.value?.max}`,
    headerClassName: 'header',
  },
  {
    field: 'jobType',
    headerName: 'Job Type',
    width: 130,
    valueGetter: (params) => params?.value?.join(','),
    headerClassName: 'header',
  },
  {
    field: 'workType',
    headerName: 'Work Type',
    width: 130,
    valueGetter: (params) => params?.value?.join(','),
    headerClassName: 'header',
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    headerClassName: 'header',
    width: 250,
    valueGetter: (params) => {
      return params.value
        ? `${moment(new Date(params.value)).format('DD MMMM YYYY hh:mm:ss A')}`
        : '-';
    },
  },
  {
    field: 'updatedOn',
    headerName: 'Updated On',
    headerClassName: 'header',
    width: 250,
    valueGetter: (params) => {
      return params.value
        ? `${moment(new Date(params.value)).format('DD MMMM YYYY hh:mm:ss A')}`
        : '-';
    },
  },
];

const Jobs = () => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [processing, setProcessing] = useState(false);
  const [jobs, setJobs] = useState([]);
  const { allJobs, fetching } = useSelector((state) => state.allJobsState);
  // const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  // const [deleteDialogOperation, setDeleteDialogOperation] = useState('delete');
  // const [contestVisibility, setContestVisibility] = useState(['published']);
  const [showContestsList, setShowContestsList] = useState(null);
  const [selectedContest, setSelectedContest] = useState('');

  const dispatch = useDispatch();

  // const handleUpdateContestLiveStatus = (e, contestId) => {
  //   console.log(e.target.checked);
  //   dispatch(
  //     updateContest(
  //       contestId,
  //       { status: e.target.checked ? 'active' : 'closed' },
  //       () => {
  //         dispatch(
  //           getAllJobs(contestType, contestVisibility, (data) =>
  //             setJobs(data),
  //           ),
  //         );
  //         alert('Contest updated');
  //       },
  //       (e) => {
  //         alert(e || 'Failed to update');
  //       },
  //     ),
  //   );
  // };

  // const handleContestDelete = () => {
  //   dispatch(
  //     deleteContest(
  //       showConfirmDeleteDialog,
  //       deleteDialogOperation === 'delete',
  //       () => {
  //         alert('Operation successful');
  //         dispatch(
  //           getAllJobs(contestType, contestVisibility, (data) => {
  //             setJobs(data);
  //             setShowConfirmDeleteDialog(null);
  //           }),
  //         );
  //       },
  //       (e) => {
  //         alert(e || 'Failed to delete contest');
  //         setShowConfirmDeleteDialog(null);
  //       },
  //     ),
  //   );
  // };

  const handleContestLinking = () => {
    console.log(selectedContest);
    setProcessing(true);
    dispatch(
      linkingCompetitionToJob(
        showContestsList,
        selectedContest,
        true,
        (data, error) => {
          if (error) {
            setProcessing(false);
            setShowContestsList(null);
            alert('Failed to link contest to job');
          } else {
            setShowContestsList(null);
            setProcessing(false);
            alert('Contest is linked with the job successfully');
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (allJobs && Array.isArray(allJobs) && allJobs.length > 0) {
      setJobs(
        allJobs?.map((job) => {
          return {
            ...job,
            id: job?.id,
            title: job?.title,
            applicationsCount: job?.count,
            companyName: job?.companyName,
            status: job?.status,
            location: {
              city: job?.city,
              state: job?.state,
              country: job?.country,
              zipCode: job?.zipCode,
            },
            openings: job?.openings,
            skills: job?.skills,
            experience: {
              min: job?.minExperience,
              max: job?.maxExperience,
            },
            jobType: job?.types,
            workType: job?.preferences,
            createdOn: job?.createdOn,
            updatedOn: job?.lastUpdatedOn,
            actions: {
              onContestLink: () => {
                setShowContestsList(job?.id);
                setSelectedContest(job?.competitionId);
              },
              onCopyJobLink: async () => {
                try {
                  await navigator.clipboard.writeText(
                    `${actionCodeBaseUrl}jobs/${job?.id}`,
                  );
                  alert('Link Copied.');
                } catch (err) {
                  alert('Failed to copy link.');
                  console.error('Failed to copy: ', err);
                }
              },
            },
          };
        }),
      );
    }
  }, [allJobs]);

  useEffect(() => {
    dispatch(getAllJobs((data) => {}));
  }, []);

  useEffect(() => {}, []);

  console.log(selectedContest);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        {jobs && Array.isArray(jobs) && jobs.length > 0 ? (
          <>
            <Box
              sx={{
                width: 1,
                '& .header': {
                  background: theme.palette.background.default,
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                },
              }}
            >
              <DataGrid
                // autoHeight={true}
                // autoPageSize={true}
                rows={jobs}
                // onRowClick={(rowData) => {
                //   console.log(rowData);
                //   setSelectedRow(rowData);
                //   setPage('participantDetails');
                // }}
                columns={columns}
                rowSelection={false}
                disableRowSelectionOnClick={true}
                // hideFooter
                // checkboxSelection
              />
              {/* {fetchingPagination ? (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: 10,
                textAlign: 'center',
              }}
            >
              <Button
                size="small"
                variant="contained"
                // type="submit"
                color="primary"
                disabled
              >
                Loading more participants ...
                <CircularProgress size={20} />
              </Button>
            </Box>
          ) : emptyList === false && allJobs?.length >= 15 ? (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: 10,
                textAlign: 'center',
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={(e) => handleListScroll(e, true)}
              >
                Load More
              </Button>
            </Box>
          ) : null} */}
            </Box>
          </>
        ) : null}
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={Boolean(showContestsList)}
          onClose={(e, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              return;
            }
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              // boxShadow: theme.shadows[5],
              outline: 'none',
              maxHeight: '80vh',
              maxWidth: '90vw',
              minWidth: '90vw',
              overflowY: 'hidden',
              borderRadius: 1,
              padding: 2,
              position: 'relative',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                padding: '4px',
                background: theme.palette.background.default,
                ':hover': {
                  backgroundColor: '#d3d3d3',
                },
              }}
              onClick={(e) => {
                setShowContestsList(null);
                setSelectedContest('');
              }}
            >
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
            <Typography variant="body1">
              Select a contest to link it to the job.
            </Typography>
            <Box
              sx={{
                marginTop: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ContestsList
                selectedContest={selectedContest}
                setSelectedContest={setSelectedContest}
                handleContestLinking={handleContestLinking}
                processing={processing}
                showContestsList={showContestsList}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    </Fixed>
  );
};

export default Jobs;
