import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch } from 'react-redux';
import getAllContests from 'redux/actions/Common/getAllContests';
import PropTypes from 'prop-types';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
} from '@mui/material';
import moment from 'moment';
import {
  Check,
  Close,
  Delete,
  Edit,
  Group,
  Link,
  Redo,
  Replay,
} from '@mui/icons-material';
import deleteContest from 'redux/actions/Common/deleteContest';
import updateContest from 'redux/actions/Common/updateContest';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

const columns = [
  // { field: 'id', headerName: 'ID', width: 70 },
  // {
  //   field: 'select',
  //   headerName: 'Select',
  //   width: 70,
  //   renderCell: (params) => (
  //     <Radio
  //       onChange={params?.value?.onChange}
  //     />
  //   ),
  // },
  {
    field: 'contestTitle',
    headerName: 'Contest Title',
    width: 280,
    headerClassName: 'header',
  },
  {
    field: 'contestType',
    headerName: 'Contest Type',
    width: 160,
    headerClassName: 'header',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    headerClassName: 'header',
  },
  {
    field: 'contestEntries',
    headerName: 'Contest Entries',
    width: 150,
    headerClassName: 'header',
  },
  {
    field: 'startOn',
    headerName: 'Start Date',
    width: 220,
    headerClassName: 'header',
    valueGetter: (params) => {
      return params.value
        ? `${moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}`
        : '-';
    },
  },
  {
    field: 'endOn',
    headerName: 'End Date',
    width: 220,
    headerClassName: 'header',
    valueGetter: (params) => {
      return params.value
        ? `${moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}`
        : '-';
    },
  },
  // {
  //   field: 'actions',
  //   headerName: 'Actions',
  //   width: 200,
  //   headerClassName: 'header',
  //   renderCell: (params) => (
  //     <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
  //       <Tooltip title="Copy Private Link" placement="top" arrow>
  //         <IconButton
  //           onClick={async () => {
  //             try {
  //               await navigator.clipboard.writeText(
  //                 `${actionCodeBaseUrl}contests/${params?.value?.slug}?src='outbound'`,
  //               );
  //               params?.value?.onLinkCopiedCalback();
  //             } catch (err) {
  //               params?.value?.onLinkCopiedFailureCallback();
  //               console.error('Failed to copy: ', err);
  //             }
  //           }}
  //         >
  //           <Link />
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title="View Participants" placement="top" arrow>
  //         <IconButton
  //           onClick={() => {
  //             params?.value?.onClickViewParticipants();
  //           }}
  //         >
  //           <Group />
  //         </IconButton>
  //       </Tooltip>
  //     </Box>
  //   ),
  // },
];

const ContestsList = ({
  setShowContestsList,
  selectedContest,
  setSelectedContest,
  handleContestLinking,
  processing,
  showContestsList,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const dataGridRef = useGridApiRef(null);
  const [contests, setContests] = useState([]);
  const [contestType, setContestType] = useState('all');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [deleteDialogOperation, setDeleteDialogOperation] = useState('delete');
  const [contestVisibility, setContestVisibility] = useState(['published']);

  const dispatch = useDispatch();

  const handleUpdateContestLiveStatus = (e, contestId) => {
    console.log(e.target.checked);
    dispatch(
      updateContest(
        contestId,
        { status: e.target.checked ? 'active' : 'closed' },
        () => {
          dispatch(
            getAllContests(contestType, contestVisibility, (data) =>
              setContests(data),
            ),
          );
          alert('Contest updated');
        },
        (e) => {
          alert(e || 'Failed to update');
        },
      ),
    );
  };

  const handleContestDelete = () => {
    dispatch(
      deleteContest(
        showConfirmDeleteDialog,
        deleteDialogOperation === 'delete',
        () => {
          alert('Operation successful');
          dispatch(
            getAllContests(contestType, contestVisibility, (data) => {
              setContests(data);
              setShowConfirmDeleteDialog(null);
            }),
          );
        },
        (e) => {
          alert(e || 'Failed to delete contest');
          setShowConfirmDeleteDialog(null);
        },
      ),
    );
  };

  useEffect(() => {
    if (
      selectedContest &&
      showContestsList &&
      dataGridRef.current &&
      dataGridRef.current.setRowSelectionModel &&
      typeof dataGridRef.current.setRowSelectionModel === 'function'
    ) {
      dataGridRef.current.setRowSelectionModel([selectedContest]);
    }
  }, [selectedContest, dataGridRef.current, showContestsList]);

  useEffect(() => {
    dispatch(
      getAllContests(contestType, contestVisibility, (data) => {
        setContests(
          data?.map((competition) => {
            return {
              ...competition,
              id: competition?.id,
              contestTitle: competition?.title,
              contestType:
                competition?.competitionType === 'real_competition'
                  ? 'Qualification AI'
                  : competition?.competitionType === 'private_competition'
                  ? 'Private'
                  : competition?.competitionType === 'semi_private_competition'
                  ? 'Public'
                  : 'Qualificaiton AI',
              contestEntries: competition?.startOn
                ? '-'
                : competition?.maxLimit,
              // actions: {
              //   slug: competition?.slug,
              //   onClick: () => {},
              //   onClickViewParticipants: () => {
              //     history.push({
              //       pathname: `/contests/${competition?.slug}/participants`,
              //       state: {
              //         competitionId: competition?.competitionId,
              //       },
              //     });
              //   },
              //   onLinkCopiedCalback: () => {
              //     dispatch(
              //       showAlert({
              //         message: 'Link copied to clipboard.',
              //         showCross: true,
              //         title: null,
              //         type: 'success',
              //         autoHideDuration: 2000,
              //         vertical: 'top',
              //         horizontal: 'center',
              //       }),
              //     );
              //   },
              //   onLinkCopiedFailureCallback: () => {
              //     dispatch(
              //       showAlert({
              //         message: 'Failed to copy link.',
              //         showCross: true,
              //         title: null,
              //         type: 'error',
              //         autoHideDuration: 2000,
              //         vertical: 'top',
              //         horizontal: 'center',
              //       }),
              //     );
              //   },
              // },
            };
          }),
        );
      }),
    );
  }, [contestType, contestVisibility]);

  useEffect(() => {
    dispatch(
      getAllContests(contestType, contestVisibility, (data) =>
        setContests(
          data?.map((competition) => {
            return {
              ...competition,
              id: competition?.id,
              contestTitle: competition?.title,
              contestType:
                competition?.competitionType === 'real_competition'
                  ? 'Qualification AI'
                  : competition?.competitionType === 'private_competition'
                  ? 'Private'
                  : competition?.competitionType === 'semi_private_competition'
                  ? 'Public'
                  : 'Qualificaiton AI',
              contestEntries: competition?.startOn
                ? '-'
                : competition?.maxLimit,
              select: {
                checked: selectedContest === competition?.id,
                onChange: (e) => {
                  setSelectedContest(competition?.id);
                },
              },
            };
          }),
        ),
      ),
    );
  }, []);

  console.log('selectedContest', selectedContest);
  console.log('dataGridRef', dataGridRef);

  return (
    <Box
      width={1}
      height={1}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'column' }}
      position={'relative'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={2}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Box width={300}>
            <FormControl fullWidth>
              <InputLabel id="contest-type-label">Contest Type</InputLabel>
              <Select
                size="small"
                labelId="contest-type-label"
                id="contest-type"
                value={contestType}
                label="Contest Type"
                onChange={(e) => setContestType(e.target.value)}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'private_competition'}>Private</MenuItem>
                <MenuItem value={'real_competition'}>Contest</MenuItem>
                <MenuItem value={'practice_competition'}>Practice</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  fontSize: 12,
                }}
                component="legend"
              >
                Visibility
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={contestVisibility.indexOf('draft') > -1}
                      onChange={(e) => {
                        const {
                          target: { checked },
                        } = e;
                        if (checked) {
                          setContestVisibility((pre) => {
                            return [...pre, 'draft'];
                          });
                        } else {
                          setContestVisibility((pre) =>
                            pre.filter((v) => v !== 'draft'),
                          );
                        }
                      }}
                      name="draft"
                    />
                  }
                  label="Draft"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={contestVisibility.indexOf('published') > -1}
                      onChange={(e) => {
                        const {
                          target: { checked },
                        } = e;
                        console.log(checked);
                        if (checked) {
                          setContestVisibility((pre) => {
                            return [...pre, 'published'];
                          });
                        } else {
                          setContestVisibility((pre) =>
                            pre.filter((v) => v !== 'published'),
                          );
                        }
                      }}
                      name="published"
                    />
                  }
                  label="Published"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={contestVisibility.indexOf('deleted') > -1}
                      onChange={(e) => {
                        const {
                          target: { checked },
                        } = e;
                        if (checked) {
                          setContestVisibility((pre) => {
                            return [...pre, 'deleted'];
                          });
                        } else {
                          setContestVisibility((pre) =>
                            pre.filter((v) => v !== 'deleted'),
                          );
                        }
                      }}
                      name="deleted"
                    />
                  }
                  label="Deleted"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
        {selectedContest ? (
          <Button
            size="small"
            variant="contained"
            onClick={() => handleContestLinking()}
            startIcon={<Check />}
            disabled={processing}
          >
            Done
          </Button>
        ) : null}
      </Box>
      {contests && Array.isArray(contests) && contests.length > 0 ? (
        <>
          <Box
            sx={{
              width: 1,
              '& .header': {
                background: theme.palette.background.default,
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
              },
            }}
          >
            <DataGrid
              apiRef={dataGridRef}
              // autoHeight
              sx={{ height: 'calc(80vh - 180px)' }}
              rows={contests}
              columns={columns}
              rowSelection={true}
              disableRowSelectionOnClick={false}
              // hideFooter
              // checkboxSelection
              onRowSelectionModelChange={(id) => {
                setSelectedContest(id[0]);
              }}
            />
          </Box>
          {/* <TableContainer component={Paper} sx={{ marginTop: 1 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Contest Title</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Draft</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created On</TableCell>
                <TableCell align="center">Contest Entries</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Deleted</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contests.map((contest) => (
                <TableRow
                  key={contest?.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor:
                      contest?.id === state?.contestId ? '#d3d3d3' : 'none',
                  }}
                >
                  <TableCell component="th" scope="row">
                    {contest?.title}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {contest?.competitionType}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {contest?.draft ? 'true' : 'false'}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      size="small"
                      checked={contest?.status === 'active'}
                      onChange={(e) =>
                        handleUpdateContestLiveStatus(e, contest?.id)
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    {moment(contest?.createdOn).format('DD MMMM YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    {contest?.maxLimit || '-'}
                  </TableCell>
                  <TableCell align="center">
                    {contest?.startOn
                      ? moment(contest?.startOn).format('DD MMMM YYYY')
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {contest?.endOn
                      ? moment(contest?.endOn).format('DD MMMM YYYY')
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {contest?.deleted.toString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        history.push({
                          pathname:
                            '/f550aeea-0bb4-41e9-b615-7a6cf101e740/add-contest',
                          state: {
                            editContest: true,
                            contest: contest,
                          },
                        });
                      }}
                    >
                      <Edit />
                    </IconButton>
                    {contest?.deleted ? (
                      <Tooltip title="Undo Delete" placement="top" arrow>
                        <IconButton
                          onClick={() => {
                            setShowConfirmDeleteDialog(contest?.id);
                            setDeleteDialogOperation('undelete');
                          }}
                        >
                          <Replay />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setShowConfirmDeleteDialog(contest?.id);
                          setDeleteDialogOperation('delete');
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                    {contest?.competitionType === 'private_competition' ? (
                      <Tooltip title="Copy Link" placement="top" arrow>
                        <IconButton
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(
                                `https://app.qualification.ai/contests/${contest?.slug}`,
                              );
                              alert('Link Copied.');
                            } catch (err) {
                              alert('Failed to copy link.');
                              console.error('Failed to copy: ', err);
                            }
                          }}
                        >
                          <Link />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <Tooltip title="View Participants" placement="top" arrow>
                      <IconButton
                        onClick={() => {
                          history.push(
                            `${pathname}/${contest?.id}/participants`,
                          );
                        }}
                      >
                        <Group />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">{contest?.accessCode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        </>
      ) : null}
      {/* <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => setShowConfirmDeleteDialog(null)}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Are you sure want to{' '}
          {deleteDialogOperation === 'delete' ? 'delete' : 'undo delete'} this
          contest?
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowConfirmDeleteDialog(null)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleContestDelete()}
            autoFocus
            color={deleteDialogOperation === 'delete' ? 'error' : 'info'}
          >
            {deleteDialogOperation === 'delete' ? 'DELETE' : 'UNDO DELETE'}
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};

ContestsList.propTypes = {
  setShowContestsList: PropTypes.func,
  setSelectedContest: PropTypes.func,
  handleContestLinking: PropTypes.func,
  selectedContest: PropTypes.string,
  processing: PropTypes.bool,
  showContestsList: PropTypes.bool,
};

export default ContestsList;
