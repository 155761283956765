import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAllConversations = (limit, lastSeen, callback) => {
  return async (dispatch) => {
    if (lastSeen) {
      dispatch({ type: 'FETHING_ALL_CONVERSATIONS_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETHING_ALL_CONVERSATIONS' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/cs/conversation`, {
            params: {
              'request-id': reqId,
              lastSeen,
              limit,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            if (lastSeen) {
              await dispatch({
                type: 'ADD_CONVERSATIONS_TO_ALL_CONVERSATIONS',
                payload: {
                  conversations: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'FETHING_ALL_CONVERSATIONS_DONE',
                payload: {
                  conversations: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETHING_ALL_CONVERSATIONS_FAILED',
              payload: `Failed to get conversations`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETHING_ALL_CONVERSATIONS_FAILED',
            payload: `Failed to get conversations`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETHING_ALL_CONVERSATIONS_FAILED',
        payload: `Failed to get conversations`,
      });
    }
  };
};

export default getAllConversations;
