const initialstate = {
  sending: null,
  sent: null,
  sendingFailed: null,
  error: null,
  errorText: null,
};

const inviteUserReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'SENDING_INVITE':
      return Object.assign({}, state, {
        sending: true,
      });
    case 'SENDING_INVITE_SUCCESSFUL':
      return Object.assign({}, state, {
        sending: false,
        sent: true,
        sendingFailed: false,
        error: false,
        errorText: '',
      });
    case 'SENDING_INVITE_FAILED':
      return Object.assign({}, state, {
        sending: false,
        sent: false,
        sendingFailed: true,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default inviteUserReducer;
