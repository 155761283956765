import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const inviteUser = (
  payload,
  callback,
  errCallback,
) => {
  return async (dispatch) => {
    dispatch({ type: 'SENDING_INVITE' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.post(
          '/ads/invitations',
          payload,
          {
            params: {},
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.status === 200) {
          dispatch({
            type: 'SENDING_INVITE_SUCCESSFUL',
          });
          if (typeof callback === 'function') callback();
        } else {
          dispatch({
            type: 'SENDING_INVITE_FAILED',
            payload: response.data,
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'SENDING_INVITE_FAILED',
          payload:
            e.response.status === 400 ? 'User already exists' : e.message,
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default inviteUser;
