const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  organizations: [],
  emptyList: null,
  lastTime: null,
};

const getAllOrganizationsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_ORGANIZATIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_ORGANIZATIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_ORGANIZATIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        organizations: action.payload.organizations,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    // case 'UPDATE_USER_IN_ALL_USERS': {
    //   const index = state.participantsDetails.findIndex(
    //     ({ participation }) =>
    //       participation.id === action.payload.participationId,
    //   );
    //   let newArr = [...state.participantsDetails];
    //   if (newArr?.[index]?.participation !== undefined) {
    //     newArr[index]['participation'][action.payload.property] =
    //       action.payload.value;
    //   } else {
    //     newArr[index]['participation'][action.payload.property] =
    //       action.payload.value;
    //   }
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     organizations: newArr,
    //     lastTime: state.lastTime,
    //     emptyList: false,
    //     error: false,
    //     errorText: '',
    //   });
    // }
    case 'ADD_ORGANIZATIONS_TO_ALL_ORGANIZATIONS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        organizations: Array.isArray(action.payload.organizations)
          ? state.organizations
            ? state.organizations.concat(action.payload.organizations)
            : action.payload.organizations
          : state.organizations,
        emptyList:
          Array.isArray(action.payload.organizations) &&
          (action.payload.organizations.length === 0 ||
            action.payload.organizations.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GETTING_ALL_ORGANIZATIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        organizations: [],
        emptyList: false,
        lastTime: '',
      });
    case 'GETTING_ALL_ORGANIZATIONS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllOrganizationsReducer;
