import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
  Autocomplete,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Article,
  ChevronRight,
  Close,
  ContentCopy,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  RecordVoiceOver,
  Redo,
  Replay,
  Send,
  StopCircle,
  Troubleshoot,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllBotsRequests from 'redux/actions/Common/getAllBotsRequests';
import RichTextEditor from 'components/RichTextEditor';

import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import updateBotRequestStatus from 'redux/actions/Common/updateBotRequestStatus';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BotsRequests = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const drawerContentRef = useRef(null);

  const [botRequests, setBotRequests] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showBotRequest, setShowBotRequest] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(
      getAllBotsRequests((data) => {
        console.log(data);
        setBotRequests(data?.botRequests);
      }),
    );
  }, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
        >
          <TableContainer
            component={Paper}
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
            // onScroll={handleListScroll}
          >
            <Table
              sx={{ minWidth: 750 }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                <TableRow>
                  <TableCell align="center">
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Bot Details
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Date
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant={'caption'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                    >
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {botRequests.map((bot, i) => (
                  <TableRow
                    key={i}
                    // sx={{
                    //   '&:last-child td, &:last-child th': {
                    //     border: 0,
                    //   },
                    // }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      <Tooltip title="Bot Details" placement="left" arrow>
                        <Article
                          sx={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                            marginRight: 2,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowBotRequest(bot);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant={'caption'}>
                        {moment(bot?.createdOn).format('DD MMMM YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant={'caption'}>{bot?.status}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {bot?.status !== 'created' ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => {
                            dispatch(
                              updateBotRequestStatus('created', bot?.id, () => {
                                dispatch(
                                  getAllBotsRequests((data) =>
                                    setBotRequests(data?.botRequests),
                                  ),
                                );
                              }),
                            );
                          }}
                        >
                          Update Status
                        </Button>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {/* <TableFooter>
                <TableRow>
                  <TableCell
                    variant="footer"
                    colSpan={6}
                    sx={{
                      padding: 0,
                    }}
                  >
                    {fetchingPagination ? (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          color="primary"
                          onClick={handleListScroll}
                          // className="searchSettings"
                          disabled
                          // fullWidth
                        >
                          <Typography color="textPrimary">
                            Loading more bots ...{' '}
                          </Typography>
                          <CircularProgress size={20} />
                        </Button>
                      </Box>
                    ) : emptyList === false && bots.length >= 10 ? (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          // type="submit"
                          // fullWidth
                          color="primary"
                          onClick={(e) => handleListScroll(e, true)}
                          // className="searchSettings"
                        >
                          Load More
                        </Button>
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableFooter> */}
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Drawer
        open={Boolean(showBotRequest)}
        anchor="right"
        onClose={(e) => {
          setShowBotRequest(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <IconButton
            onClick={(e) => {
              setShowBotRequest(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Bot Details
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Vertical ?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.domain}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Designation ?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.designation}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.botName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Voice
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.gender}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Role
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.role}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Experience (in Years)
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.experience}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Company Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companyName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            About Company
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.aboutCompany}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Company Size
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companySize}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            What might be the top challenge for buyer?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.challenges}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Products the compete with yours
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.competitors?.map((cmpt) => cmpt?.name)?.join(',')}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Other information
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.newInfo}
          </Typography>
        </Box>
      </Drawer>
    </Fixed>
  );
};

export default BotsRequests;
