import axios from 'axios';

let baseURL = '';

if (process.env.REACT_APP_TARGET_ENV === 'local') {
  baseURL = 'http://localhost:5058/api/';
}

if (process.env.REACT_APP_TARGET_ENV === 'development') {
  baseURL = 'https://app.sellingbees.com/api/';
}

if (process.env.REACT_APP_TARGET_ENV === 'production') {
  baseURL = 'https://app.qualification.ai/api/';
}

export default axios.create({
  baseURL,
});
