import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  DialogContentText,
} from '@mui/material';
import { Lock, Mic, Send, StopCircle } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import getAllConversations from 'redux/actions/Common/getAllConversations';
// import getMessageResponse from 'redux/actions/Common/getMessageResponse';
// import getConversationById from 'redux/actions/Common/getConversationById';
// import createNewConversation from 'redux/actions/Common/createNewConversation';

import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
// import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
// import uploadAudio from 'redux/actions/Common/uploadAudio';
// import verifyAudioUpload from 'redux/actions/Common/verifyAudioUpload';
// import getMergedConversation from 'redux/actions/Common/getMergedConversation';
// import getUploadAudioUrlForConversation from 'redux/actions/Common/getUploadAudioUrlForConversation';

import * as TranscribeClient from './components/transcribe';
import getCreds from 'redux/actions/Common/getCreds';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AWSTranscribePoc = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const chatsScrollview = useRef(null);
  const awsCreds = useSelector((state) => state.awsCreds);

  const [message, setMessage] = useState('');
  const [recorder, setRecorder] = useState(null);
  const [showPermissionDeniedError, setShowPermissionDeniedError] = useState(
    false,
  );
  const [permissionDenied, setPermissionDenied] = useState(null);
  const [permissionPersisting, setPermissionPersisting] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recording, setRecording] = useState(null);
  const streamRef = useRef();
  const playBackRef = useRef();
  const timer = useRef(null);
  const timer2 = useRef(null);
  const [timerSs, setTimerSs] = useState(0);
  const [timerMs, setTimerMs] = useState(0);

  const [mediaStream, setMediaStream] = useState(null);
  const [sampleRate, setSampleRate] = useState(48000);
  const [availableAudioDevices, setAvailableAudioDevices] = useState(null);

  const [firstRenderAudio, setFirstRenderAudio] = useState(false);
  const [audioSource, setAudioSource] = useState('');
  const [showMediaSelectOptions, setShowMediaSelectOptions] = useState(false);

  const [gettingResponse, setGettingResponse] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const getRecordingPermission = async () => {
    try {
      const timeStampBeforePermission = Date.now();
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      const timeStampAfterPermission = Date.now();
      setPermissionDenied(false);
      if (timeStampAfterPermission - timeStampBeforePermission < 1500) {
        setPermissionPersisting(true);
      } else {
        setPermissionPersisting(false);
      }
      streamRef.current = stream;
      const sampleRateBlock = stream.getAudioTracks()[0].getSettings()
        .sampleRate;
      setSampleRate(sampleRateBlock);
      console.log('sampleRateBlock', sampleRateBlock);
      setMediaStream(stream);

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.');
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setAvailableAudioDevices(
        devices.filter((device) => device.kind === 'audioinput'),
      );
    } catch (e) {
      console.log(e?.name);
      if (e?.name === 'NotAllowedError') {
        setShowPermissionDeniedError(true);
        setPermissionDenied(true);
        setError(true);
        setErrorMessage(
          'Camera and microphone are blocked. Please allow the permissions and start recording.',
        );
      } else {
        setError(true);
        setErrorMessage(
          'Oops we are unable to detect your camera. Please refresh this page and try again.',
        );
      }
    }
  };

  const handleRecordClick = async () => {
    if (permissionDenied) {
      setShowPermissionDeniedError(true);
    } else {
      try {
        setSelectedAudio(null);
        setRecordedBlobs([]);
        setRecording(true);
        startTimer();
        if (playBackRef && playBackRef.current) {
          playBackRef.current.src = null;
        }
        if (
          awsCreds?.expiry === 0 ||
          new Date().getTime() - awsCreds?.expiry >= 0
        ) {
          dispatch(
            getCreds(async (data) => {
              await TranscribeClient.startRecording(
                {
                  sessionToken: data?.sessionToken,
                  accessKeyId: data?.accessKey,
                  secretAccessKey: data?.secretKey,
                },
                'en-US',
                mediaStream,
                sampleRate,
                (data) => setMessage((prev) => `${prev}${data}`),
              );
            }),
          );
        } else {
          await TranscribeClient.startRecording(
            {
              sessionToken: awsCreds?.creds?.sessionToken,
              accessKeyId: awsCreds?.creds?.accessKey,
              secretAccessKey: awsCreds?.creds?.secretKey,
            },
            'en-US',
            mediaStream,
            sampleRate,
            (data) => setMessage((prev) => `${prev}${data}`),
          );
        }
      } catch (error) {
        handleRecordStopClick();
        alert('An error occurred while recording: ' + error.message);
      }
    }

    // window.clearTranscription();
  };

  const handleRecordStopClick = async () => {
    await recorder.stopRecording();
    let blob = await recorder.getBlob();
    // console.log('blob', blob);
    setSelectedAudio(blob);
    stopTimer();
    setRecording(false);
    TranscribeClient.stopRecording();
  };

  const handleClearRecordingClick = () => {
    setSelectedAudio(null);
    setRecordedBlobs([]);
    setRecording(null);
    setMessage('');
    if (selectedAudio) {
      playBackRef.current.src = null;
    }
    if (streamRef && streamRef.current)
      streamRef.current.getTracks().forEach((track) => track.stop());
    // if (browser?.name !== 'safari') {
    // getRecordingPermission();
    // }
  };

  // const playRecordedVideo = () => {
  //   const audioBlob = new Blob(recordedBlobs, { type: 'audio/webm' });
  //   setSelectedAudio(audioBlob);
  // };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimerSs((prev) => {
        if (prev === 59) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
    timer2.current = setInterval(() => {
      setTimerMs((prev) => prev + 1);
    }, 60000);
  };

  const stopTimer = () => {
    // console.log(timer.current, timer2.current);
    clearInterval(timer.current);
    clearInterval(timer2.current);
    setTimerSs(0);
    setTimerMs(0);
  };

  const handleGetResponse = () => {};

  // useEffect(() => {
  //   if (recording === false) {
  //     // if (!selectedConversation?.beTranscription) {
  //     SpeechRecognition.stopListening();
  //     // }
  //     // handleGetResponse();
  //   }
  // }, [recording]);

  // useEffect(() => {
  //   if (transcript) {
  //     setMessage(transcript);
  //   }
  // }, [transcript]);

  useEffect(() => {
    if (selectedAudio && recording === false) {
      let recordedVideoUrl = window.URL.createObjectURL(selectedAudio);
      // setRecording(false);
      if (playBackRef.current) {
        playBackRef.current.src = recordedVideoUrl;
        // playBackRef.current.play();
      }
    }
    // if (streamRef && streamRef.current)
    //   streamRef.current.getTracks().forEach((track) => track.stop());
  }, [selectedAudio, recording, playBackRef?.current]);

  useEffect(() => {
    let options = {
      mimeType: 'audio/webm',
    };
    try {
      if (mediaStream !== null) {
        setRecorder(new RecordRTCPromisesHandler(mediaStream, options));
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage('Failed to get media device');
    }
  }, [mediaStream]);

  useEffect(() => {
    if (
      recording === true &&
      recordedBlobs.length === 0 &&
      selectedAudio === null
    ) {
      try {
        recorder.onerror = (e) => {
          console.log(e);
          setError(true);
          setErrorMessage(
            'Oops! Something went wrong. Please refresh this page and try again. ',
          );
        };
        recorder.startRecording();
      } catch (e) {
        console.log(e);
        console.log('mediaRecorder API not supported for this browser');
        setError(true);
        setErrorMessage(
          'Oops! Something went wrong. Please refresh this page and try again. ',
        );
      }
    }
  }, [selectedAudio, recordedBlobs, recording]);

  useEffect(() => {
    if (!firstRenderAudio) {
      if (availableAudioDevices && availableAudioDevices.length > 0) {
        setAudioSource(availableAudioDevices[0].deviceId);
        setFirstRenderAudio(true);
      }
    }
  }, [availableAudioDevices]);

  useEffect(() => {
    getRecordingPermission();
    return () => {
      if (streamRef && streamRef.current)
        streamRef.current.getTracks().forEach((track) => track.stop());
    };
  }, []);

  // console.log('awsCreds', awsCreds);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                  }
            }
          ></Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 220px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 220px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? '120px' : 0,
              }}
              ref={chatsScrollview}
            >
              {selectedAudio ? (
                <Box
                  sx={{
                    border: '1px solid #d3d3d3',
                    borderRadius: 1,
                    padding: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <TextField
                    autoFocus
                    label="Message"
                    multiline
                    rows={6}
                    variant="outlined"
                    color="primary"
                    size="large"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                  />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    alignItems={'end'}
                  >
                    {recording === false && selectedAudio !== null ? (
                      <audio
                        preload="true"
                        id="audioPreview"
                        ref={playBackRef}
                        playsInline
                        controls
                        onPlay={(e) => {
                          console.log(e);
                          // handleTrackEvent(e, 'audio playing');
                        }}
                        onPause={(e) => console.log(e)}
                        onEnded={(e) => console.log(e)}
                        onError={(e) => {
                          // dispatch(
                          //   logError({
                          //     message: e?.message,
                          //     data: {
                          //       formatted: {
                          //         errorText: e?.message,
                          //         severity: 'error',
                          //       },
                          //       raw: e,
                          //     },
                          //   }),
                          // );
                          console.log(e);
                          // handleTrackEvent(e, 'error playing audio');
                        }}
                        // onLoadedMetadata={(e) => console.log(e)}
                      ></audio>
                    ) : null}
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      gap={2}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          handleClearRecordingClick();
                        }}
                      >
                        Clear
                      </Button>
                      <IconButton
                        size="small"
                        disabled={!selectedAudio}
                        onClick={() => handleGetResponse(true)}
                      >
                        {gettingResponse ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Send
                            fontSize="small"
                            color={selectedAudio ? 'primary' : 'default'}
                          />
                        )}
                      </IconButton>
                      <IconButton
                        size="small"
                        // disabled={!message.trim()}
                        onClick={() => {
                          if (recording) {
                            handleRecordStopClick();
                          } else {
                            handleRecordClick();
                          }
                        }}
                      >
                        {recording ? (
                          <Tooltip title="Stop Recording" arrow placement="top">
                            <StopCircle fontSize="medium" color="primary" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Record" arrow placement="top">
                            <Mic fontSize="medium" color={'primary'} />
                          </Tooltip>
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <TextField
                  autoFocus
                  label="Message"
                  multiline
                  rows={6}
                  variant="outlined"
                  color="primary"
                  size="large"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap={2}
                        >
                          {recording && (
                            <Box sx={{}}>
                              <Typography>
                                {`0${timerMs} `}:
                                {timerSs > 9
                                  ? timerSs < 0
                                    ? '00'
                                    : ` ${timerSs}`
                                  : ` 0${timerSs}`}
                              </Typography>
                            </Box>
                          )}
                          <IconButton
                            size="small"
                            disabled={!message.trim()}
                            onClick={() => handleGetResponse(false)}
                          >
                            {gettingResponse ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Send
                                fontSize="small"
                                color={message?.trim() ? 'primary' : 'default'}
                              />
                            )}
                          </IconButton>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <IconButton
                              size="small"
                              // disabled={!message.trim()}
                              onClick={() => {
                                if (recording) {
                                  handleRecordStopClick();
                                } else {
                                  handleRecordClick();
                                }
                              }}
                            >
                              {recording ? (
                                <StopCircle fontSize="medium" color="primary" />
                              ) : (
                                <Tooltip title="Record" arrow placement="top">
                                  <Mic fontSize="medium" color={'primary'} />
                                </Tooltip>
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                padding: 2,
                background: '#fff',
                width: '100%',
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default AWSTranscribePoc;
