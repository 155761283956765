import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Slide,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Fade,
  Icon,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Modal,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Avatar,
  Rating,
  Chip,
} from '@mui/material';

import { Experiment, Fixed } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { CardWithColorAccent } from 'blocks/cards';
import {
  ArrowBack,
  Check,
  Circle,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LiveHelp,
  PauseCircle,
  PlayArrow,
  PlayCircle,
  Redo,
  Replay,
  Settings,
  SettingsVoice,
  StopCircle,
  VideoCall,
  VideoCallRounded,
  Videocam,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import getElevatorPitchReport from 'redux/actions/Common/getElevatorPitchReport';
import ReportTabs from 'components/ReportTabs/ReportTabs';

const ContestReport = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { participationId } = useParams();
  const { comingFrom, activityType } = state || {
    comingFrom: null,
    activityType: null,
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [scriptReadingResults, setScriptReadingResult] = useState(null);
  const [pitchResult, setPitchResult] = useState(null);
  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [pitchFillerWords, setPitchFillerWords] = useState([]);
  const [pitchRepeatWords, setPitchRepeatWords] = useState([]);
  const [pitchFillerWordsRegexp, setPitchFillerWordsRegexp] = useState(null);
  const [pitchRepeatWordsRegexp, setPitchRepeatWordsRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('fillers');
  const [currentParam1, setCurrentParam1] = useState('fillers');

  const [pitchReport, setPitchReport] = useState(null);
  const [fillerWords, setFillerWords] = useState({});
  const [repeatWords, setRepeatWords] = useState({});
  const [epfillerWordsRegexp, setepFillerWordsRegexp] = useState({});
  const [eprepeatWordsRegexp, setepRepeatWordRegexp] = useState({});
  const [toolType, setToolType] = useState('');

  // useEffect(() => {
  //   if (fetching) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [fetching]);

  useEffect(() => {
    pitchReport?.forEach((report, index) => {
      if (
        report?.pitchReport?.fillerInfo?.fillers &&
        Object.keys(report?.pitchReport?.fillerInfo?.fillers) &&
        Object.keys(report?.pitchReport?.fillerInfo?.fillers).length > 0
      ) {
        setFillerWords({
          ...fillerWords,
          [index]: Object.keys(report?.pitchReport?.fillerInfo?.fillers),
        });
      }
      if (
        report?.pitchReport?.repetitionInfo?.repetitions &&
        Object.keys(report?.pitchReport?.repetitionInfo?.repetitions) &&
        Object.keys(report?.pitchReport?.repetitionInfo?.repetitions).length > 0
      ) {
        setRepeatWords({
          ...repeatWords,
          [index]: Object.keys(
            report?.pitchReport?.repetitionInfo?.repetitions,
          ),
        });
      }
    });
  }, [pitchReport]);

  useEffect(() => {
    pitchReport?.forEach((report, index) => {
      if (
        fillerWords &&
        fillerWords?.[index] &&
        Array.isArray(fillerWords?.[index]) &&
        fillerWords?.[index].length > 0
      ) {
        setepFillerWordsRegexp({
          ...epfillerWordsRegexp,
          [index]: new RegExp(
            fillerWords?.[index].map((w) => `{${w.trim()}}`).join('|'),
            'ig',
          ),
        });
      }
    });
  }, [fillerWords]);

  useEffect(() => {
    pitchReport?.forEach((report, index) => {
      if (
        repeatWords &&
        repeatWords?.[index] &&
        Array.isArray(repeatWords?.[index]) &&
        repeatWords?.[index].length > 0
      ) {
        setepRepeatWordRegexp({
          ...eprepeatWordsRegexp,
          [index]: new RegExp(
            repeatWords?.[index].map((w) => `{${w.trim()}}`).join('|'),
            'ig',
          ),
        });
      }
    });
  }, [repeatWords]);

  useEffect(() => {
    dispatch(
      getElevatorPitchReport(participationId, (data) => {
        setPitchReport(data?.reports);
        setToolType(data?.pitchType);
      }),
    );
  }, []);

  return (
    // <Experiment>
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        alignItems={'start'}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => history.back()}
          color="primary"
          size="small"
          sx={{ marginBottom: 2 }}
        >
          Go Back
        </Button>
        {loading ? (
          <Box
            width={1}
            height={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <CircularProgress size={20} />
            <Typography variant="body1" color={'text.primary'} mt={4}>
              Getting report data. Please wait ...
            </Typography>
          </Box>
        ) : (
          <>
            <Box>
              <Typography variant="body1" fontWeight={'bold'}>
                {toolType === 'script_practice'
                  ? 'Script Practice Report'
                  : toolType === 'pitch_practice'
                  ? 'Pitch Practice Report'
                  : toolType === 'async_interview'
                  ? 'Interview Practice Report'
                  : ''}
              </Typography>
              {pitchReport?.map((report, reportIndex) => {
                return (
                  <Box
                    key={reportIndex}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                  >
                    {toolType === 'async_interview' ? (
                      <Box sx={{ marginTop: 2 }}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{
                            wordBreak: 'break-word',
                            fontWeight: 'bold',
                            fontSize: 16,
                          }}
                        >
                          {`Question ${reportIndex + 1} of ${
                            pitchReport?.length
                          }`}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{
                            wordBreak: 'break-word',
                            fontSize: 18,
                            marginTop: 5,
                          }}
                        >
                          {report?.customFields?.question?.question}
                        </Typography>
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        width: 1,
                        height: 1,
                        padding: 1,
                        borderRadius: 2,
                        border: '1px solid #d3d3d3',
                        marginTop: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 1,
                          height: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'stretch',
                          }}
                        >
                          <Box
                            sx={{
                              flex: 1,
                              borderRadius: 8,
                              maxHeight: 400,
                              minHeight: 400,
                            }}
                          >
                            <video
                              src={report?.pitchReport?.videoUrl}
                              controls
                              style={{
                                objectFit: 'contain',
                                backgroundColor: 'rgba(0,0,0,1)',
                                width: '100%',
                                height: '100%',
                                borderRadius: 8,
                              }}
                              onError={(e) => {
                                console.log(e);
                              }}
                              controlsList="nodownload"
                              disablePictureInPicture
                              onPlay={(e) => {
                                // handleTrackEvent(e, 'video playing');
                              }}
                              onPause={(e) => {
                                // handleTrackEvent(e, 'pitch video paused')
                              }}
                              onEnded={(e) => {
                                // handleTrackEvent(e, 'pitch video ended')
                              }}
                            ></video>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              flex: 1,
                            }}
                          >
                            <ReportTabs
                              currentParam={currentParam}
                              setCurrentParam={setCurrentParam}
                              fillers={report?.pitchReport?.fillerInfo?.fillers}
                              fillerTranscript={
                                report?.pitchReport?.fillerInfo?.transcript
                              }
                              fillerWordsRegexp={
                                epfillerWordsRegexp?.[reportIndex]
                              }
                              repetitions={
                                report?.pitchReport?.repetitionInfo?.repetitions
                              }
                              repetitionsTranscript={
                                report?.pitchReport?.repetitionInfo?.transcript
                              }
                              repeatWordsRegexp={
                                eprepeatWordsRegexp?.[reportIndex]
                              }
                              pauseInfo={report?.pitchReport?.pauseInfo}
                              paceArr={report?.pitchReport?.pace}
                              showProgress={
                                report?.reportStatus === 'in_process'
                                  ? true
                                  : report?.reportStatus === 'calculated'
                                  ? false
                                  : null
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {toolType === 'async_interview' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            padding: 1,
                            borderRadius: 2,
                            border: '1px solid #d3d3d3',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <Typography
                            variant="body1"
                            color={'text.Secondary'}
                            fontWeight={'bold'}
                          >
                            Improvements
                          </Typography>
                          {report?.aiReport?.improvements?.map((imp) => {
                            return (
                              <Typography
                                variant="body1"
                                color={'text.primary'}
                              >
                                {imp}
                              </Typography>
                            );
                          })}
                        </Box>
                        <Box
                          sx={{
                            padding: 1,
                            borderRadius: 2,
                            border: '1px solid #d3d3d3',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <Typography
                            variant="body1"
                            color={'text.Secondary'}
                            fontWeight={'bold'}
                          >
                            Reviews
                          </Typography>
                          {report?.aiReport?.reviews?.map((imp) => {
                            return (
                              <Typography
                                variant="body1"
                                color={'text.primary'}
                              >
                                {imp}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </Fixed>
    // </Experiment>
  );
};

export default ContestReport;
