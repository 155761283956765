import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAllGeneratedPlaybooks = (limit, lastTime, callback) => {
  return async (dispatch) => {
    if (lastTime) {
      dispatch({
        type: 'GETTING_ALL_GENERATED_PLAYBOOK_WITH_PAGINATION',
      });
    } else {
      dispatch({ type: 'GETTING_ALL_GENERATED_PLAYBOOK' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/ads/ai/playbook`, {
            params: {
              'request-id': reqId,
              limit: limit,
              lastSeen: lastTime,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            if (lastTime) {
              await dispatch({
                type: 'ADD_PLAYBOOKS_TO_ALL_GENERATED_PLAYBOOKS',
                payload: {
                  playbooks: response?.data,
                  lastTime: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'GETTING_ALL_GENERATED_PLAYBOOK_DONE',
                payload: {
                  playbooks: response?.data,
                  lastTime: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GETTING_ALL_GENERATED_PLAYBOOK_FAILED',
              payload: `Failed to fetch generated playbooks`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GETTING_ALL_GENERATED_PLAYBOOK_FAILED',
            payload: `Failed to fetch generated playbooks`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GETTING_ALL_GENERATED_PLAYBOOK_FAILED',
        payload: `Failed to fetch generated playbooks`,
      });
    }
  };
};

export default getAllGeneratedPlaybooks;
