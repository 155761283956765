import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const addUpdatePrivacyTos = (type, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_PRIVACY_TOS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/ads/tos-and-privacy`, payload, {
            params: {
              'request-id': reqId,
              type: type,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_PRIVACY_TOS_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'UPDATING_PRIVACY_TOS_FAILED',
              payload: `Failed to update privacy and tos`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_PRIVACY_TOS_FAILED',
            payload: `Failed to update privacy and tos`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'UPDATING_PRIVACY_TOS_FAILED',
        payload: `Failed to update privacy and tos`,
      });
    }
  };
};

export default addUpdatePrivacyTos;
