import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Tabs,
  Tab,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Article,
  Audiotrack,
  ChevronLeft,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllVideoDataPoints from 'redux/actions/Common/getAllVideoDataPoints';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import SkillTagInput from './components/skillTagInput';
import labelDataPoint from 'redux/actions/Common/labelDataPoint';
import addLabels from 'redux/actions/Common/addLabels';
import getLabels from 'redux/actions/Common/getLabels';
import getAllSalesReviews from 'redux/actions/Common/getAllSalesReviews';
import getMessageResponse from 'redux/actions/Common/getMessageResponse';
import getConversationById from 'redux/actions/Common/getConversationById';
import createNewConversation from 'redux/actions/Common/createNewConversation';

import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import uploadAudio from 'redux/actions/Common/uploadAudio';
import verifyAudioUpload from 'redux/actions/Common/verifyAudioUpload';
import getMergedConversation from 'redux/actions/Common/getMergedConversation';
import getUploadAudioUrlForConversation from 'redux/actions/Common/getUploadAudioUrlForConversation';
import createNewSalesAnalysisThread from 'redux/actions/Common/createNewSalesAnalysisThread';
import confirmAudioUploadForSalesCallAnalysis from 'redux/actions/Common/confirmAudioUploadForSalesCallAnalysis';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';
import getDefaultPromptsForAnalysis from 'redux/actions/Common/getDefaultPromptsForAnalysis';
import getAllConversationsByUserId from 'redux/actions/Common/getAllConversationsByUserId';
import getAllUsers from 'redux/actions/Common/getAllUsers';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getAllPrompts from 'redux/actions/Common/getAllPrompts';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const defaultLabels = [
  'clarity',
  'pace',
  'fluency',
  'confidence',
  'enthusiasm',
  'delivery',
  'content',
];

const SalesCallAnalysisUsers = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const chatsScrollview = useRef(null);

  const {
    users,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.allUsers);
  const scrollDivRef = useRef();
  const scrollDiv1Ref = useRef();

  const [selectedUser, setSelectedUser] = useState(null);

  const [newThreadDialog, setNewThreadDIalog] = useState(false);

  const [salesReviews, setSalesReviews] = useState([]);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const [message, setMessage] = useState('');
  const [gettingResponse, setGettingResponse] = useState(false);
  const [description, setDescription] = useState('');
  const [conversationTitle, setConversationTitle] = useState('');
  const [conversationPrompt, setConversationPrompt] = useState({});
  const [creatingNewThread, setCreatingNewThread] = useState(false);

  const [recorder, setRecorder] = useState(null);
  const [showPermissionDeniedError, setShowPermissionDeniedError] = useState(
    false,
  );
  const [permissionDenied, setPermissionDenied] = useState(null);
  const [permissionPersisting, setPermissionPersisting] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recording, setRecording] = useState(null);
  const streamRef = useRef();
  const playBackRef = useRef();
  const timer = useRef(null);
  const timer2 = useRef(null);
  const [timerSs, setTimerSs] = useState(0);
  const [timerMs, setTimerMs] = useState(0);

  const [mediaStream, setMediaStream] = useState(null);
  const [availableAudioDevices, setAvailableAudioDevices] = useState(null);

  const [firstRenderAudio, setFirstRenderAudio] = useState(false);
  const [audioSource, setAudioSource] = useState('');
  const [showMediaSelectOptions, setShowMediaSelectOptions] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  const [beTranscription, setBeTranscription] = useState(false);
  const [gender, setGender] = useState('FEMALE');
  const [promptType, setPromptType] = useState('default');
  const [prospectName, setProspectName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [teamSize, setTeamSize] = useState(1);
  const [customPrompt, setCustomPrompt] = useState('');

  const [selectedAudio, setSelectedAudio] = useState(null);
  const [methods, setMethods] = useState(['meddpic']);
  const [playbook, setPlaybook] = useState('');
  const [analysingCall, setAnalysingCall] = useState(false);
  const [currentTab, setCurrentTab] = useState('meddpic');
  const [showPlaybook, setShowPlaybook] = useState(false);
  const [currentParam, setCurrentParam] = useState('transcript');
  const [currentMethod, setCurrentMethod] = useState('meddpic');

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);
  const [showAnalysisOptions, setShowAnalysisOptions] = useState(false);
  const [speaker, setSpeaker] = useState('spk_0');
  const [speakers, setSpeakers] = useState({});

  const [playbookPrompts, setPlaybookPrompts] = useState([]);
  const [methodologiesPrompts, setMethodologiesPrompts] = useState([]);

  const [selectedPlaybookPrompt, setSelectedPlaybookPrompt] = useState('');
  const [
    selectedMethodologiesPrompt,
    setSelectedMethodologiesPrompt,
  ] = useState('');

  const [
    showCreateMergedAudioDialog,
    setShowCreateMergedAudioDialog,
  ] = useState(false);
  const [mergedAudioType, setMergedAudioType] = useState('GCP');
  const [
    generatingMergedConversation,
    setGeneratingMergedConversation,
  ] = useState(false);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    false,
  );
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [showConfirmDelete, setShowConfirmDelete] = useState(null);

  const getRecordingPermission = async () => {
    try {
      const timeStampBeforePermission = Date.now();
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      });
      const timeStampAfterPermission = Date.now();
      setPermissionDenied(false);
      if (timeStampAfterPermission - timeStampBeforePermission < 1500) {
        setPermissionPersisting(true);
      } else {
        setPermissionPersisting(false);
      }
      streamRef.current = stream;
      setMediaStream(stream);
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.');
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setAvailableAudioDevices(
        devices.filter((device) => device.kind === 'audioinput'),
      );
    } catch (e) {
      console.log(e?.name);
      if (e?.name === 'NotAllowedError') {
        setShowPermissionDeniedError(true);
        setPermissionDenied(true);
        setError(true);
        setErrorMessage(
          'Camera and microphone are blocked. Please allow the permissions and start recording.',
        );
      } else {
        setError(true);
        setErrorMessage(
          'Oops we are unable to detect your camera. Please refresh this page and try again.',
        );
      }
    }
  };

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        users &&
        users.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllUsers('account_admin', 15, lastTime, () => {}));
    }
  };

  const handleRecordClick = async () => {
    if (permissionDenied) {
      setShowPermissionDeniedError(true);
    } else {
      // setShowCountdown(true);
      // const countDownInterval = setInterval(() => {
      //   setCountdown((countdown) => countdown - 1);
      // }, 1000);
      // setTimeout(() => {
      // clearInterval(countDownInterval);
      // setCountdown(3);
      // setShowCountdown(false);
      setSelectedAudio(null);
      setRecordedBlobs([]);
      setRecording(true);
      startTimer();
      // startReverseTimer();
      if (playBackRef && playBackRef.current) {
        playBackRef.current.src = null;
      }
      if (!selectedThread?.beTranscription) {
        SpeechRecognition.startListening({ continuous: true });
      }
      // }, 3000);
    }
  };

  const handleRecordStopClick = async () => {
    await recorder.stopRecording();
    let blob = await recorder.getBlob();
    // console.log('blob', blob);
    setSelectedAudio(blob);
    stopTimer();
    setRecording(false);
  };

  const handleClearRecordingClick = () => {
    setSelectedAudio(null);
    setRecordedBlobs([]);
    setRecording(null);
    if (selectedAudio) {
      playBackRef.current.src = null;
    }
    if (streamRef && streamRef.current)
      streamRef.current.getTracks().forEach((track) => track.stop());
    // if (browser?.name !== 'safari') {
    getRecordingPermission();
    // }
  };

  // const playRecordedVideo = () => {
  //   const audioBlob = new Blob(recordedBlobs, { type: 'audio/webm' });
  //   setSelectedAudio(audioBlob);
  // };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimerSs((prev) => {
        if (prev === 59) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
    timer2.current = setInterval(() => {
      setTimerMs((prev) => prev + 1);
    }, 60000);
  };

  const stopTimer = () => {
    // console.log(timer.current, timer2.current);
    clearInterval(timer.current);
    clearInterval(timer2.current);
    setTimerSs(0);
    setTimerMs(0);
  };

  const handleGetResponse = (withAudio) => {
    setGettingResponse(true);
    if (selectedThread?.beTranscription && selectedAudio) {
      dispatch(
        getUploadAudioUrlForConversation(
          selectedThreadId,
          selectedAudio?.type?.split(';')?.[0],
          (data) => {
            console.log(data);
            dispatch(
              uploadAudio(data?.url, selectedAudio, () => {
                dispatch(
                  verifyAudioUpload(data?.url && data?.audioId, 'v3', () => {
                    setGettingResponse(false);
                    dispatch(
                      getConversationById(selectedThreadId, (data) => {
                        setAutoPlay(true);
                        setMessage('');
                        resetTranscript();
                        setSelectedThread(data);
                        if (chatsScrollview?.current) {
                          chatsScrollview?.current?.scroll({
                            top: chatsScrollview?.current?.scrollHeight,
                            behaviour: 'smooth',
                          });
                        }
                      }),
                    );
                    setSelectedAudio(null);
                  }),
                );
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        getMessageResponse(
          selectedThreadId,
          message,
          async (data) => {
            console.log(selectedAudio);
            if (
              data?.userAudioUploadLink &&
              data?.userAudioId &&
              selectedAudio
            ) {
              dispatch(
                uploadAudio(data?.userAudioUploadLink, selectedAudio, () => {
                  dispatch(
                    verifyAudioUpload(
                      data?.userAudioUploadLink && data?.userAudioId,
                      null,
                      () => {
                        setSelectedAudio(null);
                      },
                    ),
                  );
                }),
              );
            }
            setGettingResponse(false);
            dispatch(
              getConversationById(selectedThreadId, (data) => {
                setAutoPlay(true);
                setMessage('');
                resetTranscript();
                setSelectedThread(data);
                if (chatsScrollview?.current) {
                  chatsScrollview?.current?.scroll({
                    top: chatsScrollview?.current?.scrollHeight,
                    behaviour: 'smooth',
                  });
                }
              }),
            );
          },
          () => {
            setGettingResponse(false);
          },
        ),
      );
    }
  };

  const handleGenerateMergedConversation = () => {
    setGeneratingMergedConversation(true);
    dispatch(
      getMergedConversation(selectedThreadId, (data) => {
        console.log(data);
        setTimeout(() => {
          dispatch(
            getConversationById(selectedThreadId, (data) => {
              setMessage('');
              resetTranscript();
              setSelectedAudio(null);
              setSelectedThread(data);
              setGeneratingMergedConversation(true);
              setShowCreateMergedAudioDialog(false);
              if (chatsScrollview?.current) {
                chatsScrollview?.current?.scroll({
                  top: chatsScrollview?.current?.scrollHeight,
                  behaviour: 'smooth',
                });
              }
            }),
          );
        }, 3000);
      }),
    );
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setMethods((prev) => [...prev, e.target.value]);
    } else {
      setMethods((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const handleGeneratePrompt = () => {
    setCustomPrompt(
      conversationPrompt
        ?.replace(/<%>gpt_name<%>/g, prospectName)
        .replace(/<%>designation<%>/g, designation)
        .replace(/<%>company_name<%>/g, companyName)
        .replace(/<%>company_details<%>/g, aboutCompany),
    );
  };

  useEffect(() => {
    if (transcript) {
      setMessage(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (recording === false) {
      if (!selectedThread?.beTranscription) {
        SpeechRecognition.stopListening();
      }
      handleGetResponse();
    }
  }, [recording]);

  // useEffect(() => {
  //   if (selectedAudio && recording === false) {
  //     let recordedVideoUrl = window.URL.createObjectURL(selectedAudio);
  //     // setRecording(false);
  //     if (playBackRef.current) {
  //       playBackRef.current.src = recordedVideoUrl;
  //       // playBackRef.current.play();
  //     }
  //   }
  //   // if (streamRef && streamRef.current)
  //   //   streamRef.current.getTracks().forEach((track) => track.stop());
  // }, [selectedAudio, recording, playBackRef?.current]);

  useEffect(() => {
    let options = {
      mimeType: 'audio/webm',
    };
    try {
      if (mediaStream !== null) {
        setRecorder(new RecordRTCPromisesHandler(mediaStream, options));
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage('Failed to get media device');
    }
  }, [mediaStream]);

  useEffect(() => {
    if (
      recording === true &&
      recordedBlobs.length === 0 &&
      selectedAudio === null
    ) {
      try {
        recorder.onerror = (e) => {
          console.log(e);
          setError(true);
          setErrorMessage(
            'Oops! Something went wrong. Please refresh this page and try again. ',
          );
        };
        recorder.startRecording();
      } catch (e) {
        console.log(e);
        console.log('mediaRecorder API not supported for this browser');
        setError(true);
        setErrorMessage(
          'Oops! Something went wrong. Please refresh this page and try again. ',
        );
      }
    }
  }, [selectedAudio, recordedBlobs, recording]);

  useEffect(() => {
    if (!firstRenderAudio) {
      if (availableAudioDevices && availableAudioDevices.length > 0) {
        setAudioSource(availableAudioDevices[0].deviceId);
        setFirstRenderAudio(true);
      }
    }
  }, [availableAudioDevices]);

  // useEffect(() => {
  //   if (browser?.name !== 'safari' && videoRef && videoRef.current) {
  //     videoRef.current.srcObject = mediaStream;
  //   }
  // }, [mediaStream]);

  // useEffect(() => {
  //   if (recording === false) {
  //     playRecordedVideo();
  //   }
  // }, [recording]);

  useEffect(() => {
    if (showAnalysisOptions) {
      setConversationPrompt(selectedThread?.prompts || {});
      let ms = [];
      if (selectedThread?.analyseMEDDPIC) {
        ms.push('meddpic');
      }
      if (selectedThread?.analyseSPIN) {
        ms.push('spin');
      }
      if (selectedThread?.analyseSPICED) {
        ms.push('spiced');
      }
      if (selectedThread?.analyseBANT) {
        ms.push('bant');
      }
      setMethods(ms);
      setPlaybook(selectedThread?.playbook);
      dispatch(
        getAllPrompts('call_analysis_method', 50, null, (data) => {
          setMethodologiesPrompts(data);
        }),
      );
      dispatch(
        getAllPrompts('call_analysis_feedback', 50, null, (data) => {
          setPlaybookPrompts(data);
        }),
      );
    }
  }, [showAnalysisOptions]);

  useEffect(() => {
    if (newThreadDialog) {
      dispatch(
        getDefaultPromptsForAnalysis((prompt) => {
          // console.log(prompt);
          setConversationPrompt(prompt);
        }),
      );
    }
  }, [newThreadDialog]);

  useEffect(() => {
    if (selectedThreadId) {
      setAutoPlay(false);
      setSelectedThread(salesReviews.find((c) => c?.id === selectedThreadId));
      // if (chatsScrollview?.current) {
      //   chatsScrollview?.current?.scroll({
      //     top: chatsScrollview?.current?.scrollHeight,
      //     behaviour: 'smooth',
      //   });
      // }
    }
  }, [selectedThreadId]);

  useEffect(() => {
    // if (newThreadDialog) {
    //   dispatch(
    //     getDefaultPrompt((prompt) => {
    //       // console.log(prompt);
    //       setConversationPrompt(prompt?.default);
    //     }),
    //   );
    // }
  }, [newThreadDialog]);

  useEffect(() => {
    if (
      selectedThread?.analysis?.fillerInfo?.fillers &&
      Object.keys(selectedThread?.analysis?.fillerInfo?.fillers) &&
      Object.keys(selectedThread?.analysis?.fillerInfo?.fillers).length > 0
    ) {
      setScriptReadingFillerWords(
        Object.keys(selectedThread?.analysis?.fillerInfo?.fillers),
      );
    }
    if (
      selectedThread?.analysis?.repetitionInfo?.repetitions &&
      Object.keys(selectedThread?.analysis?.repetitionInfo?.repetitions) &&
      Object.keys(selectedThread?.analysis?.repetitionInfo?.repetitions)
        .length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(selectedThread?.analysis?.repetitionInfo?.repetitions),
      );
    }
    setSpeakers(selectedThread?.speakers);
  }, [selectedThread]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  useEffect(() => {
    if (selectedThread) {
      // setActiveStep(2);
      setSpeakers(selectedThread?.speakers);
      if (selectedThread?.playbook) {
        setCurrentMethod('playbook');
      }
    }
  }, [selectedThread]);

  useEffect(() => {
    if (selectedUser) {
      dispatch(
        getAllConversationsByUserId(selectedUser, 'sale_reviews', (cs) => {
          setSalesReviews(cs);
        }),
      );
    }
  }, [selectedUser]);

  useEffect(() => {
    dispatch(getAllUsers('account_admin', 15, null, () => {}));
    dispatch(
      getAllSalesReviews((sr) => {
        setSalesReviews(sr);
      }),
    );
  }, []);

  // console.log(conversationPrompt);
  // console.log(speakers);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                  }
            }
          >
            {selectedUser ? (
              <>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  onClick={() => {
                    setSelectedUser(null);
                    setSelectedThread(null);
                    setSelectedThreadId(null);
                  }}
                  color="primary"
                  size="small"
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Go Back
                </Button>
                {salesReviews?.map((review) => (
                  <Box
                    // flex={1}
                    key={review?.id}
                    sx={{
                      // flex: 1,
                      padding: '8px 16px',
                      border: '1px solid #d3d3d3',
                      borderRadius: 2,
                      cursor: 'pointer',
                      background:
                        selectedThreadId === review?.id
                          ? theme.palette.primary.main
                          : 'default',
                      color:
                        selectedThreadId === review?.id ? '#fff' : 'default',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => setSelectedThreadId(review?.id)}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'flex-start'}
                      alignItems={'start'}
                      // alignItems={'center'}
                      // gap={1}
                    >
                      <Typography variant="subtitle2">
                        {review?.title || 'No title'}
                      </Typography>
                      <Tooltip
                        title={review?.hidden ? 'Show' : 'Hide'}
                        placement="right"
                        arrow
                      >
                        <IconButton
                          sx={{
                            padding: 1,
                          }}
                          onClick={() => {
                            if (review?.hidden) {
                              dispatch(
                                updateSalesReviewData(
                                  showConfirmDelete,
                                  { hidden: false },
                                  () => {
                                    setShowConfirmDelete(null);
                                    dispatch(
                                      getAllConversationsByUserId(
                                        selectedUser,
                                        'sale_reviews',
                                        (cs) => {
                                          setSalesReviews(cs);
                                        },
                                      ),
                                    );
                                  },
                                ),
                              );
                            } else {
                              setShowConfirmDelete(review?.id);
                            }
                          }}
                        >
                          {review?.hidden ? (
                            <VisibilityOff
                              sx={{
                                fontSize: 16,
                              }}
                            />
                          ) : (
                            <Visibility
                              sx={{
                                fontSize: 16,
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              users?.map((user) => (
                <Box
                  // flex={1}
                  key={user?.id}
                  sx={{
                    // flex: 1,
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    cursor: 'pointer',
                    background:
                      selectedUser === user?.id
                        ? theme.palette.primary.main
                        : 'default',
                    color: selectedUser === user?.id ? '#fff' : 'default',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setSelectedUser(user?.id)}
                >
                  <Typography
                    variant="body1"
                    color={'text.primary'}
                    fontWeight={'bold'}
                  >
                    {user?.email}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    fontWeight={'bold'}
                  >
                    {user?.fullName}
                  </Typography>
                </Box>
              ))
            )}
            {selectedUser ? null : fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more users ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && users?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 90px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 90px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? '120px' : 0,
              }}
              ref={chatsScrollview}
            >
              {selectedThread ? (
                <Box>
                  <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Box
                      // gap={2}
                      display={'flex'}
                      // p={2}
                      sx={{
                        border: '1px solid #d3d3d3',
                        height: 'auto',
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                        p={3}
                        justifyContent={'center'}
                        alignItems={'center'}
                        position={'relative'}
                      >
                        {selectedThread &&
                        selectedThread?.customData &&
                        selectedThread?.customData?.url ? (
                          <video
                            // preload
                            src={selectedThread?.customData?.url}
                            controls
                            style={{
                              objectFit: 'contain',
                              backgroundColor: selectedThread?.saleReviewMediaContentType
                                ?.split('/')
                                ?.includes('audio')
                                ? '#d3d3d3'
                                : '#000',
                              width: '100%',
                              height: '100%',
                              borderRadius: 5,
                              // borderTopLeftRadius: 8,
                              // borderBottomLeftRadius: 8,
                            }}
                            onError={(e) => {
                              console.log(e);
                            }}
                            controlsList="nofullscreen nodownload"
                            disablePictureInPicture
                          ></video>
                        ) : null}
                        {selectedThread &&
                        selectedThread?.customData &&
                        selectedThread?.customData?.url &&
                        selectedThread?.saleReviewMediaContentType
                          ?.split('/')
                          ?.includes('audio') ? (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 1,
                              height: 'calc(100% - 80px)',
                            }}
                          >
                            <Audiotrack
                              sx={{
                                fontSize: 70,
                                fontWeight: 'bold',
                                color: 'text.secondary',
                              }}
                            />
                          </Box>
                        ) : null}
                      </Box>
                      <Box
                        flex={3}
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                          borderLeft: '1px solid #d3d3d3',
                        }}
                      >
                        <Box
                          display={'flex'}
                          // alignItems={'center'}
                          // p={1}
                          // pl={1}
                          justifyContent={'space-between'}
                          sx={{
                            borderBottom: '1px solid #d3d3d3',
                          }}
                        >
                          <Tabs
                            value={currentParam}
                            onChange={(e, newValue) => {
                              setCurrentParam(newValue);
                            }}
                            aria-label="Parameter Tabs"
                            orientation={'horizontal'}
                            // indicatorColor={theme.palette.background.default}
                            variant="scrollable"
                            // scrollButtons={!isMd}
                            allowScrollButtonsMobile
                          >
                            <Tab
                              value={'transcript'}
                              label={
                                <Typography variant="body1">
                                  Transcript
                                </Typography>
                              }
                              id={`simple-tab-${0}`}
                              aria-controls={`simple-tabpanel-${0}`}
                              sx={{}}
                            />
                            <Tab
                              value={'fillers'}
                              label={
                                <Typography variant="body1">
                                  Filler Words
                                </Typography>
                              }
                              id={`simple-tab-${0}`}
                              aria-controls={`simple-tabpanel-${0}`}
                              sx={{}}
                            />
                            <Tab
                              value={'repeatitions'}
                              label={
                                <Typography variant="body1">
                                  Repeated Words
                                </Typography>
                              }
                              id={`simple-tab-${5}`}
                              aria-controls={`simple-tabpanel-${5}`}
                              sx={{}}
                            />
                            <Tab
                              value={'pace'}
                              label={
                                <Typography variant="body1">Pace</Typography>
                              }
                              id={`simple-tab-${5}`}
                              aria-controls={`simple-tabpanel-${5}`}
                              sx={{}}
                            />
                          </Tabs>
                        </Box>
                        {currentParam === 'transcript' ? (
                          <Box display={'flex'}>
                            <Box
                              ref={scrollDivRef}
                              sx={{
                                flex: 2,
                                padding: 2,
                                // border: '1px solid #d3d3d3',
                                borderRadius: 2,
                                mark: {
                                  backgroundColor: theme.palette.primary.main,
                                  color: 'white',
                                  padding: '2px 6px',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                minHeight: '270px',
                                maxHeight: '270px',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {
                                  width: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                  webkitBoxShadow:
                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: 2,
                                  // outline: '1px solid slategrey',
                                },
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {selectedThread?.transcript}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                flex: 1,
                                borderLeft: '1px solid #d3d3d3',
                                padding: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: 180,
                                  position: 'relative',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                    // textAlign={'center'}
                                  >
                                    Call Participation
                                  </Typography>
                                  <Box
                                    mt={1}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                    }}
                                  >
                                    {Object.keys(
                                      selectedThread?.analysis
                                        ?.speakProportion || {},
                                    )
                                      ?.filter((sp) => sp !== 'silence')
                                      ?.sort()
                                      ?.map((sp, index) => (
                                        <>
                                          <Box
                                            key={sp}
                                            display={'flex'}
                                            alignItems={'center'}
                                            gap={1}
                                          >
                                            <Box
                                              sx={{
                                                width: 16,
                                                height: 16,
                                                background:
                                                  sp === 'spk_0'
                                                    ? '#79155B'
                                                    : sp === 'spk_1'
                                                    ? '#468B97'
                                                    : sp === 'spk_2'
                                                    ? '#EF6262'
                                                    : sp === 'spk_3'
                                                    ? '#F3AA60'
                                                    : sp === 'spk_4'
                                                    ? '#F1C93B'
                                                    : sp === 'spk_5'
                                                    ? '#461959'
                                                    : '#d3d3d3',
                                              }}
                                            ></Box>
                                            <Typography
                                              variant="caption"
                                              color={'text.secondary'}
                                            >
                                              {selectedThread?.speakers[sp] ||
                                                'silence'}
                                            </Typography>
                                          </Box>
                                        </>
                                      ))}
                                  </Box>
                                </Box>
                                <Pie
                                  options={{
                                    responsive: true,
                                    layout: {
                                      padding: 5,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                      tooltip: {
                                        enabled: true,
                                        callbacks: {
                                          // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                                          label: (yDatapoint) => {
                                            // console.log(yDatapoint);
                                            return selectedThread?.speakers?.[
                                              yDatapoint?.label
                                            ];
                                          },
                                        },
                                      },
                                      title: {
                                        display: false,
                                        text: 'Call Participation',
                                      },
                                      datalabels: {
                                        color: 'white',
                                        textStrokeColor: '#000',
                                        textStrokeWidth: '1px',
                                        font: {
                                          weight: 'bold',
                                          size: 16,
                                        },
                                        formatter: function(value, context) {
                                          return `${value}%`;
                                        },
                                      },
                                    },
                                    elements: {
                                      arc: {
                                        borderRadius: 5,
                                        borderSkipped: true,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        display: false,
                                      },
                                      x: {
                                        display: false,
                                      },
                                    },
                                  }}
                                  data={{
                                    labels:
                                      (selectedThread?.analysis
                                        ?.speakProportion &&
                                        Object.keys(
                                          selectedThread?.analysis
                                            ?.speakProportion,
                                        )
                                          ?.filter((sp) => sp !== 'silence')
                                          ?.sort()) ||
                                      [],
                                    datasets: [
                                      {
                                        animation: false,
                                        label: '',
                                        data: Object.keys(
                                          selectedThread?.analysis
                                            ?.speakProportion || {},
                                        )
                                          ?.filter((key) => key !== 'silence')
                                          ?.map((key, index) =>
                                            Math.round(
                                              (selectedThread?.analysis
                                                ?.speakProportion[key] /
                                                Object.keys(
                                                  selectedThread?.analysis
                                                    ?.speakProportion || {},
                                                )
                                                  ?.filter(
                                                    (key) => key !== 'silence',
                                                  )
                                                  ?.reduce(
                                                    (acc, curr) =>
                                                      acc +
                                                      selectedThread?.analysis
                                                        ?.speakProportion[curr],
                                                    0,
                                                  )) *
                                                100,
                                            ),
                                          ),
                                        backgroundColor: [
                                          ...[
                                            '#79155B',
                                            '#468B97',
                                            '#EF6262',
                                            '#F3AA60',
                                            '#F1C93B',
                                            '#461959',
                                          ].slice(
                                            0,
                                            Object.keys(
                                              selectedThread?.analysis
                                                ?.speakProportion || {},
                                            )?.length - 1,
                                          ),
                                          '#d3d3d3',
                                        ],
                                      },
                                    ],
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            ref={scrollDivRef}
                            sx={{
                              padding: 2,
                              // border: '1px solid #d3d3d3',
                              borderRadius: 2,
                              mark: {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                                padding: '2px 6px',
                              },
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              minHeight: '270px',
                              maxHeight: '270px',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                                // outline: '1px solid slategrey',
                              },
                            }}
                          >
                            {currentParam === 'fillers' ? (
                              <>
                                <Box
                                  display={'flex'}
                                  gap={2}
                                  sx={{
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {selectedThread?.analysis?.fillerInfo
                                    ?.fillers &&
                                  Object.keys(
                                    selectedThread?.analysis?.fillerInfo
                                      ?.fillers,
                                  ) &&
                                  Array.isArray(
                                    Object.keys(
                                      selectedThread?.analysis?.fillerInfo
                                        ?.fillers,
                                    ),
                                  ) &&
                                  Object.keys(
                                    selectedThread?.analysis?.fillerInfo
                                      ?.fillers,
                                  )?.length > 0 ? (
                                    Object.keys(
                                      selectedThread?.analysis?.fillerInfo
                                        ?.fillers,
                                    )
                                      ?.sort((a, b) => {
                                        if (
                                          selectedThread?.analysis?.fillerInfo
                                            ?.fillers?.[a] <
                                          selectedThread?.analysis?.fillerInfo
                                            ?.fillers?.[b]
                                        ) {
                                          return 1;
                                        } else if (
                                          selectedThread?.analysis?.fillerInfo
                                            ?.fillers?.[a] >
                                          selectedThread?.analysis?.fillerInfo
                                            ?.fillers?.[b]
                                        ) {
                                          return -1;
                                        }
                                        return 0;
                                      })
                                      ?.map((filler, index) => (
                                        <Typography key={index} sx={{}}>
                                          {filler}{' '}
                                          <Chip
                                            label={
                                              selectedThread?.analysis
                                                ?.fillerInfo?.fillers?.[filler]
                                            }
                                            size="small"
                                            color="primary"
                                          ></Chip>
                                        </Typography>
                                      ))
                                  ) : (
                                    <Typography
                                      sx={{}}
                                      color={'text.secondary'}
                                    >
                                      No filler words
                                    </Typography>
                                  )}
                                </Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {selectedThread?.analysis?.fillerInfo
                                    ?.transcript
                                    ? parse(
                                        selectedThread?.analysis?.fillerInfo?.transcript?.replace(
                                          fillerWordsRegexp,
                                          (highlight) => {
                                            return `<mark>${highlight
                                              .split('{')
                                              .join('')
                                              .split('}')
                                              .join('')}</mark>`;
                                          },
                                        ),
                                      )
                                    : ''}
                                </Typography>
                              </>
                            ) : currentParam === 'repeatitions' ? (
                              <>
                                <Box
                                  display={'flex'}
                                  gap={2}
                                  sx={{
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {selectedThread?.analysis?.repetitionInfo
                                    ?.repetitions &&
                                  Object.keys(
                                    selectedThread?.analysis?.repetitionInfo
                                      ?.repetitions,
                                  ) &&
                                  Array.isArray(
                                    Object.keys(
                                      selectedThread?.analysis?.repetitionInfo
                                        ?.repetitions,
                                    ),
                                  ) &&
                                  Object.keys(
                                    selectedThread?.analysis?.repetitionInfo
                                      ?.repetitions,
                                  )?.length > 0 ? (
                                    Object.keys(
                                      selectedThread?.analysis?.repetitionInfo
                                        ?.repetitions,
                                    )
                                      ?.sort((a, b) => {
                                        if (
                                          selectedThread?.analysis
                                            ?.repetitionInfo?.repetitions?.[a] <
                                          selectedThread?.analysis
                                            ?.repetitionInfo?.repetitions?.[b]
                                        ) {
                                          return 1;
                                        } else if (
                                          selectedThread?.analysis
                                            ?.repetitionInfo?.repetitions?.[a] >
                                          selectedThread?.analysis
                                            ?.repetitionInfo?.repetitions?.[b]
                                        ) {
                                          return -1;
                                        }
                                        return 0;
                                      })
                                      ?.map((repeatition, index) => (
                                        <Typography key={index} sx={{}}>
                                          {repeatition}{' '}
                                          <Chip
                                            label={
                                              selectedThread?.analysis
                                                ?.repetitionInfo?.repetitions?.[
                                                repeatition
                                              ]
                                            }
                                            size="small"
                                            color="primary"
                                          ></Chip>
                                        </Typography>
                                      ))
                                  ) : (
                                    <Typography
                                      sx={{}}
                                      color={'text.secondary'}
                                    >
                                      No repeated words
                                    </Typography>
                                  )}
                                </Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {selectedThread?.analysis?.repetitionInfo
                                    ?.transcript
                                    ? parse(
                                        selectedThread?.analysis?.repetitionInfo?.transcript?.replace(
                                          repeatWordsRegexp,
                                          (highlight) => {
                                            return `<mark>${highlight
                                              .split('{')
                                              .join('')
                                              .split('}')
                                              .join('')}</mark>`;
                                          },
                                        ),
                                      )
                                    : ''}
                                </Typography>
                              </>
                            ) : currentParam === 'pace' ? (
                              <Box>
                                <LineGraph
                                  height={230}
                                  labels={
                                    Object.keys(
                                      selectedThread?.analysis?.paceGraph || {},
                                    )?.length > 15
                                      ? [
                                          ...new Set(
                                            Object.keys(
                                              selectedThread?.analysis
                                                ?.paceGraph,
                                            ).filter(
                                              (n) =>
                                                n %
                                                  (2 +
                                                    Math.floor(
                                                      Object.keys(
                                                        selectedThread?.analysis
                                                          ?.paceGraph,
                                                      )?.length / 30,
                                                    )) ===
                                                0,
                                            ),
                                          ),
                                        ]
                                      : Object.keys(
                                          selectedThread?.analysis?.paceGraph ||
                                            {},
                                        )
                                  }
                                  data={
                                    Object.values(
                                      selectedThread?.analysis?.paceGraph || {},
                                    )?.length > 15
                                      ? Object.values(
                                          selectedThread?.analysis?.paceGraph,
                                        )
                                          .reduce((acc, curr, index) => {
                                            const averageValue =
                                              Object.values(
                                                selectedThread?.analysis
                                                  ?.paceGraph,
                                              )
                                                ?.slice(
                                                  index *
                                                    (2 +
                                                      Math.floor(
                                                        Object.keys(
                                                          selectedThread
                                                            ?.analysis
                                                            ?.paceGraph,
                                                        )?.length / 30,
                                                      )),
                                                  (2 +
                                                    Math.floor(
                                                      Object.keys(
                                                        selectedThread?.analysis
                                                          ?.paceGraph,
                                                      )?.length / 30,
                                                    )) *
                                                    (index + 1),
                                                )
                                                ?.reduce(
                                                  (acc, curr) => acc + curr,
                                                  0,
                                                ) /
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    selectedThread?.analysis
                                                      ?.paceGraph,
                                                  )?.length / 30,
                                                ));
                                            acc.push(averageValue);
                                            return acc;
                                          }, [])
                                          .slice(
                                            0,
                                            Object.keys(
                                              selectedThread?.analysis
                                                ?.paceGraph,
                                            ).filter(
                                              (n) =>
                                                n %
                                                  (2 +
                                                    Math.floor(
                                                      Object.keys(
                                                        selectedThread?.analysis
                                                          ?.paceGraph,
                                                      )?.length / 30,
                                                    )) ===
                                                0,
                                            ).length,
                                          )
                                      : Object.values(
                                          selectedThread?.analysis?.paceGraph ||
                                            {},
                                        )
                                  }
                                />
                              </Box>
                            ) : currentParam === 'transcript' ? (
                              <Typography
                                variant="body1"
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {selectedThread?.transcript}
                              </Typography>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {selectedThread?.analysis ? (
                      <>
                        <Box
                          sx={{
                            // p: 2,
                            border: '1px solid #d3d3d3',
                            minHeight: 300,
                          }}
                        >
                          <Box display={'flex'} gap={2}>
                            {selectedThread?.analysis?.scoreStatus ===
                            'completed' ? (
                              <Box
                                flex={1}
                                sx={{
                                  padding: 2,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  minHeight: 300,
                                }}
                              >
                                {scoreCalculationInProcess ? (
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    gap={2}
                                  >
                                    <CircularProgress size={20} />
                                    <Typography
                                      variant="caption"
                                      color={'text.secondary'}
                                    >
                                      Score generation is in process...
                                    </Typography>
                                  </Box>
                                ) : (
                                  <>
                                    <Typography
                                      variant="subtitle1"
                                      color={'text.secondary'}
                                    >
                                      Scores
                                    </Typography>
                                    <Bar
                                      style={{
                                        width: '100%',
                                      }}
                                      options={{
                                        responsive: true,
                                        layout: {
                                          padding: 10,
                                        },
                                        plugins: {
                                          legend: {
                                            display: false,
                                            // position: 'top',
                                          },
                                          tooltip: {
                                            enabled: true,
                                          },

                                          title: {
                                            display: false,
                                            text: 'Skills Score',
                                          },
                                          datalabels: {
                                            color: 'white',
                                            font: {
                                              weight: 'bold',
                                              size: 14,
                                            },
                                            // rotation: -90,
                                            formatter: function(
                                              value,
                                              context,
                                            ) {
                                              return `${value}`;
                                            },
                                          },
                                        },
                                        elements: {
                                          bar: {
                                            borderRadius: 6,
                                            borderSkipped: false,
                                          },
                                        },
                                        scales: {
                                          y: {
                                            beginAtZero: true,
                                            max: 100,
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: [
                                          'Pace',
                                          'Fluency',
                                          'Confidence',
                                          'Enthusiasm',
                                          'Clarity',
                                        ],
                                        datasets: [
                                          {
                                            label: 'Score',
                                            data: [
                                              selectedThread?.analysis
                                                ?.paceScore === undefined
                                                ? '-'
                                                : Math.round(
                                                    selectedThread?.analysis
                                                      ?.paceScore,
                                                  ),
                                              selectedThread?.analysis
                                                ?.fluencyScore === undefined
                                                ? '-'
                                                : Math.round(
                                                    selectedThread?.analysis
                                                      ?.fluencyScore,
                                                  ),
                                              selectedThread?.analysis
                                                ?.confidenceScore === undefined
                                                ? '-'
                                                : Math.round(
                                                    selectedThread?.analysis
                                                      ?.confidenceScore,
                                                  ),
                                              selectedThread?.analysis
                                                ?.enthusiasmScore === undefined
                                                ? '-'
                                                : Math.round(
                                                    selectedThread?.analysis
                                                      ?.enthusiasmScore,
                                                  ),
                                              selectedThread?.analysis
                                                ?.clarity === undefined
                                                ? '-'
                                                : Math.round(
                                                    selectedThread?.analysis
                                                      ?.clarity,
                                                  ),
                                            ],
                                            backgroundColor:
                                              theme.palette.primary.main,
                                            barThickness: 20,
                                            pointStyle: 'circle',
                                            fill: true,
                                          },
                                        ],
                                      }}
                                    />
                                  </>
                                )}
                              </Box>
                            ) : null}
                            <Box
                              flex={3}
                              sx={{
                                borderLeft: '1px solid #d3d3d3',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Tabs
                                  value={currentMethod}
                                  onChange={(e, newValue) => {
                                    setCurrentMethod(newValue);
                                  }}
                                  aria-label="Feedback Tabs"
                                  orientation={'horizontal'}
                                  // indicatorColor={theme.palette.background.default}
                                  variant="scrollable"
                                  // scrollButtons={!isMd}
                                  allowScrollButtonsMobile
                                >
                                  {selectedThread?.analysis?.playbook ? (
                                    <Tab
                                      value={'playbook'}
                                      label={
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                        >
                                          <Typography variant="body1">
                                            Playbook
                                          </Typography>
                                        </Box>
                                      }
                                      id={`simple-tab-${5}`}
                                      aria-controls={`simple-tabpanel-${5}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                  {selectedThread?.analyseMEDDPIC ? (
                                    <Tab
                                      value={'meddpic'}
                                      label={
                                        <Typography variant="body1">
                                          MEDDPIC
                                        </Typography>
                                      }
                                      id={`simple-tab-${0}`}
                                      aria-controls={`simple-tabpanel-${0}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                  {selectedThread?.analyseSPICED ? (
                                    <Tab
                                      value={'spiced'}
                                      label={
                                        <Typography variant="body1">
                                          SPICED
                                        </Typography>
                                      }
                                      id={`simple-tab-${5}`}
                                      aria-controls={`simple-tabpanel-${5}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                  {selectedThread?.analyseSPIN ? (
                                    <Tab
                                      value={'spin'}
                                      label={
                                        <Typography variant="body1">
                                          SPIN
                                        </Typography>
                                      }
                                      id={`simple-tab-${5}`}
                                      aria-controls={`simple-tabpanel-${5}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                  {selectedThread?.analyseBANT ? (
                                    <Tab
                                      value={'bant'}
                                      label={
                                        <Typography variant="body1">
                                          BANT
                                        </Typography>
                                      }
                                      id={`simple-tab-${5}`}
                                      aria-controls={`simple-tabpanel-${5}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                  {selectedThread?.analyseChallenger ? (
                                    <Tab
                                      value={'challenger'}
                                      label={
                                        <Typography variant="body1">
                                          CHALLENGER
                                        </Typography>
                                      }
                                      id={`simple-tab-${5}`}
                                      aria-controls={`simple-tabpanel-${5}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                  {selectedThread?.analysis?.summary ? (
                                    <Tab
                                      value={'summary'}
                                      label={
                                        <Typography variant="body1">
                                          Call Summary
                                        </Typography>
                                      }
                                      id={`simple-tab-${5}`}
                                      aria-controls={`simple-tabpanel-${5}`}
                                      sx={{}}
                                    />
                                  ) : null}
                                </Tabs>
                                {selectedThread?.playbook &&
                                currentMethod === 'playbook' ? (
                                  <Tooltip
                                    title="show playbook"
                                    placement="left"
                                    arrow
                                  >
                                    <Article
                                      sx={{
                                        cursor: 'pointer',
                                        color: theme.palette.primary.main,
                                        marginRight: 2,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowPlaybook((prev) => !prev);
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </Box>
                              {selectedThread?.analysis?.[currentMethod] ? (
                                <Box
                                  ref={scrollDiv1Ref}
                                  sx={{
                                    display: 'flex',
                                    gap: 2,
                                    borderTop: '1px solid #d3d3d3',

                                    minHeight: '300px',
                                    maxHeight: '300px',
                                    overflowY: 'scroll',
                                    '&::-webkit-scrollbar': {
                                      width: '5px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      boxShadow:
                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                      webkitBoxShadow:
                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      borderRadius: 2,
                                      // outline: '1px solid slategrey',
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      whiteSpace: 'pre-wrap',
                                      padding: 2,
                                      flex: 1,
                                      borderRight: '1px solid #d3d3d3',
                                    }}
                                  >
                                    {selectedThread?.analysis?.[currentMethod]}
                                  </Typography>
                                  {selectedThread?.playbook &&
                                  // currentMethod === 'playbook' &&
                                  showPlaybook ? (
                                    <Drawer
                                      open={showPlaybook}
                                      anchor="right"
                                      onClose={() => setShowPlaybook(false)}
                                    >
                                      <Box
                                        p={1}
                                        sx={{
                                          borderBottom: '1px solid #d3d3d3',
                                        }}
                                      >
                                        <IconButton
                                          onClick={() => setShowPlaybook(false)}
                                        >
                                          <ChevronRight sx={{}} />
                                        </IconButton>
                                      </Box>
                                      <Box
                                        p={2}
                                        sx={{
                                          minWidth: 500,
                                          maxWidth: 500,
                                          minHeight: 'calc(100vh - 60px)',
                                          maxHeight: 'calc(100vh - 60px)',
                                          overflowY: 'scroll',
                                          '&::-webkit-scrollbar': {
                                            width: '5px',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                            boxShadow:
                                              'inset 0 0 6px rgba(0,0,0,0.00)',
                                            webkitBoxShadow:
                                              'inset 0 0 6px rgba(0,0,0,0.00)',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                            backgroundColor:
                                              theme.palette.primary.main,
                                            borderRadius: 2,
                                            // outline: '1px solid slategrey',
                                          },
                                          flex: 1,
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          color={'text.secondary'}
                                        >
                                          Playbook
                                        </Typography>
                                        <RichTextEditor
                                          value={selectedThread?.playbook}
                                          onChange={() => {}}
                                          readOnly
                                        />
                                      </Box>
                                    </Drawer>
                                  ) : null}
                                </Box>
                              ) : (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  sx={{
                                    minHeight: 'calc(100vh - 500px)',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      whiteSpace: 'pre-wrap',
                                      padding: 2,
                                    }}
                                    color={'text.secondary'}
                                  >
                                    Not available
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Box>
              ) : null}
            </Box>
            {/* {selectedThreadId ? (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  padding: '8px 16px',
                  background: '#fff',
                  width: '100%',
                }}
              >
                
              </Box>
            ) : null} */}
          </Box>
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={showAnalysisOptions}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
            paddingTop: 3,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAnalysisOptions(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Methodologies
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    checked={methods?.includes('meddpic')}
                    control={
                      <Checkbox size="small" value={'meddpic'} name="methods" />
                    }
                    label="MEDDPICC"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('spin')}
                    control={
                      <Checkbox size="small" value={'spin'} name="methods" />
                    }
                    label="SPIN"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('spiced')}
                    control={
                      <Checkbox size="small" value={'spiced'} name="methods" />
                    }
                    label="SPICED"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('bant')}
                    control={
                      <Checkbox size="small" value={'bant'} name="methods" />
                    }
                    label="BANT"
                    onChange={(e) => handleChange(e)}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Who is the salesperson?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={speaker}
                  onChange={(e) => setSpeaker(e.target.value)}
                >
                  {selectedThread?.speakers &&
                    Object.keys(selectedThread?.speakers)?.map((spk) => (
                      <FormControlLabel
                        value={spk}
                        control={<Radio />}
                        label={selectedThread?.speakers?.[spk]}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                {speakers &&
                Object.keys(speakers) &&
                Object.keys(speakers).length > 0
                  ? Object.keys(speakers).map((spk) => (
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Typography>{spk}</Typography>
                        <TextField
                          autoFocus
                          placeholder={`Name`}
                          variant="outlined"
                          color="primary"
                          size="small"
                          name={`${spk}_name`}
                          value={speakers?.[spk]}
                          onChange={(e) => {
                            const updatedSpeakes = {
                              ...speakers,
                              [spk]: e.target.value,
                            };
                            setSpeakers(updatedSpeakes);
                          }}
                          fullWidth
                        />
                      </Box>
                    ))
                  : null}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  // border: '1px solid #A8A8A8',
                  // marginTop: 1,
                  marginBottom: 1,
                  // padding: 1,
                }}
              >
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Playbook
                </Typography>
                <RichTextEditor value={playbook} onChange={setPlaybook} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" sx={{ minWidth: 1 }} size="small">
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Playbook Prompt
                </FormLabel>
                <Select
                  // ref={promptSelectRef}
                  labelId="prompt-select-label"
                  // label="Select Prompt"
                  value={selectedPlaybookPrompt || ''}
                  onChange={(e) => {
                    setSelectedPlaybookPrompt(e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Select Prompt</em>
                  </MenuItem>
                  {playbookPrompts && playbookPrompts.length > 0
                    ? playbookPrompts.map((prompt) => (
                        <MenuItem value={prompt?.id}>{prompt.title}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" sx={{ minWidth: 1 }} size="small">
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Methodologies Prompt
                </FormLabel>
                <Select
                  // ref={promptSelectRef}
                  labelId="prompt-select-label"
                  // label="Select Prompt"
                  value={selectedMethodologiesPrompt || ''}
                  onChange={(e) => {
                    setSelectedMethodologiesPrompt(e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Select Prompt</em>
                  </MenuItem>
                  {methodologiesPrompts && methodologiesPrompts.length > 0
                    ? methodologiesPrompts.map((prompt) => (
                        <MenuItem value={prompt?.id}>{prompt.title}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  size="small"
                  variant="contained"
                  disabled={analysingCall}
                  onClick={() => {
                    setAnalysingCall(true);
                    dispatch(
                      analyzeSalesCall(
                        selectedThreadId,
                        {
                          prompts: {
                            call_analysis_method: selectedMethodologiesPrompt,
                            call_analysis_feedback: selectedPlaybookPrompt,
                          },
                          analyseBANT: methods?.includes('bant'),
                          analyseChallenger: methods?.includes('challenger'),
                          analyseMEDDPIC: methods?.includes('meddpic'),
                          analyseSPIN: methods?.includes('spin'),
                          analyseSPICED: methods?.includes('spiced'),
                          playbookText: playbook,
                          // savePrompts: true,
                          speakers,
                        },
                        speaker,
                        (analysis) => {
                          setAnalysingCall(false);
                          setShowAnalysisOptions(false);
                          const updatedSalesReviews = salesReviews?.map(
                            (sr) => {
                              if (sr?.id === selectedThreadId) {
                                return {
                                  ...sr,
                                  analysis: analysis,
                                };
                              } else return sr;
                            },
                          );
                          setSelectedThread(
                            updatedSalesReviews?.filter(
                              (sr) => sr?.id === selectedThreadId,
                            )?.[0],
                          );
                        },
                        () => {},
                      ),
                    );
                  }}
                >
                  Analyze Call
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={newThreadDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
            paddingTop: 3,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setNewThreadDIalog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Title
              </Typography>
              <TextField
                autoFocus
                placeholder="Title"
                variant="outlined"
                color="primary"
                size="small"
                name="title"
                value={conversationTitle}
                onChange={(e) => setConversationTitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  // border: '1px solid #A8A8A8',
                  // marginTop: 1,
                  marginBottom: 1,
                  // padding: 1,
                }}
              >
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Playbook
                </Typography>
                <RichTextEditor value={playbook} onChange={setPlaybook} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Methodologies
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    checked={methods?.includes('meddpic')}
                    control={
                      <Checkbox size="small" value={'meddpic'} name="methods" />
                    }
                    label="MEDDPICC"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('spin')}
                    control={
                      <Checkbox size="small" value={'spin'} name="methods" />
                    }
                    label="SPIN"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('spiced')}
                    control={
                      <Checkbox size="small" value={'spiced'} name="methods" />
                    }
                    label="SPICED"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('bant')}
                    control={
                      <Checkbox size="small" value={'bant'} name="methods" />
                    }
                    label="BANT"
                    onChange={(e) => handleChange(e)}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: '14px' }}
                color={'text.secondary'}
              >
                Sales Audio Call
              </Typography>
              {selectedAudio ? (
                <Box mt={2}>
                  <audio
                    alt="not found"
                    controls
                    width={'250px'}
                    src={URL.createObjectURL(selectedAudio)}
                  />
                  <br />
                  <Button
                    sx={{
                      padding: '4px 8px',
                    }}
                    size="small"
                    variant="outlined"
                    onClick={() => setSelectedAudio(null)}
                  >
                    Remove
                  </Button>
                </Box>
              ) : null}
              <InputLabel
                htmlFor="audioCall"
                style={{
                  cursor: 'pointer',
                  border: '1px solid #d3d3d3',
                  width: 'fit-content',
                  padding: '5px 10px',
                  marginTop: 10,
                }}
              >
                {selectedAudio ? 'Change Audio' : 'Select audio'}
              </InputLabel>
              <input
                type="file"
                id="audioCall"
                name="audioCall"
                style={{ visibility: 'hidden' }}
                accept="audio/*"
                onChange={(event) => {
                  // console.log(event.target.files[0]);
                  setSelectedAudio(event.target.files[0]);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    !conversationTitle?.trim() ||
                    !selectedAudio ||
                    creatingNewThread
                  }
                  onClick={() => {
                    setCreatingNewThread(true);
                    dispatch(
                      createNewSalesAnalysisThread(
                        {
                          contentType: selectedAudio?.type?.split(';')?.[0],
                          meddpic: methods?.includes('meddpic'),
                          mediaType: 'audio',
                          playbook: playbook?.trim() ? true : false,
                          playbookText: playbook,
                          spin: methods?.includes('spin'),
                          spiced: methods?.includes('spiced'),
                          bant: methods?.includes('bant'),
                          title: conversationTitle?.trim(),
                          // prompts: conversationPrompt,
                        },
                        (data) => {
                          console.log(data);
                          dispatch(
                            uploadAudio(data?.url, selectedAudio, () => {
                              dispatch(
                                confirmAudioUploadForSalesCallAnalysis(
                                  data?.fileId,
                                  () => {
                                    dispatch(
                                      getAllSalesReviews((sr) => {
                                        setSalesReviews(sr);
                                        setCreatingNewThread(false);
                                        setNewThreadDIalog(false);
                                        setSelectedThreadId(data?.id);
                                        setMethods(['meddpic']);
                                        setSelectedAudio(null);
                                        setPlaybook('');
                                        setConversationPrompt({});
                                      }),
                                    );
                                  },
                                ),
                              );
                            }),
                          );
                        },
                      ),
                    );
                  }}
                >
                  Create new thread
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={showCreateMergedAudioDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'hidden',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowCreateMergedAudioDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} gap={1} mt={4}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Audio Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={mergedAudioType}
                onChange={(e) => setMergedAudioType(e.target.value)}
              >
                <FormControlLabel
                  value="GCP"
                  control={<Radio />}
                  label={`Google${
                    selectedThread?.audioService?.includes('GCP')
                      ? ''
                      : ' (Not available for this conversation)'
                  }`}
                  disabled={!selectedThread?.audioService?.includes('GCP')}
                />
                <FormControlLabel
                  value="ELEVEN_LABS"
                  control={<Radio />}
                  label={`Eleven Labs${
                    selectedThread?.audioService?.includes('ELEVEN_LABS')
                      ? ''
                      : ' (Not available for this conversation)'
                  }`}
                  disabled={
                    !selectedThread?.audioService?.includes('ELEVEN_LABS')
                  }
                />
              </RadioGroup>
            </FormControl>
            <Box display={'flex'} justifyContent={'flex-start'}>
              <Button
                size="small"
                variant="contained"
                disabled={generatingMergedConversation}
                onClick={() => {
                  handleGenerateMergedConversation();
                }}
              >
                Generate Merged Conversation Audio
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showConfirmDelete)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm Hide</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to hide this?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmDelete(null);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              dispatch(
                updateSalesReviewData(
                  showConfirmDelete,
                  { hidden: true },
                  () => {
                    setShowConfirmDelete(null);
                    dispatch(
                      getAllConversationsByUserId(
                        selectedUser,
                        'sale_reviews',
                        (cs) => {
                          setSalesReviews(cs);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default SalesCallAnalysisUsers;
