import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  ContentCopy,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeText } from 'utils';
import getPlans from 'redux/actions/Common/getPlans';
import getPlanTemplate from 'redux/actions/Common/getPlanTemplate';
import createCustomPlan from 'redux/actions/Common/createCustomPlan';
import duplicatePlan from 'redux/actions/Common/duplicatePlan';
import deleteCustomPlan from 'redux/actions/Common/deleteCustomPlan';
import updateCustomPlan from 'redux/actions/Common/updateCustomPlan';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomPlan = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [plans, setPlans] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [planTemplage, setPlanTemplate] = useState(null);
  const [customPlan, setCustomPlan] = useState(null);

  const [showCreateCustomPlan, setShowCreateCustomPlan] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [creatingCustomPlan, setCreatingCustomPlan] = useState(false);

  const [showPlanDetails, setShowPlanDetails] = useState(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [customerType, setCustomerType] = useState('direct');
  const [activated, setActivated] = useState('inactive');
  const [publishedForPricingPage, setPublishedForPricingPage] = useState(
    'draft',
  );
  const [planPrice, setPlanPrice] = useState(0);
  const [priceUnit, setPriceUnit] = useState('usd');
  const [priceBase, setPriceBase] = useState('seat');
  const [priceDuration, setPriceDuration] = useState('monthly');
  const [pricingScheme, setPricingScheme] = useState('seat_based');

  const [planType, setPlanType] = useState('all');
  const [planCategory, setPlanCategory] = useState('seat_based');

  const handleAddFeatureClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const [anchorElCreatePlan, setAnchorElCreatePlan] = useState(null);

  const handleCloseCreatePlanPopper = (event) => {
    if (anchorElCreatePlan) setAnchorElCreatePlan(null);
    else setAnchorElCreatePlan(event.target);
  };

  useEffect(() => {
    if (showCreateCustomPlan === 'create') {
      setFetching(true);
      dispatch(
        getPlanTemplate((template) => {
          setPlanTemplate(
            Object.keys(template || {})
              ?.filter(
                (key) =>
                  key !== 'id' &&
                  key !== 'admin' &&
                  key !== 'collection' &&
                  key !== 'createdBy' &&
                  key !== 'createdOn' &&
                  key !== 'deleted' &&
                  key !== 'lastUpdatedOn' &&
                  key !== 'planType',
              )
              ?.reduce((acc, curr) => {
                acc[curr] = template[curr];
                return acc;
              }, {}),
          );
          setCustomPlan(
            Object.keys(template || {})
              ?.filter(
                (key) =>
                  key !== 'id' &&
                  key !== 'admin' &&
                  key !== 'collection' &&
                  key !== 'createdBy' &&
                  key !== 'createdOn' &&
                  key !== 'deleted' &&
                  key !== 'lastUpdatedOn' &&
                  key !== 'planType',
              )
              ?.reduce((acc, curr) => {
                acc[curr] = template[curr];
                return acc;
              }, {}),
          );
          setFetching(false);
        }),
      );
    } else if (showCreateCustomPlan && showCreateCustomPlan !== 'create') {
      dispatch(
        getPlanTemplate((template) => {
          setPlanTemplate(
            Object.keys(template || {})
              ?.filter(
                (key) =>
                  key !== 'id' &&
                  key !== 'admin' &&
                  key !== 'collection' &&
                  key !== 'createdBy' &&
                  key !== 'createdOn' &&
                  key !== 'deleted' &&
                  key !== 'lastUpdatedOn' &&
                  key !== 'planType',
              )
              ?.reduce((acc, curr) => {
                acc[curr] = template[curr];
                return acc;
              }, {}),
          );
        }),
      );
      setCustomPlan(
        Object.keys(showCreateCustomPlan || {})
          ?.filter(
            (key) =>
              key !== 'id' &&
              key !== 'admin' &&
              key !== 'collection' &&
              key !== 'createdBy' &&
              key !== 'createdOn' &&
              key !== 'deleted' &&
              key !== 'lastUpdatedOn' &&
              key !== 'planType',
          )
          ?.reduce((acc, curr) => {
            acc[curr] = showCreateCustomPlan[curr];
            return acc;
          }, {}),
      );
      setCustomerType(showCreateCustomPlan?.forWlOrg ? 'whitelabel' : 'direct');
      setActivated(showCreateCustomPlan?.activated ? 'active' : 'inactive');
      setPublishedForPricingPage(
        showCreateCustomPlan?.publishedForPricingPage ? 'published' : 'draft',
      );
      setPlanPrice(showCreateCustomPlan?.price?.amount);
      setPriceBase(showCreateCustomPlan?.price?.priceUnit);
      setPriceUnit(showCreateCustomPlan?.price?.currency);
      setPriceDuration(showCreateCustomPlan?.price?.priceDuration);
      setPricingScheme(showCreateCustomPlan?.price?.pricingScheme);
    }
  }, [showCreateCustomPlan]);

  useEffect(() => {
    if (publishedForPricingPage === 'published') {
      setActivated('active');
    } else {
      setActivated('inactive');
    }
  }, [publishedForPricingPage]);

  useEffect(() => {
    if (fetchedPlans && fetchedPlans?.length > 0) {
      if (planType === 'all') {
        setPlans(
          fetchedPlans?.filter(
            (plan) => plan?.price?.pricingScheme === planCategory,
          ),
        );
      } else if (planType === 'default') {
        setPlans(
          fetchedPlans?.filter(
            (plan) =>
              plan?.planType !== 'custom' &&
              !plan?.forWlOrg &&
              plan?.price?.pricingScheme === planCategory,
          ),
        );
      } else if (planType === 'custom') {
        setPlans(
          fetchedPlans?.filter(
            (plan) =>
              plan?.planType === 'custom' &&
              !plan?.forWlOrg &&
              plan?.price?.pricingScheme === planCategory,
          ),
        );
      } else if (planType === 'whitelabel') {
        setPlans(
          fetchedPlans?.filter(
            (plan) =>
              plan?.forWlOrg &&
              plan?.planType === 'custom' &&
              plan?.price?.pricingScheme === planCategory,
          ),
        );
      }
    }
  }, [planType, fetchedPlans, planCategory]);

  useEffect(() => {
    setFetchingPlans(true);
    dispatch(
      getPlans((plans) => {
        setFetchedPlans(plans);
        setFetchingPlans(false);
      }),
    );
  }, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
          sx={{
            minHeight: 'calc(100vh - 80px)',
            maxHeight: 'calc(100vh - 80px)',
            overflow: 'auto',
          }}
        >
          {fetchingPlans ? (
            <Box
              width={1}
              height={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <CircularProgress size={20} />
              <Typography variant="body1" color={'text.primary'} mt={4}>
                Loading all plans ...
              </Typography>
            </Box>
          ) : fetchedPlans && fetchedPlans.length > 0 ? (
            <>
              <Box
                display={'flex'}
                alignItems={'center'}
                // justifyContent={'center'}
                gap={1}
              >
                <Typography>Seat Based</Typography>
                <Switch
                  color="primary"
                  checked={planCategory === 'usage_based'}
                  onChange={() =>
                    setPlanCategory((prev) =>
                      prev === 'seat_based' ? 'usage_based' : 'seat_based',
                    )
                  }
                />
                <Typography>Usage Based</Typography>
              </Box>
              <FormControl
                variant="outlined"
                size="small"
                // sx={{ marginBottom: 2, width: 250 }}
              >
                <Select
                  // ref={promptSelectRef}
                  labelId="prompt-select-label"
                  // label="Select Prompt"
                  value={planType || ''}
                  onChange={(e) => {
                    setPlanType(e.target.value);
                  }}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'default'}>
                    Default(Available to direct customers)
                  </MenuItem>
                  <MenuItem value={'custom'}>
                    Custom (Avaialble to assigned customers)
                  </MenuItem>
                  <MenuItem value={'whitelabel'}>
                    Whitelabel Customers(Custom plan created for assigning to
                    whitelabel customers)
                  </MenuItem>
                </Select>
              </FormControl>
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Plan</TableCell>
                      <TableCell align="center">Plan Type</TableCell>
                      <TableCell align="center">Customer Type</TableCell>
                      <TableCell align="center">Active</TableCell>
                      <TableCell align="center">Published</TableCell>
                      <TableCell align="center">Created On</TableCell>
                      <TableCell align="center">Validity Days</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plans.map((plan) => (
                      <TableRow
                        key={plan?.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {plan?.title}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {plan?.planType === 'custom' ? 'Custom' : 'Default'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {plan?.forWlOrg ? 'whitelabel' : 'direct'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {plan?.activated ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {plan?.publishedForPricingPage
                            ? 'Published'
                            : 'Draft'}
                        </TableCell>
                        <TableCell align="center">
                          {moment(plan?.createdOn).format('DD MMMM YYYY')}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {`${plan?.trailPeriodDays || '-'}`}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={1}
                          >
                            <Tooltip title={'Edit Plan'} placement="top" arrow>
                              <IconButton
                                onClick={() => {
                                  setShowCreateCustomPlan(plan);
                                }}
                              >
                                <Edit color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Plan Details'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setShowPlanDetails(plan);
                                }}
                              >
                                <Assignment color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Duplicate Plan'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  dispatch(
                                    duplicatePlan(plan?.id, () => {
                                      setFetchingPlans(true);
                                      dispatch(
                                        getPlans((plans) => {
                                          setFetchedPlans(plans);
                                          setFetchingPlans(false);
                                        }),
                                      );
                                    }),
                                  );
                                }}
                              >
                                <ContentCopy color={'default'} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Delete'} placement="top" arrow>
                              <IconButton
                                onClick={() => {
                                  setShowConfirmDeleteDialog(plan?.id);
                                }}
                              >
                                <Delete color={'default'} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Box
              width={1}
              height={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={2}
            >
              <Typography variant="body1" color={'text.secondary'} mt={4}>
                No Custom Plan Added yet.
              </Typography>
            </Box>
          )}
        </Box>
        <Tooltip arrow placement="top" title="Create New">
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            sx={{ position: 'absolute', bottom: 10, right: 20 }}
            onClick={(e) => {
              // setShowCreateCustomPlan('create');
              setAnchorElCreatePlan(e.target);
            }}
          >
            <Add />
          </Fab>
        </Tooltip>
        <Menu
          id="create-plan-menu"
          anchorEl={anchorElCreatePlan}
          keepMounted
          open={Boolean(anchorElCreatePlan)}
          onClose={() => {
            setAnchorElCreatePlan(null);
          }}
          // disableScrollLock={true}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          style={{
            marginTop: '15px',
          }}
        >
          <MenuItem
            value={'direct'}
            onClick={(e) => {
              setAnchorElCreatePlan(null);
              setShowCreateCustomPlan('create');
              setCustomerType('direct');
            }}
          >
            <Typography>Direct Customer</Typography>
          </MenuItem>
          <MenuItem
            value={'whitelabel'}
            onClick={(e) => {
              setShowCreateCustomPlan('create');
              setCustomerType('whitelabel');
              setAnchorElCreatePlan(null);
            }}
          >
            <Typography>Whitelabel Customer's Client</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showCreateCustomPlan)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowCreateCustomPlan(null);
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              {showCreateCustomPlan === 'create'
                ? 'Create Custom Plan'
                : showCreateCustomPlan?.title}{' '}
              (
              {customerType === 'whitelabel'
                ? 'which whitelabel customer can offer to their clients'
                : 'direct customer'}
              )
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: 'calc(100vh - 60px)',
              maxHeight: 'calc(100vh - 60px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
              paddingBottom: 8,
            }}
          >
            <Box flex={1}>
              {/* <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Plan Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={customerType}
                  onChange={(e) => setCustomerType(e.target.value)}
                >
                  <FormControlLabel
                    value={'direct'}
                    control={<Radio />}
                    label={'Direct Customer'}
                  />
                  <FormControlLabel
                    value={'whitelabel'}
                    control={<Radio />}
                    label={'Whitelabel Customer'}
                  />
                </RadioGroup>
              </FormControl> */}
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Activation Status (Only Active Plans can be assigned to direct
                  customers or whitelabel customers)
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={activated}
                  onChange={(e) => setActivated(e.target.value)}
                >
                  <FormControlLabel
                    value={'inactive'}
                    control={<Radio />}
                    label={'Inactive'}
                  />
                  <FormControlLabel
                    value={'active'}
                    control={<Radio />}
                    label={'Active'}
                  />
                </RadioGroup>
              </FormControl>
              {customerType === 'direct' ? (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Published or Draft
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={publishedForPricingPage}
                    onChange={(e) => setPublishedForPricingPage(e.target.value)}
                  >
                    <FormControlLabel
                      value={'published'}
                      control={<Radio />}
                      label={'Published'}
                    />
                    <FormControlLabel
                      value={'draft'}
                      control={<Radio />}
                      label={'Draft'}
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
              <Typography
                // textAlign={'center'}
                sx={{ textTransform: 'capitalize' }}
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Plan Title (visible to users)
              </Typography>
              <TextField
                // autoFocus
                placeholder="Plan Title"
                variant="outlined"
                color="primary"
                size="small"
                name="planTitle"
                value={customPlan?.title}
                onChange={(e) => {
                  setCustomPlan({
                    ...customPlan,
                    title: e.target.value,
                  });
                }}
                fullWidth
              />
              <Typography
                // textAlign={'center'}
                sx={{ textTransform: 'capitalize' }}
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Validity Days
              </Typography>
              <TextField
                // autoFocus
                placeholder="Validity Days"
                variant="outlined"
                color="primary"
                size="small"
                name="trailPeriodDays"
                value={customPlan?.trailPeriodDays}
                onChange={(e) => {
                  setCustomPlan({
                    ...customPlan,
                    trailPeriodDays: Number(e.target.value),
                  });
                }}
                fullWidth
                type="number"
              />
              <Typography
                // textAlign={'center'}
                sx={{ textTransform: 'capitalize' }}
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Plan Price
              </Typography>
              <TextField
                // autoFocus
                placeholder="Plan Price"
                variant="outlined"
                color="primary"
                size="small"
                name="planPrice"
                value={planPrice}
                onChange={(e) => {
                  setPlanPrice(e.target.value);
                }}
                fullWidth
                type="number"
              />
              <Typography
                // textAlign={'center'}
                sx={{ textTransform: 'capitalize' }}
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Price Unit
              </Typography>
              <FormControl fullWidth size="small">
                <Select
                  size="small"
                  labelId="plan-unit-select-label"
                  id="plan-unit-select"
                  name="priceUnit"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      // zIndex: 1111111,
                      // padding: '4px 8px',
                    },
                  }}
                  onChange={(e) => {
                    setPriceUnit(e.target.value);
                  }}
                  value={priceUnit}
                >
                  <MenuItem value={'usd'}>
                    <Typography variant="body2">USD</Typography>
                  </MenuItem>
                  <MenuItem value={'inr'}>
                    <Typography variant="body2">INR</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography
                // textAlign={'center'}
                sx={{ textTransform: 'capitalize' }}
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Plan Description
              </Typography>
              <TextField
                // autoFocus
                placeholder="Plan Description"
                variant="outlined"
                color="primary"
                size="small"
                multiline
                rows={3}
                name="planDescription"
                value={customPlan?.description}
                onChange={(e) => {
                  setCustomPlan({
                    ...customPlan,
                    description: e.target.value,
                  });
                }}
                fullWidth
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '8px 0px',
                }}
              >
                <Typography
                  // textAlign={'center'}
                  sx={{ textTransform: 'capitalize' }}
                  color={'text.secondary'}
                  fontWeight={'bold'}
                >
                  Features
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  color="primary"
                  onClick={(e) => {
                    handleAddFeatureClick(e);
                  }}
                >
                  Add Feature
                </Button>
                <Menu
                  id="add-section-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  disableScrollLock={true}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  style={{
                    marginTop: '15px',
                  }}
                >
                  {Object.keys(planTemplage?.features || {}).map((feature) => (
                    <Tooltip
                      key={feature}
                      title={
                        Object.keys(customPlan?.features || {})?.find(
                          (ftr) => ftr === feature,
                        )
                          ? 'Feature is already added'
                          : ''
                      }
                      arrow
                      placement="right"
                    >
                      <MenuItem
                        value={feature}
                        onClick={(e) => {
                          if (
                            !Object.keys(customPlan?.features || {})?.find(
                              (ftr) => ftr === feature,
                            )
                          ) {
                            setCustomPlan({
                              ...customPlan,
                              features: {
                                ...customPlan?.features,
                                [feature]: planTemplage?.features?.[feature],
                              },
                            });
                          }
                          setAnchorEl(null);
                        }}
                      >
                        <Typography
                          color={
                            Object.keys(customPlan?.features || {})?.find(
                              (ftr) => ftr === feature,
                            )
                              ? 'text.secondary'
                              : 'text.primary'
                          }
                        >
                          {feature}
                        </Typography>
                      </MenuItem>
                    </Tooltip>
                  ))}
                </Menu>
              </Box>
              {Object.values(customPlan?.features || {})?.map((feature) => (
                <Box
                  key={feature?.feature}
                  sx={{
                    border: '1px solid #f1f1f1',
                    borderRadius: '5px',
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={1}
                    sx={{
                      background: '#f1f1f1',
                      padding: ' 8px 16px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      sx={{ textTransform: 'capitalize' }}
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      {feature?.feature}
                    </Typography>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setCustomPlan({
                          ...customPlan,
                          features: Object.keys(customPlan?.features || {})
                            ?.filter((ftr) => ftr !== feature?.feature)
                            ?.reduce((acc, curr) => {
                              // if (curr !== feature?.feature) {
                              acc[curr] = customPlan?.features[curr];
                              // } else {
                              //   acc[curr] = null;
                              // }
                              return acc;
                            }, {}),
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={2} p={2}>
                    {Object.values(feature?.restrictions || {})?.map(
                      (restriction) => (
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{ textTransform: 'capitalize' }}
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              Restriction
                            </Typography>
                            <Typography color={'text.secondary'}>
                              {restriction?.restriction}
                            </Typography>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                            ml={2}
                          >
                            {Object.keys(restriction || {})
                              ?.filter((key) => key !== 'restriction')
                              ?.map((key, index) => (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Typography
                                    sx={{ textTransform: 'capitalize' }}
                                    color={'text.secondary'}
                                    fontWeight={'bold'}
                                    variant="subtitle2"
                                  >
                                    {index + 1}. {key}
                                  </Typography>
                                  {key === 'unit' ||
                                  key === 'action' ||
                                  key === 'level' ||
                                  key === 'refreshCycle' ||
                                  key === 'available' ? (
                                    <Typography
                                      sx={{ textTransform: 'capitalize' }}
                                      color={'text.secondary'}
                                      // fontWeight={'bold'}
                                      variant="subtitle2"
                                    >
                                      {String(restriction[key]) || ''}
                                    </Typography>
                                  ) : key === 'unlimited' ? (
                                    <Select
                                      size="small"
                                      labelId="prompt-select-label"
                                      value={restriction[key]}
                                      onChange={(e) => {
                                        // setType(e.target.value);
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={true}>True</MenuItem>
                                      <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                  ) : key === 'allowedValues' ? (
                                    <Select
                                      size="small"
                                      multiple
                                      labelId="prompt-select-label"
                                      value={restriction[key] || ''}
                                      onChange={(e) => {
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={'english'}>
                                        English
                                      </MenuItem>
                                      <MenuItem value={'hindi'}>Hindi</MenuItem>
                                    </Select>
                                  ) : key === 'limit' ? (
                                    <TextField
                                      // autoFocus
                                      placeholder="Limit"
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      name="limit"
                                      type="number"
                                      value={restriction[key] || 0}
                                      onChange={(e) => {
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: Number(e.target.value),
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                      // fullWidth
                                    />
                                  ) : null}
                                  {/* {key === 'unit' ? (
                                    <Select
                                      size="small"
                                      labelId="prompt-select-label"
                                      value={restriction[key] || ''}
                                      onChange={(e) => {
                                        // console.log(e.target.value);
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={'count'}>Count</MenuItem>
                                      <MenuItem value={'month'}>Month</MenuItem>
                                      <MenuItem value={'milli_sec'}>
                                        Millisecond
                                      </MenuItem>
                                      <MenuItem value={'availability'}>
                                        Availability
                                      </MenuItem>
                                      <MenuItem value={'value'}>Value</MenuItem>
                                    </Select>
                                  ) : key === 'level' ? (
                                    <Select
                                      size="small"
                                      labelId="prompt-select-label"
                                      value={restriction[key] || ''}
                                      onChange={(e) => {
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={'org'}>
                                        Organization
                                      </MenuItem>
                                      <MenuItem value={'user'}>User</MenuItem>
                                      <MenuItem value={'entity'}>
                                        Entity
                                      </MenuItem>
                                    </Select>
                                  ) : key === 'action' ? (
                                    restriction?.[key] !== null ? (
                                      <Select
                                        size="small"
                                        labelId="prompt-select-label"
                                        value={restriction[key] || ''}
                                        onChange={(e) => {
                                          // setType(e.target.value);
                                          setCustomPlan({
                                            ...customPlan,
                                            features: {
                                              ...customPlan?.features,
                                              [feature?.feature]: {
                                                ...feature,
                                                restrictions: {
                                                  ...feature?.restrictions,
                                                  [restriction?.restriction]: {
                                                    ...restriction,
                                                    [key]: e.target.value,
                                                  },
                                                },
                                              },
                                            },
                                          });
                                        }}
                                      >
                                        {feature.allowedActions &&
                                        feature?.allowedActions.length > 0 ? (
                                          feature.allowedActions.map(
                                            (action) => (
                                              <MenuItem value={action}>
                                                {action.split('_')?.join(' ')}
                                              </MenuItem>
                                            ),
                                          )
                                        ) : (
                                          <Typography color={'text.secondary'}>
                                            {restriction[key] || ''}
                                          </Typography>
                                        )}
                                      </Select>
                                    ) : (
                                      <Select
                                        size="small"
                                        labelId="prompt-select-label"
                                        value={restriction[key]}
                                        disabled
                                        onChange={(e) => {
                                          // setType(e.target.value);
                                        }}
                                      >
                                        <MenuItem value={null}>None</MenuItem>
                                      </Select>
                                    )
                                  ) : key === 'unlimited' ? (
                                    <Select
                                      size="small"
                                      labelId="prompt-select-label"
                                      value={restriction[key]}
                                      onChange={(e) => {
                                        // setType(e.target.value);
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={true}>True</MenuItem>
                                      <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                  ) : key === 'limit' ? (
                                    <TextField
                                      autoFocus
                                      placeholder="Limit"
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      name="limit"
                                      type="number"
                                      value={restriction[key] || 0}
                                      onChange={(e) => {
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: Number(e.target.value),
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                      // fullWidth
                                    />
                                  ) : key === 'refreshCycle' ? (
                                    <Select
                                      size="small"
                                      labelId="prompt-select-label"
                                      value={restriction[key]}
                                      onChange={(e) => {
                                        // setType(e.target.value);
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={'monthly'}>
                                        Monthly
                                      </MenuItem>
                                      <MenuItem value={'weakly'}>
                                        Weakly
                                      </MenuItem>
                                      <MenuItem value={'manually'}>
                                        Manually
                                      </MenuItem>
                                      <MenuItem value={'never'}>Never</MenuItem>
                                    </Select>
                                  ) : key === 'available' ? (
                                    <Select
                                      size="small"
                                      labelId="prompt-select-label"
                                      value={restriction[key]}
                                      onChange={(e) => {
                                        // setType(e.target.value);
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={true}>True</MenuItem>
                                      <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                  ) : key === 'allowedValues' ? (
                                    <Select
                                      size="small"
                                      multiple
                                      labelId="prompt-select-label"
                                      value={restriction[key] || ''}
                                      onChange={(e) => {
                                        setCustomPlan({
                                          ...customPlan,
                                          features: {
                                            ...customPlan?.features,
                                            [feature?.feature]: {
                                              ...feature,
                                              restrictions: {
                                                ...feature?.restrictions,
                                                [restriction?.restriction]: {
                                                  ...restriction,
                                                  [key]: e.target.value,
                                                },
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <MenuItem value={'english'}>
                                        English
                                      </MenuItem>
                                      <MenuItem value={'hindi'}>Hindi</MenuItem>
                                    </Select>
                                  ) : null} */}
                                </Box>
                              ))}
                          </Box>
                        </Box>
                      ),
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                display: 'flex',
                width: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '8px 16px',
                borderTop: '1px solid #d3d3d3',
                background: '#fff',
              }}
            >
              <Button
                disabled={creatingCustomPlan}
                startIcon={
                  creatingCustomPlan ? <CircularProgress size={20} /> : null
                }
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  if (showCreateCustomPlan === 'create') {
                    setCreatingCustomPlan(true);
                    dispatch(
                      createCustomPlan(
                        {
                          ...customPlan,
                          features: Object.values(customPlan?.features || {}),
                          qaiPlan: true,
                          activated: activated === 'active' ? true : false,
                          forWlOrg:
                            customerType === 'whitelabel' ? true : false,
                          publishedForPricingPage:
                            customerType === 'whitelabel'
                              ? false
                              : publishedForPricingPage === 'published'
                              ? true
                              : false,
                          price: {
                            amount: planPrice,
                            currency: priceUnit,
                            priceUnit: priceBase,
                            pricingDuration: priceDuration,
                            pricingScheme: pricingScheme,
                          },
                        },
                        () => {
                          setCreatingCustomPlan(false);
                          setShowCreateCustomPlan(null);
                          setFetchingPlans(true);
                          dispatch(
                            getPlans((plans) => {
                              setFetchedPlans(plans);
                              setFetchingPlans(false);
                            }),
                          );
                        },
                      ),
                    );
                  } else {
                    setCreatingCustomPlan(true);
                    dispatch(
                      updateCustomPlan(
                        showCreateCustomPlan?.id,
                        {
                          ...customPlan,
                          features: Object.values(customPlan?.features || {}),
                          qaiPlan: true,
                          activated: activated === 'active' ? true : false,
                          forWlOrg:
                            customerType === 'whitelabel' ? true : false,
                          publishedForPricingPage:
                            customerType === 'whitelabel'
                              ? false
                              : publishedForPricingPage === 'published'
                              ? true
                              : false,
                          price: {
                            amount: planPrice,
                            currency: priceUnit,
                            priceUnit: priceBase,
                            pricingDuration: priceDuration,
                            pricingScheme: pricingScheme,
                          },
                        },
                        () => {
                          setCreatingCustomPlan(false);
                          setShowCreateCustomPlan(null);
                          setFetchingPlans(true);
                          dispatch(
                            getPlans((plans) => {
                              setFetchedPlans(plans);
                              setFetchingPlans(false);
                            }),
                          );
                        },
                      ),
                    );
                  }
                }}
              >
                {showCreateCustomPlan === 'create' ? 'Create' : 'Update'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showPlanDetails)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowPlanDetails(null);
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              {showPlanDetails?.title}
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: 'calc(100vh - 60px)',
              maxHeight: 'calc(100vh - 60px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
              paddingBottom: 8,
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Typography
                  // textAlign={'center'}
                  sx={{ textTransform: 'capitalize' }}
                  color={'text.secondary'}
                  fontWeight={'bold'}
                >
                  {showPlanDetails?.title}
                </Typography>
                <Typography
                  // textAlign={'center'}
                  // sx={{ textTransform: 'capitalize' }}
                  color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  ({showPlanDetails?.description})
                </Typography>
              </Box>
              <Typography variant="caption" color={'text.secondary'}>
                {moment(showPlanDetails?.createOn).format('DD MMMM YYYY')}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {Object.values(showPlanDetails?.features || {})?.map(
                (feature) => (
                  <Box key={feature?.feature}>
                    <Typography
                      // textAlign={'center'}
                      sx={{ textTransform: 'capitalize' }}
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      {feature?.feature}
                    </Typography>

                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                      {Object.values(feature?.restrictions || {})?.map(
                        (restriction) => (
                          <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                              sx={{ textTransform: 'capitalize' }}
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              {restriction?.restriction}
                            </Typography>
                            {Object.keys(restriction || {})
                              ?.filter((key) => key !== 'restriction')
                              ?.map((key) => (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Typography
                                    sx={{ textTransform: 'capitalize' }}
                                    color={'text.secondary'}
                                    fontWeight={'bold'}
                                  >
                                    {key}
                                  </Typography>
                                  <Typography>{restriction[key]}</Typography>
                                </Box>
                              ))}
                          </Box>
                        ),
                      )}
                    </Box>
                  </Box>
                ),
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this custom plan? This might be
              associated with some organizations. Deleting this plan may lead to
              unexpected behavior.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteCustomPlan(showConfirmDeleteDialog, () => {
                  setFetchingPlans(true);
                  dispatch(
                    getPlans((plans) => {
                      setFetchedPlans(plans);
                      setFetchingPlans(false);
                    }),
                  );
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            // autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default CustomPlan;
