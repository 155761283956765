import { v4 as uuidv4 } from 'uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const linkingCompetitionToJob = (jobId, competitionId, link, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'LINKING_COMPETITION_TO_JOB' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(`/ads/jobs/${jobId}/c-link`, null, {
            params: {
              'request-id': reqId,
              action: link,
              competitionId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'LINKING_COMPETITION_TO_JOB_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data, false);
            }
          } else {
            dispatch({
              type: 'LINKING_COMPETITION_TO_JOB_FAILED',
              payload: `Failed to link competition to job`,
            });
            if (typeof callback === 'function') {
              callback(null, true);
            }
          }
        } catch (e) {
          console.log(e);
          dispatch({
            type: 'LINKING_COMPETITION_TO_JOB_FAILED',
            payload: `Failed to link competition to job`,
          });
          if (typeof callback === 'function') {
            callback(null, true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'LINKING_COMPETITION_TO_JOB_FAILED',
        payload: `Failed to link competition to job`,
      });
      if (typeof callback === 'function') {
        callback(null, true);
      }
    }
  };
};

export default linkingCompetitionToJob;
