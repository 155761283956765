const initialstate = {
  fetching: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  productDescription: null,
  prospectDetails: null,
  prospectScript: null,
  productDescriptionMcq: null,
  coldCallingScript: null,
  id: null,
};

const getAIDataForContestCreationReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_AI_DATA_FOR_CONTEST_CREATION':
      return Object.assign({}, state, {
        fetching: true,
      });
    case 'FETCHING_AI_DATA_FOR_PRODUCT_DESCRIPTION_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        id: action.payload.id,
        productDescription: action.payload.value,
        prospectDetails: state.prospectDetails,
        productDescriptionMcq: state.productDescriptionMcq,
        prospectScript: state.prospectScript,
        coldCallingScript: state.coldCallingScript,
      });
    case 'FETCHING_AI_DATA_FOR_PROSPECT_DETAILS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        id: action.payload.id,
        productDescription: state.productDescription,
        prospectDetails: action.payload.value,
        prospectScript: state.prospectScript,
        productDescriptionMcq: state.productDescriptionMcq,
        coldCallingScript: state.coldCallingScript,
      });
    case 'FETCHING_AI_DATA_FOR_COLD_CALLING_SCRIPT_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        id: action.payload.id,
        productDescription: state.productDescription,
        prospectDetails: state.prospectDetails,
        prospectScript: state.prospectScript,
        productDescriptionMcq: state.productDescriptionMcq,
        coldCallingScript: action.payload.value,
      });
    case 'FETCHING_AI_DATA_FOR_PRODUCT_DESCRIPTION_MCQS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        id: action.payload.id,
        productDescription: state.productDescription,
        prospectDetails: state.prospectDetails,
        prospectScript: state.prospectScript,
        productDescriptionMcq: action.payload.value,
        coldCallingScript: state.coldCallingScript,
      });
    case 'FETCHING_AI_DATA_FOR_PROSPECT_SCRIPT_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        id: action.payload.id,
        productDescription: state.productDescription,
        prospectDetails: state.prospectDetails,
        prospectScript: action.payload.value,
        productDescriptionMcq: state.productDescriptionMcq,
        coldCallingScript: state.coldCallingScript,
      });
    case 'FETCHING_AI_DATA_FOR_CONTEST_CREATION_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        data: null,
      });
    default:
      return state;
  }
};

export default getAIDataForContestCreationReducer;
