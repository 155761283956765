import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import inviteUser from 'redux/actions/Common/inviteUser';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import getCompetitionStandardData from 'redux/actions/Common/getCompetitionStandardData';
import { Player } from 'video-react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

//actions
import createContestAction from 'redux/actions/Common/createContest';
import uploadVideoAction from 'redux/actions/Common/uploadVideoAction';
import verifyImageUpload from 'redux/actions/Common/verifyImageUpload';
import verifyVideoUpload from 'redux/actions/Common/verifyVideoUpload';
import {
  Alert,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  StepButton,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import dayjs from 'dayjs';
import updateContest from 'redux/actions/Common/updateContest';
import updateContestSection from 'redux/actions/Common/updateContestSection';
import getAllEmployers from 'redux/actions/Common/getAllEmployers';
import { Check, Close, Link } from '@mui/icons-material';
import getSlugAvailability from 'redux/actions/Common/getSlugAvailability';
import { useDebouncedCallback } from 'use-debounce';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import getAIDataForContestCreation from 'redux/actions/Common/getAIDataForContestCreation';
import getUploadUrlForProspectVideoContestCreation from 'redux/actions/Common/getUploadUrlForProspectVideoContestCreation';
import getStatusOfVideo from 'redux/actions/Common/getStatusOfVideo';
import getDefaultPrompts from 'redux/actions/Common/getDefaultPrompts';
import saveDefaultPrompt from 'redux/actions/Common/saveDefaultPrompt';

const steps = [
  {
    label: 'Select campaign settings',
    description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Create an ad group',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Create an ad',
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
];

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  designations: yup
    .array()
    .required('Please select a role to get started')
    .min(1, 'You have to pick one role to get started')
    .max(3, 'You can pick up to three roles to get started'),
  emailVerified: yup.bool(),
});

const AICreateContest = () => {
  const theme = useTheme();
  const location = useLocation();
  const { contest, editContest } = location.state || {
    contest: null,
    editContest: null,
  };
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const aiDataForContestCreation = useSelector(
    (state) => state.aiDataForContestCreation,
  );
  const defaultPrompts = useSelector((state) => state.defaultPrompts);
  const [processing, setProcessing] = useState(false);
  const [checkingSlug, setCheckingSlug] = useState(false);
  const [contestId, setContestId] = useState(contest?.id || '');
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [overview, setOverview] = useState('');
  const [rules, setRules] = useState('');
  const [Prizes, setPrizes] = useState('');
  const [overallInstructions, setOverallInstruction] = useState('');
  const [step1Instructions, setStep1Instructions] = useState('');

  const [standardData, setStandardData] = useState(null);
  const [Faqs, setFaqs] = useState([]);
  const [currentFaqQuestion, setCurrentFaqQuestion] = useState('');
  const [currentFaqAnswer, setCurrentFaqAnswer] = useState('');
  const [script, setScript] = useState('');
  const [step2Instructions, setStep2Instructions] = useState('');
  const [step2SecondaryInstruction, setStep2SecondaryInstruction] = useState(
    '',
  );
  const [scoringScheme, setScoringScheme] = useState('');
  const [pitchInstructions, setPitchInstructions] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [competitionSuccussMessage, setCompetitionSuccussMessage] = useState(
    '',
  );
  const [customerVideoScript, setCustomerVideoScript] = useState(null);
  const [MCQs, setMCQs] = useState([]);
  const [currentMCQquestion, setCurrentMcqQuestion] = useState(null);
  const [option1, setOption1] = useState('');
  const [option2, setOption2] = useState('');
  const [option3, setOption3] = useState('');
  const [option4, setOption4] = useState('');
  const [answers, setAnswers] = useState('');
  const [tags, setTags] = useState('');
  const [videoUploaded, setVideoUploaded] = useState(false);
  const [videoVerified, setVideoVerified] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageUploadedVerified, setImageUploadedVerified] = useState(false);
  const [startOn, setStartOn] = useState(null);
  const [endOn, setEndOn] = useState(null);
  const [competitionType, setCompetitionType] = useState(
    'practice_competition',
  );
  const [maxNumberOfAttempt, setMaxNumberOfAttempts] = useState(
    competitionType === 'real_competition' ||
      competitionType === 'private_competition' ||
      competitionType === 'semi_private_competition'
      ? 1
      : 3,
  );
  const [criteria, setCriteria] = useState('dateRange');
  const [maxLimit, setMaxLimit] = useState(200);
  const [duration, setDuration] = useState(60);
  const [phase1Duration, setPhase1Duration] = useState(30);
  const [phase2Duration, setPhase2Duration] = useState(45);
  const [
    customerInteractionBackground,
    setCustomerInteractionBackground,
  ] = useState('');
  const [
    maxVideoTimeLimitInMinSection1,
    setMaxVideoTimeLimitInMinSection1,
  ] = useState(3);

  const [aboutHost, setAboutHost] = useState('');
  const [prospectName, setProspectName] = useState('');

  const [
    maxVideoTimeLimitInMinSection3,
    setMaxVideoTimeLimitInMinSection3,
  ] = useState(5);

  const [customerVideoTranscript, setCustomerVideoTranscript] = useState('');
  const [productKnowledgeKeywords, setProductKnowledgeKeywords] = useState('');
  const [
    requirementGatheringKeywords,
    setRequirementGatheringKeywords,
  ] = useState('');
  const [mcqText, setMcqText] = useState('');
  const [
    recommendedVideoTimeLimitInMin,
    setRecommendedVideoTimeLimitInMin,
  ] = useState(null);

  const [employers, setEmployers] = useState([]);
  const [selectedEmployer, setSelectedEmployer] = useState('');
  const [slugAvailable, setSlugAvailable] = useState(null);

  const [contestMode, setContestMode] = useState('normal');
  const [phaseInvitation, setPhaseInvitation] = useState('automatic');

  const [companyLink, setCompanyLink] = useState('');
  const [generatingAIData, setGeneratingAIData] = useState(false);
  const [generatingAIDataFor, setGeneratingAIDataFor] = useState('');
  const timerRef = useRef(null);
  const [videoStatus, setVideoStatus] = useState('');
  const [showGenerateAIDataDialog, setShowGenerateAIDataDialog] = useState('');
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [dataType, setDataType] = useState('');
  const [promptChanged, setPromptChanged] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const dispatch = useDispatch();
  // console.log(rules);
  const debounced = useDebouncedCallback(() => {
    setCheckingSlug(true);
    handleCheckSlugAvailability();
  }, 1000);

  const initialValues = {
    title: '',
    quickIntroduction: '',
    metadata: '',
    imageURL: null,
    companyName: '',
    productName: '',
    customerPersona: '',
    customerInteraction: '',
    productDescription: '',
    host: '',
    slug: '',
  };

  const onSubmit = (values) => {};

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  // console.log(formik);

  const handleMcqTextParse = (domain) => {
    if (mcqText.trim()) {
      const questions = mcqText.split('\n\n');
      const mcqs = questions.reduce((acc, curr) => {
        const lines = curr.split('\n');
        const question = /\)\./g.test(lines?.[0])
          ? lines?.[0]?.split(/\)\./g)?.[1]?.trim()
          : /\)/g.test(lines?.[0])
          ? lines?.[0]?.split(/\)/g)?.[1]?.trim()
          : /\./g.test(lines?.[0])
          ? lines?.[0]?.split(/\./g)?.[1]?.trim()
          : '';
        const options = lines
          .slice(1, 5)
          .map((option) =>
            /\)\./g.test(option)
              ? option?.split(/\)\./g)?.[1]?.trim()
              : /\)/g.test(option)
              ? option?.split(/\)/g)?.[1]?.trim()
              : /\./g.test(option)
              ? option?.split(/\./g)?.[1]?.trim()
              : '',
          );
        const optionsIndex = lines.slice(1, 5).map((option) =>
          /\)\./g.test(option)
            ? option
                ?.split(/\)\./g)?.[0]
                ?.trim()
                ?.toLowerCase()
            : /\)/g.test(option)
            ? option
                ?.split(/\)/g)?.[0]
                ?.trim()
                ?.toLowerCase()
            : /\./g.test(option)
            ? option
                ?.split(/\./g)?.[0]
                ?.trim()
                ?.toLowerCase()
            : '',
        );
        let answers = [];
        const answersArr = lines
          .slice(5)
          .map((answer) =>
            /\)\./g.test(answer)
              ? answer
                  ?.split(':')?.[1]
                  ?.trim()
                  ?.split(/\)\./g)?.[0]
                  ?.trim()
                  ?.toLowerCase()
              : /\)/g.test(answer)
              ? answer
                  ?.split(':')?.[1]
                  ?.trim()
                  ?.split(/\)/g)?.[0]
                  ?.trim()
                  ?.toLowerCase()
              : /\./g.test(answer)
              ? answer
                  ?.split(':')?.[1]
                  ?.trim()
                  ?.split(/\./g)?.[0]
                  ?.trim()
                  ?.toLowerCase()
              : answer
                  ?.split(':')?.[1]
                  ?.trim()
                  ?.toLowerCase(),
          )
          ?.filter((ans) => ans !== undefined);
        if (optionsIndex?.includes(answersArr?.[0])) {
          answers = options.filter(
            (op, i) =>
              i === optionsIndex?.findIndex((op) => op === answersArr[0]),
          );
        }
        acc.push({
          question: question,
          answers: [...answers],
          options: options,
          tags: [],
          domain: domain,
          type: 'mcq',
        });
        return acc;
      }, []);
      setMCQs((pre) => [...pre, ...mcqs]);
      setMcqText('');
    }
  };

  const handleCheckSlugAvailability = () => {
    if (formik.values.slug?.trim()) {
      dispatch(
        getSlugAvailability(
          formik?.values?.slug?.trim(),
          (availability) => {
            setCheckingSlug(false);
            console.log(availability);
            setSlugAvailable(availability);
          },
          (error) => {
            setCheckingSlug(false);
            console.log(error);
          },
        ),
      );
    }
  };

  const handleGenerateProspectMcqs = (cb) => {
    dispatch(
      getUploadUrlForProspectVideoContestCreation(
        aiDataForContestCreation?.id,
        videoData?.type,
        (data) => {
          console.log(data);
          if (data?.url && data?.videoId) {
            dispatch(
              uploadVideoAction(
                data?.url,
                videoData,
                () => {
                  console.log('video uploaded');
                  dispatch(
                    verifyVideoUpload(data?.videoId, () => {
                      console.log('video verified');
                      timerRef.current = setInterval(() => {
                        dispatch(
                          getStatusOfVideo(data?.videoId, (status) => {
                            console.log(status);
                            setVideoStatus(status);
                            if (status === 'success') {
                              cb();
                            }
                          }),
                        );
                      }, 3000);
                    }),
                  );
                },
                () => {},
              ),
            );
          }
        },
      ),
    );
  };

  const generateAIData = (
    type,
    savePreviousRequest,
    previousRequestType,
    savePrompt,
  ) => {
    if (savePreviousRequest) {
      dispatch(
        getAIDataForContestCreation({
          id: aiDataForContestCreation?.id || null,
          previousRequestType,
          // savePreviousRequest,
          productDescription:
            previousRequestType === 'product_description'
              ? aiDataForContestCreation?.productDescription || null
              : null,
          scenarioDescription:
            previousRequestType === 'scenario_description'
              ? aiDataForContestCreation?.prospectDetails || null
              : null,
          coldCallingScript:
            previousRequestType === 'cold_calling_script'
              ? aiDataForContestCreation?.coldCallingScript || null
              : null,
          productDescriptionMcq:
            previousRequestType === 'product_description_mcq'
              ? aiDataForContestCreation?.productDescriptionMcq || null
              : null,
          prospectScript:
            previousRequestType === 'prospect_script'
              ? aiDataForContestCreation?.prospectScript || null
              : null,
        }),
      );
    }
    if (savePrompt) {
      dispatch(saveDefaultPrompt(currentPrompt, dataType, () => {}));
    }
    setGeneratingAIData(true);
    setGeneratingAIDataFor(type);
    dispatch(
      getAIDataForContestCreation(
        {
          companyLink,
          companyShortDescription: aboutCompany,
          requestType: type,
          id: aiDataForContestCreation?.id || null,
          prospectScript:
            type === 'prospect_mcq'
              ? aiDataForContestCreation?.prospectScript
              : null,
          productDescription:
            type !== 'product_description'
              ? aiDataForContestCreation?.productDescription
              : null,
          custom_prompt: promptChanged ? currentPrompt : null,
        },
        (data) => {
          setGeneratingAIData(false);
          setGeneratingAIDataFor('');
          setShowGenerateAIDataDialog('');
          console.log('ai data', data);
          if (type === 'product_description') {
            setProductDescription(data?.response);
          } else if (type === 'scenario_description') {
            setCustomerInteractionBackground(data?.response);
          } else if (type === 'cold_calling_script') {
            setScript(data?.response);
          } else if (type === 'prospect_script') {
            setCustomerVideoTranscript(data?.response);
          } else if (
            type === 'product_description_mcq' ||
            type === 'prospect_mcq'
          ) {
            setMcqText(data?.response);
          }
        },
        (error) => {
          setGeneratingAIData(false);
          setGeneratingAIDataFor('');
          console.log(error);
        },
      ),
    );
  };

  useEffect(() => {
    // console.log('here you are');
    dispatch(
      getCompetitionStandardData((data) =>
        setStandardData(data?.dataItemsMap?.[competitionType]),
      ),
    );
    setMaxNumberOfAttempts(
      competitionType === 'real_competition' ||
        competitionType === 'private_competition' ||
        competitionType === 'semi_private_competition' ||
        contestMode === 'phased'
        ? 1
        : 3,
    );
  }, [competitionType, contestMode]);

  useEffect(() => {
    if (
      defaultPrompts?.prompts?.[showGenerateAIDataDialog] !==
      currentPrompt.trim()
    ) {
      setPromptChanged(true);
    } else {
      setPromptChanged(false);
    }
  }, [showGenerateAIDataDialog, currentPrompt, defaultPrompts]);

  useEffect(() => {
    // console.log('here you are');
    dispatch(
      getCompetitionStandardData((data) =>
        setStandardData(data?.dataItemsMap?.[competitionType]),
      ),
    );
  }, []);

  useEffect(() => {
    if (standardData && !editContest) {
      standardData?.forEach((data) => {
        if (data?.property === 'competition_rules') {
          // console.log(data.data);
          setRules(data?.data || '');
        } else if (data?.property === 'competition_scoring') {
          // console.log(data.data);
          setScoringScheme(data?.data || '');
        } else if (data?.property === 'competition_prizes') {
          // console.log(data.data);
          setPrizes(data?.data || '');
        } else if (data?.property === 'competition_faq') {
          setFaqs(data?.faqs);
        } else if (data?.property === 'competition_instruction') {
          // console.log(data.data);
          setOverallInstruction(data?.data || '');
        } else if (data?.property === 'script_reading_instruction') {
          // console.log(data.data);
          setStep1Instructions(data?.data || '');
        } else if (data?.property === 'customer_qa_instruction') {
          // console.log(data.data);
          setStep2Instructions(data?.data || '');
        } else if (data?.property === 'pitch_instruction') {
          // console.log(data.data);
          setPitchInstructions(data?.data || '');
        } else if (data?.property === 'competition_success_message') {
          // console.log(data.data);
          setCompetitionSuccussMessage(data?.data || '');
        } else if (data?.property === 'competition_overview') {
          // console.log(data.data);
          setOverview(data?.data || '');
        } else if (data?.property === 'customer_qa_secondary_instruction') {
          setStep2SecondaryInstruction(data?.data);
        } else if (data?.property === 'competition_product') {
          // console.log(data.data);
          setProductDescription(data?.data || '');
        } else if (
          data?.property === 'competition_customer_interaction_background'
        ) {
          // console.log(data.data);
          setCustomerInteractionBackground(data?.data || '');
        } else if (data?.property === 'competition_short_introduction') {
          // console.log(data.data);
          formik.setFieldValue(
            'quickIntroduction',
            data?.data ? data?.data : '',
          );
        } else if (data?.property === 'competition_host') {
          // console.log(data.data);
          formik.setFieldValue(
            'host',
            data?.dataMap ? data?.dataMap?.name : '',
          );
          setAboutHost(data?.dataMap?.about || '');
        }
      });
    }
  }, [standardData]);

  useEffect(() => {
    if (editContest) {
      setSelectedEmployer(contest?.employerId || contest?.creator?.createdBy);
      setAboutCompany(contest?.aboutCompany || '');
      setCriteria(contest?.criteria || 'dateRange');
      setMaxLimit(contest?.maxLimit || 0);
      setProspectName(contest?.sections?.[0]?.prospectName);
      setCompetitionType(contest?.competitionType || 'practice_competition');
      setContestMode(contest?.competitionMode || 'normal');
      setPhaseInvitation(contest?.phaseInvitation || 'automatic');
      setRecommendedVideoTimeLimitInMin(
        contest?.sections?.[2]?.recommendedVideoTimeLimitInMin,
      );
      setStartOn(contest?.startOn || null);
      setEndOn(contest?.endOn || null);
      setMaxNumberOfAttempts(contest?.maxNumberOfAttempt);
      setCustomerVideoTranscript(
        contest?.sections?.[1]?.customerVideoTranscript,
      );
      setProductKnowledgeKeywords(
        contest?.sections?.[1]?.productKnowledgeKeywords?.join(','),
      );
      setRequirementGatheringKeywords(
        contest?.sections?.[1]?.requirementGatheringKeywords?.join(','),
      );
      setDuration(contest?.durationInMin);
      setPhase1Duration(contest?.phase1DurationInMin);
      setPhase2Duration(contest?.phase2DurationInMin);
      formik.setFieldValue('title', contest?.title);
      formik.setFieldValue(
        'quickIntroduction',
        contest?.introduction?.shortText,
      );
      formik.setFieldValue('metadata', contest?.tags?.join(','));
      formik.setFieldValue('slug', contest?.slug);
      setProductDescription(contest?.product?.description || '');
      setCustomerInteractionBackground(
        contest?.customerInteractionBackground?.description || '',
      );
      setOverview(contest?.competitionOverview?.description || '');
      setRules(contest?.rules?.description || '');
      setScoringScheme(contest?.scoringScheme?.description || '');
      setPrizes(contest?.prize?.description || '');
      setFaqs(contest?.faqs?.faqs || []);
      setStep1Instructions(contest?.sections?.[0]?.instruction?.text || '');
      setScript(contest?.sections?.[0]?.script || '');
      setMaxVideoTimeLimitInMinSection1(
        contest?.sections?.[0]?.maxVideoTimeLimitInMin || 3,
      );
      setMaxVideoTimeLimitInMinSection3(
        contest?.sections?.[2]?.maxVideoTimeLimitInMin || 3,
      );
      setStep2Instructions(contest?.sections?.[1]?.instruction?.text || '');
      setStep2SecondaryInstruction(
        contest?.sections?.[1]?.secondaryInstruction?.text || '',
      );
      setMCQs(contest?.sections?.[1]?.sectionQuestion || []);
      setPitchInstructions(contest?.sections?.[2]?.instruction?.text || '');
      setCompetitionSuccussMessage(contest?.successMessage?.description || '');
      setCustomerVideoScript(
        contest?.sections?.[1]?.customFields?.sectionQAVideo?.videoUrls?.[0]
          ?.url,
      );
      formik.setFieldValue('host', contest?.host?.name);
      setAboutHost(contest?.host?.about || '');
    }
  }, [editContest, contest]);

  const [videoData, setVideoData] = useState(null);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setVideoData(event.target.files[0]);
    // console.log(event.target.files[0], 'event.target.files[0]');
    const url = URL.createObjectURL(file);
    setCustomerVideoScript(url);
  };

  const onContestSubmit = (draft) => {
    // console.log('onContestSubmit clicked!');
    setProcessing(true);
    dispatch(
      createContestAction(
        {
          slug: formik.values.slug,
          aboutCompany: aboutCompany,
          criteria,
          visibility: draft ? 'draft' : 'published',
          competitionMode: contestMode,
          phaseInvitation: phaseInvitation,
          competitionType: competitionType,
          hostName: formik.values.host,
          employerId: selectedEmployer,
          aboutHost: aboutHost,
          durationInMin: duration,

          phase1DurationInMin: phase1Duration,
          phase2DurationInMin: phase2Duration,
          endOn: criteria === 'dateRange' ? endOn : null,
          startOn: criteria === 'dateRange' ? startOn : null,
          maxLimit: criteria === 'maxLimit' ? maxLimit : null,
          competitionImageContentType: selectedImage?.type,
          maxNumberOfAttempts: maxNumberOfAttempt,
          competitionSuccessFullText: competitionSuccussMessage,
          customerInteractionBackground: customerInteractionBackground,
          faqs: Faqs,
          shortIntroduction: formik.values.quickIntroduction,
          prize: Prizes,
          productDetail: productDescription,
          customerPersona: formik.values.customerPersona,
          productName: formik.values.productName,
          competitionOverview: overview,
          rules: rules,
          scoringScheme: scoringScheme,
          sections: [
            {
              maxVideoTimeLimitInMin: maxVideoTimeLimitInMinSection1,
              instruction: step1Instructions,
              script: script,
              prospectName,
              sectionType: 'script_reading',
              uploadInstructionVideo: false,
              uploadIntroductionVideo: false,
              uploadSectionVideo: false,
            },

            {
              instruction: step2Instructions,
              secondaryInstruction: step2SecondaryInstruction,
              sectionQAVideoContentType: videoData?.type,
              sectionQuestions: MCQs,
              sectionType: 'customer_qa',
              uploadInstructionVideo: false,
              uploadIntroductionVideo: false,
              uploadSectionVideo: true,
              productKnowledgeKeywords: productKnowledgeKeywords?.split(','),
              requirementGatheringKeywords: requirementGatheringKeywords?.split(
                ',',
              ),
              customerVideoTranscript: customerVideoTranscript,
            },
            {
              maxVideoTimeLimitInMin: maxVideoTimeLimitInMinSection3,
              recommendedVideoTimeLimitInMin: recommendedVideoTimeLimitInMin,
              instruction: pitchInstructions,
              sectionType: 'pitch',
              uploadInstructionVideo: false,
              uploadIntroductionVideo: false,
              uploadSectionVideo: false,
            },
          ],
          status: 'active',
          tags: [formik.values.metadata],
          title: formik.values.title,
          uploadCompetitionImage: selectedImage ? true : false,
          uploadInstructionVideo: false,
          uploadIntroductionVideo: false,
        },
        (response) => {
          // console.log(response, 'this is the response-------------');
          if (selectedImage) {
            dispatch(
              uploadVideoAction(
                response.data
                  ? response.data?.find((item) => item?.fileType === 'image')
                      ?.url
                  : null,
                selectedImage,
                () => {
                  console.log('image uploaded');
                  dispatch(
                    verifyImageUpload(
                      response.data
                        ? response.data?.find(
                            (item) => item?.fileType === 'image',
                          )?.competitionId
                        : null,
                      response.data
                        ? response.data?.find(
                            (item) => item?.fileType === 'image',
                          )?.imageId
                        : null,
                      () => {
                        console.log('image verified');
                      },
                    ),
                  );
                },
                () => {
                  setProcessing(false);
                },
              ),
            );
          }
          if (videoData) {
            dispatch(
              uploadVideoAction(
                response.data
                  ? response.data?.find(
                      (item) =>
                        item?.fileType === 'video' &&
                        item?.videoSource === 'competition_section_qa',
                    )?.url
                  : null,
                videoData,
                () => {
                  console.log('video uploaded');
                  dispatch(
                    verifyVideoUpload(
                      response.data
                        ? response.data?.find(
                            (item) =>
                              item?.fileType === 'video' &&
                              item?.videoSource === 'competition_section_qa',
                          )?.videoId
                        : null,
                      () => {
                        console.log('video verified');
                        alert('Contest created!');
                        setProcessing(false);
                      },
                    ),
                  );
                },
                () => {
                  setProcessing(false);
                },
              ),
            );
          } else {
            alert('Contest created!');
            setProcessing(false);
          }
        },
        (msg) => {
          setProcessing(false);
          alert(msg || 'Failed to create contest');
        },
      ),
    );
  };

  const updateContestSubmit = (type, cb, sectionDetails, draft) => {
    if (type === 'details') {
      setProcessing(true);
      dispatch(
        updateContest(
          contestId,
          {
            slug: formik.values.slug,
            aboutCompany: aboutCompany,
            criteria,
            visibility: draft ? 'draft' : 'published',
            competitionType: competitionType,
            competitionMode: contestMode,
            phaseInvitation: phaseInvitation,
            employerId: selectedEmployer,
            durationInMin: Number(duration),
            phase1DurationInMin: Number(phase1Duration),
            phase2DurationInMin: Number(phase2Duration),
            hostName: formik.values.host,
            aboutHost: aboutHost,
            endOn: criteria === 'dateRange' ? endOn : null,
            startOn: criteria === 'dateRange' ? startOn : null,
            maxLimit: criteria === 'maxLimit' ? maxLimit : null,
            competitionImageContentType: selectedImage?.type,
            maxNumberOfAttempts: Number(maxNumberOfAttempt),
            competitionSuccessFullText: competitionSuccussMessage,
            customerInteractionBackground: customerInteractionBackground,
            faqs: Faqs,
            shortIntroduction: formik.values.quickIntroduction,
            prize: Prizes,
            productDetail: productDescription,
            customerPersona: formik.values.customerPersona,
            productName: formik.values.productName,
            competitionOverview: overview,
            rules: rules,
            scoringScheme: scoringScheme,
            status: contest?.status,
            tags: [formik.values.metadata],
            title: formik.values.title,
            uploadCompetitionImage: selectedImage ? true : false,
          },
          (response) => {
            if (selectedImage) {
              dispatch(
                uploadVideoAction(
                  response.data ? response.data[0]?.url : null,
                  selectedImage,
                  () => {
                    console.log('image uploaded');
                    dispatch(
                      verifyImageUpload(
                        response.data ? response.data[0]?.competitionId : null,
                        response.data ? response.data[0]?.imageId : null,
                        () => {
                          setProcessing(false);
                          console.log('image verified');
                        },
                      ),
                    );
                  },
                ),
              );
            } else {
              setProcessing(false);
            }
            cb();
          },
          () => {
            setProcessing(false);
            alert('Failed to update contest');
          },
        ),
      );
    } else if (type === 'sections') {
      setProcessing(true);
      // console.log('update sections');
      dispatch(
        updateContestSection(
          contestId,
          {
            customerVideoTranscript: customerVideoTranscript,
            id: sectionDetails?.id,
            instruction:
              sectionDetails?.sectionType === 'script_reading'
                ? step1Instructions
                : sectionDetails?.sectionType === 'customer_qa'
                ? step2Instructions
                : sectionDetails?.sectionType === 'pitch'
                ? pitchInstructions
                : step1Instructions,

            secondaryInstruction:
              sectionDetails?.sectionType === 'script_reading'
                ? ''
                : sectionDetails?.sectionType === 'customer_qa'
                ? step2SecondaryInstruction
                : sectionDetails?.sectionType === 'pitch'
                ? ''
                : '',
            maxVideoTimeLimitInMin:
              sectionDetails?.sectionType === 'script_reading'
                ? maxVideoTimeLimitInMinSection1
                : sectionDetails?.sectionType === 'pitch'
                ? maxVideoTimeLimitInMinSection3
                : maxVideoTimeLimitInMinSection1,
            recommendedVideoTimeLimitInMin: recommendedVideoTimeLimitInMin,
            script: script,
            prospectName,
            sectionQAVideoContentType: videoData?.type,
            sectionQuestions: MCQs,
            sectionType: sectionDetails?.sectionType,
            uploadInstructionVideo: false,
            uploadIntroductionVideo: false,
            uploadSectionVideo: videoData ? true : false,
            productKnowledgeKeywords: productKnowledgeKeywords?.split(','),
            requirementGatheringKeywords: requirementGatheringKeywords?.split(
              ',',
            ),
          },
          (response) => {
            if (response?.data?.versioned && response?.data?.id) {
              setContestId(response?.data?.id);
            }
            if (videoData) {
              dispatch(
                uploadVideoAction(
                  response.data ? response.data?.urlResponses?.[0]?.url : null,
                  videoData,
                  () => {
                    console.log('video uploaded');
                    setVideoData(null);
                    dispatch(
                      verifyVideoUpload(
                        response.data
                          ? response.data?.urlResponses?.[0]?.videoId
                          : null,
                        () => {
                          // setProcessing(false);
                          console.log('video verified');
                        },
                      ),
                    );
                  },
                  () => {
                    setVideoData(null);
                  },
                ),
              );
            }
            setProcessing(false);
            setVideoData(null);
            cb();
          },
          () => {
            setProcessing(false);
            alert('Failed to update section of contest');
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (videoStatus === 'success') {
      clearInterval(timerRef.current);
    }
  }, [videoStatus]);

  useEffect(() => {
    let contestDetails = localStorage.getItem('contestDetails');
    if (contestDetails) {
      let contestDetailsJSON = JSON.parse(contestDetails);
      // console.log(contestDetailsJSON, 'json saved data!');
      formik.setFieldValue('title', contestDetailsJSON?.title);
      formik.setFieldValue(
        'quickIntroduction',
        contestDetailsJSON?.introduction,
      );

      if (contestDetailsJSON?.tags) {
        formik.setFieldValue('metadata', contestDetailsJSON?.tags[0]);
      }
      formik.setFieldValue(
        'productDescription',
        contestDetailsJSON?.productDetail,
      );
      setFaqs(contestDetailsJSON?.faqs);
    }
    dispatch(getDefaultPrompts(() => {}));
  }, []);

  useEffect(() => {
    if (showGenerateAIDataDialog) {
      setCurrentPrompt(defaultPrompts?.prompts?.[showGenerateAIDataDialog]);
      setDataType(showGenerateAIDataDialog);
    } else {
      setCurrentPrompt('');
      setDataType('');
    }
  }, [showGenerateAIDataDialog]);

  useEffect(() => {
    if (formik.values.title) {
      if (editContest && contest?.slug) {
        formik.setFieldValue('slug', contest?.slug);
      } else {
        formik.setFieldValue(
          'slug',
          formik.values.title
            ?.trim()
            ?.toLowerCase()
            ?.replace(/[^A-Za-z- ]/g, '')
            ?.split(/-|,/)
            ?.map((w) => w?.trim())
            ?.join(' ')
            ?.split(' ')
            .join('-'),
        );
      }
    } else {
      formik.setFieldValue('slug', '');
    }
  }, [formik.values.title]);

  useEffect(() => {
    if (competitionType) {
      dispatch(
        getAllEmployers((data) => {
          setEmployers(data);
          // console.log(data);
        }),
      );
    }
  }, [competitionType]);

  // console.log(location.state);
  // console.log(MCQs);
  // console.log(aiDataForContestCreation);
  console.log(defaultPrompts);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        {/* <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button onClick={() => savedDataToLocalStorage()} variant="contained">
            Save
          </Button>
        </Box> */}
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
          <Step>
            <StepButton onClick={() => setActiveStep(0)}>
              Contest basic
            </StepButton>
            <StepContent>
              <Box>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Contest type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={competitionType}
                    onChange={(e) => setCompetitionType(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="practice_competition"
                      control={<Radio />}
                      label="Practice"
                    />
                    <FormControlLabel
                      value="real_competition"
                      control={<Radio />}
                      label="Qualification AI"
                    />
                    <FormControlLabel
                      value="semi_private_competition"
                      control={<Radio />}
                      label="Employer Public"
                    />
                    <FormControlLabel
                      value="private_competition"
                      control={<Radio />}
                      label="Employer Private"
                    />
                  </RadioGroup>
                </FormControl>
                {competitionType === 'private_competition' ||
                competitionType === 'semi_private_competition' ? (
                  <Box width={300} mt={1} mb={1}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="contest-type-label">Employers</InputLabel>
                      <Select
                        labelId="contest-type-label"
                        id="contest-type"
                        value={selectedEmployer}
                        label="Employer"
                        onChange={(e) => setSelectedEmployer(e.target.value)}
                        // disabled={selectedEmployer && editContest}
                      >
                        {employers.map((emp) => {
                          return (
                            <MenuItem value={emp?.userId}>
                              <Box>
                                <Typography variant="body1">
                                  {emp?.email}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color={'text.secondary'}
                                >
                                  {emp?.name}
                                </Typography>
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : null}
                <br />
                <Box display={'flex'} gap={2}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Contest Mode
                    </FormLabel>
                    <Select
                      size="small"
                      value={contestMode}
                      onChange={(e) => setContestMode(e.target.value)}
                    >
                      <MenuItem value="normal">Normal</MenuItem>
                      <MenuItem value="phased">Phased</MenuItem>
                    </Select>
                  </FormControl>
                  {contestMode === 'phased' ? (
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Phased Invitation
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={phaseInvitation}
                        onChange={(e) => setPhaseInvitation(e.target.value)}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="automatic"
                          control={<Radio />}
                          label="Automatic"
                        />
                        <FormControlLabel
                          value="manual"
                          control={<Radio />}
                          label="Manual"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : null}
                </Box>
                <br />
                {contestMode === 'phased' ? (
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <TextField
                      label="Phase 1 Duration in min *"
                      variant="outlined"
                      fullWidth
                      value={phase1Duration}
                      onChange={(e) => setPhase1Duration(e.target.value)}
                      // sx={{ marginTop: 1 }}
                      type="number"
                      // error={formik.touched.email && Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      label="Phase 2 Duration in min *"
                      variant="outlined"
                      fullWidth
                      value={phase2Duration}
                      onChange={(e) => setPhase2Duration(e.target.value)}
                      // sx={{ marginTop: 1 }}
                      type="number"
                      // error={formik.touched.email && Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                ) : (
                  <TextField
                    label="Duration in min *"
                    variant="outlined"
                    fullWidth
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    sx={{ marginBottom: 1 }}
                    type="number"
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={formik.touched.email && formik.errors.email}
                  />
                )}
                <br />
                <FormControl>
                  <FormLabel id="criteria-group-label">
                    Contest Registration
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="criteria-group-label"
                    value={criteria}
                    onChange={(e) => setCriteria(e.target.value)}
                    name="criteria-group"
                  >
                    <FormControlLabel
                      value="dateRange"
                      control={<Radio />}
                      label="Date Range"
                    />
                    <FormControlLabel
                      value="maxLimit"
                      control={<Radio />}
                      label="Max Limit"
                    />
                  </RadioGroup>
                </FormControl>
                {criteria === 'dateRange' ? (
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={startOn ? dayjs(new Date(startOn)) : null}
                        label="Start Date"
                        format="MM-DD-YYYY"
                        onChange={(date) => {
                          let date_ = new Date(date); // some mock date
                          // console.log(date);
                          var milliseconds = date_.getTime();
                          setStartOn(milliseconds);
                        }}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={endOn ? dayjs(new Date(endOn)) : null}
                        label="End Date"
                        format="MM-DD-YYYY"
                        onChange={(date) => {
                          let date_ = new Date(date); // some mock date
                          var milliseconds = date_.getTime();
                          setEndOn(milliseconds);
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <TextField
                      label="Max Entries Allowed (max 200)"
                      variant="outlined"
                      // fullWidth
                      value={maxLimit}
                      onChange={(e) => setMaxLimit(e.target.value)}
                      // sx={{ marginTop: 2 }}
                      type="number"
                      // error={formik.touched.email && Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                )}
                <TextField
                  label="Max number of attempts *"
                  variant="outlined"
                  // fullWidth
                  value={maxNumberOfAttempt}
                  onChange={(e) => setMaxNumberOfAttempts(e.target.value)}
                  sx={{ marginTop: 2 }}
                  type="number"
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  label="Contest Title *"
                  variant="outlined"
                  name={'title'}
                  fullWidth
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  label="Slug *"
                  variant="outlined"
                  name={'slug'}
                  fullWidth
                  value={formik.values.slug}
                  onChange={(e) => {
                    formik.handleChange(e);
                    debounced();
                  }}
                  sx={{ marginTop: 2 }}
                  onFocus={debounced}
                  onBlur={debounced}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          marginRight: 0,
                          // backgroundColor: theme.palette.background.,
                        }}
                      >
                        <Link sx={{ marginRight: 1 }}></Link>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                        >
                          {`https://app.qualification.ai/${
                            competitionType === 'practice_competition'
                              ? 'practice'
                              : 'contests'
                          }/`}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: checkingSlug ? (
                      <CircularProgress />
                    ) : slugAvailable === null ? null : slugAvailable ? (
                      <Box display={'flex'} alignItems={'center'}>
                        <Check sx={{ color: 'darkgreen' }} />
                        <Typography variant="body2">Available</Typography>
                      </Box>
                    ) : (
                      <Box display={'flex'} alignItems={'center'}>
                        <Close sx={{ color: 'darkred' }} />
                        <Typography variant="body2">Not Available</Typography>
                      </Box>
                    ),
                  }}
                />
                <TextField
                  label="Short introduction (200 Chars max)*"
                  variant="outlined"
                  name={'quickIntroduction'}
                  fullWidth
                  value={formik.values.quickIntroduction}
                  onChange={formik.handleChange}
                  sx={{ marginTop: 2 }}
                  InputProps={{ inputProps: { maxLength: 200 } }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  label="Metadata (add searchable HashTags)*"
                  variant="outlined"
                  name={'metadata'}
                  fullWidth
                  value={formik.values.metadata}
                  onChange={formik.handleChange}
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                {/* <TextField
                  label="Image URL*"
                  variant="outlined"
                  name={'imageURL'}
                  fullWidth
                  value={formik.values.imageURL}
                  onChange={formik.handleChange}
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                /> */}
                <Typography variant="body1" mt={2}>
                  Contest Image
                </Typography>
                {selectedImage ? (
                  <Box>
                    <img
                      alt="not found"
                      width={'250px'}
                      src={URL.createObjectURL(selectedImage)}
                    />
                    <br />
                    <Button onClick={() => setSelectedImage(null)}>
                      Remove
                    </Button>
                  </Box>
                ) : editContest ? (
                  contest?.customFields?.competitionImage ? (
                    <img
                      src={contest?.customFields?.competitionImage}
                      width={'250px'}
                    />
                  ) : null
                ) : null}
                <InputLabel
                  htmlFor="myImage"
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #d3d3d3',
                    width: 'fit-content',
                    padding: '5px 10px',
                    marginTop: 10,
                  }}
                >
                  {(editContest && contest?.customFields?.competitionImage) ||
                  selectedImage
                    ? 'Update Image'
                    : 'Select an image'}
                </InputLabel>
                <input
                  type="file"
                  id="myImage"
                  name="myImage"
                  style={{ visibility: 'hidden' }}
                  onChange={(event) => {
                    // console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                  }}
                />
                <TextField
                  label="Host Company"
                  variant="outlined"
                  name={'host'}
                  fullWidth
                  value={formik.values.host}
                  onChange={formik.handleChange}
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                <Box
                  sx={{
                    border: '1px solid#A8A8A8',
                    marginTop: 2,
                    marginBottom: 2,
                    padding: 1,
                  }}
                >
                  Abount Host Company
                  <RichTextEditor value={aboutHost} onChange={setAboutHost} />
                  {/* <Editor
                    editorState={aboutHost}
                    onEditorStateChange={(editorState) => {
                      setAboutHost(editorState);
                    }}
                    toolbar={toolbarOptions}
                    placeholder="About Host Company"
                  /> */}
                </Box>
                <FormLabel id="criteria-group-label">
                  Product and Prospect Details
                </FormLabel>
                <TextField
                  label="Prospect's Company Link"
                  variant="outlined"
                  name={'companyLink'}
                  fullWidth
                  value={companyLink}
                  onChange={(e) => setCompanyLink(e.target.value)}
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
                <Box
                  sx={{
                    border: '1px solid#A8A8A8',
                    marginTop: 2,
                    marginBottom: 2,
                    padding: 1,
                  }}
                >
                  About Prospect's Company(Prospect's Company Short Description)
                  <RichTextEditor
                    value={aboutCompany}
                    onChange={setAboutCompany}
                  />
                </Box>
                <Box
                  sx={{
                    border: '1px solid#A8A8A8',
                    marginTop: 2,
                    marginBottom: 2,
                    padding: 1,
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
                    <Typography>Product Details</Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() =>
                        setShowGenerateAIDataDialog('product_description')
                      }
                      disabled={
                        generatingAIData &&
                        generatingAIDataFor === 'product_description'
                      }
                    >
                      Generate with ai
                    </Button>
                  </Box>
                  <RichTextEditor
                    value={productDescription}
                    onChange={setProductDescription}
                  />
                  {/* <Editor
                    editorState={productDescription}
                    onEditorStateChange={(editorState) => {
                      setProductDescription(editorState);
                    }}
                    toolbar={toolbarOptions}
                    placeholder="Product description"
                  /> */}
                </Box>
                <Box
                  sx={{
                    border: '1px solid#A8A8A8',
                    marginTop: 2,
                    marginBottom: 2,
                    padding: 1,
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
                    <Typography>Prospect Details</Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() =>
                        setShowGenerateAIDataDialog('scenario_description')
                      }
                      disabled={
                        generatingAIData &&
                        generatingAIDataFor === 'scenario_description'
                      }
                    >
                      Generate with ai
                    </Button>
                  </Box>
                  <RichTextEditor
                    value={customerInteractionBackground}
                    onChange={setCustomerInteractionBackground}
                  />
                  {/* <Editor
                    editorState={customerInteractionBackground}
                    onEditorStateChange={(editorState) => {
                      setCustomerInteractionBackground(editorState);
                    }}
                    toolbar={toolbarOptions}
                    placeholder="Prospect"
                  /> */}
                </Box>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit(
                          'details',
                          handleNext,
                          null,
                          contest?.visibility === 'draft',
                        );
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 5 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={() => setActiveStep(1)}>
              Overview, Rules, Scoring & Prizes
            </StepButton>
            <StepContent>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Overview:
                <RichTextEditor value={overview} onChange={setOverview} />
                {/* <Editor
                  editorState={overview}
                  onEditorStateChange={(editorState) => {
                    setOverview(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Overview"
                /> */}
              </Box>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Rules and guidelines:
                <RichTextEditor value={rules} onChange={setRules} />
                {/* <Editor
                  editorState={rules}
                  onEditorStateChange={(editorState) => {
                    setRules(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Rules and guidelines"
                /> */}
              </Box>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Scoring Scheme:
                <RichTextEditor
                  value={scoringScheme}
                  onChange={setScoringScheme}
                />
                {/* <Editor
                  editorState={scoringScheme}
                  onEditorStateChange={(editorState) => {
                    setScoringScheme(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Scoring Scheme"
                /> */}
              </Box>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Prizes
                <RichTextEditor value={Prizes} onChange={setPrizes} />
                {/* <Editor
                  editorState={Prizes}
                  onEditorStateChange={(editorState) => {
                    setPrizes(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Prizes"
                /> */}
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit(
                          'details',
                          handleNext,
                          null,
                          contest?.visibility === 'draft',
                        );
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 5 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={() => setActiveStep(2)}>Faqs</StepButton>
            <StepContent>
              <Box sx={{ marginTop: 2 }}>
                {Faqs &&
                  Array.isArray(Faqs) &&
                  Faqs.length > 0 &&
                  Faqs?.map((faq, faqIndex) => {
                    return (
                      <Box
                        key={faqIndex}
                        sx={{
                          border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          padding: 2,
                          marginTop: 1,
                          position: 'relative',
                        }}
                      >
                        <Button
                          variant="text"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                            zIndex: 2,
                            background: '#fff',
                            '&:hover': {
                              background: '#fff',
                            },
                          }}
                          onClick={() => {
                            const updatedFaqs = Faqs.filter(
                              (faq, i) => i !== faqIndex,
                            );
                            setFaqs(updatedFaqs);
                          }}
                        >
                          Remove
                        </Button>
                        <TextField
                          label="Question *"
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={faq?.question}
                          sx={{ marginTop: 1 }}
                          onChange={(e) => {
                            const updatedFaqs = Faqs.map((faq, i) => {
                              if (i === faqIndex) {
                                return {
                                  ...faq,
                                  question: e.target.value,
                                };
                              } else {
                                return faq;
                              }
                            });
                            setFaqs(updatedFaqs);
                          }}
                        />
                        <TextField
                          size="small"
                          label={`Answer`}
                          variant="outlined"
                          fullWidth
                          value={faq?.answer}
                          onChange={(e) => {
                            const updatedFaqs = Faqs.map((faq, i) => {
                              if (i === faqIndex) {
                                return {
                                  ...faq,
                                  answer: e.target.value,
                                };
                              } else {
                                return faq;
                              }
                            });
                            setFaqs(updatedFaqs);
                          }}
                          sx={{ marginTop: 1 }}
                          // error={formik.touched.email && Boolean(formik.errors.email)}
                          // helperText={formik.touched.email && formik.errors.email}
                        />
                      </Box>
                    );
                  })}
              </Box>
              <Button
                onClick={() => {
                  setFaqs([
                    ...Faqs,
                    {
                      question: '',
                      answer: '',
                    },
                  ]);
                }}
                sx={{ marginTop: 1 }}
                variant="contained"
              >
                Add FAQ
              </Button>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit(
                          'details',
                          handleNext,
                          null,
                          contest?.visibility === 'draft',
                        );
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 5 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={() => setActiveStep(3)}>
              Step1 [Read this Script]:{' '}
            </StepButton>
            <StepContent>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Instructions for step 1
                <RichTextEditor
                  value={step1Instructions}
                  onChange={setStep1Instructions}
                />
                {/* <Editor
                  editorState={step1Instructions}
                  onEditorStateChange={(editorState) => {
                    setStep1Instructions(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Instructions for step 1"
                /> */}
              </Box>
              <TextField
                type={'text'}
                label="Prospect Name"
                variant="outlined"
                name={'prospectName'}
                fullWidth
                value={prospectName}
                onChange={(e) => setProspectName(e.target.value)}
                sx={{ marginTop: 2 }}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
              />
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
                  <Typography>Teleprompter Script</Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() =>
                      setShowGenerateAIDataDialog('cold_calling_script')
                    }
                    disabled={
                      generatingAIData &&
                      generatingAIDataFor === 'cold_calling_script'
                    }
                  >
                    Generate with ai
                  </Button>
                </Box>

                <RichTextEditor value={script} onChange={setScript} />
                {/* <Editor
                  editorState={script}
                  onEditorStateChange={(editorState) => {
                    setScript(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Script"
                /> */}
              </Box>
              <TextField
                type={'number'}
                label="Max Video Time Limit In Min*"
                variant="outlined"
                name={'customerInteraction'}
                fullWidth
                value={maxVideoTimeLimitInMinSection1}
                onChange={(e) =>
                  setMaxVideoTimeLimitInMinSection1(e.target.value)
                }
                sx={{ marginTop: 2 }}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
              />
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit(
                          'sections',
                          handleNext,
                          contest?.sections?.[0],
                          contest?.visibility === 'draft',
                        );
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 5 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={() => setActiveStep(4)}>
              Step 2 [Product Knowledge and Prospect's Needs Understandings]
            </StepButton>
            <StepContent>
              <Box>
                <TextField
                  label="Add Product Knowledge Keywords(comma seperated)"
                  variant="outlined"
                  fullWidth
                  value={productKnowledgeKeywords}
                  onChange={(e) => setProductKnowledgeKeywords(e.target.value)}
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Instructions for Product Knowledge MCQs
                <RichTextEditor
                  value={step2Instructions}
                  onChange={setStep2Instructions}
                />
                {/* <Editor
                  editorState={step2Instructions}
                  onEditorStateChange={(editorState) => {
                    setStep2Instructions(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Instructions for Product Knowledge MCQs"
                /> */}
              </Box>
              <Box>
                <Box sx={{ marginTop: MCQs?.length > 0 ? 1 : 2 }}>
                  Product Knowlege MCQs
                </Box>
                <Box>
                  <Box>
                    <Typography>Recommended format for best result</Typography>
                    <span>1). Question 1</span>
                    <br />
                    <span>a). Option 1</span>
                    <br />
                    <span>b). Option 2</span>
                    <br />
                    <span>c). Option 3</span>
                    <br />
                    <span>d). Option 4</span>
                    <br />
                    <br />
                    <span>2). Question 2</span>
                    <br />
                    <span>a). Option 1</span>
                    <br />
                    <span>b). Option 2</span>
                    <br />
                    <span>c). Option 3</span>
                    <br />
                    <span>d). Option 4</span>
                    <br />
                    <br />
                    .....
                  </Box>
                  <Box
                    sx={{ display: 'flex', alignItems: 'start' }}
                    mt={2}
                    gap={1}
                  >
                    <TextField
                      label="Scrap MCQs from text"
                      variant="outlined"
                      name={'mcqText'}
                      fullWidth
                      value={mcqText}
                      onChange={(e) => setMcqText(e.target.value)}
                      // sx={{ marginTop: 2 }}
                      multiline
                      rows={10}
                      // error={formik.touched.email && Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                    />
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                      alignItems={'start'}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        // sx={{ marginLeft: 2 }}
                        onClick={() => {
                          handleMcqTextParse('product_knowledge');
                        }}
                      >
                        Parse
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          setShowGenerateAIDataDialog('product_description_mcq')
                        }
                        disabled={
                          generatingAIData &&
                          generatingAIDataFor === 'product_description_mcq'
                        }
                      >
                        Generate with ai
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  {MCQs &&
                    Array.isArray(MCQs) &&
                    MCQs.length > 0 &&
                    MCQs?.filter((q) => q.domain === 'product_knowledge')
                      ?.length > 0 &&
                    MCQs?.filter((q) => q.domain === 'product_knowledge')?.map(
                      (mcq, mcqIndex) => {
                        return (
                          <Box
                            key={mcqIndex}
                            sx={{
                              border: '1px solid #d3d3d3',
                              borderRadius: 2,
                              padding: 2,
                              marginTop: 1,
                              position: 'relative',
                            }}
                          >
                            <Button
                              variant="text"
                              sx={{
                                position: 'absolute',
                                top: 5,
                                right: 10,
                                zIndex: 2,
                              }}
                              onClick={() => {
                                const updatedMCQs = MCQs.filter(
                                  (mcq, i) =>
                                    i !== mcqIndex &&
                                    mcq.domain === 'product_knowledge',
                                );
                                setMCQs((pre) => [
                                  ...updatedMCQs,
                                  ...pre.filter(
                                    (q) => q.domain === 'requirement_gathering',
                                  ),
                                ]);
                              }}
                            >
                              Remove
                            </Button>
                            {/* <FormControl sx={{ display: 'block' }}>
                              <FormLabel id="mcq-type-group-label">
                                MCQ type
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="mcq-type-group-label"
                                value={mcq?.domain}
                                onChange={(e) => {
                                  const updatedMCQs = MCQs.filter(
                                    (q) => q.domain === 'product_knowledge',
                                  ).map((mcq, i) => {
                                    if (i === mcqIndex) {
                                      return {
                                        ...mcq,
                                        domain: e.target.value,
                                      };
                                    } else return mcq;
                                  });
                                  setMCQs((pre) => [
                                    ...updatedMCQs,
                                    ...pre.filter(
                                      (q) =>
                                        q.domain === 'requirement_gathering',
                                    ),
                                  ]);
                                }}
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="product_knowledge"
                                  control={<Radio />}
                                  label="Product Knowledge"
                                />
                                <FormControlLabel
                                  value="requirement_gathering"
                                  control={<Radio />}
                                  label="Prospect Requirement Gathering"
                                />
                              </RadioGroup>
                            </FormControl> */}
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.text.secondary,
                                fontWeight: 'bold',
                              }}
                            >
                              Question {mcqIndex + 1}
                            </Typography>
                            <TextField
                              label="Question *"
                              size="small"
                              variant="outlined"
                              fullWidth
                              value={mcq?.question}
                              sx={{ marginTop: 1 }}
                              onChange={(e) => {
                                const updatedMCQs = MCQs.filter(
                                  (q) => q.domain === 'product_knowledge',
                                ).map((mcq, i) => {
                                  if (i === mcqIndex) {
                                    return {
                                      ...mcq,
                                      question: e.target.value,
                                    };
                                  } else {
                                    return mcq;
                                  }
                                });
                                setMCQs((pre) => [
                                  ...updatedMCQs,
                                  ...pre.filter(
                                    (q) => q.domain === 'requirement_gathering',
                                  ),
                                ]);
                              }}
                            />
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.text.secondary,
                                fontWeight: 'bold',
                              }}
                            >
                              Options
                            </Typography>
                            {mcq?.options?.map((option, optionIndex) => {
                              return (
                                <Box
                                  key={optionIndex}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Checkbox
                                    name="answer"
                                    checked={mcq?.answers.includes(option)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        const updatedMCQs = MCQs.filter(
                                          (q) =>
                                            q.domain === 'product_knowledge',
                                        ).map((mcq, i) => {
                                          if (i === mcqIndex) {
                                            return {
                                              ...mcq,
                                              answers: [...mcq.answers, option],
                                            };
                                          } else {
                                            return mcq;
                                          }
                                        });
                                        setMCQs((pre) => [
                                          ...updatedMCQs,
                                          ...pre.filter(
                                            (q) =>
                                              q.domain ===
                                              'requirement_gathering',
                                          ),
                                        ]);
                                      } else {
                                        const updatedMCQs = MCQs.filter(
                                          (q) =>
                                            q.domain === 'product_knowledge',
                                        ).map((mcq, i) => {
                                          if (i === mcqIndex) {
                                            const ua = mcq?.answers.filter(
                                              (a) => a !== option,
                                            );
                                            return {
                                              ...mcq,
                                              answers: ua,
                                            };
                                          } else {
                                            return mcq;
                                          }
                                        });
                                        setMCQs((pre) => [
                                          ...updatedMCQs,
                                          ...pre.filter(
                                            (q) =>
                                              q.domain ===
                                              'requirement_gathering',
                                          ),
                                        ]);
                                      }
                                    }}
                                  />
                                  <TextField
                                    size="small"
                                    label={`Option ${optionIndex + 1}`}
                                    variant="outlined"
                                    fullWidth
                                    value={option}
                                    onChange={(e) => {
                                      const updatedOptions = [...mcq.options];
                                      updatedOptions[optionIndex] =
                                        e.target.value;
                                      const updatedMCQs = MCQs.filter(
                                        (q) => q.domain === 'product_knowledge',
                                      ).map((mcq, i) => {
                                        if (i === mcqIndex) {
                                          return {
                                            ...mcq,
                                            options: updatedOptions,
                                          };
                                        } else {
                                          return mcq;
                                        }
                                      });
                                      setMCQs((pre) => [
                                        ...updatedMCQs,
                                        ...pre.filter(
                                          (q) =>
                                            q.domain ===
                                            'requirement_gathering',
                                        ),
                                      ]);
                                    }}
                                    sx={{ marginTop: 1 }}
                                    // error={formik.touched.email && Boolean(formik.errors.email)}
                                    // helperText={formik.touched.email && formik.errors.email}
                                  />
                                </Box>
                              );
                            })}
                            <Typography
                              variant="caption"
                              component={'p'}
                              sx={{
                                color: theme.palette.text.secondary,
                                fontWeight: 'bold',
                                marginTop: 2,
                              }}
                            >
                              Answers: {mcq?.answers?.join(',')}
                            </Typography>
                            <Typography
                              variant="caption"
                              component={'p'}
                              sx={{
                                color: theme.palette.text.secondary,
                                fontWeight: 'bold',
                                marginTop: 2,
                              }}
                            >
                              Tags
                            </Typography>
                            <TextField
                              size="small"
                              label="Tags (comma seperated) *"
                              variant="outlined"
                              fullWidth
                              value={mcq?.tags?.join(',')}
                              onChange={(e) => {
                                const updatedMCQs = MCQs.filter(
                                  (q) => q.domain === 'product_knowledge',
                                ).map((mcq, i) => {
                                  if (i === mcqIndex) {
                                    return {
                                      ...mcq,
                                      tags: e.target.value?.split(','),
                                    };
                                  } else return mcq;
                                });
                                setMCQs((pre) => [
                                  ...updatedMCQs,
                                  ...pre.filter(
                                    (q) => q.domain === 'requirement_gathering',
                                  ),
                                ]);
                              }}
                              sx={{ marginTop: 1 }}
                              // error={formik.touched.email && Boolean(formik.errors.email)}
                              // helperText={formik.touched.email && formik.errors.email}
                            />
                          </Box>
                        );
                      },
                    )}
                </Box>
              </Box>
              <Button
                onClick={() => {
                  setMCQs([
                    ...MCQs,
                    {
                      question: '',
                      answers: [],
                      options: ['', '', '', ''],
                      tags: [],
                      domain: 'product_knowledge',
                      type: 'mcq',
                    },
                  ]);
                }}
                sx={{ marginTop: 1 }}
                variant="contained"
              >
                Add Product Knowledge MCQ
              </Button>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Instructions for Prospect Needs Understanding MCQs
                <RichTextEditor
                  value={step2SecondaryInstruction}
                  onChange={setStep2SecondaryInstruction}
                />
                {/* <Editor
                  editorState={step2SecondaryInstruction}
                  onEditorStateChange={(editorState) => {
                    setStep2SecondaryInstruction(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Instructions for Prospect Needs MCQs"
                /> */}
              </Box>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
                  <Typography>Prospect Video Script*</Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() =>
                      setShowGenerateAIDataDialog('prospect_script')
                    }
                    disabled={
                      generatingAIData &&
                      generatingAIDataFor === 'prospect_script'
                    }
                  >
                    Generate with ai
                  </Button>
                </Box>
                <TextField
                  placeholder="Add Prospect Video Script *"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  value={customerVideoTranscript}
                  onChange={(e) => setCustomerVideoTranscript(e.target.value)}
                  // sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box>
                <TextField
                  label="Add Requirement Gathering Keywords(comma seperated)"
                  variant="outlined"
                  fullWidth
                  value={requirementGatheringKeywords}
                  onChange={(e) =>
                    setRequirementGatheringKeywords(e.target.value)
                  }
                  sx={{ marginTop: 2 }}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                  // helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box>
                <Box sx={{ marginTop: 1 }}>Prospect&apos;s Video</Box>
                <InputLabel
                  htmlFor="video"
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #d3d3d3',
                    width: 'fit-content',
                    padding: '5px 10px',
                    marginTop: 10,
                  }}
                >
                  {(editContest &&
                    contest?.sections?.[1]?.customFields?.sectionQAVideo
                      ?.videoUrls?.[0]?.url) ||
                  videoData
                    ? 'Update Video'
                    : 'Select a video'}
                </InputLabel>
                <input
                  type="file"
                  id="video"
                  name="video"
                  style={{ visibility: 'hidden' }}
                  onChange={(event) => {
                    // console.log(event.target.files[0]);
                    handleVideoChange(event);
                  }}
                />
                <Box sx={{ maxWidth: 480, maxHeight: 272, overflow: 'scroll' }}>
                  <Player
                    playsInline
                    src={customerVideoScript}
                    fluid={false}
                    max={480}
                    height={272}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ marginTop: MCQs?.length > 0 ? 1 : 2 }}>
                  Prospect&apos;s Needs Understanding MCQs
                </Box>
                <Box>
                  <Box>
                    <Typography>Recommended format for best result</Typography>
                    <span>1). Question 1</span>
                    <br />
                    <span>a). Option 1</span>
                    <br />
                    <span>b). Option 2</span>
                    <br />
                    <span>c). Option 3</span>
                    <br />
                    <span>d). Option 4</span>
                    <br />
                    <br />
                    <span>2). Question 2</span>
                    <br />
                    <span>a). Option 1</span>
                    <br />
                    <span>b). Option 2</span>
                    <br />
                    <span>c). Option 3</span>
                    <br />
                    <span>d). Option 4</span>
                    <br />
                    <br />
                    .....
                  </Box>
                  <Box
                    sx={{ display: 'flex', alignItems: 'start' }}
                    mt={2}
                    gap={1}
                  >
                    <TextField
                      label="Scrap MCQs from text"
                      variant="outlined"
                      name={'mcqText'}
                      fullWidth
                      value={mcqText}
                      onChange={(e) => setMcqText(e.target.value)}
                      // sx={{ marginTop: 2 }}
                      multiline
                      rows={10}
                      // error={formik.touched.email && Boolean(formik.errors.email)}
                      // helperText={formik.touched.email && formik.errors.email}
                    />
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                      alignItems={'start'}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        // sx={{ marginLeft: 2 }}
                        onClick={() => {
                          handleMcqTextParse('requirement_gathering');
                        }}
                      >
                        Parse
                      </Button>
                      {customerVideoTranscript &&
                      customerVideoTranscript.trim() ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setShowGenerateAIDataDialog('prospect_mcq');
                          }}
                          disabled={
                            generatingAIData &&
                            generatingAIDataFor === 'prospect_mcq'
                          }
                        >
                          Generate with ai
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  {MCQs &&
                    Array.isArray(MCQs) &&
                    MCQs.length > 0 &&
                    MCQs?.filter((q) => q.domain === 'requirement_gathering')
                      ?.length > 0 &&
                    MCQs?.filter(
                      (q) => q.domain === 'requirement_gathering',
                    )?.map((mcq, mcqIndex) => {
                      return (
                        <Box
                          key={mcqIndex}
                          sx={{
                            border: '1px solid #d3d3d3',
                            borderRadius: 2,
                            padding: 2,
                            marginTop: 1,
                            position: 'relative',
                          }}
                        >
                          <Button
                            variant="text"
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 10,
                              zIndex: 2,
                            }}
                            onClick={() => {
                              const updatedMCQs = MCQs.filter(
                                (mcq, i) =>
                                  i !== mcqIndex &&
                                  mcq.domain === 'requirement_gathering',
                              );
                              setMCQs((pre) => [
                                ...updatedMCQs,
                                ...pre.filter(
                                  (q) => q.domain === 'product_knowledge',
                                ),
                              ]);
                            }}
                          >
                            Remove
                          </Button>
                          {/* <FormControl sx={{ display: 'block' }}>
                            <FormLabel id="mcq-type-group-label">
                              MCQ type
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="mcq-type-group-label"
                              value={mcq?.domain}
                              onChange={(e) => {
                                const updatedMCQs = MCQs.filter(
                                  (mcq) =>
                                    mcq.domain === 'requirement_gathering',
                                ).map((mcq, i) => {
                                  if (i === mcqIndex) {
                                    return {
                                      ...mcq,
                                      domain: e.target.value,
                                    };
                                  } else return mcq;
                                });
                                setMCQs((pre) => [
                                  ...updatedMCQs,
                                  ...pre.filter(
                                    (q) => q.domain === 'product_knowledge',
                                  ),
                                ]);
                              }}
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                value="product_knowledge"
                                control={<Radio />}
                                label="Product Knowledge"
                              />
                              <FormControlLabel
                                value="requirement_gathering"
                                control={<Radio />}
                                label="Prospect Requirement Gathering"
                              />
                            </RadioGroup>
                          </FormControl> */}
                          <Typography
                            variant="caption"
                            sx={{
                              color: theme.palette.text.secondary,
                              fontWeight: 'bold',
                            }}
                          >
                            Question {mcqIndex + 1}
                          </Typography>
                          <TextField
                            label="Question *"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={mcq?.question}
                            sx={{ marginTop: 1 }}
                            onChange={(e) => {
                              const updatedMCQs = MCQs.filter(
                                (mcq) => mcq.domain === 'requirement_gathering',
                              ).map((mcq, i) => {
                                if (i === mcqIndex) {
                                  return {
                                    ...mcq,
                                    question: e.target.value,
                                  };
                                } else {
                                  return mcq;
                                }
                              });
                              setMCQs((pre) => [
                                ...updatedMCQs,
                                ...pre.filter(
                                  (q) => q.domain === 'product_knowledge',
                                ),
                              ]);
                            }}
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              color: theme.palette.text.secondary,
                              fontWeight: 'bold',
                            }}
                          >
                            Options
                          </Typography>
                          {mcq?.options?.map((option, optionIndex) => {
                            return (
                              <Box
                                key={optionIndex}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Checkbox
                                  name="answer"
                                  checked={mcq?.answers.includes(option)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      const updatedMCQs = MCQs.filter(
                                        (mcq) =>
                                          mcq.domain ===
                                          'requirement_gathering',
                                      ).map((mcq, i) => {
                                        if (i === mcqIndex) {
                                          return {
                                            ...mcq,
                                            answers: [...mcq.answers, option],
                                          };
                                        } else {
                                          return mcq;
                                        }
                                      });
                                      setMCQs((pre) => [
                                        ...updatedMCQs,
                                        ...pre.filter(
                                          (q) =>
                                            q.domain === 'product_knowledge',
                                        ),
                                      ]);
                                    } else {
                                      const updatedMCQs = MCQs.filter(
                                        (mcq) =>
                                          mcq.domain ===
                                          'requirement_gathering',
                                      ).map((mcq, i) => {
                                        if (i === mcqIndex) {
                                          const ua = mcq?.answers.filter(
                                            (a) => a !== option,
                                          );
                                          return {
                                            ...mcq,
                                            answers: ua,
                                          };
                                        } else {
                                          return mcq;
                                        }
                                      });
                                      setMCQs((pre) => [
                                        ...updatedMCQs,
                                        ...pre.filter(
                                          (q) =>
                                            q.domain === 'product_knowledge',
                                        ),
                                      ]);
                                    }
                                  }}
                                />
                                <TextField
                                  size="small"
                                  label={`Option ${optionIndex + 1}`}
                                  variant="outlined"
                                  fullWidth
                                  value={option}
                                  onChange={(e) => {
                                    const updatedOptions = [...mcq.options];
                                    updatedOptions[optionIndex] =
                                      e.target.value;
                                    const updatedMCQs = MCQs.filter(
                                      (mcq) =>
                                        mcq.domain === 'requirement_gathering',
                                    ).map((mcq, i) => {
                                      if (i === mcqIndex) {
                                        return {
                                          ...mcq,
                                          options: updatedOptions,
                                        };
                                      } else {
                                        return mcq;
                                      }
                                    });
                                    setMCQs((pre) => [
                                      ...updatedMCQs,
                                      ...pre.filter(
                                        (q) => q.domain === 'product_knowledge',
                                      ),
                                    ]);
                                  }}
                                  sx={{ marginTop: 1 }}
                                  // error={formik.touched.email && Boolean(formik.errors.email)}
                                  // helperText={formik.touched.email && formik.errors.email}
                                />
                              </Box>
                            );
                          })}
                          <Typography
                            variant="caption"
                            component={'p'}
                            sx={{
                              color: theme.palette.text.secondary,
                              fontWeight: 'bold',
                              marginTop: 2,
                            }}
                          >
                            Answers: {mcq?.answers?.join(',')}
                          </Typography>
                          <Typography
                            variant="caption"
                            component={'p'}
                            sx={{
                              color: theme.palette.text.secondary,
                              fontWeight: 'bold',
                              marginTop: 2,
                            }}
                          >
                            Tags
                          </Typography>
                          <TextField
                            size="small"
                            label="Tags (comma seperated) *"
                            variant="outlined"
                            fullWidth
                            value={mcq?.tags?.join(',')}
                            onChange={(e) => {
                              const updatedMCQs = MCQs.filter(
                                (mcq) => mcq.domain === 'requirement_gathering',
                              ).map((mcq, i) => {
                                if (i === mcqIndex) {
                                  return {
                                    ...mcq,
                                    tags: e.target.value?.split(','),
                                  };
                                } else return mcq;
                              });
                              setMCQs((pre) => [
                                ...updatedMCQs,
                                ...pre.filter(
                                  (q) => q.domain === 'product_knowledge',
                                ),
                              ]);
                            }}
                            sx={{ marginTop: 1 }}
                            // error={formik.touched.email && Boolean(formik.errors.email)}
                            // helperText={formik.touched.email && formik.errors.email}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Box>
              <Button
                onClick={() => {
                  setMCQs([
                    ...MCQs,
                    {
                      question: '',
                      answers: [],
                      options: ['', '', '', ''],
                      tags: [],
                      domain: 'requirement_gathering',
                      type: 'mcq',
                    },
                  ]);
                }}
                sx={{ marginTop: 1 }}
                variant="contained"
              >
                Add Prospect Needs Understanding MCQ
              </Button>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit(
                          'sections',
                          handleNext,
                          contest?.sections?.[1],
                          contest?.visibility === 'draft',
                        );
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 7 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={() => setActiveStep(5)}>Step 3</StepButton>
            <StepContent>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Instructions for step 3:
                <RichTextEditor
                  value={pitchInstructions}
                  onChange={setPitchInstructions}
                />
                {/* <Editor
                  editorState={pitchInstructions}
                  onEditorStateChange={(editorState) => {
                    setPitchInstructions(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Instructions for step 3:"
                /> */}
              </Box>
              <TextField
                type={'number'}
                label="Max Video Time Limit In Min*"
                variant="outlined"
                name={'customerInteraction'}
                fullWidth
                value={maxVideoTimeLimitInMinSection3}
                onChange={(e) =>
                  setMaxVideoTimeLimitInMinSection3(e.target.value)
                }
                sx={{ marginTop: 2 }}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                type={'number'}
                label="Recommended Video Time Limit In Min*"
                variant="outlined"
                name={'recommendedVideoTimeLimitInMin'}
                fullWidth
                value={recommendedVideoTimeLimitInMin}
                onChange={(e) =>
                  setRecommendedVideoTimeLimitInMin(e.target.value)
                }
                sx={{ marginTop: 2 }}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
              />

              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit(
                          'sections',
                          handleNext,
                          contest?.sections?.[2],
                          contest?.visibility === 'draft',
                        );
                      } else {
                        handleNext();
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 7 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepButton
              onClick={() => setActiveStep(6)}
              optional={
                activeStep === 6 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              Contest success message
            </StepButton>
            <StepContent>
              <Box
                sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}
              >
                Contest Success Message
                <RichTextEditor
                  value={competitionSuccussMessage}
                  onChange={setCompetitionSuccussMessage}
                />
                {/* <Editor
                  editorState={competitionSuccussMessage}
                  onEditorStateChange={(editorState) => {
                    setCompetitionSuccussMessage(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Contest Success Message"
                /> */}
              </Box>

              <Box sx={{ mb: 2 }}>
                <div>
                  {/* {!editContest ? ( */}
                  <>
                    <Button
                      disabled={processing}
                      variant="contained"
                      onClick={() => {
                        if (editContest) {
                          updateContestSubmit(
                            'details',
                            handleNext,
                            null,
                            true,
                          );
                        } else {
                          if (activeStep === 6) {
                            // console.log('here');
                            onContestSubmit(true);
                          } else {
                            handleNext();
                          }
                        }
                      }}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Save as Draft
                    </Button>
                  </>
                  {/* ) : null} */}
                  <Button
                    disabled={
                      processing ||
                      (activeStep === 5 &&
                        !videoData &&
                        !selectedImage &&
                        !editContest)
                    }
                    variant="contained"
                    onClick={() => {
                      if (editContest) {
                        updateContestSubmit('details', handleNext, false);
                      } else {
                        if (activeStep === 6) {
                          // console.log('here');
                          onContestSubmit(false);
                        } else {
                          handleNext();
                        }
                      }
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === 6 ? 'Publish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={Boolean(showGenerateAIDataDialog)}
          onClose={(e, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              return;
            }
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              // boxShadow: theme.shadows[5],
              outline: 'none',
              maxHeight: '80vh',
              maxWidth: '50vw',
              minWidth: '50vw',
              overflowY: 'hidden',
              borderRadius: 1,
              padding: 2,
              position: 'relative',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                padding: '4px',
                background: theme.palette.background.default,
                ':hover': {
                  backgroundColor: '#d3d3d3',
                },
              }}
              onClick={(e) => {
                setShowGenerateAIDataDialog('');
              }}
            >
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
            <Box display={'flex'} flexDirection={'column'} gap={1} mt={4}>
              {/* <TextField
                autoFocus
                label="Description"
                multiline
                rows={2}
                variant="outlined"
                color="primary"
                size="large"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              /> */}
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight={'bold'}
              >
                Data Type
              </Typography>
              <TextField
                autoFocus
                placeholder="Data Type"
                variant="outlined"
                color="primary"
                size="large"
                name="initialPrompt"
                value={dataType}
                onChange={(e) => setDataType(e.target.value)}
                fullWidth
              />
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight={'bold'}
              >
                Initial Prompt
              </Typography>
              <TextField
                autoFocus
                placeholder="Initial Prompt"
                multiline
                rows={10}
                variant="outlined"
                color="primary"
                size="large"
                name="initialPrompt"
                value={currentPrompt}
                onChange={(e) => setCurrentPrompt(e.target.value)}
                fullWidth
              />
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                gap={2}
              >
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    !currentPrompt?.trim() || generatingAIData || !promptChanged
                  }
                  onClick={() => {
                    generateAIData(
                      showGenerateAIDataDialog,
                      showGenerateAIDataDialog === 'scenario_description'
                        ? true
                        : showGenerateAIDataDialog === 'cold_calling_script'
                        ? true
                        : showGenerateAIDataDialog === 'product_description_mcq'
                        ? true
                        : showGenerateAIDataDialog === 'prospect_script'
                        ? true
                        : showGenerateAIDataDialog === 'prospect_mcq'
                        ? true
                        : showGenerateAIDataDialog === 'product_description'
                        ? false
                        : false,
                      showGenerateAIDataDialog === 'scenario_description'
                        ? 'product_description'
                        : showGenerateAIDataDialog === 'cold_calling_script'
                        ? 'scenario_description'
                        : showGenerateAIDataDialog === 'product_description_mcq'
                        ? 'cold_calling_script'
                        : showGenerateAIDataDialog === 'prospect_script'
                        ? 'product_description_mcq'
                        : showGenerateAIDataDialog === 'prospect_mcq'
                        ? 'prospect_script'
                        : showGenerateAIDataDialog === 'product_description'
                        ? null
                        : null,
                      true,
                    );
                  }}
                >
                  Save Prompt and Generate
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  disabled={!currentPrompt?.trim() || generatingAIData}
                  onClick={() => {
                    generateAIData(
                      showGenerateAIDataDialog,
                      showGenerateAIDataDialog === 'scenario_description'
                        ? true
                        : showGenerateAIDataDialog === 'cold_calling_script'
                        ? true
                        : showGenerateAIDataDialog === 'product_description_mcq'
                        ? true
                        : showGenerateAIDataDialog === 'prospect_script'
                        ? true
                        : showGenerateAIDataDialog === 'prospect_mcq'
                        ? true
                        : showGenerateAIDataDialog === 'product_description'
                        ? false
                        : false,
                      showGenerateAIDataDialog === 'scenario_description'
                        ? 'product_description'
                        : showGenerateAIDataDialog === 'cold_calling_script'
                        ? 'scenario_description'
                        : showGenerateAIDataDialog === 'product_description_mcq'
                        ? 'cold_calling_script'
                        : showGenerateAIDataDialog === 'prospect_script'
                        ? 'product_description_mcq'
                        : showGenerateAIDataDialog === 'prospect_mcq'
                        ? 'prospect_script'
                        : showGenerateAIDataDialog === 'product_description'
                        ? null
                        : null,
                      false,
                    );
                  }}
                >
                  Generate
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Fixed>
  );
};

export default AICreateContest;
