const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  allJobs: null,
  emptyList: null,
  lastTime: null,
};

const getAllJobsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_JOBS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_JOBS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_JOBS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        allJobs: action.payload.jobs,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'UPDATE_JOB_IN_ALL_JOBS': {
      const index = state.participantsDetails.findIndex(
        ({ participation }) =>
          participation.id === action.payload.participationId,
      );
      let newArr = [...state.participantsDetails];
      if (newArr?.[index]?.participation !== undefined) {
        newArr[index]['participation'][action.payload.property] =
          action.payload.value;
      } else {
        newArr[index]['participation'][action.payload.property] =
          action.payload.value;
      }
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        allJobs: newArr,
        lastTime: state.lastTime,
        emptyList: false,
        error: false,
        errorText: '',
      });
    }
    case 'ADD_PARTICIPANTS_TO_ALL_PARTICIPANTS_COMPETITION_BY_ID':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        allJobs: Array.isArray(action.payload.jobs)
          ? state.allJobs
            ? state.allJobs.concat(action.payload.jobs)
            : action.payload.jobs
          : state.allJobs,
        emptyList:
          Array.isArray(action.payload.jobs) &&
          (action.payload.jobs.length === 0 ||
            action.payload.jobs.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'FETCHING_ALL_JOBS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        allJobs: null,
        emptyList: false,
        lastTime: '',
      });
    case 'FETCHING_ALL_JOBS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllJobsReducer;
