import { TranscribeStreamingClient } from '@aws-sdk/client-transcribe-streaming';
const MicrophoneStream = require('microphone-stream').default;
import { Buffer } from 'buffer';
import { StartStreamTranscriptionCommand } from '@aws-sdk/client-transcribe-streaming';

let microphoneStream = undefined;
let transcribeClient = undefined;

export const startRecording = async (
  creds,
  language,
  stream,
  sampleRate,
  callback,
) => {
  console.log(sampleRate);
  if (!language) {
    return false;
  }
  if (microphoneStream || transcribeClient) {
    stopRecording();
  }
  createTranscribeClient(creds);
  createMicrophoneStream(stream);
  await startStreaming(language, sampleRate, callback);
};

export const stopRecording = function() {
  if (microphoneStream) {
    microphoneStream.stop();
    microphoneStream.destroy();
    microphoneStream = undefined;
  }
  if (transcribeClient) {
    transcribeClient.destroy();
    transcribeClient = undefined;
  }
};

const createTranscribeClient = (creds) => {
  transcribeClient = new TranscribeStreamingClient({
    region: 'us-east-1',
    credentials: creds,
  });
};

const createMicrophoneStream = async (stream) => {
  microphoneStream = new MicrophoneStream();
  microphoneStream.setStream(stream);
  // return microphoneStream;
};

const startStreaming = async (language, sampleRate, callback) => {
  try {
    const command = new StartStreamTranscriptionCommand({
      // EnablePartialResultsStabilization: true,
      LanguageCode: language,
      MediaEncoding: 'pcm',
      MediaSampleRateHertz: sampleRate,
      AudioStream: getAudioStream(sampleRate),
    });
    const data = await transcribeClient.send(command);
    for await (const event of data.TranscriptResultStream) {
      for (const result of event.TranscriptEvent.Transcript.Results || []) {
        if (result.IsPartial === false) {
          const noOfResults = result.Alternatives[0].Items.length;
          for (let i = 0; i < noOfResults; i++) {
            console.log(result.Alternatives[0].Items[i].Content);
            callback(result.Alternatives[0].Items[i].Content + ' ');
          }
        }
      }
    }
  } catch (err) {
    alert('An error occurred while recording: ' + err.message);
    console.log(err);
  }
};

const getAudioStream = async function*(sampleRate) {
  for await (const chunk of microphoneStream) {
    if (chunk.length <= sampleRate) {
      yield {
        AudioEvent: {
          AudioChunk: encodePCMChunk(chunk),
        },
      };
    }
  }
};

const encodePCMChunk = (chunk) => {
  const input = MicrophoneStream.toRaw(chunk);
  let offset = 0;
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);
  for (let i = 0; i < input.length; i++, offset += 2) {
    let s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return Buffer.from(buffer);
};
