import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const updatePlanTrialEndsOn = (orgId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_PLAN_TRIAL_ENDS_ON' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        // const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(`/ads/org/${orgId}/plan`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_PLAN_TRIAL_ENDS_ON_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'UPDATING_PLAN_TRIAL_ENDS_ON_FAILED',
              payload: `Failed to update trial end date`,
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_PLAN_TRIAL_ENDS_ON_FAILED',
            payload: `Failed to update trial end date`,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'UPDATING_PLAN_TRIAL_ENDS_ON_FAILED',
        payload: `Failed to update trial end date`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default updatePlanTrialEndsOn;
