import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getParticipantsByCompetitionId = (
  competitionId,
  currentRole,
  status,
  limit,
  lastTime,
  callback,
) => {
  return async (dispatch) => {
    if (lastTime) {
      dispatch({
        type: 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_WITH_PAGINATION',
      });
    } else {
      dispatch({ type: 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/ads/competitions/${competitionId}/participations`,
            {
              params: {
                'request-id': reqId,
                status: status,
                limit: limit,
                lastTime: lastTime,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            if (lastTime) {
              await dispatch({
                type: 'ADD_PARTICIPANTS_TO_ALL_PARTICIPANTS_COMPETITION_BY_ID',
                payload: {
                  participations: response?.data?.participations,
                  lastTime:
                    response?.data?.participations?.[
                      response?.data?.participations?.length - 1
                    ]?.participation?.createdOn,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_DONE',
                payload: {
                  participations: response?.data?.participations,
                  competitionDetails: response?.data?.competition,
                  lastTime:
                    response?.data?.participations?.[
                      response?.data?.participations?.length - 1
                    ]?.participation?.createdOn,
                },
              });
            }
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_FAILED',
              payload: `Failed to get competition with id ${competitionId}`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_FAILED',
            payload: `Failed to get participants competition with id ${competitionId}`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_FAILED',
        payload: `Failed to get  competition participants with id ${competitionId}`,
      });
    }
  };
};

export default getParticipantsByCompetitionId;
