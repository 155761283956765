import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllPrompts from 'redux/actions/Common/getAllPrompts';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import updatePrompt from 'redux/actions/Common/updatePrompt';
import createNewPrompt from 'redux/actions/Common/createNewPrompt';
import deletePrompt from 'redux/actions/Common/deletePrompt';
import { capitalizeText } from 'utils';
import makePromptAsDefaultPrompt from 'redux/actions/Common/makePromptAsDefaultPrompt';
import getPromptPreview from 'redux/actions/Common/getPromptPreview';
import getBotsByPromptId from 'redux/actions/Common/getBotsByPromptId';
import updateBotsPrompt from 'redux/actions/Common/updateBotsPrompt';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import updateDefaultSuggestions from 'redux/actions/Common/updateDefaultSuggestions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DefaultSuggestions = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const [type, setType] = useState('verticals');
  const [verticals, setVerticals] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [showInputDialog, setShowInputDialog] = useState('');
  const [newFields, setNewFields] = useState(['']);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [field, setField] = useState('');

  const handleAddSectionClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (showInputDialog !== 'create') {
      setField(showInputDialog);
    }
  }, [showInputDialog]);

  useEffect(() => {
    dispatch(
      getDefaultPrompt((data) => {
        console.log(data);
        setVerticals(data?.verticals);
        setDesignations(data?.designations);
      }),
    );
  }, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
          sx={{
            minHeight: 1,
          }}
        >
          <FormControl
            variant="outlined"
            size="small"
            sx={{ marginBottom: 2, width: 250 }}
          >
            <Select
              labelId="prompt-select-label"
              value={type || ''}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <MenuItem value={'verticals'}>Verticals</MenuItem>
              <MenuItem value={'designations'}>Designations</MenuItem>
            </Select>
          </FormControl>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={1}
            sx={{
              minHeight: 'calc(100vh - 160px)',
              maxHeight: 'calc(100vh - 160px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
            }}
          >
            {type === 'verticals'
              ? verticals?.map((v, i) => (
                  <Box
                    key={v}
                    sx={{
                      border: '1px solid #E5E5E5',
                      borderRadius: 1,
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography>{v}</Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <IconButton
                        onClick={() => {
                          setShowInputDialog(v);
                        }}
                      >
                        <Edit
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setShowConfirmDeleteDialog(v);
                        }}
                      >
                        <Delete
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                ))
              : type === 'designations'
              ? designations?.map((d, i) => (
                  <Box
                    key={d}
                    sx={{
                      border: '1px solid #E5E5E5',
                      borderRadius: 1,
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography>{d}</Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <IconButton
                        onClick={() => {
                          setShowInputDialog(d);
                        }}
                      >
                        <Edit
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setShowConfirmDeleteDialog(d);
                        }}
                      >
                        <Delete
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                ))
              : null}
          </Box>
          {/* {!selectedPrompt ? ( */}
          <Tooltip arrow placement="top" title="Create New">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              sx={{ position: 'absolute', bottom: 15, right: 30 }}
              onClick={() => {
                setShowInputDialog('create');
              }}
            >
              <Add />
            </Fab>
          </Tooltip>
          {/* ) : null} */}
        </Box>
      </Box>
      {/* <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showPromptPreview)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowPromptPreview('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>Prompt Preview</Typography>
          </Box>
          <Box
            // ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 80px)',
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
            }}
          >
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {showPromptPreview}
            </Typography>
          </Box>
        </Box>
      </Drawer>*/}
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography>
              Are you sure you want to delete this{' '}
              {type === 'verticals' ? 'Vertical' : 'Designation'}?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              dispatch(
                updateDefaultSuggestions(
                  {
                    domains:
                      type === 'verticals'
                        ? verticals?.filter(
                            (ver) => ver !== showConfirmDeleteDialog,
                          )
                        : null,
                    designations:
                      type === 'designations'
                        ? designations?.filter(
                            (desig) => desig !== showConfirmDeleteDialog,
                          )
                        : null,
                  },
                  () => {
                    setShowConfirmDeleteDialog(null);
                    dispatch(
                      getDefaultPrompt((data) => {
                        console.log(data);
                        setVerticals(data?.verticals);
                        setDesignations(data?.designations);
                      }),
                    );
                  },
                ),
              );
              // dispatch(
              //   deletePrompt(showConfirmDeleteDialog, () => {
              //     dispatch({
              //       type: 'UPDATE_PROMPTS_AFTER_DELETE',
              //       payload: {
              //         id: showConfirmDeleteDialog,
              //       },
              //     });
              //     setShowConfirmDeleteDialog(null);
              //   }),
              // );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showInputDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogContent>
          {showInputDialog === 'create' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // padding: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // background: '#f1f1f1',
                  padding: 2,
                }}
              >
                <Typography variant="body1">
                  {showInputDialog === 'create' ? 'Add new' : 'Update'}{' '}
                  {type === 'verticals' ? 'Vertical' : 'Designation'}
                  {showInputDialog === 'create' ? 's' : ''}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: '8px 16px',
                }}
              >
                {newFields && newFields.length > 0
                  ? newFields.map((q, i) => (
                      <Box
                        key={`q-${i}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          // gap: 1,
                          width: '100%',
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <TextField
                            placeholder="Question"
                            variant="outlined"
                            name={'question'}
                            size="small"
                            value={q}
                            onChange={(e) => {
                              setNewFields([
                                ...newFields.slice(0, i),
                                e.target.value,
                                ...newFields.slice(i + 1),
                              ]);
                            }}
                            type={'text'}
                          />
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              padding: '4px 8px',
                            }}
                            color="primary"
                            onClick={() =>
                              setNewFields((prev) => [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1),
                              ])
                            }
                          >
                            Remove
                          </Button>
                        </Box>
                      </Box>
                    ))
                  : null}
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{
                      padding: '4px 8px',
                    }}
                    onClick={() => setNewFields((prev) => [...prev, ''])}
                  >
                    Add More
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // padding: 2,
              }}
            >
              <Typography variant="body1">Update</Typography>
              <TextField
                placeholder={type === 'verticals' ? 'Vertical' : 'Designation'}
                variant="outlined"
                name={'question'}
                size="small"
                fullWidth
                multiline
                rows={3}
                value={field}
                onChange={(e) => setField(e.target.value)}
                type={'text'}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowInputDialog(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={
              (showInputDialog === 'create' &&
                (!newFields ||
                  !(
                    newFields &&
                    newFields.length > 0 &&
                    newFields?.filter((nq) => nq?.trim())?.length > 0
                  ))) ||
              (!field?.trim() && showInputDialog !== 'create')
            }
            onClick={(e) => {
              if (showInputDialog === 'create') {
                dispatch(
                  updateDefaultSuggestions(
                    {
                      domains:
                        type === 'verticals'
                          ? [
                              ...(verticals || []),
                              ...(newFields?.filter((nq) => nq?.trim()) || []),
                            ]
                          : null,
                      designations:
                        type === 'designations'
                          ? [
                              ...(designations || []),
                              ...(newFields?.filter((nq) => nq?.trim()) || []),
                            ]
                          : null,
                    },
                    () => {
                      setShowInputDialog(null);
                      setField('');
                      setNewFields(['']);
                      dispatch(
                        getDefaultPrompt((data) => {
                          setVerticals(data?.verticals);
                          setDesignations(data?.designations);
                        }),
                      );
                    },
                  ),
                );
              } else {
                dispatch(
                  updateDefaultSuggestions(
                    {
                      domains:
                        type === 'verticals'
                          ? verticals.map((qs) =>
                              qs === showInputDialog ? field?.trim() : qs,
                            )
                          : null,
                      designations:
                        type === 'designations'
                          ? designations.map((qs) =>
                              qs === showInputDialog ? field?.trim() : qs,
                            )
                          : null,
                    },
                    () => {
                      setShowInputDialog(null);
                      setField('');
                      dispatch(
                        getDefaultPrompt((data) => {
                          setVerticals(data?.verticals);
                          setDesignations(data?.designations);
                        }),
                      );
                    },
                  ),
                );
              }
            }}
            color="primary"
            autoFocus
          >
            {showInputDialog === 'create' ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default DefaultSuggestions;
