import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const role = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo')).roleName
    : '';

  return (
    <Route
      {...rest}
      render={(props) =>
        // (
        isLoggedIn ? (
          //   && role == 'ROLE_ADMIN') ||
          // role == 'ROLE_GUEST_ADMIN' ||
          // role == 'ROLE_MASK_ADMIN' ||
          // role == 'ROLE_MCQ_ADMIN'
          <Component {...props} />
        ) : (
          <Redirect to="/login-f550aeea-0bb4-41e9-b615-5346dvdsfg34" />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default AuthRoute;
