import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAddOns = (planIds, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'GET_ALL_ADD_ONS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/ads/plan/addons/list`, planIds, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'GET_ALL_ADD_ONS_DONE',
              payload: {
                addons: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GET_ALL_ADD_ONS_FAILED',
              payload: `Failed to fetch addons`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GET_ALL_ADD_ONS_FAILED',
            payload: `Failed to fetch addons`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GET_ALL_ADD_ONS_FAILED',
        payload: `Failed to fetch addons`,
      });
    }
  };
};

export default getAddOns;
