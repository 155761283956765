import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import firebaseSigninReducer from './Auth/firebaseSigninReducer';
import firebaseCheckSessionReducer from './Auth/firebaseCheckSessionReducer';

import inviteUserReducer from './Common/inviteUserReducer';
import getContestReportReducer from './Common/getContestReportReducer';
import getParticipantsByCompetitionIdReducer from './Common/getParticipantsByCompetitionIdReducer';
import getPracticesReducer from './Common/getPracticesReducer';
import getAllJobsReducer from './Common/getAllJobsReducer';
import getAllVideoDataPointsReducer from './Common/getAllVideoDataPointsReducer';
import getAIDataForContestCreationReducer from './Common/getAIDataForContestCreationReducer';
import getDefaultPromptsReducer from './Common/getDefaultPromptsReducer';
import getAllCandidatesReducer from './Common/getAllCandidatesReducer';
import getCredsReducer from './Common/getCredsReducer';
import getAllConversationsReducer from './Common/getAllConversationsReducer';
import getAllUsersReducer from './Common/getAllUsersReducer';
import getAllPromptsReducer from './Common/getAllPromptsReducer';
import getAllBotsReducer from './Common/getAllBotsReducer';
import getExperimentsReducer from './Common/getExperimentsReducer';
import getVideoDataPointsByExperimentIdReducer from './Common/getVideoDataPointsByExperimentIdReducer';
import getAllOrganizationsReducer from './Common/getAllOrganizationsReducer';
import getPracticeCallLinksReducer from './Common/getPracticeCallLinksReducer';
import getAllInvitationsReducer from './Common/getAllInvitationsReducer';
import getAllGeneratedPlaybooksReducer from './Common/getAllGeneratedPlaybooksReducer';
import getPresetQuestionsReducer from './Common/getPresetQuestionsReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['firebaseSigninState'],
};

const rootReducer = combineReducers({
  firebaseSession: firebaseCheckSessionReducer,
  firebaseSigninState: firebaseSigninReducer,
  inviteUserState: inviteUserReducer,
  getParticipantsByCompetitionIdState: getParticipantsByCompetitionIdReducer,
  contestReportData: getContestReportReducer,
  allPractices: getPracticesReducer,
  allJobsState: getAllJobsReducer,
  videoDataPoints: getAllVideoDataPointsReducer,
  aiDataForContestCreation: getAIDataForContestCreationReducer,
  defaultPrompts: getDefaultPromptsReducer,
  allCandidates: getAllCandidatesReducer,
  awsCreds: getCredsReducer,
  allConversations: getAllConversationsReducer,
  allUsers: getAllUsersReducer,
  allPrompts: getAllPromptsReducer,
  allPresetQuestions: getPresetQuestionsReducer,
  allBots: getAllBotsReducer,
  allExperiments: getExperimentsReducer,
  videoDataPointsByExpId: getVideoDataPointsByExperimentIdReducer,
  allOrganizations: getAllOrganizationsReducer,
  allPracticeLinks: getPracticeCallLinksReducer,
  allInvitations: getAllInvitationsReducer,
  allGeneratedPlaybooks: getAllGeneratedPlaybooksReducer,
});

export default persistReducer(persistConfig, rootReducer);
