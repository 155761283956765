import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeText } from 'utils';
import getPlans from 'redux/actions/Common/getPlans';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PlanManagement = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    setFetchingPlans(true);
    dispatch(
      getPlans((plans) => {
        setPlans(plans);
        setFetchingPlans(false);
      }),
    );
  }, []);

  // console.log('promptSections', promptSections);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
        >
          {fetchingPlans ? (
            <Box
              width={1}
              height={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <CircularProgress size={20} />
              <Typography variant="body1" color={'text.primary'} mt={4}>
                Loading all plans ...
              </Typography>
            </Box>
          ) : plans && plans.length > 0 ? (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Box display={'flex'} flexDirection={'row'} gap={1}>
                {plans?.map((plan) => {
                  return (
                    <Card sx={{ padding: 2, flex: 1 }} key={plan?.id}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          <Typography
                            // textAlign={'center'}
                            sx={{ textTransform: 'capitalize' }}
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            {plan?.title}
                          </Typography>
                          <Typography
                            // textAlign={'center'}
                            // sx={{ textTransform: 'capitalize' }}
                            color={'text.secondary'}
                            // fontWeight={'bold'}
                          >
                            ({plan?.description})
                          </Typography>
                        </Box>
                        <Typography variant="caption" color={'text.secondary'}>
                          {moment(plan?.createOn).format('DD MMMM YYYY')}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={2}>
                        {Object.values(plan?.features || {})?.map((feature) => (
                          <Box key={feature?.feature}>
                            <Typography
                              // textAlign={'center'}
                              sx={{ textTransform: 'capitalize' }}
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              {feature?.feature}
                            </Typography>

                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={2}
                            >
                              {Object.values(feature?.restrictions || {})?.map(
                                (restriction) => (
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                  >
                                    <Typography
                                      sx={{ textTransform: 'capitalize' }}
                                      color={'text.secondary'}
                                      fontWeight={'bold'}
                                    >
                                      {restriction?.restriction}
                                    </Typography>
                                    {Object.keys(restriction || {})
                                      ?.filter((key) => key !== 'restriction')
                                      ?.map((key) => (
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                        >
                                          <Typography
                                            sx={{ textTransform: 'capitalize' }}
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                          >
                                            {key}
                                          </Typography>
                                          <Typography>
                                            {restriction[key]}
                                          </Typography>
                                        </Box>
                                      ))}
                                  </Box>
                                ),
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Card>
                  );
                })}
              </Box>
              {/* <Box display={'flex'} flexDirection={'row'} gap={1}>
                {plans?.map((plan) => {
                  return (
                    <Card sx={{ padding: 2, flex: 1 }} key={plan?.id}>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {JSON.stringify(plan, null, 2)}
                      </Typography>
                    </Card>
                  );
                })}
              </Box> */}
            </Box>
          ) : (
            <Box
              width={1}
              height={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={2}
            >
              <Typography variant="body1" color={'text.secondary'} mt={4}>
                No Plans Added yet.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showPromptPreview)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowPromptPreview('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>Prompt Preview</Typography>
          </Box>
          <Box
            // ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 80px)',
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
            }}
          >
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {showPromptPreview}
            </Typography>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this prompt?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deletePrompt(showConfirmDeleteDialog, () => {
                  dispatch({
                    type: 'UPDATE_PROMPTS_AFTER_DELETE',
                    payload: {
                      id: showConfirmDeleteDialog,
                    },
                  });
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fixed>
  );
};

export default PlanManagement;
