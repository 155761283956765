const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  invitations: [],
  emptyList: null,
  lastSeen: null,
};

const getAllInvitationsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_INVITATIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_INVITATIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_INVITATIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        invitations: action.payload.invitations,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_INVITATIONS_TO_ALL_INVITATIONS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        invitations: Array.isArray(action.payload.invitations)
          ? state.invitations
            ? state.invitations.concat(action.payload.invitations)
            : action.payload.invitations
          : state.invitations,
        emptyList:
          Array.isArray(action.payload.invitations) &&
          (action.payload.invitations.length === 0 ||
            action.payload.invitations.length % 15 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'GETTING_ALL_INVITATIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        invitations: [],
        emptyList: false,
        lastSeen: '',
      });
    case 'GETTING_ALL_INVITATIONS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllInvitationsReducer;
