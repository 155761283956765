import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAllInvitations = (limit, lastSeen, callback) => {
  return async (dispatch) => {
    if (lastSeen) {
      dispatch({
        type: 'GETTING_ALL_INVITATIONS_WITH_PAGINATION',
      });
    } else {
      dispatch({ type: 'GETTING_ALL_INVITATIONS' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/ads/invitations/search`,
            {
              lastSeen: lastSeen,
              limit,
            },
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            if (lastSeen) {
              await dispatch({
                type: 'ADD_INVITATIONS_TO_ALL_INVITATIONS',
                payload: {
                  invitations: response?.data || [],
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'GETTING_ALL_INVITATIONS_DONE',
                payload: {
                  invitations: response?.data || [],
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GETTING_ALL_INVITATIONS_FAILED',
              payload: `Failed to fetch invitations`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GETTING_ALL_INVITATIONS_FAILED',
            payload: `Failed to fetch invitations`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GETTING_ALL_INVITATIONS_FAILED',
        payload: `Failed to fetch invitations`,
      });
    }
  };
};

export default getAllInvitations;
