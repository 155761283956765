import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase';
import firebaseLogout from './firebaseLogout';

const firebaseCheckSession = (callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FIREBASE_CHECKING_SESSION' });
    try {
      let unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          if (localStorage.getItem('userInfo')) {
            user.getIdToken().then((token) => console.log(token));
            dispatch({ type: 'FIREBASE_LOGGED_IN' });
            if (typeof callback === 'function') callback(true);
          } else {
            dispatch({ type: 'FIREBASE_NOT_LOGGED_IN' });
            dispatch(firebaseLogout());
          }
        } else {
          dispatch({ type: 'FIREBASE_NOT_LOGGED_IN' });
          dispatch(firebaseLogout());
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({ type: 'FIREBASE_NOT_LOGGED_IN' });
    }
  };
};

export default firebaseCheckSession;
