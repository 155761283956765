import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExpandMore,
  Group,
  Link,
  Lock,
  Mic,
  MoreVert,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllVideoDataPoints from 'redux/actions/Common/getAllVideoDataPoints';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import SkillTagInput from './components/skillTagInput';
import labelDataPoint from 'redux/actions/Common/labelDataPoint';
import addLabels from 'redux/actions/Common/addLabels';
import getLabels from 'redux/actions/Common/getLabels';
import getAllConversations from 'redux/actions/Common/getAllConversations';
import getMessageResponse from 'redux/actions/Common/getMessageResponse';
import getConversationById from 'redux/actions/Common/getConversationById';
import createNewConversation from 'redux/actions/Common/createNewConversation';

import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import uploadAudio from 'redux/actions/Common/uploadAudio';
import verifyAudioUpload from 'redux/actions/Common/verifyAudioUpload';
import getMergedConversation from 'redux/actions/Common/getMergedConversation';
import getUploadAudioUrlForConversation from 'redux/actions/Common/getUploadAudioUrlForConversation';
import deleteConversation from 'redux/actions/Common/deleteConversation';
import updateConversation from 'redux/actions/Common/updateConversation';
import getCreds from 'redux/actions/Common/getCreds';

import * as TranscribeClient from './components/transcribe';
import getGptRequestDataByMsgId from 'redux/actions/Common/getGptRequestDataByMsgId';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultLabels = [
  'clarity',
  'pace',
  'fluency',
  'confidence',
  'enthusiasm',
  'delivery',
  'content',
];

const ConversationAIAdmin = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const chatsScrollview = useRef(null);

  const awsCreds = useSelector((state) => state.awsCreds);
  const [newConversationDialog, setNewConversationDialog] = useState(false);
  const {
    conversations,
    fetchingPagination,
    emptyList,
    fetching,
    lastSeen,
  } = useSelector((state) => state.allConversations);

  // const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [message, setMessage] = useState('');
  const [gettingResponse, setGettingResponse] = useState(false);
  const [description, setDescription] = useState('');
  const [conversationTitle, setConversationTitle] = useState('');
  const [conversationPrompt, setConversationPrompt] = useState('');
  const [creatingConversation, setCreatingConversation] = useState(false);

  const [recorder, setRecorder] = useState(null);
  const [showPermissionDeniedError, setShowPermissionDeniedError] = useState(
    false,
  );
  const [permissionDenied, setPermissionDenied] = useState(null);
  const [permissionPersisting, setPermissionPersisting] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recording, setRecording] = useState(null);
  const streamRef = useRef();
  const playBackRef = useRef();
  const timer = useRef(null);
  const timer2 = useRef(null);
  const [timerSs, setTimerSs] = useState(0);
  const [timerMs, setTimerMs] = useState(0);

  const [mediaStream, setMediaStream] = useState(null);
  const [sampleRate, setSampleRate] = useState(48000);
  const [availableAudioDevices, setAvailableAudioDevices] = useState(null);

  const [firstRenderAudio, setFirstRenderAudio] = useState(false);
  const [audioSource, setAudioSource] = useState('');
  const [showMediaSelectOptions, setShowMediaSelectOptions] = useState(false);
  const [audioTypes, setAudioTypes] = useState(['GCP']);
  const [autoPlay, setAutoPlay] = useState(false);

  // const [beTranscription, setBeTranscription] = useState(false);
  const [transcriptionType, setTranscriptionType] = useState('webspeech');
  const [gender, setGender] = useState('FEMALE');
  const [promptType, setPromptType] = useState('default_with_name');
  const [prospectName, setProspectName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [context, setContext] = useState('');
  const [teamSize, setTeamSize] = useState(1);
  const [customPrompt, setCustomPrompt] = useState('');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(null);
  const [updatingConversation, setUpdatingConversation] = useState(false);
  const [initialPromptExpanded, setInitialPromptExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showOptions, setShowOptions] = useState(null);
  const [autoRecord, setAutoRecord] = useState(false);
  const [autoStart, setAutoStart] = useState(false);

  const [botId, setbotId] = useState('');

  const [gptRequestData, setgptRequestData] = useState({});
  const [gptRequestDataExpanded, setGptRequestDataExpanded] = useState(false);

  const [
    showCreateMergedAudioDialog,
    setShowCreateMergedAudioDialog,
  ] = useState(false);
  const [mergedAudioType, setMergedAudioType] = useState('GCP');
  const [
    generatingMergedConversation,
    setGeneratingMergedConversation,
  ] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const getRecordingPermission = async () => {
    try {
      const timeStampBeforePermission = Date.now();
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      });
      const timeStampAfterPermission = Date.now();
      setPermissionDenied(false);
      if (timeStampAfterPermission - timeStampBeforePermission < 1500) {
        setPermissionPersisting(true);
      } else {
        setPermissionPersisting(false);
      }
      streamRef.current = stream;
      const sampleRateBlock = stream.getAudioTracks()[0].getSettings()
        .sampleRate;
      setSampleRate(sampleRateBlock);
      console.log('sampleRateBlock', sampleRateBlock);
      setMediaStream(stream);
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.');
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setAvailableAudioDevices(
        devices.filter((device) => device.kind === 'audioinput'),
      );
    } catch (e) {
      console.log(e?.name);
      if (e?.name === 'NotAllowedError') {
        setShowPermissionDeniedError(true);
        setPermissionDenied(true);
        setError(true);
        setErrorMessage(
          'Camera and microphone are blocked. Please allow the permissions and start recording.',
        );
      } else {
        setError(true);
        setErrorMessage(
          'Oops we are unable to detect your camera. Please refresh this page and try again.',
        );
      }
    }
  };

  const handleRecordClick = async () => {
    setAutoStart(false);
    if (permissionDenied) {
      setShowPermissionDeniedError(true);
    } else {
      try {
        setSelectedAudio(null);
        setRecordedBlobs([]);
        setRecording(true);
        startTimer();
        if (playBackRef && playBackRef.current) {
          playBackRef.current.src = null;
        }
        if (!selectedConversation?.beTranscription) {
          if (selectedConversation?.feAwsTranscription) {
            if (
              awsCreds?.expiry === 0 ||
              new Date().getTime() - awsCreds?.expiry >= 0
            ) {
              dispatch(
                getCreds(async (data) => {
                  await TranscribeClient.startRecording(
                    {
                      sessionToken: data?.sessionToken,
                      accessKeyId: data?.accessKey,
                      secretAccessKey: data?.secretKey,
                    },
                    'en-US',
                    mediaStream,
                    sampleRate,
                    (data) => setMessage((prev) => `${prev}${data}`),
                  );
                }),
              );
            } else {
              await TranscribeClient.startRecording(
                {
                  sessionToken: awsCreds?.creds?.sessionToken,
                  accessKeyId: awsCreds?.creds?.accessKey,
                  secretAccessKey: awsCreds?.creds?.secretKey,
                },
                'en-US',
                mediaStream,
                sampleRate,
                (data) => setMessage((prev) => `${prev}${data}`),
              );
            }
          } else {
            SpeechRecognition.startListening({ continuous: true });
          }
        }
      } catch (error) {
        handleRecordStopClick();
        alert('An error occurred while recording: ' + error.message);
      }
    }
  };

  const handleRecordStopClick = async () => {
    try {
      await recorder.stopRecording();
      let blob = await recorder.getBlob();
      // console.log('blob', blob);
      setSelectedAudio(blob);
      stopTimer();
      setRecording(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearRecordingClick = () => {
    setSelectedAudio(null);
    setRecordedBlobs([]);
    setRecording(null);
    setMessage('');
    if (
      !selectedConversation?.beTranscription &&
      !selectedConversation?.feAwsTranscription
    ) {
      resetTranscript();
    }
    if (selectedAudio) {
      playBackRef.current.src = null;
    }
    if (streamRef && streamRef.current)
      streamRef.current.getTracks().forEach((track) => track.stop());
    // if (browser?.name !== 'safari') {
    getRecordingPermission();
    // }
  };

  // const playRecordedVideo = () => {
  //   const audioBlob = new Blob(recordedBlobs, { type: 'audio/webm' });
  //   setSelectedAudio(audioBlob);
  // };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimerSs((prev) => {
        if (prev === 59) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
    timer2.current = setInterval(() => {
      setTimerMs((prev) => prev + 1);
    }, 60000);
  };

  const stopTimer = () => {
    // console.log(timer.current, timer2.current);
    clearInterval(timer.current);
    clearInterval(timer2.current);
    setTimerSs(0);
    setTimerMs(0);
  };

  const handleGetResponse = (withAudio) => {
    setGettingResponse(true);
    if (selectedConversation?.beTranscription && selectedAudio) {
      dispatch(
        getUploadAudioUrlForConversation(
          selectedConversationId,
          selectedAudio?.type?.split(';')?.[0],
          (data) => {
            console.log(data);
            dispatch(
              uploadAudio(data?.url, selectedAudio, () => {
                dispatch(
                  verifyAudioUpload(data?.url && data?.audioId, 'v3', () => {
                    setGettingResponse(false);
                    dispatch(
                      getConversationById(selectedConversationId, (data) => {
                        setAutoPlay(true);
                        setMessage('');
                        resetTranscript();
                        setSelectedConversation(data);
                        if (chatsScrollview?.current) {
                          chatsScrollview?.current?.scroll({
                            top: chatsScrollview?.current?.scrollHeight,
                            behaviour: 'smooth',
                          });
                        }
                        if (autoRecord) {
                          setAutoStart(true);
                        }
                      }),
                    );
                    setSelectedAudio(null);
                  }),
                );
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        getMessageResponse(
          selectedConversationId,
          message,
          async (data) => {
            if (
              data?.userAudioUploadLink &&
              data?.userAudioId &&
              selectedAudio
            ) {
              dispatch(
                uploadAudio(data?.userAudioUploadLink, selectedAudio, () => {
                  dispatch(
                    verifyAudioUpload(
                      data?.userAudioUploadLink && data?.userAudioId,
                      null,
                      () => {
                        setSelectedAudio(null);
                      },
                    ),
                  );
                }),
              );
            }
            setGettingResponse(false);
            dispatch(
              getConversationById(selectedConversationId, (data) => {
                setAutoPlay(true);
                setMessage('');
                resetTranscript();
                if (selectedAudio) {
                  playBackRef.current.src = null;
                }
                if (streamRef && streamRef.current)
                  streamRef.current
                    .getTracks()
                    .forEach((track) => track.stop());
                // if (browser?.name !== 'safari') {
                getRecordingPermission();
                setSelectedConversation(data);
                if (chatsScrollview?.current) {
                  chatsScrollview?.current?.scroll({
                    top: chatsScrollview?.current?.scrollHeight,
                    behaviour: 'smooth',
                  });
                }
                if (autoRecord) {
                  setAutoStart(true);
                }
              }),
            );
          },
          () => {
            setGettingResponse(false);
          },
        ),
      );
    }
  };

  const handleGenerateMergedConversation = () => {
    setGeneratingMergedConversation(true);
    dispatch(
      getMergedConversation(selectedConversationId, (data) => {
        console.log(data);
        setTimeout(() => {
          dispatch(
            getConversationById(selectedConversationId, (data) => {
              setMessage('');
              resetTranscript();
              setSelectedAudio(null);
              setSelectedConversation(data);
              setGeneratingMergedConversation(true);
              setShowCreateMergedAudioDialog(false);
              if (chatsScrollview?.current) {
                chatsScrollview?.current?.scroll({
                  top: chatsScrollview?.current?.scrollHeight,
                  behaviour: 'smooth',
                });
              }
            }),
          );
        }, 3000);
      }),
    );
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setAudioTypes((prev) => [...prev, e.target.value]);
    } else {
      setAudioTypes((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const handleGeneratePrompt = () => {
    if (promptType === 'company_context_with_new_changes') {
      setCustomPrompt(
        conversationPrompt
          ?.replace(/<%>gpt_name<%>/g, prospectName)
          .replace(/<%>designation<%>/g, designation)
          .replace(/<%>company_name<%>/g, companyName)
          .replace(/<%>company_details<%>/g, aboutCompany)
          .replace(/<%>new_info<%>/g, context),
      );
    } else {
      setConversationPrompt((prev) =>
        prev?.replace(/<%>gpt_name<%>/g, prospectName),
      );
    }
  };

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        conversations &&
        conversations.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllConversations(10, lastSeen, () => {}));
    }
  };

  useEffect(() => {
    if (transcript) {
      setMessage(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (recording === false) {
      if (!selectedConversation?.beTranscription) {
        if (selectedConversation?.feAwsTranscription) {
          TranscribeClient.stopRecording();
        } else {
          SpeechRecognition.stopListening();
        }
      }
      handleGetResponse();
    }
  }, [recording]);

  useEffect(() => {
    if (selectedAudio && recording === false) {
      let recordedVideoUrl = window.URL.createObjectURL(selectedAudio);
      // setRecording(false);
      if (playBackRef.current) {
        playBackRef.current.src = recordedVideoUrl;
        // playBackRef.current.play();
      }
    }
    // if (streamRef && streamRef.current)
    //   streamRef.current.getTracks().forEach((track) => track.stop());
  }, [selectedAudio, recording, playBackRef?.current]);

  useEffect(() => {
    let options = {
      mimeType: 'audio/webm',
    };
    try {
      if (mediaStream !== null) {
        setRecorder(new RecordRTCPromisesHandler(mediaStream, options));
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage('Failed to get media device');
    }
  }, [mediaStream]);

  useEffect(() => {
    if (
      recording === true &&
      recordedBlobs.length === 0 &&
      selectedAudio === null
    ) {
      try {
        recorder.onerror = (e) => {
          console.log(e);
          setError(true);
          setErrorMessage(
            'Oops! Something went wrong. Please refresh this page and try again. ',
          );
        };
        recorder.startRecording();
      } catch (e) {
        console.log(e);
        console.log('mediaRecorder API not supported for this browser');
        setError(true);
        setErrorMessage(
          'Oops! Something went wrong. Please refresh this page and try again. ',
        );
      }
    }
  }, [selectedAudio, recordedBlobs, recording]);

  useEffect(() => {
    if (
      timerSs % 15 === 0 &&
      (timerSs !== 0 || (timerSs === 0 && timerMs !== 0))
    ) {
      if (
        !selectedConversation?.beTranscription &&
        !selectedConversation?.feAwsTranscription
      ) {
        (async function() {
          await SpeechRecognition.stopListening();
          await SpeechRecognition.startListening({ continuous: true });
        })();
      }
      console.log('restart');
    }
    // console.log(typeof timerSs);
  }, [timerSs, timerMs]);

  useEffect(() => {
    if (!firstRenderAudio) {
      if (availableAudioDevices && availableAudioDevices.length > 0) {
        setAudioSource(availableAudioDevices[0].deviceId);
        setFirstRenderAudio(true);
      }
    }
  }, [availableAudioDevices]);

  // useEffect(() => {
  //   if (browser?.name !== 'safari' && videoRef && videoRef.current) {
  //     videoRef.current.srcObject = mediaStream;
  //   }
  // }, [mediaStream]);

  // useEffect(() => {
  //   if (recording === false) {
  //     playRecordedVideo();
  //   }
  // }, [recording]);

  useEffect(() => {
    if (showEditDialog) {
      setConversationPrompt(showEditDialog?.messages?.[0]?.gptMessage?.content);
      setConversationTitle(showEditDialog?.title);
      setDescription(showEditDialog?.description);
      setCustomPrompt('');
      setPromptType('default_with_name');
      setProspectName('');
      setCompanyName('');
      setAboutCompany('');
      setDesignation('');
      setAudioTypes(showEditDialog?.audioService);
      setTranscriptionType(
        showEditDialog?.beTranscription
          ? 'awsserver'
          : showEditDialog?.feTranscription
          ? 'awsclient'
          : 'webspeech',
      );
      setGender(showEditDialog?.gender || 'FEMALE');
    } else {
      return;
    }
  }, [showEditDialog]);

  useEffect(() => {
    if (promptType) {
      setProspectName('');
      dispatch(
        getDefaultPrompt((prompt) => {
          // console.log(prompt);
          setConversationPrompt(prompt[promptType]);
        }),
      );
    }
  }, [promptType]);

  useEffect(() => {
    if (selectedConversationId) {
      setAutoPlay(false);
      setSelectedConversation(
        conversations.find((c) => c?.id === selectedConversationId),
      );
      if (chatsScrollview?.current) {
        chatsScrollview?.current?.scroll({
          top: chatsScrollview?.current?.scrollHeight,
          behaviour: 'smooth',
        });
      }
    }
  }, [selectedConversationId]);

  useEffect(() => {
    if (newConversationDialog) {
      dispatch(
        getDefaultPrompt((prompt) => {
          // console.log(prompt);
          setConversationPrompt(prompt?.['default_with_name']);
        }),
      );
    }
  }, [newConversationDialog]);

  useEffect(() => {
    if (state && state?.conversationId) {
      setSelectedConversationId(state?.conversationId);
    }
  }, [state]);

  useEffect(() => {
    dispatch(
      getAllConversations(10, null, (cs) => {
        // setConversations(cs);
      }),
    );

    getRecordingPermission();
    return () => {
      if (streamRef && streamRef.current)
        streamRef.current.getTracks().forEach((track) => track.stop());
    };
  }, []);

  // console.log(recordedBlobs);
  // console.log(selectedAudio);
  // console.log(recording);
  // console.log('transcript', transcript);
  // console.log(
  //   'browserSupportsSpeechRecognition',
  //   browserSupportsSpeechRecognition,
  // );
  // console.log('listening', listening);
  // console.log(message);
  // console.log(permissionDenied);
  // console.log(permissionPersisting);
  // console.log(showPermissionDeniedError);
  // console.log(audioTypes);
  // console.log(conversationPrompt);
  // console.log('autoStart', autoStart);
  // console.log('autoRecord', autoRecord);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                  }
            }
          >
            {/* <Button
              variant="outlined"
              size="small"
              fullWidth
              startIcon={<Add />}
              onClick={() => {
                setNewConversationDialog(true);
              }}
              sx={{
                // minWidth: isXs ? '48px' : '300px',
                whiteSpace: 'nowrap',
              }}
            >
              {isXs || isSm ? '' : 'Create new chat'}
            </Button> */}
            {conversations?.map((conversation) => (
              <Box
                key={conversation?.id}
                sx={{
                  width: '100%',
                  padding: '8px',
                  border: '1px solid #d3d3d3',
                  borderRadius: 2,
                  cursor: 'pointer',
                  background:
                    selectedConversationId === conversation?.id
                      ? theme.palette.primary.main
                      : 'default',
                  color:
                    selectedConversationId === conversation?.id
                      ? '#fff'
                      : 'default',
                  whiteSpace: isSm || isXs ? 'nowrap' : 'pre-wrap',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => setSelectedConversationId(conversation?.id)}
              >
                <Menu
                  id="options-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={
                    Boolean(anchorEl) &&
                    conversation?.id === selectedConversationId
                  }
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  disableScrollLock={true}
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  style={{
                    marginTop: '15px',
                  }}
                  PaperProps={{
                    elevation: 1,
                  }}
                >
                  <MenuItem
                    disabled={
                      conversations?.find(
                        (cs) => cs?.id === selectedConversationId,
                      )?.started
                    }
                    style={{}}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        conversations?.find(
                          (cs) => cs?.id === selectedConversationId,
                        )?.started
                      ) {
                        return;
                      } else {
                        setShowEditDialog(
                          conversations?.find(
                            (cs) => cs?.id === selectedConversationId,
                          ),
                        );
                      }
                      setAnchorEl(null);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    style={{}}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAnchorEl(null);
                      setShowConfirmDeleteDialog(
                        conversations?.find(
                          (cs) => cs?.id === selectedConversationId,
                        )?.id,
                      );
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <Typography>{conversation?.title}</Typography>
                <MoreVert
                  id={conversation?.id}
                  sx={{
                    color: '#2d2d2d',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setAnchorEl(conversation?.id)
                    setAnchorEl(e.currentTarget);
                    setSelectedConversationId(e.currentTarget?.id);
                  }}
                />
              </Box>
            ))}
            {fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more conversations ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && conversations?.length >= 10 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={(e) => handleListScroll(e, true)}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
            {selectedConversationId ? (
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => {
                  setShowCreateMergedAudioDialog(true);
                }}
                sx={{
                  minWidth: 250,
                  margin: isXs || isSm ? '0 auto' : 'auto 0',
                  whiteSpace: 'nowrap',
                }}
              >
                Generate Merged Audio Conversation
              </Button>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 220px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 220px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                // background: '#d3d3d3',
                paddingBottom: isXs || isSm ? '120px' : '20px',
              }}
              ref={chatsScrollview}
            >
              {selectedConversation?.messages?.map((msg, index) => (
                <Box display={'flex'} gap={1}>
                  {index !== 0 && msg?.gptMessage?.role === 'user' ? (
                    <Box flex={1}></Box>
                  ) : null}
                  <Box
                    key={msg?.id}
                    sx={{
                      flex: 1,
                      padding: '8px 16px',
                      border: '1px solid #d3d3d3',
                      borderRadius: 1,
                      // maxWidth: index === 0 ? '100%' : '50%',
                      alignSelf:
                        msg?.gptMessage?.role === 'user' ? 'end' : 'start',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      background: index === 0 ? '#d3d3d3' : 'transparent',
                    }}
                  >
                    {index === 0 ? (
                      <>
                        {selectedConversation?.gptConfig ? (
                          <Box display={'flex'} alignItems={'center'} gap={2}>
                            <Typography>
                              maxTokens:{' '}
                              {selectedConversation?.gptConfig?.maxTokens}
                            </Typography>
                            <Typography>
                              topP: {selectedConversation?.gptConfig?.topP}
                            </Typography>
                            <Typography>
                              frequencyPenalty:{' '}
                              {
                                selectedConversation?.gptConfig
                                  ?.frequencyPenalty
                              }
                            </Typography>
                            <Typography>
                              presencePenalty:{' '}
                              {selectedConversation?.gptConfig?.presencePenalty}
                            </Typography>
                            <Typography>
                              temperature:{' '}
                              {selectedConversation?.gptConfig?.temperature}
                            </Typography>
                          </Box>
                        ) : null}
                        <Accordion
                          expanded={initialPromptExpanded}
                          onChange={() =>
                            setInitialPromptExpanded((prev) => !prev)
                          }
                          elevation={0}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="submission-content"
                            id="submission-header"
                            sx={{
                              height: '30px',
                              background: '#d3d3d3',
                              boxShadow: 'none',
                              width: '100%',
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              mb={1}
                              width={'100%'}
                            >
                              <Typography
                                variant="caption"
                                color={'text.secondary'}
                              >
                                Initial Prompt
                              </Typography>
                              <Typography
                                variant="caption"
                                color={'text.secondary'}
                              >
                                {moment(msg?.createdOn).format(
                                  'DD MMMM YYYY hh:mm:ss A',
                                )}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              color={index === 0 ? '#343a40' : 'text.primary'}
                            >
                              {msg?.gptMessage?.content}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    ) : (
                      <>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          mb={1}
                        >
                          {msg?.gptMessage?.role === 'user' ? (
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              You
                            </Typography>
                          ) : index !== 0 ? (
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              System
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              Initial Prompt
                            </Typography>
                          )}
                          {index === 0 ? null : (
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              {moment(msg?.createdOn).format(
                                'DD MMMM YYYY hh:mm:ss A',
                              )}
                            </Typography>
                          )}
                        </Box>
                        <Typography
                          color={index === 0 ? '#343a40' : 'text.primary'}
                        >
                          {msg?.gptMessage?.content}
                        </Typography>
                        {msg?.customFields?.gcpAudioLink ||
                        msg?.customFields?.elevenLabsAudioLink ? (
                          <>
                            <Divider />
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={1}
                              flexDirection={{
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                              }}
                            >
                              {msg?.customFields?.elevenLabsAudioLink ? (
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                >
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    Eleven Labs
                                  </Typography>
                                  {msg?.customFields?.elevenLabsAudioLink ? (
                                    <audio
                                      autoPlay={
                                        index ===
                                          selectedConversation?.messages
                                            ?.length -
                                            1 &&
                                        selectedConversation?.audioService?.includes(
                                          'ELEVEN_LABS',
                                        ) &&
                                        autoPlay
                                      }
                                      preload
                                      controls
                                      src={
                                        msg?.customFields?.elevenLabsAudioLink
                                      }
                                      style={{
                                        maxWidth: '220px',
                                        padding: '10px 20px',
                                      }}
                                    />
                                  ) : null}
                                </Box>
                              ) : null}
                              {msg?.customFields?.gcpAudioLink ? (
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                >
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                  >
                                    Google
                                  </Typography>
                                  {msg?.customFields?.gcpAudioLink ? (
                                    <audio
                                      autoPlay={
                                        index ===
                                          selectedConversation?.messages
                                            ?.length -
                                            1 &&
                                        !selectedConversation?.audioService?.includes(
                                          'ELEVEN_LABS',
                                        ) &&
                                        autoPlay
                                      }
                                      preload
                                      controls
                                      src={msg?.customFields?.gcpAudioLink}
                                      style={{
                                        maxWidth: '220px',
                                        padding: '10px 20px',
                                      }}
                                      onEnded={() => {
                                        console.log('audio played');
                                        if (autoStart && autoRecord) {
                                          handleRecordClick();
                                        }
                                      }}
                                    />
                                  ) : null}
                                </Box>
                              ) : null}
                            </Box>
                            {index !== 0 &&
                            msg?.gptMessage?.role === 'assistant' ? (
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => {
                                  dispatch(
                                    getGptRequestDataByMsgId(
                                      msg?.id,
                                      (data) => {
                                        setgptRequestData((prev) => {
                                          return {
                                            ...prev,
                                            [msg?.id]: data,
                                          };
                                        });
                                      },
                                    ),
                                  );
                                }}
                              >
                                Get request data
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    )}
                  </Box>
                  {index !== 0 && msg?.gptMessage?.role !== 'user' ? (
                    <Box flex={1}>
                      {gptRequestData?.[msg?.id] &&
                      index !== 0 &&
                      msg?.gptMessage?.role === 'assistant' ? (
                        <Accordion
                          expanded={gptRequestDataExpanded}
                          onChange={() =>
                            setGptRequestDataExpanded((prev) => !prev)
                          }
                          elevation={0}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="submission-content"
                            id="submission-header"
                            sx={{
                              height: '30px',
                              background: '#d3d3d3',
                              boxShadow: 'none',
                              width: '100%',
                            }}
                          >
                            <Typography>Request Data</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              border: '1px solid #d3d3d3',
                              maxHeight: 300,
                              overflowX: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                                height: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                                // outline: '1px solid slategrey',
                              },
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ whiteSpace: 'pre-wrap' }}
                            >
                              {JSON.stringify(
                                gptRequestData?.[msg?.id],
                                null,
                                2,
                              )}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              ))}
              {selectedConversation?.customFields?.audioLink ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Typography variant="body1" color={'text.secondary'}>
                    Merged Audio Conversation
                  </Typography>
                  {selectedConversation?.customFields?.audioLink ? (
                    <audio
                      preload
                      controls
                      src={selectedConversation?.customFields?.audioLink}
                      style={{
                        maxWidth: '220px',
                        padding: '10px 20px',
                      }}
                    />
                  ) : null}
                </Box>
              ) : null}
            </Box>
            {selectedConversationId ? (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  padding: '8px 16px',
                  background: '#fff',
                  width: '100%',
                }}
              >
                <Box display={'flex'} justifyContent={'flex-end'} pr={2}>
                  <Tooltip
                    title="Automatically start recording after the response audio is completely played"
                    placement="right"
                    arrow
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          size="medium"
                          color="primary"
                          checked={autoRecord}
                          onChange={(e) => setAutoRecord(e.target.checked)}
                        />
                      }
                      label={
                        <Typography variant="subtitle2" fontWeight={700}>
                          Autostart Recording
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </Tooltip>
                </Box>
                {selectedAudio ? (
                  <Box
                    sx={{
                      border: '1px solid #d3d3d3',
                      borderRadius: 1,
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <TextField
                      autoFocus
                      label="Message"
                      multiline
                      rows={2}
                      variant="outlined"
                      color="primary"
                      size="large"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      fullWidth
                    />
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={1}
                      alignItems={'end'}
                    >
                      {recording === false && selectedAudio !== null ? (
                        <audio
                          preload="true"
                          id="audioPreview"
                          ref={playBackRef}
                          playsInline
                          controls
                          onPlay={(e) => {
                            console.log(e);
                            // handleTrackEvent(e, 'audio playing');
                          }}
                          onPause={(e) => console.log(e)}
                          onEnded={(e) => console.log(e)}
                          onError={(e) => {
                            // dispatch(
                            //   logError({
                            //     message: e?.message,
                            //     data: {
                            //       formatted: {
                            //         errorText: e?.message,
                            //         severity: 'error',
                            //       },
                            //       raw: e,
                            //     },
                            //   }),
                            // );
                            console.log(e);
                            // handleTrackEvent(e, 'error playing audio');
                          }}
                          // onLoadedMetadata={(e) => console.log(e)}
                        ></audio>
                      ) : null}
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={2}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            handleClearRecordingClick();
                          }}
                        >
                          Clear
                        </Button>
                        <IconButton
                          size="small"
                          disabled={!selectedAudio}
                          onClick={() => handleGetResponse(true)}
                        >
                          {gettingResponse ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Send
                              fontSize="small"
                              color={selectedAudio ? 'primary' : 'default'}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          size="small"
                          // disabled={!message.trim()}
                          onClick={() => {
                            if (recording) {
                              handleRecordStopClick();
                            } else {
                              handleRecordClick();
                            }
                          }}
                        >
                          {recording ? (
                            <Tooltip
                              title="Stop Recording"
                              arrow
                              placement="top"
                            >
                              <StopCircle fontSize="medium" color="primary" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Record" arrow placement="top">
                              <Mic fontSize="medium" color={'primary'} />
                            </Tooltip>
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <TextField
                    autoFocus
                    label="Message"
                    multiline
                    rows={2}
                    variant="outlined"
                    color="primary"
                    size="large"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={2}
                          >
                            {recording && (
                              <Box sx={{}}>
                                <Typography>
                                  {`0${timerMs} `}:
                                  {timerSs > 9
                                    ? timerSs < 0
                                      ? '00'
                                      : ` ${timerSs}`
                                    : ` 0${timerSs}`}
                                </Typography>
                              </Box>
                            )}
                            <IconButton
                              size="small"
                              disabled={!message.trim()}
                              onClick={() => handleGetResponse(false)}
                            >
                              {gettingResponse ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Send
                                  fontSize="small"
                                  color={
                                    message?.trim() ? 'primary' : 'default'
                                  }
                                />
                              )}
                            </IconButton>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 2,
                              }}
                            >
                              <IconButton
                                size="small"
                                // disabled={!message.trim()}
                                onClick={() => {
                                  if (recording) {
                                    handleRecordStopClick();
                                  } else {
                                    handleRecordClick();
                                  }
                                }}
                              >
                                {recording ? (
                                  <StopCircle
                                    fontSize="medium"
                                    color="primary"
                                  />
                                ) : (
                                  <Tooltip title="Record" arrow placement="top">
                                    <Mic fontSize="medium" color={'primary'} />
                                  </Tooltip>
                                )}
                              </IconButton>
                            </Box>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={newConversationDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setNewConversationDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Title
              </Typography>
              <TextField
                autoFocus
                placeholder="Title"
                variant="outlined"
                color="primary"
                size="small"
                name="title"
                value={conversationTitle}
                onChange={(e) => setConversationTitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Description
              </Typography>
              <TextField
                autoFocus
                placeholder="Description"
                multiline
                rows={2}
                variant="outlined"
                color="primary"
                size="small"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Available Bots
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    value={'FEMALE'}
                    control={<Radio />}
                    label={`Female Voice`}
                  />
                  <FormControlLabel
                    value={'MALE'}
                    control={<Radio />}
                    label={`Male Voice`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Transcription Engine
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={transcriptionType}
                  onChange={(e) => setTranscriptionType(e.target.value)}
                >
                  <FormControlLabel
                    value={'webspeech'}
                    control={<Radio />}
                    label={`Frontend (Web Speech API)(Default)`}
                  />
                  <FormControlLabel
                    disabled
                    value={'awsclient'}
                    control={<Radio />}
                    label={`Frontend (AWS Client SDK)`}
                  />
                  <FormControlLabel
                    disabled
                    value={'awsserver'}
                    control={<Radio />}
                    label={`Backend (AWS Transcription)`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    !conversationTitle?.trim() ||
                    creatingConversation ||
                    (promptType === 'company_context_with_new_changes' &&
                      !customPrompt?.trim())
                  }
                  onClick={() => {
                    setCreatingConversation(true);
                    dispatch(
                      createNewConversation(
                        {
                          botId: botId,
                          description: description?.trim(),
                          title: conversationTitle?.trim(),
                          beTranscription: transcriptionType === 'awsserver',
                          feAwsTranscription: transcriptionType === 'awsclient',
                        },
                        (data) => {
                          dispatch(
                            getAllConversations(10, null, (cs) => {
                              // setConversations(cs);
                              setCreatingConversation(false);
                              setNewConversationDialog(false);
                              setSelectedConversationId(data);
                              setConversationPrompt('');
                              setConversationTitle('');
                              setDescription('');
                              setCustomPrompt('');
                              setPromptType('default_with_name');
                              setProspectName('');
                              setCompanyName('');
                              setAboutCompany('');
                              setDesignation('');
                            }),
                          );
                        },
                      ),
                    );
                  }}
                >
                  Create new chat
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showEditDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowEditDialog(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Title
              </Typography>
              <TextField
                autoFocus
                placeholder="Title"
                variant="outlined"
                color="primary"
                size="small"
                name="title"
                value={conversationTitle}
                onChange={(e) => setConversationTitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Description
              </Typography>
              <TextField
                autoFocus
                placeholder="Description"
                multiline
                rows={2}
                variant="outlined"
                color="primary"
                size="small"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Audio Types
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    checked={audioTypes?.includes('GCP')}
                    control={
                      <Checkbox size="small" value={'GCP'} name="audioTypes" />
                    }
                    label="Google"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={audioTypes?.includes('ELEVEN_LABS')}
                    control={
                      <Checkbox
                        size="small"
                        value={'ELEVEN_LABS'}
                        name="audioTypes"
                      />
                    }
                    label="Eleven Labs"
                    onChange={(e) => handleChange(e)}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Voice Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    value={'FEMALE'}
                    control={<Radio />}
                    label={`Female Voice`}
                  />
                  <FormControlLabel
                    value={'MALE'}
                    control={<Radio />}
                    label={`Male Voice`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Transcription Engine
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={transcriptionType}
                  onChange={(e) => setTranscriptionType(e.target.value)}
                >
                  <FormControlLabel
                    value={'webspeech'}
                    control={<Radio size="small" />}
                    label={
                      <Typography variant="subtitle2">{`Frontend (Web Speech API)(Default)`}</Typography>
                    }
                  />
                  <FormControlLabel
                    value={'awsclient'}
                    control={<Radio size="small" />}
                    label={
                      <Typography variant="subtitle2">{`Frontend (AWS Client SDK)(Default)`}</Typography>
                    }
                  />
                  <FormControlLabel
                    value={'awsserver'}
                    control={<Radio size="small" />}
                    label={
                      <Typography variant="subtitle2">{`Backend (AWS Transcription)`}</Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Initial Prompt
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={promptType}
                  onChange={(e) => setPromptType(e.target.value)}
                >
                  <FormControlLabel
                    value={'default_with_name'}
                    control={<Radio />}
                    label={`Default`}
                  />
                  <FormControlLabel
                    value={'company_context_with_new_changes'}
                    control={<Radio />}
                    label={`Custom Context`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {promptType === 'company_context_with_new_changes' ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Prospect Name
                  </Typography>
                  <TextField
                    placeholder="Prospect Name"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="prospectName"
                    value={prospectName}
                    onChange={(e) => setProspectName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Designation
                  </Typography>
                  <TextField
                    placeholder="Designation"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Company Name
                  </Typography>
                  <TextField
                    placeholder="Company Name"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Team Size
                  </Typography>
                  <TextField
                    placeholder="Team Size"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="teamSize"
                    value={teamSize}
                    onChange={(e) => setTeamSize(e.target.value)}
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    About Company
                  </Typography>
                  <TextField
                    placeholder="About Company"
                    variant="outlined"
                    color="primary"
                    size="small"
                    multiline
                    rows={4}
                    name="aboutCompany"
                    value={aboutCompany}
                    onChange={(e) => setAboutCompany(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}
            {promptType === 'company_context_with_new_changes' ? (
              <Grid item xs={12} align="end">
                <Button
                  variant="contained"
                  size="small"
                  disabled={
                    !(
                      prospectName &&
                      designation &&
                      companyName &&
                      aboutCompany
                    )
                  }
                  onClick={() => {
                    if (
                      prospectName &&
                      designation &&
                      companyName &&
                      aboutCompany
                    ) {
                      handleGeneratePrompt();
                    }
                  }}
                >
                  Generate prompt
                </Button>
              </Grid>
            ) : null}
            {promptType === 'default_with_name' ? (
              <Grid item xs={12}>
                <TextField
                  placeholder="Initial Prompt"
                  multiline
                  rows={7}
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="initialPrompt"
                  value={conversationPrompt}
                  onChange={(e) => setConversationPrompt(e.target.value)}
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  placeholder="Initial Prompt"
                  multiline
                  rows={7}
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="customPrompt"
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    !conversationTitle?.trim() ||
                    updatingConversation ||
                    (promptType === 'company_context_with_new_changes' &&
                      !customPrompt?.trim())
                  }
                  onClick={() => {
                    setUpdatingConversation(true);
                    dispatch(
                      updateConversation(
                        showEditDialog?.id,
                        {
                          audioServices: audioTypes,
                          description: description?.trim(),
                          prompt:
                            promptType === 'default_with_name'
                              ? conversationPrompt
                              : customPrompt,
                          title: conversationTitle?.trim(),
                          beTranscription:
                            transcriptionType === 'awsserver' ? true : false,
                          feAwsTranscription: transcriptionType === 'awsclient',
                          gender,
                        },
                        (data) => {
                          dispatch(
                            getAllConversations(10, null, (cs) => {
                              // setConversations(cs);
                              setUpdatingConversation(false);
                              setSelectedConversationId(data);
                              setConversationPrompt('');
                              setConversationTitle('');
                              setDescription('');
                              setCustomPrompt('');
                              setPromptType('default_with_name');
                              setProspectName('');
                              setCompanyName('');
                              setAboutCompany('');
                              setDesignation('');
                              setShowEditDialog(null);
                            }),
                          );
                        },
                      ),
                    );
                  }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={showCreateMergedAudioDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'hidden',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowCreateMergedAudioDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} gap={1} mt={4}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Audio Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={mergedAudioType}
                onChange={(e) => setMergedAudioType(e.target.value)}
              >
                <FormControlLabel
                  value="GCP"
                  control={<Radio />}
                  label={`Google${
                    selectedConversation?.audioService?.includes('GCP')
                      ? ''
                      : ' (Not available for this conversation)'
                  }`}
                  disabled={
                    !selectedConversation?.audioService?.includes('GCP')
                  }
                />
                <FormControlLabel
                  value="ELEVEN_LABS"
                  control={<Radio />}
                  label={`Eleven Labs${
                    selectedConversation?.audioService?.includes('ELEVEN_LABS')
                      ? ''
                      : ' (Not available for this conversation)'
                  }`}
                  disabled={
                    !selectedConversation?.audioService?.includes('ELEVEN_LABS')
                  }
                />
              </RadioGroup>
            </FormControl>
            <Box display={'flex'} justifyContent={'flex-start'}>
              <Button
                size="small"
                variant="contained"
                disabled={generatingMergedConversation}
                onClick={() => {
                  handleGenerateMergedConversation();
                }}
              >
                Generate Merged Conversation Audio
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this conversation?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteConversation(showConfirmDeleteDialog, () => {
                  // setConversations((prev) =>
                  //   prev?.filter((cs) => cs.id !== showConfirmDeleteDialog),
                  // );
                  setShowConfirmDeleteDialog(null);
                  setSelectedConversationId(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default ConversationAIAdmin;
