const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  conversations: null,
  emptyList: null,
  lastSeen: null,
};

const getAllConversationsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETHING_ALL_CONVERSATIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETHING_ALL_CONVERSATIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETHING_ALL_CONVERSATIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        conversations: action.payload.conversations,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_CONVERSATIONS_TO_ALL_CONVERSATIONS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        conversations: Array.isArray(action.payload.conversations)
          ? state.conversations
            ? state.conversations.concat(action.payload.conversations)
            : action.payload.conversations
          : state.conversations,
        emptyList:
          Array.isArray(action.payload.conversations) &&
          (action.payload.conversations.length === 0 ||
            action.payload.conversations.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'FETHING_ALL_CONVERSATIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        conversations: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETHING_ALL_CONVERSATIONS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllConversationsReducer;
