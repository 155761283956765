import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const updatePlanForOrganization = (orgId, planId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_PLAN_FOR_ORGANIZATION' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(
            `/ads/plan/${planId}/orgs`,
            [orgId],
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_PLAN_FOR_ORGANIZATION_DONE',
              payload: {
                orgId: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(orgId);
            }
          } else {
            dispatch({
              type: 'UPDATING_PLAN_FOR_ORGANIZATION_FAILED',
              payload: `Failed to update plan`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_PLAN_FOR_ORGANIZATION_FAILED',
            payload: `Failed to update plan`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'UPDATING_PLAN_FOR_ORGANIZATION_FAILED',
        payload: `Failed to update plan`,
      });
    }
  };
};

export default updatePlanForOrganization;
