const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  candidates: [],
  emptyList: null,
  lastTime: null,
};

const getAllCandidatesReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_CANDIDATES':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_CANDIDATES_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_CANDIDATES_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        candidates: action.payload.candidates,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'UPDATE_JOB_IN_ALL_JOBS': {
      const index = state.participantsDetails.findIndex(
        ({ participation }) =>
          participation.id === action.payload.participationId,
      );
      let newArr = [...state.participantsDetails];
      if (newArr?.[index]?.participation !== undefined) {
        newArr[index]['participation'][action.payload.property] =
          action.payload.value;
      } else {
        newArr[index]['participation'][action.payload.property] =
          action.payload.value;
      }
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        candidates: newArr,
        lastTime: state.lastTime,
        emptyList: false,
        error: false,
        errorText: '',
      });
    }
    case 'ADD_CANDIDATES_TO_ALL_CANDIDATES':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        candidates: Array.isArray(action.payload.candidates)
          ? state.candidates
            ? state.candidates.concat(action.payload.candidates)
            : action.payload.candidates
          : state.candidates,
        emptyList:
          Array.isArray(action.payload.candidates) &&
          (action.payload.candidates.length === 0 ||
            action.payload.candidates.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GETTING_ALL_CANDIDATES_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        candidates: [],
        emptyList: false,
        lastTime: '',
      });
    case 'GETTING_ALL_CANDIDATES_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllCandidatesReducer;
