import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch } from 'react-redux';
import inviteUser from 'redux/actions/Common/inviteUser';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import getCompetitionStandardData from 'redux/actions/Common/getCompetitionStandardData';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

//actions
import saveContestStandardData from 'redux/actions/Common/saveContestStandardData';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  designations: yup
    .array()
    .required('Please select a role to get started')
    .min(1, 'You have to pick one role to get started')
    .max(3, 'You can pick up to three roles to get started'),
  emailVerified: yup.bool(),
});

const StandardData = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [competitionType, setCompetitionType] = useState(
    'practice_competition',
  );
  const [processing, setProcessing] = useState(false);
  const [overview, setOverview] = useState('');
  const [rules, setRules] = useState('');
  const [prizes, setPrizes] = useState('');
  // const [overallInstructions, setOverallInstruction] = useState(
  //   '',
  // );
  const [step1Instructions, setStep1Instructions] = useState('');

  const [standardData, setStandardData] = useState(null);
  const [Faqs, setFaqs] = useState([]);
  const [currentFaqQuestion, setCurrentFaqQuestion] = useState('');
  const [currentFaqAnswer, setCurrentFaqAnswer] = useState('');
  // const [script, setScript] = useState('');
  const [step2Instructions, setStep2Instructions] = useState('');
  const [step2SecondaryInstruction, setStep2SecondaryInstruction] = useState(
    '',
  );
  const [scoringScheme, setScoringScheme] = useState('');
  const [pitchInstructions, setPitchInstructions] = useState('');
  // const [productDescription, setProductDescription] = useState(
  //   '',
  // );
  const [competitionSuccussMessage, setCompetitionSuccussMessage] = useState(
    '',
  );
  const [aboutHost, setAboutHost] = useState('');
  const dispatch = useDispatch();

  const initialValues = {
    quickIntroduction: '',
    host: '',
  };

  const onSubmit = (values) => {};

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    dispatch(
      getCompetitionStandardData((data) =>
        setStandardData(data?.dataItemsMap?.[competitionType]),
      ),
    );
  }, [competitionType]);

  useEffect(() => {
    dispatch(
      getCompetitionStandardData((data) =>
        setStandardData(data?.dataItemsMap?.[competitionType]),
      ),
    );
  }, []);

  useEffect(() => {
    if (standardData) {
      standardData?.forEach((data) => {
        if (data?.property === 'competition_rules') {
          setRules(data?.data || '');
        } else if (data?.property === 'competition_scoring') {
          setScoringScheme(data?.data || '');
        } else if (data?.property === 'competition_prizes') {
          setPrizes(data?.data || '');
        } else if (data?.property === 'competition_faq') {
          setFaqs(data?.faqs);
        } else if (data?.property === 'script_reading_instruction') {
          setStep1Instructions(data?.data || '');
        } else if (data?.property === 'customer_qa_instruction') {
          setStep2Instructions(data?.data || '');
        } else if (data?.property === 'pitch_instruction') {
          setPitchInstructions(data?.data || '');
        } else if (data?.property === 'competition_success_message') {
          setCompetitionSuccussMessage(data?.data || '');
        } else if (data?.property === 'competition_overview') {
          setOverview(data?.data);
        } else if (data?.property === 'customer_qa_secondary_instruction') {
          setStep2SecondaryInstruction(data?.data);
        } else if (data?.property === 'competition_host') {
          formik.setFieldValue(
            'host',
            data?.dataMap ? data?.dataMap?.name : '',
          );
          setAboutHost(data?.dataMap?.about || '');
        }
      });
    }
  }, [standardData]);

  const onContestSubmit = () => {
    dispatch(
      saveContestStandardData(
        {
          dataItemsMap: {
            [competitionType]: [
              {
                property: 'competition_success_message',
                data: competitionSuccussMessage,
                faqs: null,
              },
              {
                property: 'competition_overview',
                data: overview,
                faqs: null,
              },
              // {
              //   property: 'competition_customer_interaction_background',
              //   data: String(
              //     draftToHtml(
              //       convertToRaw(
              //         customerInteractionBackground,
              //       ),
              //     ),
              //   ),
              //   faqs: null,
              // },
              // {
              //   property: 'competition_product',
              //   data: String(
              //     draftToHtml(
              //       convertToRaw(productDescription),
              //     ),
              //   ),
              //   faqs: null,
              // },
              {
                property: 'competition_faq',
                data: null,
                faqs: Faqs,
              },
              // {
              //   property: 'competition_short_introduction',
              //   data: formik.values.quickIntroduction,
              //   faqs: null,
              // },
              {
                property: 'competition_prizes',
                data: prizes,
                faqs: null,
              },
              {
                property: 'competition_rules',
                data: rules,
                faqs: null,
              },
              {
                property: 'competition_scoring',
                data: scoringScheme,
                faqs: null,
              },
              {
                property: 'script_reading_instruction',
                data: step1Instructions,
                faqs: null,
              },
              {
                property: 'customer_qa_instruction',
                data: step2Instructions,
                faqs: null,
              },
              {
                property: 'customer_qa_secondary_instruction',
                data: step2SecondaryInstruction,
                faqs: null,
              },
              {
                property: 'pitch_instruction',
                data: pitchInstructions,
                faqs: null,
              },
              {
                property: 'competition_host',
                dataMap: {
                  name: formik.values.host,
                  about: aboutHost,
                },
                faqs: null,
              },
            ],
          },
        },
        (response) => {
          console.log(response, 'this is the response-------------');
          dispatch(
            getCompetitionStandardData((data) =>
              setStandardData(data?.dataItemsMap?.[competitionType]),
            ),
          );

          alert('Data Saved!');
        },
      ),
    );
  };

  useEffect(() => {}, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <Box>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Contest type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={competitionType}
              onChange={(e) => setCompetitionType(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="practice_competition"
                control={<Radio />}
                label="Practice competition"
              />
              <FormControlLabel
                value="real_competition"
                control={<Radio />}
                label="Real competition"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <TextField
          label="Host Company"
          variant="outlined"
          name={'host'}
          fullWidth
          value={formik.values.host}
          onChange={formik.handleChange}
          sx={{ marginTop: 2 }}
          // error={formik.touched.email && Boolean(formik.errors.email)}
          // helperText={formik.touched.email && formik.errors.email}
        />
        <Box
          sx={{
            border: '1px solid#A8A8A8',
            marginTop: 2,
            marginBottom: 2,
            padding: 1,
          }}
        >
          Abount Host Company
          <RichTextEditor value={aboutHost} onChange={setAboutHost} />
        </Box>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Overview:
          <RichTextEditor value={overview} onChange={setOverview} />
          {/* <Editor
            editorState={overview}
            onEditorStateChange={(editorState) => {
              setOverview(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Overview"
          /> */}
        </Box>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Rules and guidelines:
          <RichTextEditor value={rules} onChange={setRules} />
          {/* <Editor
            editorState={rules}
            onEditorStateChange={(editorState) => {
              setRules(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Rules and guidelines"
          /> */}
        </Box>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Scoring Scheme:
          <RichTextEditor value={scoringScheme} onChange={setScoringScheme} />
          {/* <Editor
            editorState={scoringScheme}
            onEditorStateChange={(editorState) => {
              setScoringScheme(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Scoring Scheme"
          /> */}
        </Box>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Prizes
          <RichTextEditor value={prizes} onChange={setPrizes} />
          {/* <Editor
            editorState={prizes}
            onEditorStateChange={(editorState) => {
              setPrizes(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Prizes"
          /> */}
        </Box>
        <Box sx={{ marginTop: 1 }}>FAQs</Box>
        {Faqs?.map((faq, index) => {
          return (
            <Box key={index}>
              <Box sx={{ fontWeight: '700', marginTop: 1 }}>
                Q{index + 1}. {faq?.question}
              </Box>
              <Box>Ans. {faq?.answer}</Box>
            </Box>
          );
        })}
        <TextField
          label="Add Question *"
          variant="outlined"
          fullWidth
          value={currentFaqQuestion}
          onChange={(e) => setCurrentFaqQuestion(e.target.value)}
          sx={{ marginTop: 2 }}
          // error={formik.touched.email && Boolean(formik.errors.email)}
          // helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          label="Add Answer *"
          variant="outlined"
          fullWidth
          value={currentFaqAnswer}
          onChange={(e) => setCurrentFaqAnswer(e.target.value)}
          sx={{ marginTop: 2 }}
          // error={formik.touched.email && Boolean(formik.errors.email)}
          // helperText={formik.touched.email && formik.errors.email}
        />

        <Button
          onClick={() => {
            let faqsCp = Faqs;
            Faqs?.push({
              question: currentFaqQuestion,
              answer: currentFaqAnswer,
            });
            setCurrentFaqQuestion('');
            setCurrentFaqAnswer('');
            setFaqs(faqsCp);
          }}
          sx={{ marginTop: 1 }}
          variant="contained"
        >
          Add FAQ
        </Button>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Instructions for step 1
          <RichTextEditor
            value={step1Instructions}
            onChange={setStep1Instructions}
          />
          {/* <Editor
            editorState={step1Instructions}
            onEditorStateChange={(editorState) => {
              setStep1Instructions(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Instructions for step 1"
          /> */}
        </Box>
        {/* <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Teleprompter Script
          <Editor
            editorState={script}
            onEditorStateChange={(editorState) => {
              setScript(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Script"
          />
        </Box> */}
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Instructions for Product Knowledge MCQs
          <RichTextEditor
            value={step2Instructions}
            onChange={setStep2Instructions}
          />
          {/* <Editor
                  editorState={step2Instructions}
                  onEditorStateChange={(editorState) => {
                    setStep2Instructions(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Instructions for Product Knowledge MCQs"
                /> */}
        </Box>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Instructions for Prospect Needs Understanding MCQs
          <RichTextEditor
            value={step2SecondaryInstruction}
            onChange={setStep2SecondaryInstruction}
          />
          {/* <Editor
                  editorState={step2SecondaryInstruction}
                  onEditorStateChange={(editorState) => {
                    setStep2SecondaryInstruction(editorState);
                  }}
                  toolbar={toolbarOptions}
                  placeholder="Instructions for Prospect Needs MCQs"
                /> */}
        </Box>
        {/* <Box>
          <TextField
            label="Add Product Knowledge Keywords(comma seperated)"
            variant="outlined"
            fullWidth
            value={productKnowledgeKeywords}
            onChange={(e) => setProductKnowledgeKeywords(e.target.value)}
            sx={{ marginTop: 2 }}
            // error={formik.touched.email && Boolean(formik.errors.email)}
            // helperText={formik.touched.email && formik.errors.email}
          />
        </Box>
        <Box>
          <TextField
            label="Add Requirement Gathering Keywords(comma seperated)"
            variant="outlined"
            fullWidth
            value={requirementGatheringKeywords}
            onChange={(e) => setRequirementGatheringKeywords(e.target.value)}
            sx={{ marginTop: 2 }}
            // error={formik.touched.email && Boolean(formik.errors.email)}
            // helperText={formik.touched.email && formik.errors.email}
          />
        </Box> */}
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Instructions for step 3:
          <RichTextEditor
            value={pitchInstructions}
            onChange={setPitchInstructions}
          />
          {/* <Editor
            editorState={pitchInstructions}
            onEditorStateChange={(editorState) => {
              setPitchInstructions(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Instructions for step 3:"
          /> */}
        </Box>
        <Box sx={{ border: '1px solid#A8A8A8', marginTop: 2, padding: 1 }}>
          Competition Success Message
          <RichTextEditor
            value={competitionSuccussMessage}
            onChange={setCompetitionSuccussMessage}
          />
          {/* <Editor
            editorState={competitionSuccussMessage}
            onEditorStateChange={(editorState) => {
              setCompetitionSuccussMessage(editorState);
            }}
            toolbar={toolbarOptions}
            placeholder="Competition Success Message"
          /> */}
        </Box>

        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            onClick={() => onContestSubmit()}
            sx={{ mt: 1, mr: 1 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Fixed>
  );
};

export default StandardData;
