import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAllBotsRequests = (callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_ALL_BOTS_REQUESTS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/ads/bot_requests`, {
            params: {
              'request-id': reqId,
              last_seen: null,
              size: 100,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_ALL_BOTS_REQUESTS_DONE',
              payload: {
                bots: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_ALL_BOTS_REQUESTS_FAILED',
              payload: `Failed to get bots requests`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_ALL_BOTS_REQUESTS_FAILED',
            payload: `Failed to get bots requests`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_ALL_BOTS_REQUESTS_FAILED',
        payload: `Failed to get bots requests`,
      });
    }
  };
};

export default getAllBotsRequests;
