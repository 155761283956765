import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const Home = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    window.location.replace('https://app.qualification.ai');
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'row' }}
      position={'relative'}
      justifyContent="center"
      alignItems={'center'}
    >
      <Typography
        variant="h4"
        component={'h4'}
        align={isMd ? 'left' : 'center'}
        sx={{ fontWeight: 700 }}
      >
        Selling Bees
      </Typography>
    </Box>
  );
};

export default Home;
