import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const getPractices = (limit, lastTime, callback) => {
  return async (dispatch) => {
    if (lastTime) {
      dispatch({ type: 'FETCHING_ALL_PRACTICES_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETCHING_ALL_PRACTICES' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/ads/practices`, {
            params: {
              'request-id': reqId,
              last_seen: lastTime,
              size: 25,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  // 'x-api-version': 'v2',
                }
              : {},
          });
          console.log(response);
          if (response.status === 200) {
            if (lastTime) {
              await dispatch({
                type: 'ADD_PRACTICES_TO_ALL_PRACTICES',
                payload: {
                  practices: response?.data?.practices,
                  lastTime: response?.data?.last_seen,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_ALL_PRACTICES_DONE',
                payload: {
                  practices: response?.data?.practices,
                  lastTime: response?.data?.last_seen,
                },
              });
            }
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'FETCHING_ALL_PRACTICES_FAILED',
              payload: 'Failed to fetch practices',
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_ALL_PRACTICES_FAILED',
            payload: 'Failed to fetch practices',
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_ALL_PRACTICES_FAILED',
        payload: 'Failed to fetch practices',
      });
    }
  };
};

export default getPractices;
