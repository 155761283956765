import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const generatePlaybook = (payload, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'GENERATING_PLAYBOOK' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/ads/ai/playbook`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'GENERATING_PLAYBOOK_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GENERATING_PLAYBOOK_FAILED',
              payload: `Failed to generate playbook`,
            });
            if (typeof errCb === 'function') {
              errCb(response?.data);
            }
          }
        } catch (e) {
          dispatch({
            type: 'GENERATING_PLAYBOOK_FAILED',
            payload: `Failed to generate playbook`,
          });
          if (typeof errCb === 'function') {
            errCb(e?.response?.data);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'GENERATING_PLAYBOOK_FAILED',
        payload: `Failed to generate playbook`,
      });
      if (typeof errCb === 'function') {
        errCb(e?.response?.data);
      }
    }
  };
};

export default generatePlaybook;
