import validate from 'validate.js';

export const validateLinkedinURL = (url) => {};

export const validateURL = (url) => {};

export const validateEmail = (email) => {
  return validate({ email: email }, { email: { email: true } }) === undefined;
};

export const getHighestQualityVideoURL = (files) => {};

export const getPortraitThumbnail = (thumbnails) => {};

export const getHighestQualityThumbnail = (thumbnails) => {};

export const getFormattedDate = (epochTime) => {};

export const tooltipDelay = 500;

export const checkTimeDiff = (videoUploadedTime) => {};

export const capitalizeText = (text) => {
  return text
    ?.trim()
    ?.split(' ')
    ?.filter((w) => w.trim())
    ?.map((w) => `${w.split('')[0].toUpperCase()}${w.slice(1)}`)
    .join(' ');
};
