import uuid from 'draft-js/lib/uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const getCompetitionStandardData = (callback, errorCallback) => {
  return async (dispatch) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.get('/ads/competitions/standard-data', {
          params: {
            'request-id': uuid(),
            sessionId: 'ksdhf',
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          if (typeof callback === 'function') callback(response?.data);
        } else {
          if (typeof errorCallback === 'function')
            errorCallback(response?.data);
        }
      } catch (e) {
        console.log(e);
      }
      unsubscribe();
    });
  };
};

export default getCompetitionStandardData;
