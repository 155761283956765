import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import { Check } from '@mui/icons-material';

const ReportTabs = ({
  currentParam,
  setCurrentParam,
  fillers,
  fillerTranscript,
  fillerWordsRegexp,
  repetitions,
  repetitionsTranscript,
  repeatWordsRegexp,
  pauseInfo,
  paceArr,
  showProgress,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Box width={1} height={1} {...rest}>
      <Tabs
        value={currentParam}
        onChange={(e, newValue) => setCurrentParam(newValue)}
        aria-label="Parameter Tabs"
        orientation={'horizontal'}
        // indicatorColor={theme.palette.background.default}
        variant="scrollable"
        // scrollButtons={!isMd}
        allowScrollButtonsMobile
      >
        <Tab
          value={'fillers'}
          label={<Typography variant="body1">Filler Words</Typography>}
          id={`simple-tab-${0}`}
          aria-controls={`simple-tabpanel-${0}`}
          sx={{}}
        />
        <Tab
          value={'repeatitions'}
          label={<Typography variant="body1">Repeated Words</Typography>}
          id={`simple-tab-${5}`}
          aria-controls={`simple-tabpanel-${5}`}
          sx={{}}
        />
        <Tab
          value={'pauses'}
          label={<Typography variant="body1">Big Pauses</Typography>}
          id={`simple-tab-${5}`}
          aria-controls={`simple-tabpanel-${5}`}
          sx={{}}
        />
        <Tab
          value={'pace'}
          label={<Typography variant="body1">Pace</Typography>}
          id={`simple-tab-${5}`}
          aria-controls={`simple-tabpanel-${5}`}
          sx={{}}
        />
      </Tabs>
      <Box
        sx={{
          padding: 2,
          border: '1px solid #d3d3d3',
          borderRadius: 2,
          mark: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            padding: '2px 6px',
          },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: '420px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 2,
            // outline: '1px solid slategrey',
          },
        }}
      >
        {showProgress ? (
          <Box
            width={1}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            // alignItems={'center'}
            gap={2}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Check />
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  textAlign={'center'}
                  mt={1}
                >
                  Record multiple videos and get feedback on each of them
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Check />
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  textAlign={'center'}
                >
                  Track your progress and keep getting better
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Check />
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  textAlign={'center'}
                >
                  A host of other features
                </Typography>
              </Box>
            </Box>
            <Divider />
            <CircularProgress size={16} sx={{ alignSelf: 'center' }} />
            <Typography
              variant="body1"
              color={'text.secondary'}
              textAlign={'center'}
            >
              Hang tight, we are analyzing your video. You&apos;ll see the
              results here in about a minute.
            </Typography>
          </Box>
        ) : currentParam === 'fillers' ? (
          <>
            <Box display={'flex'} gap={2}>
              {fillers &&
              Object.keys(fillers) &&
              Array.isArray(Object.keys(fillers)) &&
              Object.keys(fillers)?.length > 0 ? (
                Object.keys(fillers)?.map((filler, index) => (
                  <Typography key={index} sx={{}}>
                    {filler}{' '}
                    <Chip
                      label={fillers?.[filler]}
                      size="small"
                      color="primary"
                    ></Chip>
                  </Typography>
                ))
              ) : (
                <Typography sx={{}} color={'text.secondary'}>
                  No filler words
                </Typography>
              )}
            </Box>
            <Typography variant="body1">
              {fillerTranscript
                ? parse(
                    fillerTranscript?.replace(
                      fillerWordsRegexp,
                      (highlight) => {
                        return `<mark>
                                        ${highlight
                                          .split('{')
                                          .join('')
                                          .split('}')
                                          .join('')}
                                      </mark>`;
                      },
                    ),
                  )
                : ''}
            </Typography>
          </>
        ) : currentParam === 'repeatitions' ? (
          <>
            <Box display={'flex'} gap={2}>
              {repetitions &&
              Object.keys(repetitions) &&
              Array.isArray(Object.keys(repetitions)) &&
              Object.keys(repetitions)?.length > 0 ? (
                Object.keys(repetitions)?.map((filler, index) => (
                  <Typography key={index} sx={{}}>
                    {filler}{' '}
                    <Chip
                      label={repetitions?.[filler]}
                      size="small"
                      color="primary"
                    ></Chip>
                  </Typography>
                ))
              ) : (
                <Typography sx={{}} color={'text.secondary'}>
                  No repeated words
                </Typography>
              )}
            </Box>
            <Typography variant="body1">
              {repetitionsTranscript
                ? parse(
                    repetitionsTranscript?.replace(
                      repeatWordsRegexp,
                      (highlight) => {
                        return `<mark>
                                        ${highlight
                                          .split('{')
                                          .join('')
                                          .split('}')
                                          .join('')}
                                      </mark>`;
                      },
                    ),
                  )
                : ''}
            </Typography>
          </>
        ) : currentParam === 'pauses' ? (
          <>
            <Box display={'flex'} gap={2}>
              {pauseInfo?.count && pauseInfo?.count > 0 ? (
                <Typography>{`${pauseInfo?.count} big pauses`}</Typography>
              ) : (
                <Typography sx={{}} color={'text.secondary'}>
                  No big pauses
                </Typography>
              )}
            </Box>
            <Typography variant="body1">
              {pauseInfo?.transcript
                ? parse(
                    pauseInfo?.transcript?.replace(/\$/g, (highlight) => {
                      return `<mark></mark>`;
                    }),
                  )
                : ''}
            </Typography>
          </>
        ) : currentParam === 'pace' ? (
          <>
            <LineGraph
              labels={
                Object.keys(paceArr)?.length > 15
                  ? [
                      ...new Set(
                        Object.keys(paceArr).filter(
                          (n) =>
                            n %
                              (2 +
                                Math.floor(
                                  Object.keys(paceArr)?.length / 30,
                                )) ===
                            0,
                        ),
                      ),
                    ]
                  : Object.keys(paceArr)
              }
              data={
                Object.values(paceArr)?.length > 15
                  ? Object.values(paceArr)
                      .reduce((acc, curr, index) => {
                        const averageValue =
                          Object.values(paceArr)
                            ?.slice(
                              index *
                                (2 +
                                  Math.floor(
                                    Object.keys(paceArr)?.length / 30,
                                  )),
                              (2 +
                                Math.floor(Object.keys(paceArr)?.length / 30)) *
                                (index + 1),
                            )
                            ?.reduce((acc, curr) => acc + curr, 0) /
                          (2 + Math.floor(Object.keys(paceArr)?.length / 30));
                        acc.push(averageValue);
                        return acc;
                      }, [])
                      .slice(
                        0,
                        Object.keys(paceArr).filter(
                          (n) =>
                            n %
                              (2 +
                                Math.floor(
                                  Object.keys(paceArr)?.length / 30,
                                )) ===
                            0,
                        ).length,
                      )
                  : Object.values(paceArr)
              }
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

ReportTabs.propTypes = {
  currentParam: PropTypes.string,
  setCurrentParam: PropTypes.func,
  fillers: PropTypes.object,
  fillerTranscript: PropTypes.string,
  fillerWordsRegexp: PropTypes.any,
  repetitions: PropTypes.object,
  repetitionsTranscript: PropTypes.string,
  repeatWordsRegexp: PropTypes.any,
  pauseInfo: PropTypes.object,
  paceArr: PropTypes.object,
  showProgress: PropTypes.bool,
};

export default ReportTabs;
