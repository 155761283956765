const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  questions: [],
  emptyList: null,
  lastTime: null,
};

const getPresetQuestionsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GET_PRESET_QUESTIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GET_PRESET_QUESTIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GET_PRESET_QUESTIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        questions: action.payload.questions,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'UPDATE_PROMPTS_AFTER_DELETE': {
      const updatedQuestions = state.questions.filter(
        (pr) => pr.id !== action.payload.id,
      );
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        questions: updatedQuestions,
        emptyList: state.emptyList,
        lastTime: state.lastTime,
        error: false,
        errorText: '',
      });
    }
    case 'GET_PRESET_QUESTIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        questions: [],
        emptyList: false,
        lastTime: '',
      });
    default:
      return state;
  }
};

export default getPresetQuestionsReducer;
