import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const saveDefaultPrompt = (prompt, type, callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'SAVING_DEFAULT_PROMPT' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.post('/ads/ai-contest/prompts', prompt, {
          params: {
            type,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200) {
          dispatch({
            type: 'SAVING_DEFAULT_PROMPT_SUCCESSFUL',
          });
          if (typeof callback === 'function') callback();
        } else {
          dispatch({
            type: 'SAVING_DEFAULT_PROMPT_FAILED',
            payload: response.data,
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'SAVING_DEFAULT_PROMPT_FAILED',
          payload:
            e.response.status === 400 ? 'Failed to save prompt' : e.message,
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default saveDefaultPrompt;
