const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  bots: [],
  emptyList: null,
  lastTime: null,
};

const getAllBotsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_BOTS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_BOTS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_BOTS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        bots: action.payload.bots,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_BOTS_TO_ALL_BOTS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        bots: Array.isArray(action.payload.bots)
          ? state.bots
            ? state.bots.concat(action.payload.bots)
            : action.payload.bots
          : state.bots,
        emptyList:
          Array.isArray(action.payload.bots) &&
          (action.payload.bots.length === 0 ||
            action.payload.bots.length % 15 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'UPDATE_BOTS_AFTER_DELETE': {
      const updatedBots = state.bots.filter(
        (pr) => pr.id !== action.payload.id,
      );
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        bots: updatedBots,
        emptyList: state.emptyList,
        lastTime: state.lastTime,
        error: false,
        errorText: '',
      });
    }
    case 'GETTING_ALL_BOTS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        bots: [],
        emptyList: false,
        lastTime: '',
      });
    case 'GETTING_ALL_BOTS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllBotsReducer;
