import uuid from 'draft-js/lib/uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const getAIDataForContestCreation = (payload, callback, errorCallback) => {
  return async (dispatch) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.post('/ads/ai-contest', payload, {
          params: {
            'request-id': uuid(),
            sessionId: 'ksdhf',
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response, 'this is the response');
        if (response.status === 200) {
          if (payload.requestType === 'product_description') {
            await dispatch({
              type: 'FETCHING_AI_DATA_FOR_PRODUCT_DESCRIPTION_DONE',
              payload: {
                id: response?.data?.id,
                value: response?.data?.response,
              },
            });
          } else if (payload.requestType === 'scenario_description') {
            await dispatch({
              type: 'FETCHING_AI_DATA_FOR_PROSPECT_DETAILS_DONE',
              payload: {
                id: response?.data?.id,
                value: response?.data?.response,
              },
            });
          } else if (payload.requestType === 'product_description_mcq') {
            await dispatch({
              type: 'FETCHING_AI_DATA_FOR_PRODUCT_DESCRIPTION_MCQS_DONE',
              payload: {
                id: response?.data?.id,
                value: response?.data?.response,
              },
            });
          } else if (payload.requestType === 'cold_calling_script') {
            await dispatch({
              type: 'FETCHING_AI_DATA_FOR_COLD_CALLING_SCRIPT_DONE',
              payload: {
                id: response?.data?.id,
                value: response?.data?.response,
              },
            });
          } else if (payload.requestType === 'prospect_script') {
            await dispatch({
              type: 'FETCHING_AI_DATA_FOR_PROSPECT_SCRIPT_DONE',
              payload: {
                id: response?.data?.id,
                value: response?.data?.response,
              },
            });
          }
          if (typeof callback === 'function') callback(response?.data);
        } else {
          if (typeof errorCallback === 'function')
            errorCallback(response?.data?.message);
        }
      } catch (e) {
        console.log(e);
        if (typeof errorCallback === 'function')
          errorCallback(e?.response?.data?.message);
      }
      unsubscribe();
    });
  };
};

export default getAIDataForContestCreation;
