const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  links: [],
  emptyList: null,
  lastTime: null,
};

const getPracticeCallLinksReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GET_PRACTICE_CALL_LINKS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GET_PRACTICE_CALL_LINKS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GET_PRACTICE_CALL_LINKS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        links: action.payload.links,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    // case 'UPDATE_USER_IN_ALL_USERS': {
    //   const index = state.participantsDetails.findIndex(
    //     ({ participation }) =>
    //       participation.id === action.payload.participationId,
    //   );
    //   let newArr = [...state.participantsDetails];
    //   if (newArr?.[index]?.participation !== undefined) {
    //     newArr[index]['participation'][action.payload.property] =
    //       action.payload.value;
    //   } else {
    //     newArr[index]['participation'][action.payload.property] =
    //       action.payload.value;
    //   }
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     links: newArr,
    //     lastTime: state.lastTime,
    //     emptyList: false,
    //     error: false,
    //     errorText: '',
    //   });
    // }
    case 'ADD_LINKS_TO_ALL_PRACTICE_LINKS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        links: Array.isArray(action.payload.links)
          ? state.links
            ? state.links.concat(action.payload.links)
            : action.payload.links
          : state.links,
        emptyList:
          Array.isArray(action.payload.links) &&
          (action.payload.links.length === 0 ||
            action.payload.links.length % 25 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GET_PRACTICE_CALL_LINKS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        links: [],
        emptyList: false,
        lastTime: '',
      });
    case 'GET_PRACTICE_CALL_LINKS_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getPracticeCallLinksReducer;
