import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Slide,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Fade,
  Icon,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Modal,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Avatar,
  Rating,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from '@mui/material';

import { Experiment } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { CardWithColorAccent } from 'blocks/cards';
import {
  ArrowBack,
  Check,
  Circle,
  Close,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LiveHelp,
  PauseCircle,
  PlayArrow,
  PlayCircle,
  Redo,
  Replay,
  Settings,
  SettingsVoice,
  StopCircle,
  VideoCall,
  VideoCallRounded,
  Videocam,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import PropTypes from 'prop-types';
import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LineGraph from 'components/LineGraph/LineGraph';

const CompetitionReportEmployerView = ({ setPage, selectedRow }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [engagement, setEngagement] = useState(0);
  const [substance, setSubstance] = useState(0);
  const [feedback, setFeedback] = useState('');

  const [mcqs, setMCQs] = useState([]);
  const [mcqAnswers, setMcqAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [prospectVideo, setProspectVideo] = useState(null);
  const [section1VideoUrl, setSection1VideoUrl] = useState(null);
  const [section3VideoUrl, setSection3VideoUrl] = useState(null);

  const [reportTabValue, setReportTabValue] = useState('report');
  const [skillScoreExpanded, setSkillScoreExpanded] = useState(true);
  const [submissionExpanded, setSubmissionExpanded] = useState(true);
  const [submissionSection, setSubmissionSection] = useState('product_qna');
  const [mcqIndex, setMcqIndex] = useState(0);

  const [scriptReadingResults, setScriptReadingResult] = useState(null);
  const [pitchResult, setPitchResult] = useState(null);
  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [pitchFillerWords, setPitchFillerWords] = useState([]);
  const [pitchRepeatWords, setPitchRepeatWords] = useState([]);
  const [pitchFillerWordsRegexp, setPitchFillerWordsRegexp] = useState(null);
  const [pitchRepeatWordsRegexp, setPitchRepeatWordsRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('fillers');
  const [currentParam1, setCurrentParam1] = useState('fillers');
  const { competitionDetails } = useSelector(
    (state) => state.getParticipantsByCompetitionIdState,
  );
  const [prospectVideoUrl, setProspectVideoUrl] = useState('');
  const [showProspectVideo, setShowProspectVideo] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/cs/participation/${selectedRow?.id}/feedback-and-rating`,
            {
              feedbackRequestDto: {
                addUpdateFeedback: true,
                feedback: feedback,
              },
              scoreRequestDto: {
                engagement: engagement * 10,
                substance: substance * 10,
              },
            },
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                'current-role': 'employer',
              },
            },
          );
          if (response.status === 200) {
            console.log('feedback submitted');
            try {
              const reqId1 = uuidv4();
              const response = await axios.get(
                `/cs/participation/${selectedRow?.id}/feedback-and-rating`,

                {
                  params: {
                    'request-id': reqId1,
                  },
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'current-role': 'employer',
                  },
                },
              );
              console.log(response);
            } catch (e) {
              console.log('error');
            }
          } else {
            console.log('error');
          }
        } catch (e) {
          console.log('error');
        }
        unsubscribe();
      });
    } catch (e) {
      console.log('error');
    }
  };

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        let unsubscribe = onAuthStateChanged(auth, async (user) => {
          const token = await user?.getIdToken();
          const userInfo = localStorage.getItem('userInfo');
          const userId = JSON.parse(userInfo)?.id;
          try {
            const reqId1 = uuidv4();
            const response = await axios.get(
              `/cs/participation/${selectedRow?.id}/feedback-and-rating`,

              {
                params: {
                  'request-id': reqId1,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  'current-role': 'employer',
                },
              },
            );
            if (response.status === 200) {
              setFeedback(response?.data?.data?.[0]?.feedback);
            }
          } catch (e) {
            console.log('error');
          }
          unsubscribe();
        });
      } catch (e) {
        console.log('error');
      }
    };
    fetchFeedbacks();
  }, []);

  useEffect(() => {
    setScriptReadingResult(
      selectedRow?.participationReport?.reports?.[0] || null,
    );
    setPitchResult(selectedRow?.participationReport?.reports?.[1] || null);
    if (selectedRow?.participationReport?.reports?.[0]?.videoUrl) {
      setSection1VideoUrl(
        selectedRow?.participationReport?.reports?.[0]?.videoUrl,
      );
    }
    if (selectedRow?.participationReport?.reports?.[1]?.videoUrl) {
      setSection3VideoUrl(
        selectedRow?.participationReport?.reports?.[1]?.videoUrl,
      );
    }
    if (
      selectedRow?.sections?.[1]?.participationSectionQuestions &&
      Array.isArray(
        selectedRow?.sections?.[1]?.participationSectionQuestions,
      ) &&
      selectedRow?.sections?.[1]?.participationSectionQuestions?.length > 0
    ) {
      setMCQs(selectedRow?.sections?.[1]?.participationSectionQuestions);
    }
    if (
      selectedRow?.sections?.[1]?.sectionAnswers &&
      Array.isArray(selectedRow?.sections?.[1]?.sectionAnswers) &&
      selectedRow?.sections?.[1]?.sectionAnswers?.length > 0
    ) {
      setMcqAnswers(selectedRow?.sections?.[1]?.sectionAnswers);
    }
    if (
      selectedRow?.sections?.[1]?.customFields?.prospectVideo?.videoUrls?.[0]
        ?.url
    ) {
      setProspectVideoUrl(
        selectedRow?.sections?.[1]?.customFields?.prospectVideo?.videoUrls?.[0]
          ?.url,
      );
    }
  }, [selectedRow]);

  useEffect(() => {
    if (
      scriptReadingResults?.fillerInfo?.fillers &&
      Object.keys(scriptReadingResults?.fillerInfo?.fillers) &&
      Object.keys(scriptReadingResults?.fillerInfo?.fillers).length > 0
    ) {
      setScriptReadingFillerWords(
        Object.keys(scriptReadingResults?.fillerInfo?.fillers),
      );
    }
    if (
      scriptReadingResults?.repetitionInfo?.repetitions &&
      Object.keys(scriptReadingResults?.repetitionInfo?.repetitions) &&
      Object.keys(scriptReadingResults?.repetitionInfo?.repetitions).length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(scriptReadingResults?.repetitionInfo?.repetitions),
      );
    }
  }, [scriptReadingResults]);

  useEffect(() => {
    if (
      pitchResult?.fillerInfo?.fillers &&
      Object.keys(pitchResult?.fillerInfo?.fillers) &&
      Object.keys(pitchResult?.fillerInfo?.fillers).length > 0
    ) {
      setPitchFillerWords(Object.keys(pitchResult?.fillerInfo?.fillers));
    }
    if (
      pitchResult?.repetitionInfo?.repetitions &&
      Object.keys(pitchResult?.repetitionInfo?.repetitions) &&
      Object.keys(pitchResult?.repetitionInfo?.repetitions).length > 0
    ) {
      setPitchRepeatWords(
        Object.keys(pitchResult?.repetitionInfo?.repetitions),
      );
    }
  }, [pitchResult]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  useEffect(() => {
    if (
      pitchFillerWords &&
      Array.isArray(pitchFillerWords) &&
      pitchFillerWords.length > 0
    ) {
      setPitchFillerWordsRegexp(
        new RegExp(pitchFillerWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [pitchFillerWords]);

  useEffect(() => {
    if (
      pitchRepeatWords &&
      Array.isArray(pitchRepeatWords) &&
      pitchRepeatWords.length > 0
    ) {
      setPitchRepeatWordsRegexp(
        new RegExp(pitchRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [pitchRepeatWords]);

  // console.log('data', selectedRow);
  // console.log('data', mcqs);
  // console.log('data', mcqAnswers);
  // console.log('data', correctAnswers);
  console.log(pitchResult);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 1,
        }}
      >
        <ArrowBackIcon
          onClick={() => setPage('allParticipants')}
          color="#ffffff"
          sx={{ cursor: 'pointer' }}
        />
        <Box>
          <Typography
            variant="h6"
            color={theme.palette.primary.main}
            alignSelf={'end'}
          >
            {`${selectedRow?.candidateFakeName
              ?.split(' ')?.[0]
              ?.split('')?.[0]
              ?.toUpperCase()}${selectedRow?.candidateFakeName
              ?.split(' ')?.[0]
              ?.slice(1)} ${selectedRow?.candidateFakeName
              ?.split(' ')?.[1]
              ?.split('')?.[0]
              ?.toUpperCase()}${selectedRow?.candidateFakeName
              ?.split(' ')?.[1]
              ?.slice(1)}`}{' '}
          </Typography>
          <Typography
            variant="body1"
            color={theme.palette.primary.main}
            alignSelf={'end'}
          >
            {competitionDetails?.title}{' '}
          </Typography>
        </Box>
      </Box>
      {/* <Tabs
        value={reportTabValue}
        onChange={(e, newValue) => setReportTabValue(newValue)}
        aria-label="Report tabs"
        orientation={'horizontal'}
        // indicatorColor={theme.palette.background.default}
        sx={{
          border: `1px solid ${theme.palette.background.level1}`,
          borderRadius: 2,
        }}
      >
        <Tab
          value={'report'}
          label={<Typography variant="body1">Report</Typography>}
          id={`simple-tab-${0}`}
          aria-controls={`simple-tabpanel-${0}`}
          sx={{}}
          // disabled
        />
        <Tab
          value={'submission'}
          label={<Typography variant="body1">Submission</Typography>}
          id={`simple-tab-${5}`}
          aria-controls={`simple-tabpanel-${5}`}
          sx={{}}
        />
      </Tabs> */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          marginTop: 1,
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            '& .MuiAccordion-root': {
              boxShadow: 'none',
            },
          }}
        >
          <Accordion
            expanded={skillScoreExpanded}
            onChange={() => setSkillScoreExpanded((prev) => !prev)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="skill-score-content"
              id="skill-score-header"
              sx={{ height: '30px' }}
            >
              <Typography variant="body1" fontWeight={'bold'}>
                Skill Scores
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box
                  sx={{
                    width: 1,
                    height: 1,
                    padding: 1,
                    borderRadius: 2,
                    border: '1px solid #d3d3d3',
                  }}
                >
                  <Bar
                    options={{
                      responsive: true,
                      layout: {
                        padding: 10,
                      },
                      plugins: {
                        legend: {
                          display: false,
                          // position: 'top',
                        },
                        tooltip: {
                          enabled: true,
                        },

                        title: {
                          display: false,
                          text: 'Skills Score',
                        },
                        datalabels: {
                          color: 'white',
                          font: {
                            weight: 'bold',
                            size: 16,
                          },
                          rotation: -90,
                          formatter: function(value, context) {
                            return `${value}`;
                          },
                        },
                      },
                      elements: {
                        bar: {
                          borderRadius: 6,
                          borderSkipped: false,
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          max: 100,
                        },
                      },
                    }}
                    data={{
                      labels: [
                        'Clarity',
                        'Pace',
                        'Fluency',
                        'Identifying Needs',
                        'Product Knowledge',
                        'Confidence',
                        'Engaging',
                        'Substance',
                      ],
                      datasets: [
                        {
                          label: 'Score',
                          data: [
                            selectedRow?.report?.clarity === undefined
                              ? '-'
                              : Math.round(selectedRow?.report?.clarity),
                            selectedRow?.report?.pace === undefined
                              ? '-'
                              : Math.round(selectedRow?.report?.pace),
                            selectedRow?.report?.fluency === undefined
                              ? '-'
                              : Math.round(selectedRow?.report?.fluency),
                            selectedRow?.report?.needs === undefined
                              ? '-'
                              : Math.round(selectedRow?.report?.needs),
                            selectedRow?.report?.productKnowledge === undefined
                              ? '-'
                              : Math.round(
                                  selectedRow?.report?.productKnowledge,
                                ),
                            selectedRow?.report?.speechConfidence === undefined
                              ? '-'
                              : Math.round(
                                  selectedRow?.report?.speechConfidence,
                                ),
                            selectedRow?.report?.engagement === undefined
                              ? '-'
                              : Math.round(selectedRow?.report?.engagement),
                            selectedRow?.report?.substance === undefined
                              ? '-'
                              : Math.round(selectedRow?.report?.substance),
                          ],
                          backgroundColor: theme.palette.primary.main,
                          barThickness: 50,
                          pointStyle: 'circle',
                          fill: true,
                        },
                      ],
                    }}
                  />
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={submissionExpanded}
            onChange={() => setSubmissionExpanded((prev) => !prev)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="submission-content"
              id="submission-header"
              sx={{ height: '30px' }}
            >
              <Typography variant="body1" fontWeight={'bold'}>
                Submissions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{
                    border: `1px solid ${theme.palette.background.level1}`,
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                  }}
                >
                  <Tabs
                    value={submissionSection}
                    onChange={(e, newValue) => {
                      setMcqIndex(0);
                      setSubmissionSection(newValue);
                    }}
                    aria-label="Submission tabs"
                    orientation={'horizontal'}
                  >
                    <Tab
                      value={'product_qna'}
                      label={
                        <Typography variant="body1">Product Q&A</Typography>
                      }
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{}}
                    />
                    <Tab
                      value={'script_reading'}
                      label={
                        <Typography variant="body1">Script Reading</Typography>
                      }
                      id={`simple-tab-${1}`}
                      aria-controls={`simple-tabpanel-${1}`}
                      sx={{}}
                      // disabled
                    />
                    <Tab
                      value={'prospect_qna'}
                      label={
                        <Typography variant="body1">Prospect Q&A</Typography>
                      }
                      id={`simple-tab-${2}`}
                      aria-controls={`simple-tabpanel-${2}`}
                      sx={{}}
                    />
                    <Tab
                      value={'pitch'}
                      label={<Typography variant="body1">Pitch</Typography>}
                      id={`simple-tab-${3}`}
                      aria-controls={`simple-tabpanel-${3}`}
                      sx={{}}
                    />
                  </Tabs>
                  <Button
                    variant="text"
                    startIcon={<Videocam />}
                    size="small"
                    onClick={() => setShowProspectVideo(prospectVideoUrl)}
                  >
                    Prospect Video
                  </Button>
                </Box>
                {submissionSection === 'script_reading' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'stretch',
                      padding: 2,
                    }}
                  >
                    <Box sx={{ flex: 1, borderRadius: 8 }}>
                      <video
                        src={scriptReadingResults?.videoUrl}
                        controls
                        style={{
                          objectFit: 'contain',
                          backgroundColor: 'rgba(0,0,0,1)',
                          width: '100%',
                          height: '100%',
                          borderRadius: 8,
                        }}
                        onError={(e) => {
                          console.log(e);
                        }}
                        controlsList="nofullscreen nodownload"
                        disablePictureInPicture
                      ></video>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        flex: 1,
                      }}
                    >
                      <Tabs
                        value={currentParam}
                        onChange={(e, newValue) => setCurrentParam(newValue)}
                        aria-label="Parameter Tabs"
                        orientation={'horizontal'}
                        // indicatorColor={theme.palette.background.default}
                        variant="scrollable"
                        // scrollButtons={!isMd}
                        allowScrollButtonsMobile
                      >
                        <Tab
                          value={'fillers'}
                          label={
                            <Typography variant="body1">
                              Filler Words
                            </Typography>
                          }
                          id={`simple-tab-${0}`}
                          aria-controls={`simple-tabpanel-${0}`}
                          sx={{}}
                        />
                        <Tab
                          value={'repeatitions'}
                          label={
                            <Typography variant="body1">
                              Repeated Words
                            </Typography>
                          }
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                        <Tab
                          value={'pauses'}
                          label={
                            <Typography variant="body1">Big Pauses</Typography>
                          }
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                        <Tab
                          value={'pace'}
                          label={<Typography variant="body1">Pace</Typography>}
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                      </Tabs>
                      <Box
                        sx={{
                          padding: 2,
                          border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          mark: {
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            padding: '2px 6px',
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                          maxHeight: '420px',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                        }}
                      >
                        {currentParam === 'fillers' ? (
                          <>
                            <Box display={'flex'} gap={2}>
                              {scriptReadingResults?.fillerInfo?.fillers &&
                              Object.keys(
                                scriptReadingResults?.fillerInfo?.fillers,
                              ) &&
                              Array.isArray(
                                Object.keys(
                                  scriptReadingResults?.fillerInfo?.fillers,
                                ),
                              ) &&
                              Object.keys(
                                scriptReadingResults?.fillerInfo?.fillers,
                              )?.length > 0 ? (
                                Object.keys(
                                  scriptReadingResults?.fillerInfo?.fillers,
                                )?.map((filler, index) => (
                                  <Typography key={index} sx={{}}>
                                    {filler}{' '}
                                    <Chip
                                      label={
                                        scriptReadingResults?.fillerInfo
                                          ?.fillers?.[filler]
                                      }
                                      size="small"
                                      color="primary"
                                    ></Chip>
                                  </Typography>
                                ))
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No filler words
                                </Typography>
                              )}
                            </Box>
                            <Typography variant="body1">
                              {scriptReadingResults?.fillerInfo?.transcript
                                ? parse(
                                    scriptReadingResults?.fillerInfo?.transcript?.replace(
                                      fillerWordsRegexp,
                                      (highlight) => {
                                        return `<mark>
                                    ${highlight
                                      .split('{')
                                      .join('')
                                      .split('}')
                                      .join('')}
                                  </mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam === 'repeatitions' ? (
                          <>
                            <Box display={'flex'} gap={2}>
                              {scriptReadingResults?.repetitionInfo
                                ?.repetitions &&
                              Object.keys(
                                scriptReadingResults?.repetitionInfo
                                  ?.repetitions,
                              ) &&
                              Array.isArray(
                                Object.keys(
                                  scriptReadingResults?.repetitionInfo
                                    ?.repetitions,
                                ),
                              ) &&
                              Object.keys(
                                scriptReadingResults?.repetitionInfo
                                  ?.repetitions,
                              )?.length > 0 ? (
                                Object.keys(
                                  scriptReadingResults?.repetitionInfo
                                    ?.repetitions,
                                )?.map((filler, index) => (
                                  <Typography key={index} sx={{}}>
                                    {filler}{' '}
                                    <Chip
                                      label={
                                        scriptReadingResults?.repetitionInfo
                                          ?.repetitions?.[filler]
                                      }
                                      size="small"
                                      color="primary"
                                    ></Chip>
                                  </Typography>
                                ))
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No repeated words
                                </Typography>
                              )}
                            </Box>
                            <Typography variant="body1">
                              {scriptReadingResults?.repetitionInfo?.transcript
                                ? parse(
                                    scriptReadingResults?.repetitionInfo?.transcript?.replace(
                                      repeatWordsRegexp,
                                      (highlight) => {
                                        return `<mark>
                                    ${highlight
                                      .split('{')
                                      .join('')
                                      .split('}')
                                      .join('')}
                                  </mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam === 'pauses' ? (
                          <>
                            <Box display={'flex'} gap={2}>
                              {scriptReadingResults?.pauseInfo?.count &&
                              scriptReadingResults?.pauseInfo?.count > 0 ? (
                                <Typography>
                                  {`${scriptReadingResults?.pauseInfo?.count} big pauses`}
                                </Typography>
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No big pauses
                                </Typography>
                              )}
                            </Box>
                            <Typography variant="body1">
                              {scriptReadingResults?.pauseInfo?.transcript
                                ? parse(
                                    scriptReadingResults?.pauseInfo?.transcript?.replace(
                                      /\$/g,
                                      (highlight) => {
                                        return `<mark></mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam === 'pace' ? (
                          <>
                            <LineGraph
                              labels={
                                Object.keys(scriptReadingResults?.pace)
                                  ?.length > 15
                                  ? [
                                      ...new Set(
                                        Object.keys(
                                          scriptReadingResults?.pace,
                                        ).filter(
                                          (n) =>
                                            n %
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    scriptReadingResults?.pace,
                                                  )?.length / 30,
                                                )) ===
                                            0,
                                        ),
                                      ),
                                    ]
                                  : Object.keys(scriptReadingResults?.pace)
                              }
                              data={
                                Object.values(scriptReadingResults?.pace)
                                  ?.length > 15
                                  ? Object.values(scriptReadingResults?.pace)
                                      .reduce((acc, curr, index) => {
                                        const averageValue =
                                          Object.values(
                                            scriptReadingResults?.pace,
                                          )
                                            ?.slice(
                                              index *
                                                (2 +
                                                  Math.floor(
                                                    Object.keys(
                                                      scriptReadingResults?.pace,
                                                    )?.length / 30,
                                                  )),
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    scriptReadingResults?.pace,
                                                  )?.length / 30,
                                                )) *
                                                (index + 1),
                                            )
                                            ?.reduce(
                                              (acc, curr) => acc + curr,
                                              0,
                                            ) /
                                          (2 +
                                            Math.floor(
                                              Object.keys(
                                                scriptReadingResults?.pace,
                                              )?.length / 30,
                                            ));
                                        acc.push(averageValue);
                                        return acc;
                                      }, [])
                                      .slice(
                                        0,
                                        Object.keys(
                                          scriptReadingResults?.pace,
                                        ).filter(
                                          (n) =>
                                            n %
                                              (2 +
                                                Math.floor(
                                                  Object.keys(
                                                    scriptReadingResults?.pace,
                                                  )?.length / 30,
                                                )) ===
                                            0,
                                        ).length,
                                      )
                                  : Object.values(scriptReadingResults?.pace)
                              }
                            />
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                ) : submissionSection === 'product_qna' ||
                  submissionSection === 'prospect_qna' ? (
                  <Box p={2} display={'flex'} gap={2}>
                    <Box sx={{ flex: 2 }}>
                      <Stepper
                        activeStep={mcqIndex}
                        // connector={<QontoConnector />}
                        orientation="horizontal"
                      >
                        {mcqs &&
                          mcqs?.filter((mcq) =>
                            submissionSection === 'product_qna'
                              ? mcq?.domain === 'product_knowledge'
                              : mcq?.domain === 'requirement_gathering',
                          ) &&
                          mcqs?.filter((mcq) =>
                            submissionSection === 'product_qna'
                              ? mcq?.domain === 'product_knowledge'
                              : mcq?.domain === 'requirement_gathering',
                          ).length > 0 &&
                          mcqs
                            ?.filter((mcq) =>
                              submissionSection === 'product_qna'
                                ? mcq?.domain === 'product_knowledge'
                                : mcq?.domain === 'requirement_gathering',
                            )
                            .map((mcq, index) => {
                              return (
                                <Step key={mcq?.id}>
                                  <StepButton
                                    onClick={() => setMcqIndex(index)}
                                    disabled={false}
                                  >
                                    <StepLabel
                                      StepIconComponent={({
                                        active,
                                        completed,
                                        className,
                                        ...rest
                                      }) => (
                                        <Box
                                          className={className}
                                          sx={{
                                            backgroundColor:
                                              mcq?.answersIndex?.[0] ===
                                              mcqAnswers?.filter(
                                                (m) => m?.id === mcq?.id,
                                              )?.[0]?.answersIndex?.[0]
                                                ? theme.palette.primary.main
                                                : theme.palette.error.light,
                                            width: active ? 35 : 25,
                                            height: active ? 35 : 25,
                                            display: 'flex',
                                            borderRadius: '50%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#fff',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <Typography
                                            variant={active ? 'body1' : 'body2'}
                                            fontWeight={active ? 'bold' : null}
                                          >
                                            {index + 1}
                                          </Typography>
                                        </Box>
                                      )}
                                    ></StepLabel>
                                  </StepButton>
                                </Step>
                              );
                            })}
                      </Stepper>
                      <Box mt={2}>
                        <Typography variant="body1" fontWeight={'bold'}>
                          Question{' '}
                          {mcqIndex > 8
                            ? `${mcqIndex + 1}`
                            : `0${mcqIndex + 1}`}
                        </Typography>
                        <Typography variant="body2" mt={2}>
                          {
                            mcqs?.filter((mcq) =>
                              submissionSection === 'product_qna'
                                ? mcq?.domain === 'product_knowledge'
                                : mcq?.domain === 'requirement_gathering',
                            )?.[mcqIndex]?.question
                          }
                        </Typography>
                        <Typography variant="body1" fontWeight={'bold'} mt={2}>
                          Options
                        </Typography>
                        {Object.values(
                          mcqs?.filter((mcq) =>
                            submissionSection === 'product_qna'
                              ? mcq?.domain === 'product_knowledge'
                              : mcq?.domain === 'requirement_gathering',
                          )?.[mcqIndex]?.options || {},
                        )?.map((option, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Box
                                sx={{
                                  flex: 1,
                                  background:
                                    mcqAnswers?.filter((mcq) =>
                                      submissionSection === 'product_qna'
                                        ? mcq?.domain === 'product_knowledge'
                                        : mcq?.domain ===
                                          'requirement_gathering',
                                    )?.[mcqIndex]?.answersIndex?.[0] === index
                                      ? mcqAnswers?.filter((mcq) =>
                                          submissionSection === 'product_qna'
                                            ? mcq?.domain ===
                                              'product_knowledge'
                                            : mcq?.domain ===
                                              'requirement_gathering',
                                        )?.[mcqIndex]?.answersIndex?.[0] ===
                                        mcqs?.filter((mcq) =>
                                          submissionSection === 'product_qna'
                                            ? mcq?.domain ===
                                              'product_knowledge'
                                            : mcq?.domain ===
                                              'requirement_gathering',
                                        )?.[mcqIndex]?.answersIndex?.[0]
                                        ? theme.palette.primary.main
                                        : theme.palette.error.light
                                      : theme.palette.background.default,
                                  color:
                                    mcqAnswers?.filter((mcq) =>
                                      submissionSection === 'product_qna'
                                        ? mcq?.domain === 'product_knowledge'
                                        : mcq?.domain ===
                                          'requirement_gathering',
                                    )?.[mcqIndex]?.answersIndex?.[0] === index
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  border:
                                    index ===
                                    mcqs?.filter((mcq) =>
                                      submissionSection === 'product_qna'
                                        ? mcq?.domain === 'product_knowledge'
                                        : mcq?.domain ===
                                          'requirement_gathering',
                                    )?.[mcqIndex]?.answersIndex?.[0]
                                      ? `3px solid ${theme.palette.primary.main}`
                                      : 'none',
                                }}
                                p={1}
                                borderRadius={1}
                                mt={2}
                                data-trackid={`select_mcq_option_${index + 1}`}
                              >
                                <Typography variant="body2">
                                  <span style={{ fontWeight: 'bold' }}>
                                    {index + 1}
                                    {'). '}
                                  </span>
                                  {option}
                                </Typography>
                              </Box>
                              {mcqs?.filter((mcq) =>
                                submissionSection === 'product_qna'
                                  ? mcq?.domain === 'product_knowledge'
                                  : mcq?.domain === 'requirement_gathering',
                              )?.[mcqIndex]?.answersIndex?.[0] === index &&
                              index ===
                                mcqs
                                  ?.filter((mcq) =>
                                    submissionSection === 'product_qna'
                                      ? mcq?.domain === 'product_knowledge'
                                      : mcq?.domain === 'requirement_gathering',
                                  )
                                  ?.[mcqIndex]?.options?.findIndex(
                                    (ele) => ele === option,
                                  ) &&
                              mcqAnswers?.filter((mcq) =>
                                submissionSection === 'product_qna'
                                  ? mcq?.domain === 'product_knowledge'
                                  : mcq?.domain === 'requirement_gathering',
                              )?.[mcqIndex]?.answersIndex?.[0] === index &&
                              mcqAnswers?.filter((mcq) =>
                                submissionSection === 'product_qna'
                                  ? mcq?.domain === 'product_knowledge'
                                  : mcq?.domain === 'requirement_gathering',
                              )?.[mcqIndex]?.answersIndex?.[0] ===
                                mcqs?.filter((mcq) =>
                                  submissionSection === 'product_qna'
                                    ? mcq?.domain === 'product_knowledge'
                                    : mcq?.domain === 'requirement_gathering',
                                )?.[mcqIndex]?.answersIndex?.[0] ? (
                                <Check
                                  sx={{
                                    marginTop: 2,
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              ) : mcqAnswers?.filter((mcq) =>
                                  submissionSection === 'product_qna'
                                    ? mcq?.domain === 'product_knowledge'
                                    : mcq?.domain === 'requirement_gathering',
                                )?.[mcqIndex]?.answersIndex?.[0] === index &&
                                mcqAnswers?.filter((mcq) =>
                                  submissionSection === 'product_qna'
                                    ? mcq?.domain === 'product_knowledge'
                                    : mcq?.domain === 'requirement_gathering',
                                )?.[mcqIndex]?.answersIndex?.[0] !==
                                  mcqs?.filter((mcq) =>
                                    submissionSection === 'product_qna'
                                      ? mcq?.domain === 'product_knowledge'
                                      : mcq?.domain === 'requirement_gathering',
                                  )?.[mcqIndex]?.answersIndex?.[0] ? (
                                <Close
                                  sx={{
                                    marginTop: 2,
                                    color: theme.palette.error.light,
                                  }}
                                />
                              ) : (
                                <Close
                                  sx={{
                                    marginTop: 2,
                                    color: theme.palette.error.light,
                                    visibility: 'hidden',
                                  }}
                                />
                              )}
                            </Box>
                          );
                        })}
                        {/* <Typography variant="body1" fontWeight={'bold'} mt={1}>
                          Correct Answer:{' '}
                          {correctAnswers[mcqs?.[mcqIndex]?.questionId]}
                        </Typography> */}
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={mcqIndex === 0}
                            onClick={(e) => {
                              setMcqIndex(mcqIndex - 1);
                            }}
                          >
                            Previous
                          </Button>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={(e) => {
                                setMcqIndex(mcqIndex + 1);
                              }}
                              disabled={
                                mcqIndex ===
                                mcqs?.filter((mcq) =>
                                  submissionSection === 'product_qna'
                                    ? mcq?.domain === 'product_knowledge'
                                    : mcq?.domain === 'requirement_gathering',
                                )?.length -
                                  1
                              }
                            >
                              Next
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      {/* <Box>
                        <video
                          src={prospectVideo}
                          controls
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 8,
                          }}
                          onError={(e) => console.log(e)}
                        ></video>
                      </Box> */}
                    </Box>
                  </Box>
                ) : submissionSection === 'pitch' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'stretch',
                      padding: 2,
                    }}
                  >
                    <Box sx={{ flex: 1, borderRadius: 8 }}>
                      <video
                        src={pitchResult?.videoUrl || ''}
                        controls
                        style={{
                          objectFit: 'contain',
                          backgroundColor: 'rgba(0,0,0,1)',
                          width: '100%',
                          height: '100%',
                          borderRadius: 8,
                        }}
                        onError={(e) => {
                          console.log(e);
                        }}
                        controlsList="nofullscreen nodownload"
                        disablePictureInPicture
                      ></video>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        flex: 1,
                      }}
                    >
                      <Tabs
                        value={currentParam1}
                        onChange={(e, newValue) => setCurrentParam1(newValue)}
                        aria-label="Parameter Tabs"
                        orientation={'horizontal'}
                        // indicatorColor={theme.palette.background.default}
                        variant="scrollable"
                        // scrollButtons={!isMd}
                        allowScrollButtonsMobile
                      >
                        <Tab
                          value={'fillers'}
                          label={
                            <Typography variant="body1">
                              Filler Words
                            </Typography>
                          }
                          id={`simple-tab-${0}`}
                          aria-controls={`simple-tabpanel-${0}`}
                          sx={{}}
                        />
                        <Tab
                          value={'repeatitions'}
                          label={
                            <Typography variant="body1">
                              Repeated Words
                            </Typography>
                          }
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                        <Tab
                          value={'pauses'}
                          label={
                            <Typography variant="body1">Big Pauses</Typography>
                          }
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                        <Tab
                          value={'pace'}
                          label={<Typography variant="body1">Pace</Typography>}
                          id={`simple-tab-${5}`}
                          aria-controls={`simple-tabpanel-${5}`}
                          sx={{}}
                        />
                      </Tabs>
                      <Box
                        sx={{
                          padding: 2,
                          border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          mark: {
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            padding: '2px 6px',
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        {currentParam1 === 'fillers' ? (
                          <>
                            <Box display={'flex'} gap={2}>
                              {pitchResult?.fillerInfo?.fillers &&
                              Object.keys(pitchResult?.fillerInfo?.fillers) &&
                              Array.isArray(
                                Object.keys(pitchResult?.fillerInfo?.fillers),
                              ) &&
                              Object.keys(pitchResult?.fillerInfo?.fillers)
                                ?.length > 0 ? (
                                Object.keys(
                                  pitchResult?.fillerInfo?.fillers,
                                )?.map((filler, index) => (
                                  <Typography key={index} sx={{}}>
                                    {filler}{' '}
                                    <Chip
                                      label={
                                        pitchResult?.fillerInfo?.fillers?.[
                                          filler
                                        ]
                                      }
                                      size="small"
                                      color="primary"
                                    ></Chip>
                                  </Typography>
                                ))
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No filler words
                                </Typography>
                              )}
                            </Box>
                            <Typography variant="body1">
                              {pitchResult?.fillerInfo?.transcript
                                ? parse(
                                    pitchResult?.fillerInfo?.transcript?.replace(
                                      pitchFillerWordsRegexp,
                                      (highlight) => {
                                        return `<mark>
                                        ${highlight
                                          .split('{')
                                          .join('')
                                          .split('}')
                                          .join('')}
                                      </mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam1 === 'repeatitions' ? (
                          <>
                            <Box display={'flex'} gap={2}>
                              {pitchResult?.repetitionInfo?.repetitions &&
                              Object.keys(
                                pitchResult?.repetitionInfo?.repetitions,
                              ) &&
                              Array.isArray(
                                Object.keys(
                                  pitchResult?.repetitionInfo?.repetitions,
                                ),
                              ) &&
                              Object.keys(
                                pitchResult?.repetitionInfo?.repetitions,
                              )?.length > 0 ? (
                                Object.keys(
                                  pitchResult?.repetitionInfo?.repetitions,
                                )?.map((filler, index) => (
                                  <Typography key={index} sx={{}}>
                                    {filler}{' '}
                                    <Chip
                                      label={
                                        pitchResult?.repetitionInfo
                                          ?.repetitions?.[filler]
                                      }
                                      size="small"
                                      color="primary"
                                    ></Chip>
                                  </Typography>
                                ))
                              ) : (
                                <Typography sx={{}} color={'text.secondary'}>
                                  No repeated words
                                </Typography>
                              )}
                            </Box>
                            <Typography variant="body1">
                              {pitchResult?.repetitionInfo?.transcript
                                ? parse(
                                    pitchResult?.repetitionInfo?.transcript?.replace(
                                      pitchRepeatWordsRegexp,
                                      (highlight) => {
                                        return `<mark>
                                        ${highlight
                                          .split('{')
                                          .join('')
                                          .split('}')
                                          .join('')}
                                      </mark>`;
                                      },
                                    ),
                                  )
                                : ''}
                            </Typography>
                          </>
                        ) : currentParam1 === 'pauses' ? (
                          <>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 700,
                              }}
                            >
                              Pauses
                            </Typography>
                            <Box display={'flex'} gap={2}>
                              <Typography
                                color={
                                  pitchResult?.noOfPauses
                                    ? 'text.primary'
                                    : 'text.secondary'
                                }
                              >
                                {pitchResult?.noOfPauses || 'No'} big pause
                                {pitchResult?.noOfPauses > 1 ? 's' : ''}
                              </Typography>
                            </Box>
                          </>
                        ) : currentParam1 === 'pace' ? (
                          <>
                            <LineGraph
                              labels={
                                Object.keys(pitchResult?.pace)?.length > 15
                                  ? [
                                      ...new Set(
                                        Object.keys(pitchResult?.pace).filter(
                                          (n) =>
                                            n %
                                              (2 +
                                                Math.floor(
                                                  Object.keys(pitchResult?.pace)
                                                    ?.length / 30,
                                                )) ===
                                            0,
                                        ),
                                      ),
                                    ]
                                  : Object.keys(pitchResult?.pace)
                              }
                              data={
                                Object.values(pitchResult?.pace)?.length > 15
                                  ? Object.values(pitchResult?.pace)
                                      .reduce((acc, curr, index) => {
                                        const averageValue =
                                          Object.values(pitchResult?.pace)
                                            ?.slice(
                                              index *
                                                (2 +
                                                  Math.floor(
                                                    Object.keys(
                                                      pitchResult?.pace,
                                                    )?.length / 30,
                                                  )),
                                              (2 +
                                                Math.floor(
                                                  Object.keys(pitchResult?.pace)
                                                    ?.length / 30,
                                                )) *
                                                (index + 1),
                                            )
                                            ?.reduce(
                                              (acc, curr) => acc + curr,
                                              0,
                                            ) /
                                          (2 +
                                            Math.floor(
                                              Object.keys(pitchResult?.pace)
                                                ?.length / 30,
                                            ));
                                        acc.push(averageValue);
                                        return acc;
                                      }, [])
                                      .slice(
                                        0,
                                        Object.keys(pitchResult?.pace).filter(
                                          (n) =>
                                            n %
                                              (2 +
                                                Math.floor(
                                                  Object.keys(pitchResult?.pace)
                                                    ?.length / 30,
                                                )) ===
                                            0,
                                        ).length,
                                      )
                                  : Object.values(pitchResult?.pace)
                              }
                            />
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                <Divider />
                {submissionSection === 'pitch' && pitchResult?.aiReview ? (
                  <Box
                    sx={{
                      padding: 1,
                      borderRadius: 2,
                      border: '1px solid #d3d3d3',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color={'text.Secondary'}
                      fontWeight={'bold'}
                    >
                      Feedbacks
                    </Typography>
                    {pitchResult?.aiReview ? (
                      <Typography variant="body1" color={'text.primary'}>
                        {pitchResult?.aiReview}
                      </Typography>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {/* <Box
            sx={{
              width: 1,
              padding: 2,
              borderRadius: 2,
              border: '1px solid #d3d3d3',
              paddingTop: 2,
              paddingBottom: 2,
              position: 'sticky',
            }}
          >
            <form onSubmit={handleSubmit}>
              <TextField
                label="Your Feedback"
                variant="outlined"
                name={'feedback'}
                multiline
                rows={3}
                fullWidth
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
              <Box pl={2} pr={2}>
                <Box sx={{ marginTop: 1, marginBottom: 1 }}>Engaging</Box>
                <Slider
                  step={1}
                  max={10}
                  min={0}
                  valueLabelDisplay="auto"
                  value={engagement}
                  onChange={(value, newValue) => {
                    setEngagement(newValue);
                  }}
                  marks={[
                    { value: 0, label: '0' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                    { value: 6, label: '6' },
                    { value: 7, label: '7' },
                    { value: 8, label: '8' },
                    { value: 9, label: '9' },
                    { value: 10, label: '10' },
                  ]}
                />
                <Box sx={{ marginTop: 1, marginBottom: 1 }}>Substance</Box>
                <Slider
                  step={1}
                  max={10}
                  min={0}
                  valueLabelDisplay="auto"
                  value={substance}
                  onChange={(value, newValue) => {
                    setSubstance(newValue);
                  }}
                  marks={[
                    { value: 0, label: '0' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                    { value: 6, label: '6' },
                    { value: 7, label: '7' },
                    { value: 8, label: '8' },
                    { value: 9, label: '9' },
                    { value: 10, label: '10' },
                  ]}
                />
              </Box>
              <Box
                sx={{
                  width: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 2,
                }}
              >
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          </Box> */}

          <Modal
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            open={Boolean(showProspectVideo)}
            onClose={(e, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                return;
              }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                // boxShadow: theme.shadows[5],
                outline: 'none',
                maxHeight: '100%',
                maxWidth: 600,
                minWidth: 600,
                overflow: 'visible',
                borderRadius: 1,
                padding: 2,
                position: 'relative',
              }}
            >
              <IconButton
                // data-trackid={'close_prospect_video_dialog'}
                sx={{
                  position: 'absolute',
                  top: -10,
                  right: -10,
                  padding: '4px',
                  background: theme.palette.background.default,
                  ':hover': {
                    backgroundColor: '#d3d3d3',
                  },
                }}
                onClick={(e) => {
                  // handleTrackEvent(e, `close_prospect_video_dialog`);
                  setShowProspectVideo('');
                }}
              >
                <Close sx={{ fontSize: 16 }} />
              </IconButton>
              <video
                src={showProspectVideo}
                controls
                style={{
                  objectFit: 'contain',
                  backgroundColor: 'rgba(0,0,0,1)',
                  width: '100%',
                  height: '100%',
                  borderRadius: 8,
                }}
                onError={(e) => {
                  console.log(e);
                }}
                controlsList="nofullscreen nodownload"
                disablePictureInPicture
              />
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

CompetitionReportEmployerView.propTypes = {
  setPage: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
};

export default CompetitionReportEmployerView;
