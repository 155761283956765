import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  TableFooter,
  Fab,
  Menu,
  Drawer,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Home,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import RichTextEditor from 'components/RichTextEditor';
import getPrivacyTos from 'redux/actions/Common/getPrivacyTos';
import addUpdatePrivacyTos from 'redux/actions/Common/addUpdatePrivacyTos';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PrivacyTos = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [type, setType] = useState('privacy_policy');
  const [privacy, setPrivacy] = useState('');
  const [tos, setTos] = useState('');

  useEffect(() => {
    dispatch(
      getPrivacyTos(type, (data) => {
        console.log(data);
        if (data) {
          if (type === 'privacy_policy') {
            setPrivacy(data);
          } else {
            setTos(data);
          }
        }
      }),
    );
  }, [type]);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'auto',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          p={2}
          position={'relative'}
          gap={2}
          alignItems={'start'}
        >
          <FormControl variant="outlined" size="small">
            <Select
              // ref={promptSelectRef}
              labelId="type-select-label"
              // label="Select Prompt"
              value={type || ''}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <MenuItem value={'privacy_policy'}>Privacy Policy</MenuItem>
              <MenuItem value={'tos'}>Terms of Service</MenuItem>
            </Select>
          </FormControl>
          <RichTextEditor
            height={'calc(100vh - 280px)'}
            value={type === 'tos' ? tos : privacy}
            onChange={(value) => {
              if (type === 'tos') {
                setTos(value);
              } else {
                setPrivacy(value);
              }
            }}
            // readOnly
          />
          <Button
            variant="contained"
            size="small"
            sx={{
              mt: 2,
            }}
            onClick={() => {
              dispatch(
                addUpdatePrivacyTos(
                  type,
                  type === 'privacy_policy' ? privacy : tos,
                  () => {
                    alert('Information updated');
                  },
                ),
              );
            }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Fixed>
  );
};

export default PrivacyTos;
