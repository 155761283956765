import React from 'react';
import { Fixed } from 'layouts';
import { DataGrid } from '@mui/x-data-grid';

// Create a page, with table of all candidate feedback received, sorted desc.
// Show, date and time of feedback, users email, user ID,
// feedback score, things they love, things that can be better,  a link to their contest entries (Task 92)

const columns = [
  { field: 'id', headerName: 'User Id', width: 70 },
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'feedbackScore', headerName: 'Feedback Score', width: 130 },
  { field: 'thingsTheyLove', headerName: 'Things they love', width: 130 },
  {
    field: 'thingsThatCanBeBetter',
    headerName: 'Things that can be better',
    width: 200,
  },
  { field: 'link', headerName: 'Link', width: 130 },
];

const rows = [
  {
    id: 1,
    date: '23/05/2023',
    email: 'mandeep+c7@list2shop.com',
    feedbackScore: 0,
    thingsTheyLove: 'null',
    thingsThatCanBeBetter: 'null',
    link: 'View report',
  },
];

export default function FeedbackView() {
  return (
    <Fixed>
      <DataGrid
        rows={rows}
        columns={columns}
        rowSelection={false}
        disableRowSelectionOnClick={true}
        // hideFooter
      />
    </Fixed>
  );
}
