import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import parse from 'html-react-parser';
import LineGraph from 'components/LineGraph/LineGraph';
import getPractices from 'redux/actions/Common/getPractices';
import { Fixed } from 'layouts';

const columns = [
  {
    field: 'activityType',
    headerName: 'Activity',
    width: 200,
    headerClassName: 'header',
    valueGetter: (params) =>
      params.value
        ? params.value === 'contest'
          ? 'Contest'
          : params.value === 'pitch_practice'
          ? 'Practice Pitch'
          : params.value === 'script_practice'
          ? 'Practice Script'
          : params.value === 'async_interview'
          ? 'Practice Interview'
          : '-'
        : '-',
  },
  {
    field: 'candidateName',
    headerName: 'Name',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'report',
    headerName: 'Report',
    width: 160,
    headerClassName: 'header',
    renderCell: (params) =>
      params?.value?.reportStatus === 'calculated' ? (
        <Button variant="text" color="primary" onClick={params?.value?.onClick}>
          View
        </Button>
      ) : params?.value?.reportStatus === 'in_process' ? (
        'processing'
      ) : (
        '-'
      ),
  },
  {
    field: 'startedOn',
    headerName: 'Started On',
    headerClassName: 'header',
    width: 140,
    renderCell: (params) => {
      return params.value ? (
        <Tooltip
          arrow
          placement="auto"
          title={moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}
        >
          <Typography>{moment(params.value).format('DD MMMM YYYY')}</Typography>
        </Tooltip>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'completedOn',
    headerName: 'Completed On',
    headerClassName: 'header',
    width: 140,
    renderCell: (params) => {
      return params.value ? (
        <Tooltip
          arrow
          placement="auto"
          title={moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}
        >
          <Typography>{moment(params.value).format('DD MMMM YYYY')}</Typography>
        </Tooltip>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: 'header',
    width: 200,
  },
];

const pitchReportColumns = [
  {
    field: 'createdOn',
    headerName: 'Date',
    headerClassName: 'header',
    width: 250,
    valueGetter: (params) =>
      params.value
        ? `${moment(params.value).format('DD MMMM YYYY hh:mm:ss A')}`
        : '-',
  },
  {
    field: 'report',
    headerName: 'Pitch Report',
    width: 160,
    headerClassName: 'header',
    renderCell: (params) =>
      params?.value?.reportStatus === 'calculated' ? (
        <Button variant="text" size="small" onClick={params?.value?.onClick}>
          View
        </Button>
      ) : params?.value?.reportStatus === 'in_process' ? (
        'processing'
      ) : (
        '-'
      ),
  },
  {
    field: 'reportStatus',
    headerName: 'Status',
    headerClassName: 'header',
    width: 200,
  },
];

const Practice = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { practices, fetching } = useSelector((state) => state.allPractices);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();

  useEffect(() => {
    if (fetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetching]);

  useEffect(() => {
    if (practices && Array.isArray(practices) && practices.length > 0) {
      setRows(
        practices.map((p) => {
          return {
            ...p,
            id: p.id,
            activityType: p?.participationType || '-',
            candidateName: `${p?.user?.firstName || ''} ${p?.user?.lastName ||
              ''}`,
            email: p?.user?.email,
            report: {
              participationId: p?.id,
              reportStatus: p?.report?.status,
              onClick: () => {
                history.push({
                  pathname: `/f550aeea-0bb4-41e9-b615-7a6cf101e740/report/${p?.id}`,
                  state: {
                    comingFrom: p?.id,
                    activityType: p?.participationType,
                  },
                });
              },
            },
          };
        }),
      );
    }
  }, [practices]);

  useEffect(() => {
    dispatch(getPractices(null, null, () => {}));
  }, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <Grid container>
          {loading ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <CircularProgress size={20} />
                <Typography variant="body1" color={'text.primary'} mt={4}>
                  Loading all activities ...
                </Typography>
              </Box>
            </Grid>
          ) : rows && Array.isArray(rows) && rows.length > 0 ? (
            <Box
              sx={{
                width: 1,
                '& .header': {
                  background: theme.palette.background.default,
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                rowSelection={false}
                disableRowSelectionOnClick={true}
                // hideFooter
              ></DataGrid>
            </Box>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <Typography variant="body1" color={'text.primary'} mt={4}>
                  There is no activity yet.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Fixed>
  );
};

export default Practice;
