import { v4 as uuidv4 } from 'uuid';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const getContestPrivateLinkToken = (competitionId, type, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_CONTEST_PRIVATE_LINK_TOKEN' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/ads/competitions/${competitionId}/link-token`,
            {
              params: {
                'request-id': reqId,
                sessionId: '43dfdf',
                type: type,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          console.log(response);
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_CONTEST_PRIVATE_LINK_TOKEN_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data, false);
            }
          } else {
            dispatch({
              type: 'FETCHING_CONTEST_PRIVATE_LINK_TOKEN_FAILED',
              payload: `Failed to get private link token`,
            });
            if (typeof callback === 'function') {
              callback(null, true);
            }
          }
        } catch (e) {
          console.log(e);
          dispatch({
            type: 'FETCHING_CONTEST_PRIVATE_LINK_TOKEN_FAILED',
            payload: `Failed to get private link token`,
          });
          if (typeof callback === 'function') {
            callback(null, true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'FETCHING_CONTEST_PRIVATE_LINK_TOKEN_FAILED',
        payload: `Failed to get private link token`,
      });
      if (typeof callback === 'function') {
        callback(null, true);
      }
    }
  };
};

export default getContestPrivateLinkToken;
