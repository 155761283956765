import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import uuid from 'draft-js/lib/uuid';

const excludeParticipation = (
  participationId,
  status,
  callback,
  errCallback,
) => {
  return async (dispatch) => {
    dispatch({ type: 'EXCLUDING_PARTICIPATION' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.put(
          `/ads/participations/${participationId}/exclude`,
          null,
          {
            params: {
              'request-id': uuid(),
              status,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.status === 200) {
          dispatch({
            type: 'EXCLUDING_PARTICIPATION_SUCCESSFUL',
          });
          if (typeof callback === 'function') callback();
        } else {
          dispatch({
            type: 'EXCLUDING_PARTICIPATION_FAILED',
            payload: 'Failed to exclude participation',
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'EXCLUDING_PARTICIPATION_FAILED',
          payload: 'Failed to exclude participation',
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default excludeParticipation;
