import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch } from 'react-redux';
import getAllContests from 'redux/actions/Common/getAllContests';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
} from '@mui/material';
import moment from 'moment';
import {
  CopyAll,
  Delete,
  Edit,
  Group,
  Link,
  PendingActions,
  Redo,
  Replay,
} from '@mui/icons-material';
import deleteContest from 'redux/actions/Common/deleteContest';
import updateContest from 'redux/actions/Common/updateContest';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import getContestPrivateLinkToken from 'redux/actions/Common/getContestPrivateLinkToken';

const Contests = () => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [processing, setProcessing] = useState(false);
  const [contests, setContests] = useState([]);
  const [contestType, setContestType] = useState('all');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [deleteDialogOperation, setDeleteDialogOperation] = useState('delete');
  const [contestVisibility, setContestVisibility] = useState(['published']);

  const dispatch = useDispatch();

  const handleUpdateContestLiveStatus = (e, contestId) => {
    console.log(e.target.checked);
    dispatch(
      updateContest(
        contestId,
        { status: e.target.checked ? 'active' : 'closed' },
        () => {
          dispatch(
            getAllContests(contestType, contestVisibility, (data) =>
              setContests(data),
            ),
          );
          alert('Contest updated');
        },
        (e) => {
          alert(e || 'Failed to update');
        },
      ),
    );
  };

  const handleContestDelete = () => {
    dispatch(
      deleteContest(
        showConfirmDeleteDialog,
        deleteDialogOperation === 'delete',
        () => {
          alert('Operation successful');
          dispatch(
            getAllContests(contestType, contestVisibility, (data) => {
              setContests(data);
              setShowConfirmDeleteDialog(null);
            }),
          );
        },
        (e) => {
          alert(e || 'Failed to delete contest');
          setShowConfirmDeleteDialog(null);
        },
      ),
    );
  };

  useEffect(() => {
    dispatch(
      getAllContests(contestType, contestVisibility, (data) => {
        setContests(data);
      }),
    );
  }, [contestType, contestVisibility]);

  useEffect(() => {
    dispatch(
      getAllContests(contestType, contestVisibility, (data) =>
        setContests(data),
      ),
    );
  }, []);

  useEffect(() => {}, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Box width={300}>
            <FormControl fullWidth>
              <InputLabel id="contest-type-label">Contest Type</InputLabel>
              <Select
                labelId="contest-type-label"
                id="contest-type"
                value={contestType}
                label="Contest Type"
                onChange={(e) => setContestType(e.target.value)}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'private_competition'}>Private</MenuItem>
                <MenuItem value={'real_competition'}>Contest</MenuItem>
                <MenuItem value={'practice_competition'}>Practice</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Visibility</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contestVisibility.indexOf('draft') > -1}
                      onChange={(e) => {
                        const {
                          target: { checked },
                        } = e;
                        if (checked) {
                          setContestVisibility((pre) => {
                            return [...pre, 'draft'];
                          });
                        } else {
                          setContestVisibility((pre) =>
                            pre.filter((v) => v !== 'draft'),
                          );
                        }
                      }}
                      name="draft"
                    />
                  }
                  label="Draft"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contestVisibility.indexOf('published') > -1}
                      onChange={(e) => {
                        const {
                          target: { checked },
                        } = e;
                        console.log(checked);
                        if (checked) {
                          setContestVisibility((pre) => {
                            return [...pre, 'published'];
                          });
                        } else {
                          setContestVisibility((pre) =>
                            pre.filter((v) => v !== 'published'),
                          );
                        }
                      }}
                      name="published"
                    />
                  }
                  label="Published"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contestVisibility.indexOf('deleted') > -1}
                      onChange={(e) => {
                        const {
                          target: { checked },
                        } = e;
                        if (checked) {
                          setContestVisibility((pre) => {
                            return [...pre, 'deleted'];
                          });
                        } else {
                          setContestVisibility((pre) =>
                            pre.filter((v) => v !== 'deleted'),
                          );
                        }
                      }}
                      name="deleted"
                    />
                  }
                  label="Deleted"
                />
              </FormGroup>
            </FormControl>
            {/* <FormControl fullWidth>
              <InputLabel id="contest-visibility-label">Visibility</InputLabel>
              <Select
                labelId="contest-visibility-label"
                id="contest-visibility"
                value={contestVisibility}
                label="Contest Visibility"
                multiple
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setContestVisibility(
                    typeof value === 'string' ? value.split(',') : value,
                  );
                }}
                renderValue={(selected) => selected.join(', ')}
              >
                <MenuItem value={'draft'}>
                  <Checkbox checked={contestVisibility.indexOf('draft') > -1} />
                  <ListItemText primary={'Draft'} />
                </MenuItem>
                <MenuItem value={'published'}>
                  <Checkbox
                    checked={contestVisibility.indexOf('published') > -1}
                  />
                  <ListItemText primary={'Published'} />
                </MenuItem>
                <MenuItem value={'deleted'}>
                  <Checkbox
                    checked={contestVisibility.indexOf('deleted') > -1}
                  />
                  <ListItemText primary={'Deleted'} />
                </MenuItem>
              </Select>
            </FormControl> */}
          </Box>
        </Box>

        {contests && contests.length > 0 ? (
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Contest Title</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Draft</TableCell>
                  <TableCell align="center">Phased</TableCell>
                  <TableCell align="center">Phase Invitation Type</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Created On</TableCell>
                  <TableCell align="center">Contest Entries</TableCell>
                  <TableCell align="center">Start Date</TableCell>
                  <TableCell align="center">End Date</TableCell>
                  <TableCell align="center">Deleted</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contests.map((contest) => (
                  <TableRow
                    key={contest?.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor:
                        contest?.id === state?.contestId ? '#d3d3d3' : 'none',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {contest?.title}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {contest?.competitionType}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {contest?.visibility === 'draft' ? 'true' : 'false'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {contest?.competitionMode === 'phased' ? 'true' : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {contest?.phaseInvitation || '-'}
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        size="small"
                        checked={contest?.status === 'active'}
                        onChange={(e) =>
                          handleUpdateContestLiveStatus(e, contest?.id)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      {moment(contest?.createdOn).format('DD MMMM YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {contest?.maxLimit || '-'}
                    </TableCell>
                    <TableCell align="center">
                      {contest?.startOn
                        ? moment(contest?.startOn).format('DD MMMM YYYY')
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {contest?.endOn
                        ? moment(contest?.endOn).format('DD MMMM YYYY')
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {contest?.deleted.toString()}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          history.push({
                            pathname:
                              '/f550aeea-0bb4-41e9-b615-7a6cf101e740/add-contest',
                            state: {
                              editContest: true,
                              contest: contest,
                            },
                          });
                        }}
                      >
                        <Edit />
                      </IconButton>
                      {contest?.deleted ? (
                        <Tooltip title="Undo Delete" placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              setShowConfirmDeleteDialog(contest?.id);
                              setDeleteDialogOperation('undelete');
                            }}
                          >
                            <Replay />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setShowConfirmDeleteDialog(contest?.id);
                            setDeleteDialogOperation('delete');
                          }}
                        >
                          <Delete />
                        </IconButton>
                      )}
                      {/* {contest?.competitionType === 'private_competition' ? ( */}
                      <Tooltip title="Copy Link" placement="top" arrow>
                        <IconButton
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(
                                `${actionCodeBaseUrl}${
                                  contest?.competitionType ===
                                  'practice_competition'
                                    ? 'practice/'
                                    : ''
                                }contests/${contest?.slug}`,
                              );
                              alert('Link Copied.');
                            } catch (err) {
                              alert('Failed to copy link.');
                              console.error('Failed to copy: ', err);
                            }
                          }}
                        >
                          <Link />
                        </IconButton>
                      </Tooltip>
                      {/* ) : null} */}
                      <Tooltip title="View Participants" placement="top" arrow>
                        <IconButton
                          onClick={() => {
                            history.push(
                              `${pathname}/${contest?.id}/participants`,
                            );
                          }}
                        >
                          <Group />
                        </IconButton>
                      </Tooltip>
                      {contest?.competitionType === 'practice_competition' ? (
                        <Tooltip
                          title="Copy Public Preview Link"
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={async () => {
                              try {
                                await navigator.clipboard.writeText(
                                  `${actionCodeBaseUrl}preview/${
                                    contest?.visibility === 'draft'
                                      ? 'draft'
                                      : contest?.competitionType ===
                                        'practice_competition'
                                      ? 'practice'
                                      : ''
                                  }/start/contests/${contest?.slug}`,
                                );
                                alert('Link Copied.');
                              } catch (err) {
                                alert('Failed to copy link.');
                                console.error('Failed to copy: ', err);
                              }
                            }}
                          >
                            <CopyAll />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      {contest?.competitionType === 'private_competition' ? (
                        <Tooltip
                          title="Generate and copy Private Preview Link"
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={async () => {
                              dispatch(
                                getContestPrivateLinkToken(
                                  contest?.id,
                                  null,
                                  async (data) => {
                                    try {
                                      await navigator.clipboard.writeText(
                                        `${actionCodeBaseUrl}preview/start/contests/epdc/${data}`,
                                      );
                                      alert('Link Copied.');
                                    } catch (err) {
                                      alert('Failed to copy link.');
                                      console.error('Failed to copy: ', err);
                                    }
                                  },
                                ),
                              );
                            }}
                          >
                            <PendingActions />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">{contest?.accessCode}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        <Dialog
          open={Boolean(showConfirmDeleteDialog)}
          onClose={() => setShowConfirmDeleteDialog(null)}
          aria-labelledby="confirm-delete-dialog-title"
          aria-describedby="confirm-delete-dialog-description"
        >
          <DialogTitle id="confirm-delete-dialog-title">
            Are you sure want to{' '}
            {deleteDialogOperation === 'delete' ? 'delete' : 'undo delete'} this
            contest?
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="confirm-delete-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmDeleteDialog(null)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleContestDelete()}
              autoFocus
              color={deleteDialogOperation === 'delete' ? 'error' : 'info'}
            >
              {deleteDialogOperation === 'delete' ? 'DELETE' : 'UNDO DELETE'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Fixed>
  );
};

export default Contests;
