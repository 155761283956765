import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Alert,
  Autocomplete,
  Tabs,
  Tab,
  TableFooter,
  Popper,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assessment,
  Close,
  ContentCopy,
  CopyAll,
  Delete,
  Edit,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
  VoiceChat,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import getAllOrganizations from 'redux/actions/Common/getAllOrganizations';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import updateOrganization from 'redux/actions/Common/updateOrganization';
import { validateEmail } from 'utils';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import generatePlaybook from 'redux/actions/Common/generatePlaybook';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getAllGeneratedPlaybooks from 'redux/actions/Common/getAllGeneratedPlaybooks';
import extractProductDescription from 'redux/actions/Common/extractProductDescription';
import extractCompanyDescription from 'redux/actions/Common/extractCompanyDescription';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchemaPlaybook = yup.object({
  companyWebsite: yup
    .string()
    .trim()
    .required('Please enter company website'),
  productWebsite: yup
    .string()
    .trim()
    .required('atleast one product url is required to generate playbook'),
});

const PlaybookGeneration = ({
  asComponent,
  handlePrefillFromGeneratedPlaybook,
  setShowGeneratePlaybookWithAI,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const {
    playbooks,
    fetching,
    fetchingPagination,
    lastTime,
    emptyList,
  } = useSelector((state) => state.allGeneratedPlaybooks);

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [generatedPlaybook, setGeneratedPlaybook] = useState('');
  const [currentTab, setCurrentTab] = useState('new');
  const [generatingPlaybook, setGeneratingPlaybook] = useState(false);
  const [
    productDescriptionGenerationError,
    setProductDescriptionGenerationError,
  ] = useState(false);

  const [
    companyDescriptionGenerationError,
    setCompanyDescriptionGenerationError,
  ] = useState(false);

  const [companyWebsiteError, setCompanyWebsiteError] = useState(null);
  const [productUrlsError, setProductUrlsError] = useState(null);

  const [errorUrls, setErrorUrls] = useState([]);

  const [
    generatingProductDescription,
    setGeneratingProductDescription,
  ] = useState(false);
  const [
    generatingCompanyDescription,
    setGeneratingCompanyDescription,
  ] = useState(false);

  const [
    generateCompanyDescriptionPopper,
    setGenerateCompanyDescriptionPopper,
  ] = useState(null);
  const openCDPopper = Boolean(generateCompanyDescriptionPopper);
  const [
    generateProductDescriptionPopper,
    setGenerateProductDescriptionPopper,
  ] = useState(null);
  const openPDPopper = Boolean(generateProductDescriptionPopper);

  const [playbookGenerationErrors, setPlaybookGenerationErrors] = useState([]);

  const initialValuesPlaybook = {
    // companyName: '',
    companyWebsite: '',
    // productDescription: '',
    // aboutCompany: '',
    productWebsite: '',
  };

  const onSubmitPlaybook = (values) => {
    setGeneratingPlaybook(true);
    dispatch(
      generatePlaybook(
        {
          aboutCompanyUrls: [values?.companyWebsite],
          productUrls: values?.productWebsite
            ?.split('\n')
            ?.map((url) => url.trim()),
        },
        (data) => {
          setGeneratedPlaybook(data);
          setGeneratingPlaybook(false);
          setPlaybookGenerationErrors([]);
        },
        (error) => {
          console.log(error);
          setGeneratingPlaybook(false);
          setPlaybookGenerationErrors(error?.failedFields || []);
        },
      ),
    );
  };

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        playbooks &&
        playbooks.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllGeneratedPlaybooks(15, lastTime, (data) => {
          // setLinks(data || []);
        }),
      );
    }
  };

  useEffect(() => {
    if (currentTab === 'history') {
      dispatch(getAllGeneratedPlaybooks(15, null, () => {}));
    }
    setGeneratedPlaybook('');
  }, [currentTab]);

  useEffect(() => {
    dispatch(
      getDefaultPrompt((data) => {
        console.log(data);
        setVerticalOptions(data?.verticals);
      }),
    );
  }, []);

  return asComponent ? (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          width: 1,
          minHeight:
            isXs || isSm ? 'calc(100vh - 120px)' : 'calc(100vh - 120px)',
          maxHeight:
            isXs || isSm ? 'calc(100vh - 120px)' : 'calc(100vh - 120px)',
          // border: '1px solid #d3d3d3',
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            border: '1px solid #d3d3d3',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            aria-label="Playbook Generation Tabs"
            orientation={'horizontal'}
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <Tab
              value={'new'}
              label={<Typography variant="body1">Generate Playbook</Typography>}
              id={`simple-tab-${0}`}
              aria-controls={`simple-tabpanel-${0}`}
              sx={{}}
            />
            <Tab
              value={'history'}
              label={<Typography variant="body1">Playbook History</Typography>}
              id={`simple-tab-${0}`}
              aria-controls={`simple-tabpanel-${0}`}
              sx={{}}
            />
          </Tabs>
          <Button
            variant="text"
            size="small"
            startIcon={<Close />}
            onClick={() => setShowGeneratePlaybookWithAI(false)}
            sx={{
              marginRight: 2,
            }}
          >
            Close
          </Button>
        </Box>
        <Box display={'flex'} position={'relative'}>
          {currentTab === 'new' ? (
            <>
              <Box
                flex={1}
                display={'flex'}
                gap={2}
                sx={{
                  width: 1,
                  minHeight:
                    isXs || isSm ? 'calc(100vh - 88px)' : 'calc(100vh - 88px)',
                  maxHeight:
                    isXs || isSm ? 'calc(100vh - 88px)' : 'calc(100vh - 88px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                  padding: 2,
                  borderRight: '1px solid #d3d3d3',
                  position: 'relative',
                }}
              >
                <form onSubmit={formikPlaybook.handleSubmit}>
                  <Grid container spacing={1}>
                    {/* <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1, fontWeight: 'bold' }}
                      >
                        Company Name*
                      </Typography>
                      <TextField
                        placeholder="Enter company name*"
                        variant="outlined"
                        name={'companyName'}
                        fullWidth
                        size="small"
                        value={formikPlaybook.values.companyName}
                        onChange={formikPlaybook.handleChange}
                        error={
                          formikPlaybook.touched.companyName &&
                          Boolean(formikPlaybook.errors.companyName)
                        }
                        helperText={
                          formikPlaybook.touched.companyName &&
                          formikPlaybook.errors.companyName
                        }
                        type={'text'}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Company Website*
                        </span>
                        <br />
                        (About us page is preferred e.g.
                        https://qualification.ai)
                      </Typography>
                      <TextField
                        placeholder="Enter company website*"
                        variant="outlined"
                        name={'companyWebsite'}
                        fullWidth
                        size="small"
                        value={formikPlaybook.values.companyWebsite}
                        onChange={formikPlaybook.handleChange}
                        error={
                          // formikPlaybook.touched.companyWebsite &&
                          Boolean(
                            playbookGenerationErrors.includes('companyName') ||
                              playbookGenerationErrors.includes(
                                'companyDescription',
                              ) ||
                              formikPlaybook.errors.companyWebsite,
                          )
                        }
                        helperText={
                          formikPlaybook.errors.companyWebsite
                            ? 'Please enter valid company website'
                            : playbookGenerationErrors.includes(
                                'companyName',
                              ) ||
                              playbookGenerationErrors.includes(
                                'companyDescription',
                              )
                            ? 'Unable to generate playbook because we could not extract company name or description from the given company website'
                            : ''
                        }
                        onBlur={() => {
                          setCompanyWebsiteError(null);
                        }}
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Product URLs*{' '}
                        </span>
                        <br />
                        (Enter each url in separate line. These urls can be used
                        to generate product description.) e.g.
                        https://qualification.ai
                      </Typography>
                      <TextField
                        placeholder="Enter product urls*"
                        variant="outlined"
                        name={'productWebsite'}
                        fullWidth
                        size="small"
                        multiline
                        rows={3}
                        value={formikPlaybook.values.productWebsite}
                        onChange={formikPlaybook.handleChange}
                        error={Boolean(
                          playbookGenerationErrors.includes(
                            'productDescription',
                          ) || formikPlaybook.errors.productWebsite,
                        )}
                        helperText={
                          formikPlaybook.errors.productWebsite
                            ? 'Please enter atleast one product url'
                            : playbookGenerationErrors.includes(
                                'productDescription',
                              )
                            ? 'Unable to generate playbook because we could not extract product description from the given product urls'
                            : null
                        }
                        type={'text'}
                        onBlur={() => {
                          setProductUrlsError(null);
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        sx={{
                          marginBottom: 1,
                        }}
                      >
                        <Typography
                          variant={'subtitle2'}
                          sx={{ fontWeight: 'bold' }}
                        >
                          About Company*
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            padding: '8px 16px',
                            minWidth: 150,
                          }}
                          onClick={(e) => {
                            setGenerateCompanyDescriptionPopper(e.target);
                          }}
                        >
                          Generate with AI
                        </Button>
                        <Popper
                          open={openCDPopper}
                          anchorEl={generateCompanyDescriptionPopper}
                          sx={{
                            zIndex: 1300,
                          }}
                          placement="bottom"
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                              border: '1px solid #d3d3d3',
                              borderRadius: 1,
                              padding: 2,
                              background: '#fff',
                              minWidth: 300,
                            }}
                          >
                            <Typography
                              variant={'subtitle2'}
                              sx={{ marginBottom: 1 }}
                            >
                              <span style={{ fontWeight: 'bold' }}>
                                Company Website*{' '}
                              </span>
                              <br />
                              (Required to generate about company with AI) e.g.
                              https://qualification.ai
                            </Typography>
                            <TextField
                              placeholder="Enter company website*"
                              variant="outlined"
                              name={'companyWebsite'}
                              fullWidth
                              size="small"
                              value={formikPlaybook.values.companyWebsite}
                              onChange={formikPlaybook.handleChange}
                              error={
                                // formikPlaybook.touched.companyWebsite &&
                                Boolean(companyWebsiteError)
                              }
                              helperText={
                                // formikPlaybook.touched.companyWebsite &&
                                companyWebsiteError
                              }
                              onBlur={() => {
                                setCompanyWebsiteError(null);
                              }}
                              type={'text'}
                            />
                            {companyDescriptionGenerationError ? (
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                              >
                                <Close color="error" sx={{ fontSize: 20 }} />
                                <Typography variant="caption" color={'error'}>
                                  Failed to generate company description using
                                  company website
                                </Typography>
                              </Box>
                            ) : null}
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              gap={1}
                            >
                              <Button
                                startIcon={<Close />}
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                  setGenerateCompanyDescriptionPopper(null)
                                }
                              >
                                Close
                              </Button>
                              <Tooltip
                                arrow
                                placement="top"
                                title={
                                  !formikPlaybook.values.companyWebsite.trim()
                                    ? 'Please provide company website to generate company description with AI'
                                    : ''
                                }
                              >
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled={generatingCompanyDescription}
                                  startIcon={
                                    generatingCompanyDescription ? (
                                      <CircularProgress size={20} />
                                    ) : null
                                  }
                                  onClick={() => {
                                    if (
                                      formikPlaybook.values.companyWebsite.trim()
                                    ) {
                                      setGeneratingCompanyDescription(true);
                                      dispatch(
                                        extractCompanyDescription(
                                          [
                                            formikPlaybook?.values
                                              ?.companyWebsite,
                                          ],
                                          (data) => {
                                            console.log(data);
                                            if (data?.description) {
                                              formikPlaybook.setFieldValue(
                                                'aboutCompany',
                                                data?.description,
                                              );
                                              setCompanyDescriptionGenerationError(
                                                false,
                                              );
                                              setGenerateCompanyDescriptionPopper(
                                                null,
                                              );
                                              formikPlaybook.setFieldValue(
                                                'companyWebsite',
                                                '',
                                              );
                                            } else {
                                              setCompanyDescriptionGenerationError(
                                                true,
                                              );
                                            }
                                            setGeneratingCompanyDescription(
                                              false,
                                            );
                                          },
                                          () => {
                                            setGeneratingCompanyDescription(
                                              false,
                                            );
                                            setCompanyDescriptionGenerationError(
                                              true,
                                            );
                                          },
                                        ),
                                      );
                                    } else {
                                      setCompanyWebsiteError(
                                        'Please provide company website to generate company description with AI',
                                      );
                                    }
                                  }}
                                >
                                  Generate with AI
                                </Button>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Popper>
                      </Box>
                      <TextField
                        placeholder="Enter about company*"
                        variant="outlined"
                        name={'aboutCompany'}
                        fullWidth
                        size="small"
                        multiline
                        rows={6}
                        value={formikPlaybook.values.aboutCompany}
                        onChange={formikPlaybook.handleChange}
                        error={
                          formikPlaybook.touched.aboutCompany &&
                          Boolean(formikPlaybook.errors.aboutCompany)
                        }
                        helperText={
                          formikPlaybook.touched.aboutCompany &&
                          formikPlaybook.errors.aboutCompany
                        }
                        type={'text'}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Typography
                          variant={'subtitle2'}
                          sx={{ marginBottom: 1 }}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            Product Description*{' '}
                          </span>
                          <br />
                          (Please describe your product in detail. Please also
                          add URLs to specific webpages and shared documents if
                          any.)
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            padding: '8px 16px',
                            minWidth: 150,
                          }}
                          onClick={(e) => {
                            setGenerateProductDescriptionPopper(e.target);
                          }}
                        >
                          Generate with AI
                        </Button>
                        <Popper
                          open={openPDPopper}
                          anchorEl={generateProductDescriptionPopper}
                          sx={{
                            zIndex: 1300,
                          }}
                          placement="top"
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                              border: '1px solid #d3d3d3',
                              borderRadius: 1,
                              padding: 2,
                              background: '#fff',
                              minWidth: 300,
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                variant={'subtitle2'}
                                sx={{ marginBottom: 1 }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  Product URLs*{' '}
                                </span>
                                <br />
                                (Enter each url in separate line. These urls can
                                be used to generate product description.) e.g.
                                https://qualification.ai
                              </Typography>
                            </Box>
                            <TextField
                              placeholder="Enter product urls*"
                              variant="outlined"
                              name={'productWebsite'}
                              fullWidth
                              size="small"
                              multiline
                              rows={3}
                              value={formikPlaybook.values.productWebsite}
                              onChange={formikPlaybook.handleChange}
                              error={Boolean(productUrlsError)}
                              helperText={productUrlsError}
                              type={'text'}
                              onBlur={() => {
                                setProductUrlsError(null);
                              }}
                            />
                            {productDescriptionGenerationError &&
                            errorUrls &&
                            errorUrls.length > 0 ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                // gap={1}
                              >
                                <Typography variant="caption" color={'error'}>
                                  Failed to generate product description using
                                  given urls
                                </Typography>
                                {errorUrls && errorUrls?.length > 0 ? (
                                  <Box
                                    // gap={1}
                                    display={'flex'}
                                    flexDirection={'column'}
                                  >
                                    {errorUrls?.map((url) => {
                                      return (
                                        <Box
                                          key={url}
                                          flex={1}
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            borderRadius: 1,
                                          }}
                                        >
                                          <Close
                                            color="error"
                                            sx={{ fontSize: 20 }}
                                          />
                                          <Typography
                                            variant="caption"
                                            color={'error'}
                                          >
                                            {url}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                ) : null}
                              </Box>
                            ) : null}
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              gap={1}
                            >
                              <Button
                                startIcon={<Close />}
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                  setGenerateProductDescriptionPopper(null)
                                }
                              >
                                Close
                              </Button>
                              <Tooltip
                                arrow
                                placement="top"
                                title={
                                  !formikPlaybook.values.productWebsite.trim()
                                    ? 'Please provide atleast one product url to generate product description with AI'
                                    : ''
                                }
                              >
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled={generatingProductDescription}
                                  startIcon={
                                    generatingProductDescription ? (
                                      <CircularProgress size={20} />
                                    ) : null
                                  }
                                  sx={{
                                    padding: '8px 16px',
                                    minWidth: 150,
                                  }}
                                  onClick={() => {
                                    if (
                                      formikPlaybook.values.productWebsite.trim()
                                    ) {
                                      setGeneratingProductDescription(true);
                                      dispatch(
                                        extractProductDescription(
                                          formikPlaybook?.values?.productWebsite
                                            ?.split('\n')
                                            ?.map((url) => url.trim()),
                                          (data) => {
                                            console.log(data);
                                            if (data?.description) {
                                              formikPlaybook.setFieldValue(
                                                'productDescription',
                                                data?.description,
                                              );
                                              setProductDescriptionGenerationError(
                                                false,
                                              );
                                              setErrorUrls(
                                                Object.keys(data?.error || {}),
                                              );
                                              setGenerateProductDescriptionPopper(
                                                null,
                                              );
                                              formikPlaybook.setFieldValue(
                                                'productWebsite',
                                                '',
                                              );
                                            } else {
                                              setProductDescriptionGenerationError(
                                                true,
                                              );
                                              setErrorUrls(
                                                Object.keys(data?.error || {}),
                                              );
                                            }
                                            setGeneratingProductDescription(
                                              false,
                                            );
                                          },
                                          () => {
                                            setGeneratingProductDescription(
                                              false,
                                            );
                                            setProductDescriptionGenerationError(
                                              true,
                                            );
                                          },
                                        ),
                                      );
                                    } else {
                                      setProductUrlsError(
                                        'Please provide atleast one product url to generate product description with AI',
                                      );
                                    }
                                  }}
                                >
                                  Generate with AI
                                </Button>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Popper>
                      </Box>
                      {errorUrls && errorUrls?.length > 0 ? (
                        <Box
                          // gap={1}
                          display={'flex'}
                          flexDirection={'column'}
                        >
                          <Typography variant="caption" color={'error'}>
                            Failed to generate product description using
                            following urls
                          </Typography>
                          {errorUrls?.map((url) => {
                            return (
                              <Box
                                key={url}
                                flex={1}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  borderRadius: 1,
                                }}
                              >
                                <Close color="error" sx={{ fontSize: 20 }} />
                                <Typography variant="caption" color={'error'}>
                                  {url}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      ) : null}
                      <TextField
                        placeholder="Enter product description*"
                        variant="outlined"
                        name={'productDescription'}
                        fullWidth
                        size="small"
                        multiline
                        rows={6}
                        value={formikPlaybook.values.productDescription}
                        onChange={formikPlaybook.handleChange}
                        error={
                          formikPlaybook.touched.productDescription &&
                          Boolean(formikPlaybook.errors.productDescription)
                        }
                        helperText={
                          formikPlaybook.touched.productDescription &&
                          formikPlaybook.errors.productDescription
                        }
                        type="text"
                        sx={{ mt: 1 }}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        startIcon={
                          generatingPlaybook ? (
                            <CircularProgress size={20} />
                          ) : null
                        }
                        variant="contained"
                        disabled={generatingPlaybook}
                        type="submit"
                        sx={{
                          mb: 2,
                        }}
                      >
                        {'Generate Playbook'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
              <Box
                flex={1}
                sx={{
                  width: 1,
                  minHeight:
                    isXs || isSm
                      ? 'calc(100vh - 148px)'
                      : 'calc(100vh - 148px)',
                  maxHeight:
                    isXs || isSm
                      ? 'calc(100vh - 148px)'
                      : 'calc(100vh - 148px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                  paddingBottom: 8,
                  position: 'relative',
                }}
              >
                {generatedPlaybook ? (
                  <>
                    <RichTextEditor
                      value={generatedPlaybook?.playbookText}
                      // onChange={() => {}}
                      readOnly
                    />
                  </>
                ) : null}
              </Box>
            </>
          ) : (
            <>
              <Box flex={1} display={'flex'} gap={2}>
                <TableContainer
                  // component={Paper}
                  sx={{
                    width: 1,
                    minHeight: 'calc(100vh - 88px)',
                    maxHeight: 'calc(100vh - 88px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    borderRight: '1px solid #d3d3d3',
                  }}
                  onScroll={handleListScroll}
                >
                  <Table
                    // sx={{ minWidth: 750 }}
                    stickyHeader
                    aria-label="simple table"
                  >
                    <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                          >
                            ID
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                            align="center"
                          >
                            DATE
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant={'caption'}
                            fontWeight={700}
                            sx={{ textTransform: 'uppercase' }}
                            align="center"
                          >
                            ACTIONS
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {playbooks.map((pb, i) => (
                        <TableRow
                          key={i}
                          // sx={{
                          //   '&:last-child td, &:last-child th': {
                          //     border: 0,
                          //   },
                          // }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography variant={'subtitle2'} fontWeight={700}>
                              {pb?.id}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={'caption'}>
                              {moment(pb?.createdOn).format('DD MMMM YYYY')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Tooltip
                                title="view playbook"
                                placement="left"
                                arrow
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  sx={{ marginLeft: 1 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setGeneratedPlaybook(pb?.playbook);
                                  }}
                                >
                                  View
                                </Button>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          variant="footer"
                          colSpan={6}
                          sx={{
                            padding: 0,
                          }}
                        >
                          {fetchingPagination ? (
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 1,
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                // type="submit"
                                color="primary"
                                onClick={handleListScroll}
                                // className="searchSettings"
                                disabled
                                // fullWidth
                              >
                                <Typography color="textPrimary">
                                  Loading more playbooks ...{' '}
                                </Typography>
                                <CircularProgress size={20} />
                              </Button>
                            </Box>
                          ) : emptyList === false && playbooks.length >= 10 ? (
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 1,
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                size="small"
                                variant="contained"
                                // type="submit"
                                // fullWidth
                                color="primary"
                                onClick={(e) => handleListScroll(e, true)}
                                // className="searchSettings"
                              >
                                Load More
                              </Button>
                            </Box>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                flex={1}
                sx={{
                  width: 1,
                  minHeight:
                    isXs || isSm
                      ? 'calc(100vh - 148px)'
                      : 'calc(100vh - 148px)',
                  maxHeight:
                    isXs || isSm
                      ? 'calc(100vh - 148px)'
                      : 'calc(100vh - 148px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                  paddingBottom: 8,
                }}
              >
                {generatedPlaybook ? (
                  <RichTextEditor
                    value={generatedPlaybook?.playbookText}
                    // onChange={() => {}}
                    readOnly
                  />
                ) : null}
              </Box>
            </>
          )}
          {generatedPlaybook ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0.5,
                display: 'flex',
                justifyContent: 'flex-end',
                p: 1,
                background: '#fff',
                borderTop: '1px solid #d3d3d3',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  handlePrefillFromGeneratedPlaybook(generatedPlaybook);
                }}
              >
                Use this
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  ) : (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
            sx={{
              width: 1,
            }}
          >
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              aria-label="Playbook Generation Tabs"
              orientation={'horizontal'}
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{
                border: '1px solid #d3d3d3',
              }}
            >
              <Tab
                value={'new'}
                label={
                  <Typography variant="body1">Generate Playbook</Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
              <Tab
                value={'history'}
                label={
                  <Typography variant="body1">Playbook History</Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
            </Tabs>
            <Box display={'flex'}>
              {currentTab === 'new' ? (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    alignItems={'start'}
                    sx={{
                      flex: 1,
                      padding: 2,
                      borderRight: '1px solid #d3d3d3',
                      position: 'relative',
                      minHeight:
                        isXs || isSm
                          ? 'calc(100vh - 130px)'
                          : 'calc(100vh - 130px)',
                      maxHeight:
                        isXs || isSm
                          ? 'calc(100vh - 130px)'
                          : 'calc(100vh - 130px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                    }}
                  >
                    <Box>
                      <form onSubmit={formikPlaybook.handleSubmit}>
                        <Grid container spacing={1}>
                          {/* <Grid item xs={12}>
                            <Typography
                              variant={'subtitle2'}
                              sx={{ marginBottom: 1, fontWeight: 'bold' }}
                            >
                              Company Name*
                            </Typography>
                            <TextField
                              placeholder="Enter company name*"
                              variant="outlined"
                              name={'companyName'}
                              fullWidth
                              size="small"
                              value={formikPlaybook.values.companyName}
                              onChange={formikPlaybook.handleChange}
                              error={
                                formikPlaybook.touched.companyName &&
                                Boolean(formikPlaybook.errors.companyName)
                              }
                              helperText={
                                formikPlaybook.touched.companyName &&
                                formikPlaybook.errors.companyName
                              }
                              type={'text'}
                            />
                          </Grid> */}
                          {/* <Grid item xs={12}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              sx={{
                                marginBottom: 1,
                              }}
                            >
                              <Typography
                                variant={'subtitle2'}
                                sx={{ fontWeight: 'bold' }}
                              >
                                About Company*
                              </Typography>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                  setGenerateCompanyDescriptionPopper(e.target);
                                }}
                              >
                                Generate with AI
                              </Button>
                              <Popper
                                open={openCDPopper}
                                anchorEl={generateCompanyDescriptionPopper}
                                sx={{
                                  zIndex: 1300,
                                }}
                                placement="bottom"
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    border: '1px solid #d3d3d3',
                                    borderRadius: 1,
                                    padding: 2,
                                    background: '#fff',
                                    minWidth: 300,
                                  }}
                                >
                                  <Typography
                                    variant={'subtitle2'}
                                    sx={{ marginBottom: 1 }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>
                                      Company Website*{' '}
                                    </span>
                                    <br />
                                    (Required to generate about company with AI)
                                    e.g. https://qualification.ai
                                  </Typography>
                                  <TextField
                                    placeholder="Enter company website*"
                                    variant="outlined"
                                    name={'companyWebsite'}
                                    fullWidth
                                    size="small"
                                    value={formikPlaybook.values.companyWebsite}
                                    onChange={formikPlaybook.handleChange}
                                    error={
                                      // formikPlaybook.touched.companyWebsite &&
                                      Boolean(companyWebsiteError)
                                    }
                                    helperText={
                                      // formikPlaybook.touched.companyWebsite &&
                                      companyWebsiteError
                                    }
                                    onBlur={() => {
                                      setCompanyWebsiteError(null);
                                    }}
                                    type={'text'}
                                  />
                                  {companyDescriptionGenerationError ? (
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                    >
                                      <Close
                                        color="error"
                                        sx={{ fontSize: 20 }}
                                      />
                                      <Typography
                                        variant="caption"
                                        color={'error'}
                                      >
                                        Failed to generate company description
                                        using company website
                                      </Typography>
                                    </Box>
                                  ) : null}
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    gap={1}
                                  >
                                    <Button
                                      startIcon={<Close />}
                                      variant="outlined"
                                      size="small"
                                      onClick={() =>
                                        setGenerateCompanyDescriptionPopper(
                                          null,
                                        )
                                      }
                                    >
                                      Close
                                    </Button>
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={
                                        !formikPlaybook.values.companyWebsite.trim()
                                          ? 'Please provide company website to generate company description with AI'
                                          : ''
                                      }
                                    >
                                      <Button
                                        variant="contained"
                                        size="small"
                                        disabled={generatingCompanyDescription}
                                        startIcon={
                                          generatingCompanyDescription ? (
                                            <CircularProgress size={20} />
                                          ) : null
                                        }
                                        onClick={() => {
                                          if (
                                            formikPlaybook.values.companyWebsite.trim()
                                          ) {
                                            setGeneratingCompanyDescription(
                                              true,
                                            );
                                            dispatch(
                                              extractCompanyDescription(
                                                [
                                                  formikPlaybook?.values
                                                    ?.companyWebsite,
                                                ],
                                                (data) => {
                                                  console.log(data);
                                                  if (data?.description) {
                                                    formikPlaybook.setFieldValue(
                                                      'aboutCompany',
                                                      data?.description,
                                                    );
                                                    setCompanyDescriptionGenerationError(
                                                      false,
                                                    );
                                                    setGenerateCompanyDescriptionPopper(
                                                      null,
                                                    );
                                                    formikPlaybook.setFieldValue(
                                                      'companyWebsite',
                                                      '',
                                                    );
                                                  } else {
                                                    setCompanyDescriptionGenerationError(
                                                      true,
                                                    );
                                                  }
                                                  setGeneratingCompanyDescription(
                                                    false,
                                                  );
                                                },
                                                () => {
                                                  setGeneratingCompanyDescription(
                                                    false,
                                                  );
                                                  setCompanyDescriptionGenerationError(
                                                    true,
                                                  );
                                                },
                                              ),
                                            );
                                          } else {
                                            setCompanyWebsiteError(
                                              'Please provide company website to generate company description with AI',
                                            );
                                          }
                                        }}
                                      >
                                        Generate with AI
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Popper>
                            </Box>
                            <TextField
                              placeholder="Enter about company*"
                              variant="outlined"
                              name={'aboutCompany'}
                              fullWidth
                              size="small"
                              multiline
                              rows={6}
                              value={formikPlaybook.values.aboutCompany}
                              onChange={formikPlaybook.handleChange}
                              error={
                                formikPlaybook.touched.aboutCompany &&
                                Boolean(formikPlaybook.errors.aboutCompany)
                              }
                              helperText={
                                formikPlaybook.touched.aboutCompany &&
                                formikPlaybook.errors.aboutCompany
                              }
                              type={'text'}
                            />
                          </Grid> */}
                          {/* <Grid item xs={12}>
                            <Box display={'flex'} flexDirection={'column'}>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Typography
                                  variant={'subtitle2'}
                                  sx={{ marginBottom: 1 }}
                                >
                                  <span style={{ fontWeight: 'bold' }}>
                                    Product Description*{' '}
                                  </span>
                                  <br />
                                  (Please describe your product in detail.
                                  Please also add URLs to specific webpages and
                                  shared documents if any.)
                                </Typography>
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    padding: '8px 16px',
                                    minWidth: 150,
                                  }}
                                  onClick={(e) => {
                                    setGenerateProductDescriptionPopper(
                                      e.target,
                                    );
                                  }}
                                >
                                  Generate with AI
                                </Button>
                                <Popper
                                  open={openPDPopper}
                                  anchorEl={generateProductDescriptionPopper}
                                  sx={{
                                    zIndex: 1300,
                                  }}
                                  placement="top"
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 1,
                                      border: '1px solid #d3d3d3',
                                      borderRadius: 1,
                                      padding: 2,
                                      background: '#fff',
                                      minWidth: 300,
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        sx={{ marginBottom: 1 }}
                                      >
                                        <span style={{ fontWeight: 'bold' }}>
                                          Product URLs*{' '}
                                        </span>
                                        <br />
                                        (Enter each url in separate line. These
                                        urls can be used to generate product
                                        description.) e.g.
                                        https://qualification.ai
                                      </Typography>
                                    </Box>
                                    <TextField
                                      placeholder="Enter product urls*"
                                      variant="outlined"
                                      name={'productWebsite'}
                                      fullWidth
                                      size="small"
                                      multiline
                                      rows={3}
                                      value={
                                        formikPlaybook.values.productWebsite
                                      }
                                      onChange={formikPlaybook.handleChange}
                                      error={Boolean(productUrlsError)}
                                      helperText={productUrlsError}
                                      type={'text'}
                                      onBlur={() => {
                                        setProductUrlsError(null);
                                      }}
                                    />
                                    {productDescriptionGenerationError &&
                                    errorUrls &&
                                    errorUrls.length > 0 ? (
                                      <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        // gap={1}
                                      >
                                        <Typography
                                          variant="caption"
                                          color={'error'}
                                        >
                                          Failed to generate product description
                                          using given urls
                                        </Typography>
                                        {errorUrls && errorUrls?.length > 0 ? (
                                          <Box
                                            // gap={1}
                                            display={'flex'}
                                            flexDirection={'column'}
                                          >
                                            {errorUrls?.map((url) => {
                                              return (
                                                <Box
                                                  key={url}
                                                  flex={1}
                                                  sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    borderRadius: 1,
                                                  }}
                                                >
                                                  <Close
                                                    color="error"
                                                    sx={{ fontSize: 20 }}
                                                  />
                                                  <Typography
                                                    variant="caption"
                                                    color={'error'}
                                                  >
                                                    {url}
                                                  </Typography>
                                                </Box>
                                              );
                                            })}
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ) : null}
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                      gap={1}
                                    >
                                      <Button
                                        startIcon={<Close />}
                                        variant="outlined"
                                        size="small"
                                        onClick={() =>
                                          setGenerateProductDescriptionPopper(
                                            null,
                                          )
                                        }
                                      >
                                        Close
                                      </Button>
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title={
                                          !formikPlaybook.values.productWebsite.trim()
                                            ? 'Please provide atleast one product url to generate product description with AI'
                                            : ''
                                        }
                                      >
                                        <Button
                                          variant="contained"
                                          size="small"
                                          disabled={
                                            generatingProductDescription
                                          }
                                          startIcon={
                                            generatingProductDescription ? (
                                              <CircularProgress size={20} />
                                            ) : null
                                          }
                                          sx={{
                                            padding: '8px 16px',
                                            minWidth: 150,
                                          }}
                                          onClick={() => {
                                            if (
                                              formikPlaybook.values.productWebsite.trim()
                                            ) {
                                              setGeneratingProductDescription(
                                                true,
                                              );
                                              dispatch(
                                                extractProductDescription(
                                                  formikPlaybook?.values?.productWebsite
                                                    ?.split('\n')
                                                    ?.map((url) => url.trim()),
                                                  (data) => {
                                                    console.log(data);
                                                    if (data?.description) {
                                                      formikPlaybook.setFieldValue(
                                                        'productDescription',
                                                        data?.description,
                                                      );
                                                      setProductDescriptionGenerationError(
                                                        false,
                                                      );
                                                      setErrorUrls(
                                                        Object.keys(
                                                          data?.error || {},
                                                        ),
                                                      );
                                                      setGenerateProductDescriptionPopper(
                                                        null,
                                                      );
                                                      formikPlaybook.setFieldValue(
                                                        'productWebsite',
                                                        '',
                                                      );
                                                    } else {
                                                      setProductDescriptionGenerationError(
                                                        true,
                                                      );
                                                      setErrorUrls(
                                                        Object.keys(
                                                          data?.error || {},
                                                        ),
                                                      );
                                                    }
                                                    setGeneratingProductDescription(
                                                      false,
                                                    );
                                                  },
                                                  () => {
                                                    setGeneratingProductDescription(
                                                      false,
                                                    );
                                                    setProductDescriptionGenerationError(
                                                      true,
                                                    );
                                                  },
                                                ),
                                              );
                                            } else {
                                              setProductUrlsError(
                                                'Please provide atleast one product url to generate product description with AI',
                                              );
                                            }
                                          }}
                                        >
                                          Generate with AI
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </Popper>
                              </Box>
                              {errorUrls && errorUrls?.length > 0 ? (
                                <Box
                                  // gap={1}
                                  display={'flex'}
                                  flexDirection={'column'}
                                >
                                  <Typography variant="caption" color={'error'}>
                                    Failed to generate product description using
                                    following urls
                                  </Typography>
                                  {errorUrls?.map((url) => {
                                    return (
                                      <Box
                                        key={url}
                                        flex={1}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          borderRadius: 1,
                                        }}
                                      >
                                        <Close
                                          color="error"
                                          sx={{ fontSize: 20 }}
                                        />
                                        <Typography
                                          variant="caption"
                                          color={'error'}
                                        >
                                          {url}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              ) : null}
                              <TextField
                                placeholder="Enter product description*"
                                variant="outlined"
                                name={'productDescription'}
                                fullWidth
                                size="small"
                                multiline
                                rows={6}
                                value={formikPlaybook.values.productDescription}
                                onChange={formikPlaybook.handleChange}
                                error={
                                  formikPlaybook.touched.productDescription &&
                                  Boolean(
                                    formikPlaybook.errors.productDescription,
                                  )
                                }
                                helperText={
                                  formikPlaybook.touched.productDescription &&
                                  formikPlaybook.errors.productDescription
                                }
                                type="text"
                                sx={{ mt: 1 }}
                              />
                            </Box>
                          </Grid> */}
                          <Grid item xs={12}>
                            <Typography
                              variant={'subtitle2'}
                              sx={{ marginBottom: 1 }}
                            >
                              <span style={{ fontWeight: 'bold' }}>
                                Company Website*
                              </span>
                              <br />
                              (About us page is preferred e.g.
                              https://qualification.ai)
                            </Typography>
                            <TextField
                              placeholder="Enter company website*"
                              variant="outlined"
                              name={'companyWebsite'}
                              fullWidth
                              size="small"
                              value={formikPlaybook.values.companyWebsite}
                              onChange={formikPlaybook.handleChange}
                              error={
                                // formikPlaybook.touched.companyWebsite &&
                                Boolean(
                                  playbookGenerationErrors.includes(
                                    'companyName',
                                  ) ||
                                    playbookGenerationErrors.includes(
                                      'companyDescription',
                                    ) ||
                                    formikPlaybook.errors.companyWebsite,
                                )
                              }
                              helperText={
                                formikPlaybook.errors.companyWebsite
                                  ? 'Please enter valid company website'
                                  : playbookGenerationErrors.includes(
                                      'companyName',
                                    ) ||
                                    playbookGenerationErrors.includes(
                                      'companyDescription',
                                    )
                                  ? 'Unable to generate playbook because we could not extract company name or description from the given company website'
                                  : ''
                              }
                              onBlur={() => {
                                setCompanyWebsiteError(null);
                              }}
                              type={'text'}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant={'subtitle2'}
                              sx={{ marginBottom: 1 }}
                            >
                              <span style={{ fontWeight: 'bold' }}>
                                Product URLs*{' '}
                              </span>
                              <br />
                              (Enter each url in separate line. These urls can
                              be used to generate product description.) e.g.
                              https://qualification.ai
                            </Typography>
                            <TextField
                              placeholder="Enter product urls*"
                              variant="outlined"
                              name={'productWebsite'}
                              fullWidth
                              size="small"
                              multiline
                              rows={3}
                              value={formikPlaybook.values.productWebsite}
                              onChange={formikPlaybook.handleChange}
                              error={Boolean(
                                playbookGenerationErrors.includes(
                                  'productDescription',
                                ) || formikPlaybook.errors.productWebsite,
                              )}
                              helperText={
                                formikPlaybook.errors.productWebsite
                                  ? 'Please enter atleast one product url'
                                  : playbookGenerationErrors.includes(
                                      'productDescription',
                                    )
                                  ? 'Unable to generate playbook because we could not extract product description from the given product urls'
                                  : null
                              }
                              type={'text'}
                              onBlur={() => {
                                setProductUrlsError(null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              startIcon={
                                generatingPlaybook ? (
                                  <CircularProgress size={20} />
                                ) : null
                              }
                              variant="contained"
                              disabled={
                                generatingPlaybook ||
                                generatingProductDescription
                              }
                              type="submit"
                            >
                              {'Generate Playbook'}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                  <Box
                    flex={1}
                    sx={{
                      flex: 1,
                      padding: 2,
                      borderRight: '1px solid #d3d3d3',
                      position: 'relative',
                      minHeight:
                        isXs || isSm
                          ? 'calc(100vh - 130px)'
                          : 'calc(100vh - 130px)',
                      maxHeight:
                        isXs || isSm
                          ? 'calc(100vh - 130px)'
                          : 'calc(100vh - 130px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                    }}
                  >
                    {generatedPlaybook ? (
                      <RichTextEditor
                        value={generatedPlaybook?.playbookText}
                        // onChange={() => {}}
                        readOnly
                      />
                    ) : null}
                  </Box>
                </>
              ) : (
                <>
                  <Box flex={1} display={'flex'} gap={2}>
                    <TableContainer
                      // component={Paper}
                      sx={{
                        width: 1,
                        minHeight: 'calc(100vh - 130px)',
                        maxHeight: 'calc(100vh - 130px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                        },
                        borderRight: '1px solid #d3d3d3',
                      }}
                      onScroll={handleListScroll}
                    >
                      <Table
                        // sx={{ minWidth: 750 }}
                        stickyHeader
                        aria-label="simple table"
                      >
                        <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                ID
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                                align="center"
                              >
                                DATE
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                                align="center"
                              >
                                ACTIONS
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {playbooks.map((pb, i) => (
                            <TableRow
                              key={i}
                              // sx={{
                              //   '&:last-child td, &:last-child th': {
                              //     border: 0,
                              //   },
                              // }}
                            >
                              <TableCell component="th" scope="row">
                                <Typography
                                  variant={'subtitle2'}
                                  fontWeight={700}
                                >
                                  {pb?.id}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant={'caption'}>
                                  {moment(pb?.createdOn).format('DD MMMM YYYY')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Tooltip
                                    title="view playbook"
                                    placement="left"
                                    arrow
                                  >
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="primary"
                                      sx={{ marginLeft: 1 }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setGeneratedPlaybook(pb?.playbook);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell
                              variant="footer"
                              colSpan={6}
                              sx={{
                                padding: 0,
                              }}
                            >
                              {fetchingPagination ? (
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 1,
                                    textAlign: 'center',
                                  }}
                                >
                                  <Button
                                    size="small"
                                    variant="contained"
                                    // type="submit"
                                    color="primary"
                                    onClick={handleListScroll}
                                    // className="searchSettings"
                                    disabled
                                    // fullWidth
                                  >
                                    <Typography color="textPrimary">
                                      Loading more playbooks ...{' '}
                                    </Typography>
                                    <CircularProgress size={20} />
                                  </Button>
                                </Box>
                              ) : emptyList === false &&
                                playbooks.length >= 10 ? (
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 1,
                                    textAlign: 'center',
                                  }}
                                >
                                  <Button
                                    size="small"
                                    variant="contained"
                                    // type="submit"
                                    // fullWidth
                                    color="primary"
                                    onClick={(e) => handleListScroll(e, true)}
                                    // className="searchSettings"
                                  >
                                    Load More
                                  </Button>
                                </Box>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box
                    flex={1}
                    sx={{
                      flex: 1,
                      padding: 2,
                      borderRight: '1px solid #d3d3d3',
                      position: 'relative',
                      minHeight:
                        isXs || isSm
                          ? 'calc(100vh - 130px)'
                          : 'calc(100vh - 130px)',
                      maxHeight:
                        isXs || isSm
                          ? 'calc(100vh - 130px)'
                          : 'calc(100vh - 130px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                    }}
                  >
                    {generatedPlaybook ? (
                      <RichTextEditor
                        value={generatedPlaybook?.playbookText}
                        // onChange={() => {}}
                        readOnly
                      />
                    ) : null}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fixed>
  );
};

export default PlaybookGeneration;
